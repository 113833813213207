import * as moment from "moment";
import * as uuid from "uuid";

import { AddEntryAddEntry, AddEntryVariables, GetChildrenNamesChildren, GetChildrenNamesQuery } from "@models/graphql/types";
import { GraphQL } from "@graphql/graphQL";

import { OperationQueueEntry } from "../queueLink";
import { HandleMutationResult, MutationHandler } from "./mutationHandler";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";

export class AddEntryHandler extends MutationHandler {
    public mutationName: string = "addEntry";
    private children: GetChildrenNamesChildren[];

    constructor(private client: ApolloClient<NormalizedCacheObject>) {
        super();
        this.initialize();
    }

    public handleMutation(observer: ZenObservable.SubscriptionObserver<any>, operationQueueEntry: OperationQueueEntry): HandleMutationResult {

        const { operation } = operationQueueEntry;

        const vars = operation.variables as AddEntryVariables;

        const childMatch = this.children.find(child => child.code === vars.childCode);

        if (!childMatch) {
            const returnVal: AddEntryAddEntry = {
                __typename: "AddEntryResult",
                childNotFound: true
            };

            observer.next({ data: { addEntry: returnVal } });

            return {
                enqueueMutation: false
            };
        } else {

            const returnVal: AddEntryAddEntry = {
                __typename: "AddEntryResult",
                registration: {
                    __typename: "Registration",
                    child: {
                        firstName: childMatch.firstName,
                        lastName: childMatch.lastName,
                        id: childMatch.id,
                        __typename: "Child"
                    },
                    date: moment().toISOString(),
                    entries: [
                        {
                            __typename: "Entry",
                            time: moment().toISOString(),
                            id: -1,
                            order: 0,
                            type: vars.type
                        }
                    ],
                    id: uuid.v1() as any,
                    valid: true
                },
                childNotFound: false
            };

            observer.next({ data: { addEntry: returnVal } });

            return {
                enqueueMutation: true
            };
        }
    }

    public async initialize() {
        const observableQuery = this.client.watchQuery<GetChildrenNamesQuery>({
            query: GraphQL.getChildrenNames
        });

        observableQuery.subscribe({
            next: (result) => {
                this.children = result.data.children;
            }
        });
    }
}
