import styled from "styled-components";
import { Icon } from "@style/icon";
import { Colors } from "@style/colors";
import { Padding } from "@style/padding";
import { TableBlock } from "@pages/registrationsOverview/registrationsOverviewStyle";

export const TableHeaderStyle = styled.div`
    width: 100%;
    background-color: ${Colors.white};
    height: 45px;
    margin-top: ${Padding.large};
    display: flex;
    align-items: center;
    padding: 0 ${Padding.small};
    margin-bottom: 6px;

    ${TableBlock} {
        color: ${Colors.grey};
        .today {
            color: ${Colors.primary()};
            font-weight: 600;
        }
    }

    .divider {
        width: 1px;
        background-color: ${Colors.grey};
        height: 20px;
    }

`;
