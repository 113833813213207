import styled from "styled-components";
import { OrganiserLoginFormStyle } from "@components/organiserLoginForm/organiserLoginFormStyle";
import { Button } from "@style/button";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";

export const ManualSyncFormStyle = styled(OrganiserLoginFormStyle)`

    h1 {
        color: ${Colors.primary(1)};
        margin-top: 40px;
    }

    .bottomBar {
        display: flex;
        justify-content: flex-end;

        ${Button} {
            max-width: 200px;

        }
    }


    .search-with-icon {
        display: flex;
        align-items: center;
    }

    .remove-btn {
        cursor: pointer;
        color: rgba(255,96,96,1);
        opacity: 0.5;
        margin-left: 1rem;

        :hover {
            opacity: 1;
        }
    }

    .result {
        font-weight: 600;
        padding-top: ${Padding.medium};
    }

`;
