import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import {
    updateDaycareOverviewSearch,
    UpdateDaycareOverviewSearchActionOptions
} from "@redux/actions/updateDaycareOverviewSearch";
import { Action } from "@redux/actions/action";
import { DaycareSearchResults } from "@components/daycare/daycareSearchResults/daycareSearchResults";

export interface DaycareSearchResultsContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: DaycareSearchResultsContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    changeSearch(data: UpdateDaycareOverviewSearchActionOptions) { dispatch(updateDaycareOverviewSearch(data)); }

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const DaycareSearchResultsContainer = compose<React.ComponentClass<DaycareSearchResultsContainerProps>>(
    withRedux
)(DaycareSearchResults);

export type DaycareSearchResultsProps =
    DaycareSearchResultsContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
