import { OperationQueueEntry } from "../queueLink";

export interface HandleMutationResult {
    enqueueMutation: boolean;
    operationQueue?: OperationQueueEntry[];
}

export abstract class MutationHandler {

    public abstract mutationName: string;
    public abstract initialize(): void;
    public abstract handleMutation(observer: ZenObservable.SubscriptionObserver<any>, operationQueueEntry: OperationQueueEntry, operationQueue: OperationQueueEntry[]): HandleMutationResult;
}
