import { Popover } from "antd";
import * as classNames from "classnames";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage, FormattedRelative } from "react-intl";
import ReactToPrint from "react-to-print";

import { Colors } from "@style/colors";
import { Box } from "@style/box";
import { GetChildrenNamesChildren } from "@models/graphql/types";
import { QrCodeViewer } from "@components/qrCodeViewer/qrCodeViewer";
import { ParentChildListStyle } from "@components/parentChildList/parentChildListStyle";
import { ParentChildListProps } from "@components/parentChildList/parentChildListContainer";
import { ChildPrintView } from "@components/childPrintView/childPrintView";

export interface ParentChildListState {
    printChild: GetChildrenNamesChildren | null;
}

@autobind
export class ParentChildList extends React.Component<ParentChildListProps, ParentChildListState> {
    public state: ParentChildListState = {
        printChild: null
    };

    private printEl: null | React.ComponentClass;
    private printTriggerEl: any;

    public render() {

        const { childrenList: children, childrenLoading, setChildCallback, activeChildId } = this.props;
        const { printChild } = this.state;

        if (childrenLoading) {
            return (
                <ParentChildListStyle>

                </ParentChildListStyle>
            );
        }

        if (!children || !children.length) {
            return;
        }

        return (
            <ParentChildListStyle>
                <ChildPrintView child={printChild} refCallback={(el) => this.printEl = el} />
                <ReactToPrint
                    ref={(el => this.printTriggerEl = el)}
                    trigger={() => <span></span>}
                    content={() => this.printEl}
                />
                {children.map(child => {
                    const { id, firstName, lastName, gender, birthdate, code, createdOn } = child;
                    const active = id === activeChildId;

                    return <Box className={classNames({ active })} onClick={() => setChildCallback(id)} key={id}>
                        <div className="left">
                            <span className="name">
                                {firstName} {lastName}
                            </span>
                            <span className="info">
                                <FormattedMessage id={`gender.${gender.toLowerCase()}`} /> | {moment(birthdate).format("L")}
                            </span>
                            <div className="update-on">
                                <FormattedRelative value={createdOn} /> <FormattedMessage id="parentChildList.added" />
                            </div>
                        </div>
                        <Popover trigger="click" content={this.popoverContent(child)}>
                            <div className="right" >
                                <QrCodeViewer bgColor={Colors.white} code={code} />
                                {code}
                            </div>
                        </Popover>

                    </Box>;
                })}
                <span className="spacer" />
            </ParentChildListStyle>
        );
    }

    private popoverContent(child: GetChildrenNamesChildren) {
        return (
            <a onClick={() => this.printChild(child)}>
                Print QR Code
            </a>
        );
    }

    private printChild(child: GetChildrenNamesChildren) {
        this.setState({ printChild: child }, () => {
            if (this.printTriggerEl) {
                this.printTriggerEl.handlePrint();
            }
        });
    }
}
