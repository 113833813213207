import { autobind } from "core-decorators";
import * as React from "react";

import { ExportSelectorProps } from "./exportSelectorContainer";
import { ExportSelectorStyle } from "./exportSelectorStyle";
import { Select, Button } from "antd";

import * as moment from "moment";
import { Aggregation } from "@models/graphql/types";
import { ExportExcelButtonContainer } from "./exportExcelButtonContainer/exportExcelButtonContainer";

export interface ExportSelectorState {
    currentMonth: number;
    currentYear: number;
}

@autobind
export class ExportSelector extends React.Component<ExportSelectorProps, ExportSelectorState> {

    public state: ExportSelectorState = {
        currentMonth: moment().get("month"),
        currentYear: moment().get("year")
    };

    public render() {

        const { currentMonth, currentYear } = this.state;

        return (
            <ExportSelectorStyle>
                <div>
                    <div className="item">
                            <Select onChange={this.onMonthChange} value={currentMonth}>
                                <Select.Option value={0}>Januari</Select.Option>
                                <Select.Option value={1}>Februari</Select.Option>
                                <Select.Option value={2}>Maart</Select.Option>
                                <Select.Option value={3}>April</Select.Option>
                                <Select.Option value={4}>Mei</Select.Option>
                                <Select.Option value={5}>Juni</Select.Option>
                                <Select.Option value={6}>Juli</Select.Option>
                                <Select.Option value={7}>Augustus</Select.Option>
                                <Select.Option value={8}>September</Select.Option>
                                <Select.Option value={9}>Oktober</Select.Option>
                                <Select.Option value={10}>November</Select.Option>
                                <Select.Option value={11}>December</Select.Option>
                            </Select>
                    </div>
                    <div className="item">
                        <Select onChange={this.onYearChange} value={currentYear}>
                            {this.renderYearOptions()}
                        </Select>
                    </div>
                    <div className="item">
                        <ExportExcelButtonContainer month={currentMonth} year={currentYear} />
                    </div>
                </div>
            </ExportSelectorStyle>
        );
    }

    private renderYearOptions() {
        const years: JSX.Element[] = [];
        // There is no data before 2018, hence the start is 2018
        const currentYear = moment().year();
        for (let i = 2018; i < currentYear + 1; i += 1) {
            years.push(<Select.Option key={String(i)} value={i}>{i}</Select.Option>);
        }

        return years;
    }

    private onYearChange(currentYear: number) {
        const { currentMonth } = this.state;

        this.setState({
            currentYear
        });

        const aggregation = Aggregation.DAY;

        this.props.setAggregation({ aggregation });

        this.props.setDate({
            startDate: moment().set("year", currentYear).set("month", currentMonth).set("date", 1).startOf("week"),
            endDate: moment().set("year", currentYear).set("month", currentMonth).set("date", 1).endOf("week")
        });
    }

    private onMonthChange(currentMonth: number) {

        const { currentYear } = this.state;

        this.setState({
            currentMonth
        });

        this.props.setDate({
            startDate: moment().set("year", currentYear).set("month", currentMonth).set("date", 1).startOf("week"),
            endDate: moment().set("year", currentYear).set("month", currentMonth).set("date", 1).endOf("week")
        });

    }
}
