import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { updateEditRegistration, UpdateEditRegistrationActionOptions } from "@redux/actions/updateEditRegistrationId";
import { updateDaycareLogin, UpdateDaycareLoginActionOptions } from "@redux/actions/updateDaycareLogin";
import {
    setRegistrationFlowRegistrationResult,
    SetRegistrationFlowRegistrationResultActionOptions
} from "@redux/actions/setRegistrationFlowRegistrationResult";
import { setRegistrationFlowCode } from "@redux/actions/setRegistrationFlowCode";
import { Action } from "@redux/actions/action";
import { InvalidRegistrationPage } from "@pages/invalidRegistrationPage/invalidRegistrationPage";

export interface InvalidRegistrationPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: InvalidRegistrationPageContainerProps) => ({
    user: state.auth.user,
    registration: state.registrationFlow.registrationResult
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    editRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); },
    setRegistrationResult(data: SetRegistrationFlowRegistrationResultActionOptions) { dispatch(setRegistrationFlowRegistrationResult(data)); },
    updateDaycareLogin(data: UpdateDaycareLoginActionOptions) { dispatch(updateDaycareLogin(data)); },

    resetCode() { dispatch(setRegistrationFlowCode({ code: null })); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const InvalidRegistrationPageContainer = compose<React.ComponentClass<InvalidRegistrationPageContainerProps>>(
    withRedux,
    withRouter
)(InvalidRegistrationPage);

export type InvalidRegistrationPageProps =
    InvalidRegistrationPageContainerProps &
    RouteComponentProps &
    WithReduxStateProps &
    WithReduxDispatchProps;
