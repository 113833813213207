import * as React from "react";

import { Icon } from "@style/icon";
import { AlertStyle } from "@components/alert/alertStyle";

export interface AlertProps {
    type: "success" | "error" | "info";
    children: React.ReactNode;
}

export const Alert: React.StatelessComponent<AlertProps> = (props: AlertProps) => {
    const { type, children } = props;

    if (!children) {
        return null;
    }

    return (
        <AlertStyle className={type}>
            <Icon type={type} />
            <div className="content">
                {children}
            </div>
        </AlertStyle>
    );
};
