import { Form, Input, Modal } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { ErrorCode, parseError } from "@utils/parseError";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { ContactInput } from "@models/graphql/types";
import { EditContact, EditContactsModalStyle } from "@components/editContactsModal/editContactsModalStyle";
import { EditContactsModalProps } from "@components/editContactsModal/editContactsModalContainer";
import { Alert } from "@components/alert/alert";

export interface EditContactsModalState {
    contacts: (number | string)[];
    loaded: boolean;
    submitting: boolean;
    submitError: string | null;
}

interface FormValues {
    contacts?: {
        [index: string]: Contact;
    };
}

export interface Contact {
    firstName: string;
    lastName: string;
    email: string;
}

@autobind
export class EditContactsModal extends React.Component<EditContactsModalProps, EditContactsModalState> {
    public state: EditContactsModalState = {
        contacts: [],
        loaded: false,
        submitting: false,
        submitError: null
    };
    private contactCount: number = 0;

    public static getDerivedStateFromProps(props: EditContactsModalProps, state: EditContactsModalState): Partial<EditContactsModalState> | null {
        if (!state.loaded) {
            return {
                contacts: props.contacts.map(contact => contact.id),
                loaded: true
            };
        }

        return null;
    }

    public render() {
        const { submitError, submitting, contacts } = this.state;
        const { visible } = this.props;

        return (
            <Modal width="700px" footer={null} onCancel={this.close} maskClosable={false} visible={visible}>
                <EditContactsModalStyle>
                    <Form onSubmit={this.onSubmit}>
                        <FormattedMessage tagName="h1" id="editContactsModal.title" />
                        {this.renderContacts()}
                        {
                            contacts.length < 5 &&
                            <Button className="addContact" onClick={this.addContact} ><FormattedMessage id="addChildForm.addContact" /></Button>
                        }

                        {submitError && <Alert type="error"><FormattedMessage id={submitError} /></Alert>}
                        <div className="buttons">
                            <Button onClick={this.close}><FormattedMessage id="editContactsModal.cancel" /></Button>
                            <Button loading={submitting} onClick={this.onSubmit} type="primary" htmlType="submit"><FormattedMessage id="editContactsModal.save" /></Button>
                        </div>
                    </Form>
                </EditContactsModalStyle>
            </Modal>
        );
    }

    private close() {
        const { form, hideCallback } = this.props;
        this.setState({
            loaded: false
        });
        form.resetFields();
        hideCallback();
    }

    private onSubmit(e: React.FormEvent | React.MouseEvent) {
        e.preventDefault();

        const { form, updateChild, childId } = this.props;

        form.validateFields({ force: true }, async (errors, values: FormValues) => {
            if (!errors) {
                try {
                    this.setState({
                        submitting: true,
                        submitError: null
                    });
                    let contacts: ContactInput[] = [];
                    if (values.contacts) {

                        contacts = Object.entries(values.contacts).map<ContactInput>(contact => {
                            const [key, value] = contact;

                            let id: string | null = null;
                            // Check if it's an ID
                            if (key.length > 10) {
                                id = key;
                            }

                            return {
                                email: value.email,
                                firstName: value.firstName,
                                lastName: value.lastName,
                                id
                            };

                        });
                    }

                    const result = await updateChild({
                        id: childId,
                        input: {
                            contacts
                        }
                    });

                    if (result) {
                        this.setState({
                            submitting: false
                        });

                        this.close();
                    } else {
                        this.setState({
                            submitting: false,
                            submitError: ErrorCode.UNKNOWN
                        });
                    }
                } catch (err) {
                    console.log(err);
                    this.setState({
                        submitError: parseError(err),
                        submitting: false
                    });
                }
            }
        });
    }

    private renderContacts() {
        const { contacts } = this.state;
        const { form, intl, contacts: childContacts } = this.props;

        return contacts.map(contact => {
            const match = childContacts.find(c => c.id === contact);

            return (
                <EditContact key={contact}>
                    <Form.Item label={intl.formatMessage({ id: "addChildForm.firstName" })}>
                        {form.getFieldDecorator(`contacts[${contact}].firstName`, {
                            initialValue: match && match.firstName,
                            rules: [{ required: true, message: intl.formatMessage({ id: "addChildForm.firstNameRequired" }) }],
                            validateTrigger: "onSubmit"
                        })(
                            <Input placeholder={intl.formatMessage({ id: "addChildForm.firstName" })} />
                        )}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: "addChildForm.lastName" })}>
                        {form.getFieldDecorator(`contacts[${contact}].lastName`, {
                            initialValue: match && match.lastName,
                            rules: [{ required: true, message: intl.formatMessage({ id: "addChildForm.lastNameRequired" }) }]
                        })(
                            <Input placeholder={intl.formatMessage({ id: "addChildForm.lastName" })} />
                        )}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: "addChildForm.email" })}>
                        {form.getFieldDecorator(`contacts[${contact}].email`, {
                            initialValue: match && match.email,
                            validateTrigger: "onSubmit",
                            rules: [
                                { required: true, type: "email", message: intl.formatMessage({ id: "addChildForm.emailNotValid" }) },
                                { validator: this.validateContactEmail }
                            ]
                        })(
                            <Input placeholder={intl.formatMessage({ id: "addChildForm.email" })} />
                        )}
                    </Form.Item>
                    <Icon onClick={() => this.removeContact(contact)} type="trash" />
                </EditContact>
            );
        });
    }

    private validateContactEmail(rule: { field: string }, value: string, callback: (err?: string) => void, source?: any, options?: any) {
        const { form, intl } = this.props;

        if (value && rule.field.match(/^.*\[\d+\]\..*$/)) {
            const index = rule.field.replace(/^.*\[/, "").replace(/\]\..*$/, "");

            const contacts: { [index: string]: Contact } = form.getFieldValue("contacts");

            if (Object.entries(contacts).some(([i, contact]) => contact.email.toLowerCase().trim() === value.toLowerCase().trim() && i !== index)) {
                return callback(intl.formatMessage({ id: "addChildForm.duplicateEmail" }));
            }
        }

        callback();
    }

    private addContact() {
        const { contacts } = this.state;

        contacts.push(this.contactCount);
        this.setState({ contacts });

        this.contactCount += 1;
    }

    private removeContact(contactId) {
        const { contacts } = this.state;

        this.setState({
            contacts: contacts.filter(contact => contactId !== contact)
        });
    }
}
