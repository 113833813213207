import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { InnerContainer } from "@style/innerContainer";
import { ParentOverviewPageStyle } from "@pages/parentOverviewPage/parentOverviewPageStyle";
import { ParentOverviewPageProps } from "@pages/parentOverviewPage/parentOverviewPageContainer";
import { ParentChildListContainer } from "@components/parentChildList/parentChildListContainer";
import {
    ChildDetailRegistrationsListContainer
} from "@components/childDetail/childDetailRegistrationsList/childDetailRegistrationsListContainer";

export interface ParentOverviewPageState {
    selectedChildId: number | null;
}

@autobind
export class ParentOverviewPage extends React.Component<ParentOverviewPageProps, ParentOverviewPageState> {

    public state: ParentOverviewPageState = {
        selectedChildId: null
    };

    public static getDerivedStateFromProps(props: ParentOverviewPageProps, state: ParentOverviewPageState): Partial<ParentOverviewPageState> | null {

        if (!state.selectedChildId && props.childrenList && props.childrenList.length) {
            return {
                selectedChildId: props.childrenList[0].id
            };
        }

        return null;
    }

    public render() {
        const { selectedChildId } = this.state;

        return (
            <ParentOverviewPageStyle>
                <div className="greenBlock" />
                <div className="overlay">

                    <InnerContainer>
                        <FormattedMessage tagName="h1" id="parentOverviewPage.title" />
                    </InnerContainer>
                    <InnerContainer className="content">
                        {selectedChildId && <ParentChildListContainer activeChildId={selectedChildId} setChildCallback={this.setChild} />}

                        <InnerContainer>
                            {selectedChildId && <ChildDetailRegistrationsListContainer childId={selectedChildId} />}
                        </InnerContainer>
                    </InnerContainer>
                </div>
            </ParentOverviewPageStyle>
        );
    }

    private setChild(childId: number) {
        this.setState({ selectedChildId: childId });
    }

}
