import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { ResetPasswordPage } from "@pages/resetPasswordPage/resetPasswordPage";
import { createResetPasswordMutation, ResetPasswordMutationResult } from "@graphql/hocs/resetPassword";

export interface ResetPasswordPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: ResetPasswordPageContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

const withResetPasswordMutation = createResetPasswordMutation();

export const ResetPasswordPageContainer = compose<React.ComponentClass<ResetPasswordPageContainerProps>>(
    withRedux,
    withForm,
    injectIntl,
    withResetPasswordMutation
)(ResetPasswordPage);

export type ResetPasswordPageProps =
    ResetPasswordPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    FormComponentProps &
    InjectedIntlProps &
    ResetPasswordMutationResult;
