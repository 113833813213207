import { autobind } from "core-decorators";
import * as moment from "moment-timezone";
import * as React from "react";
import { Offline } from "react-detect-offline";
import { FormattedMessage } from "react-intl";

import { Icon } from "@style/icon";
import { LayoutStyle, OfflineMessage, TimeZoneMessage } from "@components/layout/layoutStyle";
import { LayoutProps } from "@components/layout/layoutContainer";
import { HeaderContainer } from "@components/header/headerContainer";

export interface LayoutState {

}

@autobind
export class Layout extends React.Component<LayoutProps, LayoutState> {

    public render() {

        const { children } = this.props;

        const differentTimezone = moment().utcOffset() !== moment.tz("Europe/Brussels").utcOffset();

        return (
            <LayoutStyle id="main-layout">
                <Offline>
                    <OfflineMessage>
                        <Icon type="error" />
                        <FormattedMessage id="info.noInternet" />
                    </OfflineMessage>
                </Offline>
                {differentTimezone && <TimeZoneMessage>De tijdzone van je toestel staat verkeerd. Gelieve deze juist te zetten. (Europa/Brussel)</TimeZoneMessage>}
                <HeaderContainer />
                {children}
            </LayoutStyle>

        );
    }
}
