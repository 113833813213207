import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import {
    updateDaycareOverviewSearch,
    UpdateDaycareOverviewSearchActionOptions
} from "@redux/actions/updateDaycareOverviewSearch";
import { Action } from "@redux/actions/action";
import { DayCareSearch } from "@components/daycare/dayCareSearch/dayCareSearch";

export interface DayCareSearchContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: DayCareSearchContainerProps) => ({
    searchValue: state.daycare.overviewSearch
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    changeSearch(data: UpdateDaycareOverviewSearchActionOptions) { dispatch(updateDaycareOverviewSearch(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const DayCareSearchContainer = compose<React.ComponentClass<DayCareSearchContainerProps>>(
    withRedux,
    injectIntl
)(DayCareSearch);

export type DayCareSearchProps =
    DayCareSearchContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    InjectedIntlProps;
