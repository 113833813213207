import * as React from "react";
import * as moment from "moment";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { Day } from "@components/registrations/calendar/day/day";

export interface DayContainerProps {
    day: moment.Moment;
    selected?: boolean;
    disabled?: boolean;

}

export const mapStateToProps = (state: StoreState, ownProps: DayContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const DayContainer = compose<React.ComponentClass<DayContainerProps>>(
    withRedux
)(Day);

export type DayProps =
    DayContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
