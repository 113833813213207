import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Placeholder } from "@style/placeholder";
import { GetUploadDetailInvalidRegistrations, GetUploadDetailUploads, Status, UploadInfo } from "@models/graphql/types";
import { UploadDetailStyle } from "@components/uploadDetail/uploadDetailStyle";
import { UploadDetailProps } from "@components/uploadDetail/uploadDetailContainer";
import classNames from "classnames";

export interface UploadDetailState {

}

@autobind
export class UploadDetail extends React.Component<UploadDetailProps, UploadDetailState> {

    public render() {

        const { uploadDetail, uploadDetailLoading } = this.props;

        if (uploadDetailLoading) {
            return (
                <UploadDetailStyle style={{ width: "100%" }}>
                    <Placeholder style={{ height: "2em", marginBottom: "1em" }} />
                    <Placeholder style={{ height: "1em", marginBottom: "1em" }} />
                    <Placeholder style={{ width: "80%", height: "1em" }} />
                </UploadDetailStyle>
            );
        }

        if (!uploadDetail) {
            return null;
        }

        switch (uploadDetail.status) {
            case Status.PENDING_FEEDBACK: return this.renderPendingFeedback(uploadDetail);
            case Status.PLANNED: return this.renderPlanned(uploadDetail);
            case Status.PENDING: return this.renderPending(uploadDetail);
            case Status.PROCESSED: return this.renderProcessed(uploadDetail);

            default: return null;
        }
    }

    private renderPending(uploadDetail: GetUploadDetailUploads) {
        const { startOfMonth } = uploadDetail;

        const month = moment(startOfMonth);

        return (
            <UploadDetailStyle>
                <h1><FormattedMessage id="uploadDetail.uploadPlanned" values={{ month: month.format("MMMM YYYY") }} /></h1>
                <FormattedMessage
                    tagName="p"
                    id="uploadDetail.uploadPlannedTonightInfo"
                    values={{
                        month: month.format("MMMM YYYY")
                    }}
                />
            </UploadDetailStyle>
        );
    }

    private renderPlanned(uploadDetail: GetUploadDetailUploads) {
        console.log(uploadDetail);
        const { startOfMonth, plannedDate } = uploadDetail;

        const month = moment(startOfMonth);
        const plannedDateMoment = moment(plannedDate);

        return (
            <UploadDetailStyle>
                <h1><FormattedMessage id="uploadDetail.uploadPlanned" values={{ month: month.format("MMMM YYYY") }} /></h1>
                <FormattedMessage
                    tagName="p"
                    id="uploadDetail.uploadPlannedInfo"
                    values={{
                        month: month.format("MMMM YYYY"),
                        day: plannedDateMoment.format("D MMMM")
                    }}
                />
            </UploadDetailStyle>
        );
    }

    private renderProcessed(uploadDetail: GetUploadDetailUploads) {
        const { startOfMonth, processedOn, status } = uploadDetail;

        if (!processedOn) {
            return null;
        }

        const month = moment(startOfMonth);
        const processed = moment(processedOn);

        return (
            <UploadDetailStyle className={status}>
                <h1><FormattedMessage id="uploadDetail.uploadProcessed" values={{ month: month.format("MMMM YYYY") }} /></h1>
                <FormattedMessage
                    tagName="p"
                    id="uploadDetail.uploadProcessedInfo"
                    values={{
                        month: month.format("MMMM YYYY"),
                        day: processed.format("D MMMM"),
                        time: processed.format("HH:mm")
                    }}
                />
            </UploadDetailStyle>
        );
    }

    private renderPendingFeedback(uploadDetail: GetUploadDetailUploads) {
        const { startOfMonth, status, invalidRegistrations, error, remarks, processedOn } = uploadDetail;

        if (!processedOn) {
            return null;
        }

        const month = moment(startOfMonth);
        const processed = moment(processedOn);

        const hasRemarks = (error || []).length === 0 && (remarks || []).length > 0;

        return (
            <UploadDetailStyle className={classNames(status, { hasRemarks })}>
                <h1><FormattedMessage id={hasRemarks ? "uploadDetail.uploadPendingWithOnlyRemarks" : "uploadDetail.uploadPending"} values={{ month: month.format("MMMM YYYY") }} /></h1>
                <FormattedMessage
                            tagName="p"
                            id="uploadDetail.uploadPendingLastTimeTried"
                            values={{
                                month: month.format("MMMM YYYY"),
                                day: processed.format("D MMMM"),
                                time: processed.format("HH:mm")
                            }}
                        />
                {(error || []).length > 0 ?
                    <React.Fragment>
                        <FormattedMessage
                            tagName="p"
                            id="uploadDetail.uploadPendingInfoForErrors"
                            values={{
                                month: month.format("MMMM YYYY")
                            }}
                        />
                        {this.renderErrors(error)}
                        {this.renderInvalidRegistrations(invalidRegistrations)}
                    </React.Fragment>
                    : null}
                {(remarks || []).length > 0 ?
                    <React.Fragment>
                        <FormattedMessage
                            tagName="p"
                            id="uploadDetail.uploadPendingInfoForRemarks"
                            values={{
                                month: month.format("MMMM YYYY")
                            }}
                        />
                        {this.renderRemarks(remarks)}
                    </React.Fragment>
                    : null}
            </UploadDetailStyle>
        );
    }

    private renderInvalidRegistrations(invalidRegistrations: GetUploadDetailInvalidRegistrations[]) {
        const { editRegistration } = this.props;

        if (invalidRegistrations.length) {

            const children: Set<number> = new Set();
            invalidRegistrations.forEach(reg => children.add(reg.child.id));

            const errors: JSX.Element[] = [];

            {
                children.forEach(childId => {
                    const invalidRegs = invalidRegistrations.filter(reg => reg.child.id === childId);
                    const { firstName, lastName } = invalidRegs[0].child;

                    errors.push(<div key={childId} className="child">
                        <h3>{firstName} {lastName}</h3>
                        <ul>
                            {invalidRegs.map(reg => <li key={reg.id}>
                                <span>{moment(reg.date).format("DD MMMM")}</span>
                                <span onClick={() => editRegistration({ edit: { childId, date: reg.date, registration: reg } })}>

                                    <FormattedMessage id="daycareProblemListItem.edit" /> {/* TODO: Add link */}
                                </span>
                            </li>)}
                        </ul>
                    </div>);

                });

                return (
                    <div className="problems">
                        <h2><FormattedMessage id="daycareProblemListItem.invalidRegistrations" /></h2>
                        {errors}
                    </div>
                );
            }
        }
    }

    private renderErrors(errors: UploadInfo[] | null | undefined) {

        if (errors && errors.length) {
            return (
                <div className="problems">
                    {errors.map((error, index) => {
                        return (
                            <React.Fragment key={index}>
                                <FormattedMessage tagName="h2" id={error.title} />
                                <FormattedMessage tagName="p" id={error.message} />
                            </React.Fragment>
                        );
                    })}
                </div>
            );
        }
    }

    private renderRemarks(remarks: UploadInfo[] | null | undefined) {

        if (remarks && remarks.length) {
            return (
                <div className="remarks">
                    {remarks.map((error, index) => {
                        return (
                            <React.Fragment key={index}>
                                <FormattedMessage tagName="h2" id={error.title} />
                                <FormattedMessage tagName="p" id={error.message} />
                            </React.Fragment>
                        );
                    })}
                </div>
            );
        }
    }
}
