import * as React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { Dispatch } from "redux";

import { Role } from "@utils/user";
import { AuthService } from "@utils/authService";
import { StoreState } from "@redux/reducers/root";
import {
    updateDaycareSelectedDaycareId,
    UpdateDaycareSelectedDaycareIdActionOptions
} from "@redux/actions/updateDaycareSelectedDaycareId";
import { updateDaycareLogin, UpdateDaycareLoginActionOptions } from "@redux/actions/updateDaycareLogin";
import { Action } from "@redux/actions/action";
import { UnauthorizedPageContainer } from "@pages/unauthorizedPage/unauthorizedPageContainer";

export interface PrivateRouteProps extends RouteProps {
    needsDaycareId?: boolean;
    requiredRoles?: Role[];
    needsCode?: boolean;
}

export class PrivateRouteComponent extends React.Component<PrivateRouteComponentProps> {
    public render() {
        const { component: Component, render, needsDaycareId, requiredRoles, needsCode, user, daycare, setDaycare, setDaycareLogin, ...rest } = this.props;

        return (

            <Route
                {...rest}
                render={props => {

                    if (!user) {
                        return <Redirect
                            to={{
                                pathname: "/login"
                            }}
                        />;
                    } else {
                        if (needsCode) {

                            if (AuthService.isAuthorized) {

                                return this.renderRoute(props);
                            } else {

                                setDaycareLogin({
                                    daycareLogin: {
                                        caseNumber: user.username,
                                        redirectPath: this.props.path as string || "/"
                                    }
                                });

                                return <Redirect
                                    to={{
                                        pathname: "/daycare-login"
                                    }}
                                />;
                            }
                        }

                        if (!requiredRoles) {
                            return this.renderRoute(props);
                        } else if (user.roles.some(r => requiredRoles.includes(r))) {

                            if (needsDaycareId && !daycare.selectedDaycareId) {
                                if (user.roles.includes(Role.ORGANISER)) {
                                    return <Redirect
                                        to={{
                                            pathname: "/daycare-overview"
                                        }}
                                    />;
                                } else if (user.roles.includes(Role.DAYCARE)) {
                                    setDaycare({ daycareId: user.id });
                                } else {
                                    return <UnauthorizedPageContainer />;
                                }
                            }

                            return this.renderRoute(props);
                        } else {
                            return <UnauthorizedPageContainer />;
                        }
                    }
                }}
            />
        );
    }

    private renderRoute(props: RouteComponentProps<any, StaticContext, any>) {
        const { component: Component, render, ...rest } = this.props;

        if (Component) {
            return <Component {...props} />;
        } else if (render) {
            return render(props);
        }
    }
}

export const mapStateToProps = (state: StoreState, ownProps: PrivateRouteProps) => ({
    user: state.auth.user,
    daycare: state.daycare
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setDaycareLogin(data: UpdateDaycareLoginActionOptions) { dispatch(updateDaycareLogin(data)); },
    setDaycare(data: UpdateDaycareSelectedDaycareIdActionOptions) { dispatch(updateDaycareSelectedDaycareId(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const PrivateRoute: React.ComponentClass<PrivateRouteProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateRouteComponent);

export type PrivateRouteComponentProps =
    PrivateRouteProps &
    WithReduxStateProps &
    WithReduxDispatchProps;
