import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { createLoginMutation, LoginMutationResult } from "@graphql/hocs/loginMutation";
import { ParentLoginForm } from "@components/parentLoginForm/parentLoginForm";

export interface ParentLoginFormContainerProps {

}

interface RouteParams {
    status?: string;
}

export const mapStateToProps = (state: StoreState, ownProps: ParentLoginFormContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

const withLoginMutation = createLoginMutation();

export const ParentLoginFormContainer = compose<React.ComponentClass<ParentLoginFormContainerProps>>(
    withLoginMutation,
    withRedux,
    withForm,
    injectIntl,
    withRouter
)(ParentLoginForm);

export type ParentLoginFormProps =
    ParentLoginFormContainerProps &
    LoginMutationResult &
    WithReduxDispatchProps &
    WithReduxStateProps &
    RouteComponentProps<RouteParams> &
    InjectedIntlProps &
    FormComponentProps;
