/* tslint:disable */
/** Generated in 2021-10-25T14:48:20+02:00 */

// ====================================================
// START: Typescript template
// ====================================================

// ====================================================
// Scalars
// ====================================================

export type Date = string;

export type DateTime = string;

export type Json = string;

export type Time = string;

export type Upload = File | FileList | Blob;

export type ConstraintString = string;

export type ConstraintNumber = number;

// ====================================================
// Types
// ====================================================

export interface Query {
  validateDaycareCaseNumber?: DaycareInfo | null;

  children: Child[];

  uploads: UploadData[];

  firstUpload: Date;

  daycares: Daycare[];

  inactiveMonths: InactiveMonth[];
}

export interface DaycareInfo {
  id: string;

  name: string;

  address: string;
}

export interface Child {
  id: number;

  code: string;

  firstName: string;

  lastName: string;

  inSchoolSince?: Date | null;

  childBenefitEntitlement: boolean;

  childBenefitPeriods: ChildBenefitPeriod[];

  isActive: boolean;

  birthdate: Date;

  gender: Gender;

  createdOn: DateTime;

  contacts: Contact[];

  aggregatedRegistrations: AggregatedRegistration[];

  registrations: Registration[];
}

export interface ChildBenefitPeriod {
  start: Date;

  end?: Date | null;
}

export interface Contact {
  id: string;

  email: string;

  phone?: string | null;

  firstName: string;

  lastName: string;
}

export interface WeekAggregatedRegistration {
  date: Date;

  amountOfDays: number;

  allRegistrationsValid: boolean;
}

export interface DayAggregatedRegistration {
  date: Date;

  amountOfMinutes: number;

  registration: Registration;
}

export interface Registration {
  id: number;

  date: Date;

  valid: boolean;

  entries: Entry[];

  child: Child;
}

export interface Entry {
  id: number;

  type: EntryType;

  time: DateTime;

  order: number;
}

export interface UploadData {
  id: number;

  startOfMonth: Date;

  status: Status;

  processedOn?: DateTime | null;

  plannedDate: DateTime;

  hasRemarks: boolean;

  error?: UploadInfo[] | null;

  remarks?: UploadInfo[] | null;

  invalidRegistrations: Registration[];
}

export interface UploadInfo {
  title: string;

  message: string;
}

export interface Daycare {
  id: string;

  name: string;

  caseNumber: string;

  address: string;

  isSuspended: boolean;

  inactiveMonths: InactiveMonth[];

  invalidRegistrations: Registration[];

  invalidUploads: UploadData[];

  hasInvalidRegistrations: boolean;

  hasInvalidUploads: boolean;

  pincodeSet: boolean;

  activeMonths: string[];
}

export interface InactiveMonth {
  id: number;

  startOfMonth: Date;
}

export interface Mutation {
  login: LoginResult;

  refreshLogin: LoginResult;

  changePassword: boolean;

  changePincode: boolean;

  changeEmail: boolean;

  resetPassword: boolean;

  setPassword: boolean;

  initUpload: boolean;

  initImport: boolean;

  prepareUploads: boolean;

  validateRegistrations: boolean;

  logoutDaycare: boolean;

  addChild: Child;

  addChildToDaycare: Child;

  updateChild: Child;

  sendQrCode: boolean;

  updateUploadStatus: boolean;

  createInactiveMonth: InactiveMonth;

  deleteInactiveMonth: boolean;

  addEntry: AddEntryResult;

  deleteLastEntry: boolean;

  updateRegistration?: Registration | null;

  createRegistration?: Registration | null;

  registrations: Download;
}

export interface LoginResult {
  accessToken: string;

  refreshToken: string;
}

export interface AddEntryResult {
  registration?: Registration | null;

  childNotFound: boolean;

  child?: Child | null;
}

export interface Download {
  content: string;

  contentType: string;

  fileName: string;
}

// ====================================================
// InputTypes
// ====================================================

export interface ChildFilterInput {
  search?: string | null;

  firstNameFuzzy?: string | null;

  lastNameFuzzy?: string | null;

  registrationsFrom?: Date | null;

  registrationsTo?: Date | null;

  hasRegistrations?: boolean | null;

  isNotInSchool?: boolean | null;

  childIds?: number[] | null;

  organisationId?: string | null;

  isActive?: boolean | null;

  daycareId?: string | null;
}

export interface UploadFilterInput {
  month?: Date | null;

  id?: number | null;

  daycareId?: string | null;

  uploadsTo?: Date | null;
}

export interface Sort {
  field: string;

  order: SortOrder;
}

export interface DaycareFilterInput {
  id?: string | null;

  search?: string | null;

  organisationId: string;
}

export interface ChildInput {
  code: string;

  firstName: string;

  lastName: string;

  inSchoolSince?: Date | null;

  gender: Gender;

  birthdate: Date;

  childBenefitEntitlement: boolean;

  childBenefitPeriods?: ChildBenefitPeriodInput[] | null;

  contacts?: ContactInput[] | null;

  organisationId: string;
}

export interface ChildBenefitPeriodInput {
  start: Date;

  end?: Date | null;
}

export interface ContactInput {
  id?: string | null;

  email: string;

  phone?: string | null;

  firstName: string;

  lastName: string;
}

export interface ChildUpdateInput {
  firstName?: string | null;

  lastName?: string | null;

  inSchoolSince?: Date | null;

  gender?: Gender | null;

  birthdate?: Date | null;

  childBenefitEntitlement?: boolean | null;

  childBenefitPeriods?: ChildBenefitPeriodInput[] | null;

  isActive?: boolean | null;

  contacts?: ContactInput[] | null;
}

export interface UpdateUploadStatusInput {
  ids: number[];
}

export interface UpdateRegistrationInput {
  id: number;

  entries: EntryInput[];

  childId: number;

  date: Date;
}

export interface EntryInput {
  type: EntryType;

  time: DateTime;

  order: number;
}

export interface CreateRegistrationInput {
  entries: EntryInput[];

  childId: number;

  date: Date;
}

export interface RegistrationFilterInput {
  registrationsFrom: Date;

  registrationsTo: Date;

  daycareId: string;

  childIds?: number[] | null;
}

// ====================================================
// Arguments
// ====================================================

export interface ValidateDaycareCaseNumberQueryArgs {
  caseNumber: string;
}
export interface ChildrenQueryArgs {
  filter?: ChildFilterInput | null;
}
export interface UploadsQueryArgs {
  filter?: UploadFilterInput | null;

  sort?: Sort[] | null;
}
export interface DaycaresQueryArgs {
  filter: DaycareFilterInput;
}
export interface AggregatedRegistrationsChildArgs {
  aggregation: Aggregation;
}
export interface LoginMutationArgs {
  username: string;

  password: string;
}
export interface RefreshLoginMutationArgs {
  refreshToken: string;
}
export interface ChangePasswordMutationArgs {
  oldPassword: string;

  newPassword: string;
}
export interface ChangePincodeMutationArgs {
  newPincode: string;
}
export interface ChangeEmailMutationArgs {
  email: string;
}
export interface ResetPasswordMutationArgs {
  email: string;
}
export interface SetPasswordMutationArgs {
  token: string;

  password: string;
}
export interface PrepareUploadsMutationArgs {
  month: Date;
}
export interface LogoutDaycareMutationArgs {
  caseNumber: string;
}
export interface AddChildMutationArgs {
  input: ChildInput;
}
export interface AddChildToDaycareMutationArgs {
  childId: number;
}
export interface UpdateChildMutationArgs {
  id: number;

  input: ChildUpdateInput;
}
export interface SendQrCodeMutationArgs {
  childId: number;
}
export interface UpdateUploadStatusMutationArgs {
  input: UpdateUploadStatusInput;
}
export interface CreateInactiveMonthMutationArgs {
  startOfMonth: Date;
}
export interface DeleteInactiveMonthMutationArgs {
  monthId: number;
}
export interface AddEntryMutationArgs {
  childCode: string;

  time: DateTime;

  type: EntryType;
}
export interface DeleteLastEntryMutationArgs {
  childCode: string;

  time: DateTime;
}
export interface UpdateRegistrationMutationArgs {
  input: UpdateRegistrationInput;
}
export interface CreateRegistrationMutationArgs {
  input: CreateRegistrationInput;
}
export interface RegistrationsMutationArgs {
  filter: RegistrationFilterInput;

  type?: DownloadType | null;
}

// ====================================================
// Enums
// ====================================================

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE"
}

export enum Aggregation {
  DAY = "DAY",
  WEEK = "WEEK"
}

export enum EntryType {
  IN = "IN",
  OUT = "OUT"
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC"
}

export enum Status {
  PLANNED = "PLANNED",
  PROCESSED = "PROCESSED",
  PENDING_FEEDBACK = "PENDING_FEEDBACK",
  PENDING = "PENDING"
}

export enum DownloadType {
  Excel = "Excel",
  Csv = "Csv"
}

// ====================================================
// Unions
// ====================================================

export type AggregatedRegistration =
  | WeekAggregatedRegistration
  | DayAggregatedRegistration;

// ====================================================
// END: Typescript template
// ====================================================

// ====================================================
// Documents
// ====================================================

export type AddChildVariables = {
  input: ChildInput;
};

export type AddChildMutation = {
  __typename?: "Mutation";

  addChild: AddChildAddChild;
};

export type AddChildAddChild = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;

  code: string;
};

export type AddChildToDaycareVariables = {
  childId: number;
};

export type AddChildToDaycareMutation = {
  __typename?: "Mutation";

  addChildToDaycare: AddChildToDaycareAddChildToDaycare;
};

export type AddChildToDaycareAddChildToDaycare = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;

  code: string;
};

export type AddEntryVariables = {
  childCode: string;
  time: DateTime;
  type: EntryType;
};

export type AddEntryMutation = {
  __typename?: "Mutation";

  addEntry: AddEntryAddEntry;
};

export type AddEntryAddEntry = {
  __typename?: "AddEntryResult";

  registration?: AddEntryRegistration | null;

  childNotFound: boolean;
};

export type AddEntryRegistration = {
  __typename?: "Registration";

  id: number;

  valid: boolean;

  date: Date;

  entries: AddEntryEntries[];

  child: AddEntryChild;
};

export type AddEntryEntries = {
  __typename?: "Entry";

  id: number;

  type: EntryType;

  time: DateTime;

  order: number;
};

export type AddEntryChild = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;
};

export type ChangeEmailVariables = {
  email: string;
};

export type ChangeEmailMutation = {
  __typename?: "Mutation";

  changeEmail: boolean;
};

export type ChangePasswordVariables = {
  oldPassword: string;
  newPassword: string;
};

export type ChangePasswordMutation = {
  __typename?: "Mutation";

  changePassword: boolean;
};

export type ChangePincodeVariables = {
  newPincode: string;
};

export type ChangePincodeMutation = {
  __typename?: "Mutation";

  changePincode: boolean;
};

export type CreateInactiveMonthVariables = {
  startOfMonth: Date;
};

export type CreateInactiveMonthMutation = {
  __typename?: "Mutation";

  createInactiveMonth: CreateInactiveMonthCreateInactiveMonth;
};

export type CreateInactiveMonthCreateInactiveMonth = {
  __typename?: "InactiveMonth";

  startOfMonth: Date;

  id: number;
};

export type CreateRegistrationVariables = {
  input: CreateRegistrationInput;
};

export type CreateRegistrationMutation = {
  __typename?: "Mutation";

  createRegistration?: CreateRegistrationCreateRegistration | null;
};

export type CreateRegistrationCreateRegistration = {
  __typename?: "Registration";

  id: number;

  valid: boolean;

  date: Date;

  entries: CreateRegistrationEntries[];

  child: CreateRegistrationChild;
};

export type CreateRegistrationEntries = {
  __typename?: "Entry";

  id: number;

  type: EntryType;

  time: DateTime;

  order: number;
};

export type CreateRegistrationChild = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;
};

export type DeleteInactiveMonthVariables = {
  monthId: number;
};

export type DeleteInactiveMonthMutation = {
  __typename?: "Mutation";

  deleteInactiveMonth: boolean;
};

export type DeleteLastEntryVariables = {
  childCode: string;
  time: DateTime;
};

export type DeleteLastEntryMutation = {
  __typename?: "Mutation";

  deleteLastEntry: boolean;
};

export type GetChildDetailVariables = {
  filter?: ChildFilterInput | null;
};

export type GetChildDetailQuery = {
  __typename?: "Query";

  children: GetChildDetailChildren[];
};

export type GetChildDetailChildren = {
  __typename?: "Child";

  id: number;

  code: string;

  firstName: string;

  lastName: string;

  gender: Gender;

  birthdate: Date;

  childBenefitEntitlement: boolean;

  childBenefitPeriods: GetChildDetailChildBenefitPeriods[];

  inSchoolSince?: Date | null;

  isActive: boolean;

  contacts: GetChildDetailContacts[];
};

export type GetChildDetailChildBenefitPeriods = {
  __typename?: "ChildBenefitPeriod";

  start: Date;

  end?: Date | null;
};

export type GetChildDetailContacts = {
  __typename?: "Contact";

  id: string;

  email: string;

  phone?: string | null;

  firstName: string;

  lastName: string;
};

export type GetChildrenNamesVariables = {
  filter?: ChildFilterInput | null;
};

export type GetChildrenNamesQuery = {
  __typename?: "Query";

  children: GetChildrenNamesChildren[];
};

export type GetChildrenNamesChildren = {
  __typename?: "Child";

  id: number;

  firstName: string;

  code: string;

  lastName: string;

  birthdate: Date;

  gender: Gender;

  createdOn: DateTime;

  isActive: boolean;

  childBenefitEntitlement: boolean;

  childBenefitPeriods: GetChildrenNamesChildBenefitPeriods[];
};

export type GetChildrenNamesChildBenefitPeriods = {
  __typename?: "ChildBenefitPeriod";

  start: Date;

  end?: Date | null;
};

export type GetChildrenRegistrationListVariables = {
  filter?: ChildFilterInput | null;
  aggregation: Aggregation;
};

export type GetChildrenRegistrationListQuery = {
  __typename?: "Query";

  children: GetChildrenRegistrationListChildren[];
};

export type GetChildrenRegistrationListChildren = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;

  childBenefitPeriods: GetChildrenRegistrationListChildBenefitPeriods[];

  birthdate: Date;

  gender: Gender;

  aggregatedRegistrations: GetChildrenRegistrationListAggregatedRegistrations[];
};

export type GetChildrenRegistrationListChildBenefitPeriods = {
  __typename?: "ChildBenefitPeriod";

  start: Date;

  end?: Date | null;
};

export type GetChildrenRegistrationListAggregatedRegistrations =
  | GetChildrenRegistrationListDayAggregatedRegistrationInlineFragment
  | GetChildrenRegistrationListWeekAggregatedRegistrationInlineFragment;

export type GetChildrenRegistrationListDayAggregatedRegistrationInlineFragment = {
  __typename?: "DayAggregatedRegistration";

  date: Date;

  amountOfMinutes: number;

  registration: GetChildrenRegistrationListRegistration;
};

export type GetChildrenRegistrationListRegistration = {
  __typename?: "Registration";

  id: number;

  valid: boolean;

  entries: GetChildrenRegistrationListEntries[];
};

export type GetChildrenRegistrationListEntries = {
  __typename?: "Entry";

  id: number;

  type: EntryType;

  time: DateTime;

  order: number;
};

export type GetChildrenRegistrationListWeekAggregatedRegistrationInlineFragment = {
  __typename?: "WeekAggregatedRegistration";

  date: Date;

  amountOfDays: number;

  allRegistrationsValid: boolean;
};

export type GetDaycareListVariables = {
  filter: DaycareFilterInput;
};

export type GetDaycareListQuery = {
  __typename?: "Query";

  daycares: GetDaycareListDaycares[];
};

export type GetDaycareListDaycares = {
  __typename?: "Daycare";

  id: string;

  name: string;

  caseNumber: string;

  isSuspended: boolean;

  inactiveMonths: GetDaycareListInactiveMonths[];

  address: string;

  hasInvalidRegistrations: boolean;

  hasInvalidUploads: boolean;
};

export type GetDaycareListInactiveMonths = {
  __typename?: "InactiveMonth";

  startOfMonth: Date;
};

export type GetDaycareNamesVariables = {
  filter: DaycareFilterInput;
};

export type GetDaycareNamesQuery = {
  __typename?: "Query";

  daycares: GetDaycareNamesDaycares[];
};

export type GetDaycareNamesDaycares = {
  __typename?: "Daycare";

  id: string;

  name: string;

  caseNumber: string;

  pincodeSet: boolean;

  activeMonths: string[];
};

export type GetDaycareProblemsVariables = {
  filter: DaycareFilterInput;
};

export type GetDaycareProblemsQuery = {
  __typename?: "Query";

  daycares: GetDaycareProblemsDaycares[];
};

export type GetDaycareProblemsDaycares = {
  __typename?: "Daycare";

  id: string;

  name: string;

  caseNumber: string;

  invalidRegistrations: GetDaycareProblemsInvalidRegistrations[];

  invalidUploads: GetDaycareProblemsInvalidUploads[];
};

export type GetDaycareProblemsInvalidRegistrations = {
  __typename?: "Registration";

  id: number;

  date: Date;

  valid: boolean;

  child: GetDaycareProblemsChild;

  entries: GetDaycareProblemsEntries[];
};

export type GetDaycareProblemsChild = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;
};

export type GetDaycareProblemsEntries = {
  __typename?: "Entry";

  id: number;

  time: DateTime;

  type: EntryType;

  order: number;
};

export type GetDaycareProblemsInvalidUploads = {
  __typename?: "UploadData";

  startOfMonth: Date;

  error?: GetDaycareProblemsError[] | null;

  invalidRegistrations: GetDaycareProblems_InvalidRegistrations[];
};

export type GetDaycareProblemsError = {
  __typename?: "UploadInfo";

  title: string;

  message: string;
};

export type GetDaycareProblems_InvalidRegistrations = {
  __typename?: "Registration";

  id: number;

  date: Date;

  valid: boolean;

  child: GetDaycareProblems_Child;
};

export type GetDaycareProblems_Child = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;
};

export type GetFirstUploadVariables = {};

export type GetFirstUploadQuery = {
  __typename?: "Query";

  firstUpload: Date;
};

export type GetInactiveMonthsVariables = {};

export type GetInactiveMonthsQuery = {
  __typename?: "Query";

  inactiveMonths: GetInactiveMonthsInactiveMonths[];
};

export type GetInactiveMonthsInactiveMonths = {
  __typename?: "InactiveMonth";

  startOfMonth: Date;

  id: number;
};

export type GetRegistrationsVariables = {
  filter: RegistrationFilterInput;
  type?: DownloadType | null;
};

export type GetRegistrationsMutation = {
  __typename?: "Mutation";

  registrations: GetRegistrationsRegistrations;
};

export type GetRegistrationsRegistrations = {
  __typename?: "Download";

  content: string;

  contentType: string;

  fileName: string;
};

export type GetUploadDetailVariables = {
  filter?: UploadFilterInput | null;
};

export type GetUploadDetailQuery = {
  __typename?: "Query";

  uploads: GetUploadDetailUploads[];
};

export type GetUploadDetailUploads = {
  __typename?: "UploadData";

  id: number;

  startOfMonth: Date;

  status: Status;

  processedOn?: DateTime | null;

  plannedDate: DateTime;

  error?: GetUploadDetailError[] | null;

  remarks?: GetUploadDetailRemarks[] | null;

  invalidRegistrations: GetUploadDetailInvalidRegistrations[];
};

export type GetUploadDetailError = {
  __typename?: "UploadInfo";

  title: string;

  message: string;
};

export type GetUploadDetailRemarks = {
  __typename?: "UploadInfo";

  title: string;

  message: string;
};

export type GetUploadDetailInvalidRegistrations = {
  __typename?: "Registration";

  id: number;

  date: Date;

  valid: boolean;

  child: GetUploadDetailChild;

  entries: GetUploadDetailEntries[];
};

export type GetUploadDetailChild = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;
};

export type GetUploadDetailEntries = {
  __typename?: "Entry";

  id: number;

  time: DateTime;

  type: EntryType;

  order: number;
};

export type GetUploadsListVariables = {
  filter?: UploadFilterInput | null;
  sort?: Sort[] | null;
};

export type GetUploadsListQuery = {
  __typename?: "Query";

  uploads: GetUploadsListUploads[];
};

export type GetUploadsListUploads = {
  __typename?: "UploadData";

  id: number;

  startOfMonth: Date;

  status: Status;

  error?: GetUploadsListError[] | null;

  hasRemarks: boolean;
};

export type GetUploadsListError = {
  __typename?: "UploadInfo";

  title: string;

  message: string;
};

export type GetValidateDaycareCaseNumberVariables = {
  caseNumber: string;
};

export type GetValidateDaycareCaseNumberQuery = {
  __typename?: "Query";

  validateDaycareCaseNumber?: GetValidateDaycareCaseNumberValidateDaycareCaseNumber | null;
};

export type GetValidateDaycareCaseNumberValidateDaycareCaseNumber = {
  __typename?: "DaycareInfo";

  id: string;

  name: string;

  address: string;
};

export type LoginVariables = {
  username: string;
  password: string;
};

export type LoginMutation = {
  __typename?: "Mutation";

  login: LoginLogin;
};

export type LoginLogin = {
  __typename?: "LoginResult";

  accessToken: string;

  refreshToken: string;
};

export type LogoutDaycareVariables = {
  caseNumber: string;
};

export type LogoutDaycareMutation = {
  __typename?: "Mutation";

  logoutDaycare: boolean;
};

export type RefreshLoginVariables = {
  refreshToken: string;
};

export type RefreshLoginMutation = {
  __typename?: "Mutation";

  refreshLogin: RefreshLoginRefreshLogin;
};

export type RefreshLoginRefreshLogin = {
  __typename?: "LoginResult";

  accessToken: string;

  refreshToken: string;
};

export type ResetPasswordVariables = {
  email: string;
};

export type ResetPasswordMutation = {
  __typename?: "Mutation";

  resetPassword: boolean;
};

export type SendQrCodeVariables = {
  childId: number;
};

export type SendQrCodeMutation = {
  __typename?: "Mutation";

  sendQrCode: boolean;
};

export type SetPasswordVariables = {
  token: string;
  password: string;
};

export type SetPasswordMutation = {
  __typename?: "Mutation";

  setPassword: boolean;
};

export type UpdateChildVariables = {
  id: number;
  input: ChildUpdateInput;
};

export type UpdateChildMutation = {
  __typename?: "Mutation";

  updateChild: UpdateChildUpdateChild;
};

export type UpdateChildUpdateChild = {
  __typename?: "Child";

  id: number;

  code: string;

  firstName: string;

  lastName: string;

  gender: Gender;

  birthdate: Date;

  childBenefitEntitlement: boolean;

  inSchoolSince?: Date | null;
};

export type UpdateRegistrationVariables = {
  input: UpdateRegistrationInput;
};

export type UpdateRegistrationMutation = {
  __typename?: "Mutation";

  updateRegistration?: UpdateRegistrationUpdateRegistration | null;
};

export type UpdateRegistrationUpdateRegistration = {
  __typename?: "Registration";

  id: number;

  valid: boolean;

  date: Date;

  entries: UpdateRegistrationEntries[];

  child: UpdateRegistrationChild;
};

export type UpdateRegistrationEntries = {
  __typename?: "Entry";

  id: number;

  type: EntryType;

  time: DateTime;

  order: number;
};

export type UpdateRegistrationChild = {
  __typename?: "Child";

  id: number;

  firstName: string;

  lastName: string;
};

export type UpdateUploadStatusVariables = {
  input: UpdateUploadStatusInput;
};

export type UpdateUploadStatusMutation = {
  __typename?: "Mutation";

  updateUploadStatus: boolean;
};
