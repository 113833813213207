import * as React from "react";

import { compose } from "redux";

import { FaqPage } from "@pages/faqPage/faqPage";

export interface FaqPageContainerProps {

}

export const FaqPageContainer = compose<React.ComponentClass<FaqPageContainerProps>>(

)(FaqPage);

export type FaqPageProps =
    FaqPageContainerProps;
