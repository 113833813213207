import * as moment from "moment";

import { Role } from "@utils/user";

export const canEditRegistration = (date: moment.Moment, roles: Role[] | null): boolean => {
    const fiveYearsAgo = moment().subtract(5, "years");
    const twoMonthsAgo = moment().subtract(2, "months");

    if (!roles) {
        return false;
    }

    const fullEdit = roles.some(role => role === Role.ORGANISER);

    if (date.isBefore(fiveYearsAgo)) {
        return false;
    }

    if (date.isBefore(twoMonthsAgo) && !fullEdit) {
        return false;
    }

    return true;
};
