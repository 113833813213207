import { Form, Input } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { ErrorCode, parseError } from "@utils/parseError";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { ResetPasswordPageStyle } from "@pages/resetPasswordPage/resetPasswordPageStyle";
import { ResetPasswordPageProps } from "@pages/resetPasswordPage/resetPasswordPageContainer";
import { Alert } from "@components/alert/alert";

export interface ResetPasswordPageState {
    submitting: boolean;
    submitError: string | null;
    submitSuccessfull: boolean;
}

@autobind
export class ResetPasswordPage extends React.Component<ResetPasswordPageProps, ResetPasswordPageState> {
    public state: ResetPasswordPageState = {
        submitError: null,
        submitting: false,
        submitSuccessfull: false
    };

    public render() {

        const { form, intl } = this.props;
        const { submitError, submitSuccessfull, submitting } = this.state;

        return (
            <ResetPasswordPageStyle>
                <InnerContainer>
                    <FormattedMessage tagName="h1" id="resetPasswordPage.title" />
                    <FormattedMessage tagName="h2" id="resetPasswordPage.subTitle" />
                </InnerContainer>

                <InnerContainer>
                    <Box>
                        <Form onSubmit={this.onSubmit}>
                            {!submitSuccessfull && <Form.Item label={intl.formatMessage({ id: "resetPasswordPage.email" })}>
                                {form.getFieldDecorator("email", {
                                    rules: [
                                        { required: true, message: intl.formatMessage({ id: "resetPasswordPage.emailRequired" }) },
                                        { type: "email", message: intl.formatMessage({ id: "resetPasswordPage.emailInvalid" }) }
                                    ],
                                    validateTrigger: "onSubmit"
                                })(
                                    <Input placeholder={intl.formatMessage({ id: "resetPasswordPage.email" })} />
                                )}
                            </Form.Item>}
                            {submitError && <Alert type="error"><FormattedMessage id={submitError} /></Alert>}
                            {submitSuccessfull && <Alert type="success"><FormattedMessage id="resetPasswordPage.success" /></Alert>}
                            {!submitSuccessfull && <Button loading={submitting} htmlType="submit" onClick={this.onSubmit} type="primary"><FormattedMessage id="resetPasswordPage.submit" /></Button>}
                        </Form>
                        <div className="bottomBar">
                            <Link className="back" to="/login"><Icon type="back" /><FormattedMessage id="resetPasswordPage.back" /></Link>
                        </div>
                    </Box>
                </InnerContainer>
            </ResetPasswordPageStyle>
        );
    }

    private onSubmit(e: React.MouseEvent | React.FormEvent) {
        e.preventDefault();

        const { form, intl, resetPassword } = this.props;

        form.validateFields(async (errors, values) => {
            this.setState({
                submitError: null,
                submitSuccessfull: false
            });
            if (!errors) {
                this.setState({
                    submitting: true
                });

                try {
                    const result = await resetPassword({
                        email: values.email
                    });

                    if (result) {
                        this.setState({
                            submitting: false,
                            submitSuccessfull: true
                        });
                        form.resetFields();
                    } else {
                        this.setState({
                            submitting: false,
                            submitError: ErrorCode.UNKNOWN
                        });
                    }

                } catch (err) {
                    console.error(err);
                    this.setState({
                        submitting: false,
                        submitError: parseError(err)
                    });
                }
            }
        });
    }
}
