import { Form, Input } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { ChildItem, ChildSearchStyle } from "@components/addChild/childSearch/childSearchStyle";
import { ChildSearchProps } from "@components/addChild/childSearch/childSearchContainer";

export interface ChildSearchState {

}

@autobind
export class ChildSearch extends React.Component<ChildSearchProps, ChildSearchState> {

	public componentWillMount() {
		if (this.props.initialFirstName || this.props.initialLastName) {
			this.props.setLocalState({
				searchFirstName: this.props.initialFirstName,
				searchLastName: this.props.initialLastName
			});
		}
	}

	public render() {

		const { form, intl, childrenList: children, initialFirstName, initialLastName } = this.props;

		return (
			<ChildSearchStyle>
				<FormattedMessage tagName="h1" id="childSearch.title" />

				<Form layout="vertical" onSubmit={this.onSubmit}>
					<Form.Item label={intl.formatMessage({ id: "childSearch.firstName" })}>

						{form.getFieldDecorator("firstName", {
							initialValue: initialFirstName || "",
							rules: [{ required: true, message: intl.formatMessage({ id: "childSearch.firstNameRequired" }) }]
						})(
							<Input placeholder={intl.formatMessage({ id: "childSearch.firstName" })} />
						)}
					</Form.Item>
					<Form.Item label={intl.formatMessage({ id: "childSearch.lastName" })}>
						{form.getFieldDecorator("lastName", {
							initialValue: initialLastName || "",
							rules: [{ required: true, message: intl.formatMessage({ id: "childSearch.lastNameRequired" }) }]
						})(
							<Input placeholder={intl.formatMessage({ id: "childSearch.lastName" })} />
						)}
					</Form.Item>
					<Button htmlType="submit" type="primary"><Icon type="search" /></Button>
				</Form>

				{this.renderResults()}
			</ChildSearchStyle>

		);
	}

	private renderResults() {
		const { childrenList: children, searchFirstName, searchLastName, childrenLoading, addExistingChildCallback, intl } = this.props;

		if (searchFirstName === "" || searchLastName === "") {
			return <div className="result empty">
				<FormattedMessage id="childSearch.searchChild" />
			</div>;
		}

		if (childrenLoading) {
			return (
				<div className="result empty">
					<FormattedMessage id="childSearch.searching" values={{ firstName: searchFirstName }} />
				</div>
			);
		}

		if (!children || !children.length) {
			return <div className="result">
				<FormattedMessage tagName="h2" id="childSearch.foundTheseChildren" />
				<FormattedMessage tagName="h3" id="childSearch.noChildrenFound" />
				<ChildItem className="wrong">
					<FormattedMessage id="childSearch.notFound" tagName="h3" values={{ firstName: searchFirstName }} />
					<Button onClick={this.addNewChild}><FormattedMessage id="childSearch.addNew" /> </Button>
				</ChildItem>
			</div>;
		}

		return (
			<div className="result">
				<FormattedMessage tagName="h2" id="childSearch.foundTheseChildren" />
				{children.map(child => {
					const { firstName, birthdate, lastName, id, gender } = child;

					return (
						<ChildItem key={id}>
							<div className="left">
								<span>
									{firstName} {lastName}
								</span>
								<span>
									{intl.formatMessage({ id: `gender.${gender.toLowerCase()}` })} — {moment(birthdate, "YYYY-MM-DD").format("L")}
								</span>
							</div>

							<Button onClick={() => addExistingChildCallback(id)}><FormattedMessage id="childSearch.add" /> </Button>
						</ChildItem>
					);
				})}
				<ChildItem className="wrong">
					<FormattedMessage id="childSearch.notFound" tagName="h3" values={{ firstName: searchFirstName }} />
					<Button onClick={this.addNewChild}><FormattedMessage id="childSearch.addNew" /> </Button>
				</ChildItem>
			</div>
		);
	}

	private onSubmit(e) {
		e.preventDefault();

		const { form, setLocalState } = this.props;

		form.validateFields((errors, values) => {
			if (!errors) {
				setLocalState({
					searchFirstName: values.firstName,
					searchLastName: values.lastName
				});
			}
		});
	}

	private addNewChild() {
		const { searchFirstName, searchLastName, addNewChild } = this.props;

		addNewChild(searchFirstName, searchLastName);
	}
}
