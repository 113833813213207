import * as React from "react";
import { compose } from "redux";

import { createGetChildrenNamesQuery, GetChildrenNamesQueryResult } from "@graphql/hocs/getChildrenNames";
import { ParentChildList } from "@components/parentChildList/parentChildList";

export interface ParentChildListContainerProps {
    activeChildId: number;
    setChildCallback(childId: number): void;
}

const withGetChildrenNamesQuery = createGetChildrenNamesQuery();

export const ParentChildListContainer = compose<React.ComponentClass<ParentChildListContainerProps>>(
    withGetChildrenNamesQuery
)(ParentChildList);

export type ParentChildListProps =
    ParentChildListContainerProps &
    GetChildrenNamesQueryResult;
