import { ChangeEmailMutation, ChangeEmailVariables } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";
import { addRefetchQueries } from "@utils/addRefetchQueries";

export interface ChangeEmailMutationResult {
    changeEmail(variables: ChangeEmailVariables): Promise<boolean>;
}

export const createChangeEmailMutation = <TProps = {}>(
    args: {
        opOptions?: OperationOption<TProps, ChangeEmailMutation, ChangeEmailVariables, ChangeEmailMutationResult>;
        awaitRefetchQueries?: boolean;
    } = {}
) => {
    const { opOptions, awaitRefetchQueries } = args;
    const options = addRefetchQueries(opOptions, [], awaitRefetchQueries);

    return graphql<TProps, ChangeEmailMutation, ChangeEmailVariables, ChangeEmailMutationResult>(GraphQL.changeEmail, {
        props({ mutate }): ChangeEmailMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async changeEmail(variables: ChangeEmailVariables): Promise<boolean> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        return result.data.changeEmail;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
};
