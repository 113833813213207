import * as React from "react";
import { QRCode } from "react-qr-svg";

import { GetChildrenNamesChildren } from "@models/graphql/types";
import { ChildPrintViewStyle } from "@components/childPrintView/childPrintViewStyle";

export interface ChildPrintViewProps {
    refCallback: React.Ref<any>;
    child: GetChildrenNamesChildren | null;
}

export class ChildPrintView extends React.Component<ChildPrintViewProps> {

    public render() {

        const { refCallback, child } = this.props;

        if (!child) {
            return <div ref={refCallback} />;
        }

        const { firstName, lastName, code } = child;

        return (

            <div ref={refCallback}>
                <ChildPrintViewStyle >
                    <div className="name">
                        {firstName} {lastName}
                    </div>
                    <div className="code">
                        <QRCode
                            value={code}
                            fgColor={"black"}
                        />
                        {code}
                    </div>

                </ChildPrintViewStyle>
            </div>
        );
    }
}
