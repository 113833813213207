import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { withQueueLinkState } from "@graphql/withQueueLinkState";
import { QueueLinkState } from "@graphql/queueLinkProvider";
import { createGetChildrenNamesQuery, GetChildrenNamesQueryResult } from "@graphql/hocs/getChildrenNames";
import { PendingRegistrations } from "@components/pendingRegistrations/pendingRegistrations";

export interface PendingRegistrationsContainerProps {
    hide?: boolean;
}

export const mapStateToProps = (state: StoreState, ownProps: PendingRegistrationsContainerProps) => ({
    selectedDaycare: state.daycare.selectedDaycareId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetChildrenNames = createGetChildrenNamesQuery<WithReduxStateProps>({
    skip(ownProps) {
        return !ownProps.selectedDaycare;
    }
});

export const PendingRegistrationsContainer = compose<React.ComponentClass<PendingRegistrationsContainerProps>>(
    withRedux,
    withGetChildrenNames,
    withQueueLinkState
)(PendingRegistrations);

export type PendingRegistrationsProps =
    PendingRegistrationsContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    QueueLinkState &
    GetChildrenNamesQueryResult;
