import { graphql, OperationOption } from "react-apollo";

import { GetChildrenNamesChildren, GetChildrenNamesQuery, GetChildrenNamesVariables } from "@models/graphql/types";
import { GraphQL } from "@graphql/graphQL";

export interface GetChildrenNamesQueryResult {
    childrenList?: GetChildrenNamesChildren[];
    childrenLoading: boolean;
}

export const createGetChildrenNamesQuery = <TProps = {}>(options?: OperationOption<TProps, GetChildrenNamesQuery, GetChildrenNamesVariables, GetChildrenNamesQueryResult>) =>
    graphql<TProps, GetChildrenNamesQuery, GetChildrenNamesVariables, GetChildrenNamesQueryResult>(GraphQL.getChildrenNames, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                childrenList: data.children,
                childrenLoading: data.loading
            };
        },
        ...options
    });
