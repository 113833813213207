export const isIosPwa = () => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
        const iDevices = [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod"
        ];

        if (!!navigator.platform) {
            if (iDevices.some(device => device === navigator.platform)) {
                return true;
            }
        }
    }

    return false;

};
