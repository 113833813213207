import styled from "styled-components";

import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { Box } from "@style/box";

export const LoginOverviewPageStyle = styled.div`
    h1, h2 {
        color: ${Colors.white};
        font-size: 40px;
        font-weight: 200;
    }

    h2 {
        font-size: 20px;
        padding: 15px 0 30px 0;
    }

    .links {
        margin-bottom: 30px;
        color: ${Colors.text};
        display: flex;
        flex-wrap: wrap;

        span {
            opacity: 0.5;
            margin: 0 10px;
        }


        a {
            opacity: 0.5;
            color: ${Colors.text};
            font-size: 16px;


            &:hover {
                color: ${Colors.primary()};
                opacity: 1;
            }

        }

        @media (max-width: 480px) {
            flex-direction: column;
             a {
                 padding-bottom: 10px;
             }

            span {
                display: none;
            }
        }
    }

`;

export const OptionsContainer = styled(InnerContainer)`

    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .spacer {
        display: none;
        flex-shrink: 0;
    }

    @media (max-width: 850px) {
        .spacer {
            display:block;
            width: 25px;
        }
    }


    ${Box} {
        padding: 5px;

        margin-bottom: ${Padding.large};
        width: 33%;
        min-width: 250px;

        &:not(:last-of-type) {
            margin-right: 25px;
        }

        img {
            border-radius: 4px;
            display: block;
            width: 100%
        }

        ${Button} {
            width: 100%;
            height: 50px;
            margin-top: 5px;
        }
    }
`;
