import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { ChangePinCodePage } from "@pages/changePinCodePage/changePinCodePage";
import { ChangePincodeMutationResult, createChangePincodeMutation } from "@graphql/hocs/changePincode";
import { createLogoutDaycareMutation, LogoutDaycareMutationResult } from "@graphql/hocs/logoutDaycare";

export interface ChangePinCodePageContainerProps {
    daycareName: string;
    caseNumber: string;
    open: boolean;
    pincodeSet: boolean;
    closeCallback(): void;
}

export const mapStateToProps = (state: StoreState, ownProps: ChangePinCodePageContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withChangePincodeMutation = createChangePincodeMutation({ awaitRefetchQueries: true });
const withLogoutDaycareMutation = createLogoutDaycareMutation({});
const withForm = Form.create();

export const ChangePinCodePageContainer = compose<React.ComponentClass<ChangePinCodePageContainerProps>>(
    withRedux,
    withRouter,
    injectIntl,
    withForm,
    withChangePincodeMutation,
    withLogoutDaycareMutation
)(ChangePinCodePage);

export type ChangePinCodePageProps =
    ChangePinCodePageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    ChangePincodeMutationResult &
    LogoutDaycareMutationResult &
    RouteComponentProps &
    InjectedIntlProps &
    FormComponentProps;
