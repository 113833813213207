import { graphql, OperationOption } from "react-apollo";

import {
    GetChildrenRegistrationListChildren,
    GetChildrenRegistrationListQuery,
    GetChildrenRegistrationListVariables
} from "@models/graphql/types";
import { GraphQL } from "@graphql/graphQL";

export interface GetChildrenRegistrationListQueryResult {
    children?: GetChildrenRegistrationListChildren[];
    childrenLoading: boolean;
    refetch(): void;
}

export const createGetChildrenRegistrationListQuery = <TProps = {}>(options?: OperationOption<TProps, GetChildrenRegistrationListQuery, GetChildrenRegistrationListVariables, GetChildrenRegistrationListQueryResult>) =>
    graphql<TProps, GetChildrenRegistrationListQuery, GetChildrenRegistrationListVariables, GetChildrenRegistrationListQueryResult>(GraphQL.getChildrenRegistrationList, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                children: data.children,
                childrenLoading: data.loading,
                refetch: data.refetch
            };
        },
        ...options
    });
