import { Popover } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@style/icon";
import { MoreActionsButtonStyle } from "@components/moreActionsButton/moreActionsButtonStyle";
import { MoreActionsButtonProps } from "@components/moreActionsButton/moreActionsButtonContainer";

export interface MoreActionsButtonState {

}

@autobind
export class MoreActionsButton extends React.Component<MoreActionsButtonProps, MoreActionsButtonState> {

    public render() {
        const { children } = this.props;

        return (
            <Popover content={children} trigger="click" overlayClassName="moreActionsPopover" placement="bottomRight">
                <MoreActionsButtonStyle>
                    <FormattedMessage id="moreActionsButton.title" /> <Icon type="chevron-down" />
                </MoreActionsButtonStyle>
            </Popover>

        );
    }
}
