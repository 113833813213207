import * as React from "react";

import { QueueLinkConsumer } from "./queueLinkProvider";

export function withQueueLinkState(Component) {
    return (props) => {
        return (
            <QueueLinkConsumer>
                {state => <Component {...props} {...state} />}
            </QueueLinkConsumer>
        );
    };
}
