import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";

export const RegistrationItemStyle = styled.div`
    width: 100%;
    background-color: ${Colors.white};
    height: 75px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    padding: 0 ${Padding.small};



    .infoColumn {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        cursor: pointer;
        &:hover {
            color: ${Colors.primary()};
        }
    }

    .name {
        font-weight: 600;
        margin-bottom: 7px;
        font-size: 16px;
    }

    .info {
        font-size: 12px;
    }

`;

export const ValidBlock = styled.div`
    background-color: ${Colors.primary(0.15)};
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.primary()};
    font-weight: 600;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    padding: 0 4px;
    padding: 0 10px;
    line-height: 140%;

    &:hover {
        background-color: ${Colors.primary(0.25)};
    }
`;

export const NowPresentBlock = styled(ValidBlock)`
    background-color: ${Colors.primary(0.05)}
`;

export const InvalidBlock = styled(ValidBlock)`
    background-color: ${Colors.error(0.05)};
    color: ${Colors.error()};
    border: 2px solid ${Colors.error()};
    position: relative;

     &:hover {
        background-color: ${Colors.error(0.2)};
    }

    .circle {
        position: absolute;
        z-index: 1;
        top: -13px;
        right: -13px;
        font-size: 14px;
        width: 26px;
        height: 26px;
        background-color: ${Colors.error()};
        color: ${Colors.white};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
    }
`;

export const EmptyBlock = styled(ValidBlock)`
    background-color: #F5F5F5;
    color: ${Colors.text};
    font-weight: 200;
    font-size: 25px;

    &.disabled {
        opacity: .5;
        pointer-events: none;
        color: transparent !important;
    }

     &:hover {
        background-color:   #EAEAEA;
    }

    &.week {
        font-size: 12px;
        font-weight: 400;
        color: ${Colors.grey};
    }
`;

export const CheckinDisplay = styled.div`
    display: flex;
    align-items: center;
    font-weight: 200;

    .icon {
        margin-right: 5px;
        font-size: 8px;
    }

    .checkin,.checkout, .error, & > span{
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
            margin-right: 15px;
        }
    }




    .checkin {
        color: ${Colors.primary()};
    }

    .error {
        color: ${Colors.error()};
    }

    .checkout {
        color: ${Colors.secondary()};
    }
`;
