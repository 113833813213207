import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { RegistrationErrorPageStyle } from "@pages/registrationErrorPage/registrationErrorPageStyle";

export const InvalidRegistrationPageStyle = styled(RegistrationErrorPageStyle)`

    a.back {
        align-items: center;
        color: white;
        position: absolute;
        top: 30px;
        left: 30px;
        font-size: 20px;
        display: flex;
        z-index: 10;
        padding: 20px 20px 20px 0;

        .icon {
            font-size: 10px;
            margin-right: ${Padding.small};
        }
    }

    ${Button} {
        &.ant-btn-danger {
            margin: 0 0 ${Padding.medium} 0;

            border-color: ${Colors.white};
            color: ${Colors.white};

            &:hover {
                background-color: ${Colors.white};
                color: ${Colors.error()};
            }

            &:focus:not(:hover) {
                background-color: transparent;
                border-color: ${Colors.white};
                color: ${Colors.white};
            }
        }
    }
`;
