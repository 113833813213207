import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { DaycareProblemListItem } from "@components/daycare/daycareProblemListItem/daycareProblemListItem";
import { GetDaycareProblemsDaycares } from "@models/graphql/types";
import { UpdateEditRegistrationActionOptions, updateEditRegistration } from "@redux/actions/updateEditRegistrationId";
import { UpdateCreateRegistrationActionOptions, updateCreateRegistration } from "@redux/actions/updateCreateRegistration";
import { UpdateDaycareSelectedDaycareIdActionOptions, updateDaycareSelectedDaycareId } from "@redux/actions/updateDaycareSelectedDaycareId";
import { withRouter, RouteComponentProps } from "react-router";

export interface DaycareProblemListItemContainerProps {
    daycare: GetDaycareProblemsDaycares;
}

export const mapStateToProps = (state: StoreState, ownProps: DaycareProblemListItemContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    editRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); },
    setDaycare(data: UpdateDaycareSelectedDaycareIdActionOptions) { dispatch(updateDaycareSelectedDaycareId(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const DaycareProblemListItemContainer = compose<React.ComponentClass<DaycareProblemListItemContainerProps>>(
    withRedux,
    withRouter
)(DaycareProblemListItem);

export type DaycareProblemListItemProps =
    DaycareProblemListItemContainerProps &
    WithReduxDispatchProps &
    RouteComponentProps &
    WithReduxStateProps;
