import classNames from "classnames";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { minutesToHm } from "@utils/minutesToHm";
import { isSuspended } from "@utils/isSupsended";
import { isDayAggregation, isWeekAggregation } from "@utils/aggregatedRegistrationHelper";
import { Icon } from "@style/icon";
import {
    Aggregation,
    EntryType,
    GetChildrenRegistrationListAggregatedRegistrations,
    GetChildrenRegistrationListDayAggregatedRegistrationInlineFragment
} from "@models/graphql/types";
import { RegistrationListItemStyle } from "@components/registrations/registrationListItem/registrationListItemStyle";
import { RegistrationListItemProps } from "@components/registrations/registrationListItem/registrationListItemContainer";
import {
    EmptyBlock,
    InvalidBlock,
    NowPresentBlock,
    ValidBlock
} from "@components/registrations/registrationItem/registrationItemStyle";
import { ChildBenefitCheck } from "@components/childBenefitCheck/childBenefitCheck";

export interface RegistrationListItemState {

}

@autobind
export class RegistrationListItem extends React.Component<RegistrationListItemProps, RegistrationListItemState> {

    public render() {

        const { child, date, history } = this.props;
        const { aggregatedRegistrations, firstName, lastName, gender, childBenefitPeriods, birthdate, id } = child;

        const match = aggregatedRegistrations.find(reg => date.isSame(reg.date, "day"));

        return (
            <RegistrationListItemStyle>
                <div className="left" onClick={() => history.push(`/child/${id}`)}>
                    <div className="name">{firstName} {lastName}</div>
                    <div className="info">
                        <FormattedMessage id={`gender.${gender.toLowerCase()}`} />&nbsp;&nbsp;|&nbsp;&nbsp;{moment(birthdate).format("L")}
                        <ChildBenefitCheck childBenefitPeriods={childBenefitPeriods} />
                    </div>
                </div>

                {this.renderDuration()}
            </RegistrationListItemStyle>

        );
    }

    private renderDuration() {
        const { child, date, aggregation, intl, daycares } = this.props;
        const { aggregatedRegistrations } = child;

        if (!daycares || !daycares.length) {
            return;
        }

        let match: GetChildrenRegistrationListAggregatedRegistrations | undefined;

        if (aggregation === Aggregation.DAY) {
            match = aggregatedRegistrations.find(reg => date.isSame(reg.date, "day"));
        } else {

            match = aggregatedRegistrations.find(reg => date.isSame(reg.date, "week"));
        }

        if (match) {

            if (isWeekAggregation(match) && match.allRegistrationsValid) {
                return (
                    <ValidBlock onClick={() => this.changeDate(date)} >
                        {match.amountOfDays}&nbsp;<FormattedMessage id={`registrationItem.days.${intl.formatPlural(match.amountOfDays)}`} />
                    </ValidBlock>
                );
            } else if (isDayAggregation(match) && match.registration.valid) {
                const registration = match.registration;
                const regDate = match.date;

                if (date.isSame(moment(), "d") && match.registration.entries.length > 0) {
                    const lastEntry = match.registration.entries[match.registration.entries.length - 1];

                    if (lastEntry.type === EntryType.IN) {
                        return (
                            <NowPresentBlock onClick={() => this.props.editRegistration({ edit: { date: regDate, registration, childId: child.id } })}>
                                <FormattedMessage id="registrationItem.nowPresent" />
                            </NowPresentBlock>
                        );
                    }
                }

                return (
                    <ValidBlock onClick={() => this.props.editRegistration({ edit: { date: regDate, registration, childId: child.id } })} >
                        {minutesToHm((match as GetChildrenRegistrationListDayAggregatedRegistrationInlineFragment).amountOfMinutes)}
                    </ValidBlock>
                );
            } else if (isDayAggregation(match)) {
                const registration = match.registration;
                const regDate = match.date;

                return (
                    <InvalidBlock onClick={() => this.props.editRegistration({ edit: { date: regDate, registration, childId: child.id } })} >
                        <div className="circle"><Icon type="edit" /></div>
                        <FormattedMessage id="registrationItem.invalidRegistrations" />
                    </InvalidBlock>
                );

            }

        } else {
            const disabled = date.isAfter(moment(), "day") || isSuspended(daycares[0].activeMonths, date);

            return (
                <EmptyBlock className={classNames({ disabled })} onClick={() => this.props.createRegistration({ create: { childId: child.id, date: date.toISOString() } })}>
                    +
                </EmptyBlock>
            );
        }

    }

    private changeDate(weekDate: moment.Moment) {

        const { setDate, setAggregation } = this.props;

        setDate({
            startDate: weekDate.clone().startOf("week"),
            endDate: weekDate.clone().endOf("week")
        });

        setAggregation({ aggregation: Aggregation.DAY });
    }
}
