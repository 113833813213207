import styled from "styled-components";

import { Placeholder } from "@style/placeholder";
import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";
import { Colors } from "@style/colors";
import { Button } from "@style/button";

export const SubHeaderStyle = styled.div`
    width: 100%;
    background-color: ${Colors.primary()};
    padding: ${Padding.medium} 0;
    color: ${Colors.white};


    ${Placeholder} {
        height: 40px;
        width: 200px;
        opacity: 0.3;
    }

    &.withBackButton {
        padding: 14px 0 0;

        .left {
            cursor: pointer;
            &:hover {
                opacity: .7;
            }
        }
    }

    .left {
        .icon {
            color: ${Colors.white};
            display: block;
            transition: opacity .2s;
            padding: 28px ${Padding.medium} ${Padding.medium} 0;
        }
    }

    ${InnerContainer}, .left, .right {
        display: flex;
        align-items: center;
    }

    ${InnerContainer} {
        justify-content: space-between;
    }

    .icon.back {
        font-size: 10px;
    }

    .info {
        font-weight: 200;
        font-size: 24px;
    }

    .right > span {
        cursor: pointer;


        &:hover {
            opacity: 0.7;
        }
    }


    ${Button}{
        width: 170px;
        &:not(:last-of-type) {
            margin-right: 15px;
        }
    }

    .divider {
        height: 14px;
        width: 1px;
        background-color: ${Colors.white};
        margin: 0 ${Padding.medium};
    }



    @media (max-width: 650px) {
        .info {
            font-size: 18px;
        }
    }
`;
