import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";
import { Aggregation } from "@models/graphql/types";

export interface SetRegistrationAggregationActionOptions {
    aggregation: Aggregation;
}

export const setRegistrationAggregation = (data: SetRegistrationAggregationActionOptions): SetRegistrationAggregationAction => {
    return {
        type: ActionTypes.SetRegistrationAggregation,
        ...data
    };
};

export type SetRegistrationAggregationAction = Action & SetRegistrationAggregationActionOptions;
export const isSetRegistrationAggregationAction = (action: Action): action is SetRegistrationAggregationAction => action.type === ActionTypes.SetRegistrationAggregation;
