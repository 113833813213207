import { Popover, message } from "antd";
import classNames from "classnames";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@style/icon";
import { DaycareListItemStyle } from "@components/daycare/daycareListItem/daycareListItemStyle";
import { DaycareListItemProps } from "@components/daycare/daycareListItem/daycareListItemContainer";
import { RoleComponent } from "@components/roleComponent/roleComponent";
import { MoreActionsButtonContainer } from "@components/moreActionsButton/moreActionsButtonContainer";
import { Role } from "@utils/user";
import { HiddenButtonsButtonContainer } from "@components/hiddenButtonsButton/hiddenButtonsButtonContainer";

export interface DaycareListItemState {

}

@autobind
export class DaycareListItem extends React.Component<DaycareListItemProps, DaycareListItemState> {

    public render() {

        const { daycare } = this.props;
        const { name, caseNumber, address, hasInvalidRegistrations, hasInvalidUploads, inactiveMonths, isSuspended, id } = daycare;

        const isInactive = inactiveMonths.find(month => moment().isSame(month.startOfMonth, "month")) ? true : false;

        const invalid = hasInvalidRegistrations || hasInvalidUploads;

        return (
            <DaycareListItemStyle className={classNames({ isInactive, invalid, isSuspended })}>
                {invalid && <div className="errorBlock" />}

                <div className="info" onClick={this.redirectToDaycare}>
                    <span>
                        {name} - {caseNumber}
                    </span>
                    <span>
                        {address}
                    </span>
                </div>

                <div className="status" onClick={this.redirectToDaycare}>
                    {!isInactive && !isSuspended ? <>
                        {hasInvalidRegistrations ? <Icon className="fail" type="time" /> : <Popover content="Registraties"><Icon type="time" /></Popover>}
                        {hasInvalidUploads ? <Icon className="fail" type="upload-fail" /> : <Popover content="Uploads"><Icon type="upload-success" /></Popover>}

                    </> :
                        isInactive ? <FormattedMessage id="daycareListItem.inactive" /> : <FormattedMessage id="daycareListItem.suspended" />
                    }
                </div>
                <RoleComponent requiredRoles={[Role.ORGANISER]}>
                    <HiddenButtonsButtonContainer>
                        <span onClick={this.logoutAllUsers} className="item"><FormattedMessage id="moreActionsButton.logoutAllUsers" /></span>
                    </HiddenButtonsButtonContainer>
                </RoleComponent>
            </DaycareListItemStyle>
        );
    }

    private async logoutAllUsers() {
        // log out all the users with mutation

        const { daycare, logoutDaycare, intl } = this.props;

        if (daycare) {
            const succeeded = await logoutDaycare({
                caseNumber: daycare.caseNumber
            });

            if (succeeded) {
                message.success(intl.formatMessage({ id: "logout.successful"}));
            }
        }
    }

    private redirectToDaycare() {
        const { daycare, history, setDaycare } = this.props;
        const { id } = daycare;

        setDaycare({ daycareId: id });

        history.push(`/daycare`);
    }
}
