import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { withQueueLinkState } from "@graphql/withQueueLinkState";
import { QueueLinkState } from "@graphql/queueLinkProvider";
import {
    createGetChildrenRegistrationListQuery,
    GetChildrenRegistrationListQueryResult
} from "@graphql/hocs/getChildrenRegistrationList";
import { RegistrationList } from "@components/registrations/registrationList/registrationList";

export interface RegistrationListContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationListContainerProps) => ({
    aggregation: state.registration.aggregation,
    childrenFilter: state.registration.childrenFilter,
    startDate: state.registration.startDate,
    endDate: state.registration.endDate
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetChildrenRegistrationList = createGetChildrenRegistrationListQuery<RegistrationListContainerProps & WithReduxStateProps>({
    options(ownProps) {
        const { aggregation, childrenFilter } = ownProps;

        return {
            variables: {
                aggregation,
                filter: {
                    ...childrenFilter,
                    isActive: true
                }
            }
        };
    }
});

export const RegistrationListContainer = compose<React.ComponentClass<RegistrationListContainerProps>>(
    withRedux,
    withQueueLinkState,
    withGetChildrenRegistrationList
)(RegistrationList);

export type RegistrationListProps =
    RegistrationListContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    GetChildrenRegistrationListQueryResult &
    QueueLinkState;
