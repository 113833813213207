import { Button, message, Menu, Dropdown, Modal, Checkbox } from "antd";
import { autobind } from "core-decorators";
import { saveAs } from "file-saver";
import * as moment from "moment";
import * as React from "react";

import { parseError } from "@utils/parseError";

import { ExportExcelButtonProps } from "./exportExcelButtonContainer";
import { DownloadType } from "@models/graphql/types";

export interface ExportExcelButtonState {
    registrationsLoading: boolean;
    selectedIds?: number[];
    exportModal?: {
        visible: boolean;
        type: DownloadType;
    };
}

@autobind
export class ExportExcelButton extends React.Component<ExportExcelButtonProps, ExportExcelButtonState> {

    public state: ExportExcelButtonState = {
        registrationsLoading: false
    };

    public render() {
        const { registrationsLoading } = this.state;

        const options = (this.props.children || []).map(child => ({ label: `${child.firstName} ${child.lastName}`, value: child.id }));

        const modal = (
            <Modal
                okText="Exporteer"
                cancelText="Annuleren"
                title="Kies welke data je wilt exporteren"
                visible={(this.state.exportModal && this.state.exportModal.visible) || false}
                onOk={() => this.downloadFile((this.state.exportModal && this.state.exportModal.type))}
                onCancel={this.hideModal}
            >
                <Checkbox.Group
                    onChange={(selected: number[]) => this.setState({ selectedIds: selected })}
                    options={options}
                    value={this.state.selectedIds || []}
                />
            </Modal>
        );

        const menu = (
            <Menu>
                <Menu.Item onClick={() => this.showModal(DownloadType.Excel)}>
                    Exporteer naar Excel
                </Menu.Item>
                <Menu.Item onClick={() => this.showModal(DownloadType.Csv)}>
                    Exporteer naar CSV
                </Menu.Item>
            </Menu>
        );

        return (
            <>
                {options.length && modal}
                <Dropdown overlay={menu} placement="bottomRight">
                    <Button type="primary" loading={registrationsLoading} disabled={registrationsLoading}>Exporteer</Button>
                </Dropdown>
            </>
        );
    }

    private async showModal(type: DownloadType) {
        this.setState({
            selectedIds: (this.props.children || []).map(child => child.id),
            exportModal: {
                visible: true,
                type
            }
        });
    }

    private async hideModal() {
        this.setState({
            selectedIds: undefined,
            exportModal: undefined
        });
    }

    private async downloadFile(type?: DownloadType) {

        const { year, month, selectedDaycareId, getRegistrations, intl } = this.props;

        this.setState({ registrationsLoading: true });

        try {

            const registrations = await getRegistrations({
                filter: {
                    registrationsFrom: moment().set("year", year).set("month", month).set("date", 1).startOf("month").format("YYYY-MM-DD"),
                    registrationsTo: moment().set("year", year).set("month", month).set("date", 1).endOf("month").format("YYYY-MM-DD"),
                    daycareId: selectedDaycareId ? selectedDaycareId : "",
                    childIds:
                        this.state.selectedIds
                },
                type
            });

            if (registrations) {
                switch (type) {
                    case DownloadType.Csv:
                        saveAs(`data:${registrations.contentType};charset=utf-8,${registrations.content}`, registrations.fileName);
                        break;
                    case DownloadType.Excel:
                        saveAs(`data:${registrations.contentType};base64,${registrations.content}`, registrations.fileName);
                        break;
                    default:
                        saveAs(`data:${registrations.contentType};base64,${registrations.content}`, registrations.fileName);
                        break;
                }

                this.hideModal();
            }
        } catch (err) {
            message.error(intl.formatMessage({ id: parseError(err) }));
        }

        this.setState({ registrationsLoading: false });

    }
}
