import * as React from "react";

import { compose, Dispatch } from "redux";

import { RegistrationErrorPage } from "@pages/registrationErrorPage/registrationErrorPage";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { setRegistrationFlowCode } from "@redux/actions/setRegistrationFlowCode";
import { connect } from "react-redux";

export interface RegistrationErrorPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationErrorPageContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    retry() { dispatch(setRegistrationFlowCode({ code: null })); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const RegistrationErrorPageContainer = compose<React.ComponentClass<RegistrationErrorPageContainerProps>>(
    withRedux
)(RegistrationErrorPage);

export type RegistrationErrorPageProps =
    RegistrationErrorPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
