import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { UpdateRegistrationPage } from "@pages/updateRegistrationPage/updateRegistrationPage";
import { withRouter, RouteComponentProps } from "react-router";
import { UpdateEditRegistrationActionOptions, updateEditRegistration } from "@redux/actions/updateEditRegistrationId";
import { setRegistrationFlowRegistrationResult, SetRegistrationFlowRegistrationResultActionOptions } from "@redux/actions/setRegistrationFlowRegistrationResult";

export interface UpdateRegistrationPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: UpdateRegistrationPageContainerProps) => ({
    registration: state.registration.editRegistration,
    entryType: state.registrationFlow.entryType
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    updateEditRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); },
    setRegistrationFlowRegistrationResult(data: SetRegistrationFlowRegistrationResultActionOptions) { dispatch(setRegistrationFlowRegistrationResult(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const UpdateRegistrationPageContainer = compose<React.ComponentClass<UpdateRegistrationPageContainerProps>>(
    withRedux,
    withRouter
)(UpdateRegistrationPage);

export type UpdateRegistrationPageProps =
    UpdateRegistrationPageContainerProps &
    WithReduxDispatchProps &
    RouteComponentProps &
    WithReduxStateProps;
