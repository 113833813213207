import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";

export const ErrorBlockStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${Padding.large};
    text-align: center;
    background-color: ${Colors.white};
    border-radius: 4px;

    h1 {
        color: ${Colors.error()};
        font-size: 48px;
        font-weight: bold;
        margin-top: ${Padding.large};
    }

    h2 {
        color: ${Colors.error()};
        font-size: 16px;
        font-weight: 400;
        margin-top: ${Padding.large};
        line-height: 140%;
    }

    img {
        width: 100px;
    }
`;
