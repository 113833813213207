import { GetUploadDetailQuery, GetUploadDetailVariables, GetUploadDetailUploads } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";

export interface GetUploadDetailQueryResult {
    uploadDetail?: GetUploadDetailUploads;
    uploadDetailLoading: boolean;
}

export const createGetUploadDetailQuery = <TProps = {}>(options?: OperationOption<TProps, GetUploadDetailQuery, GetUploadDetailVariables, GetUploadDetailQueryResult>) =>
    graphql<TProps, GetUploadDetailQuery, GetUploadDetailVariables, GetUploadDetailQueryResult>(GraphQL.getUploadDetail, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                uploadDetail: data.uploads && data.uploads && data.uploads[0],
                uploadDetailLoading: data.loading
            };
        },
        ...options
    });
