import { Form, Input, message } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Role } from "@utils/user";
import { parseError } from "@utils/parseError";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { SettingsPageStyle } from "@pages/settingsPage/settingsPageStyle";
import { SettingsPageProps } from "@pages/settingsPage/settingsPageContainer";
import { RoleComponent } from "@components/roleComponent/roleComponent";
import { Alert } from "@components/alert/alert";

export interface SettingsPageState {
    submittingEmail: boolean;
    submitEmailError: string | null;
    submitEmailSuccess: string | null;
    submittingPassword: boolean;
    submitPasswordError: string | null;
    submitPasswordSuccess: string | null;
}

interface FormValues {
    oldPassword: string;
    newPassword: string;
    email: string;
}

@autobind
export class SettingsPage extends React.Component<SettingsPageProps, SettingsPageState> {
    public state: SettingsPageState = {
        submitEmailError: null,
        submitPasswordError: null,
        submitEmailSuccess: null,
        submitPasswordSuccess: null,
        submittingEmail: false,
        submittingPassword: false
    };

    public render() {

        const { form, intl, user } = this.props;
        const { submitEmailError, submitPasswordError, submittingEmail, submittingPassword, submitEmailSuccess, submitPasswordSuccess } = this.state;

        return (
            <SettingsPageStyle>
                <div className="greenBlock" />
                <InnerContainer>
                    <Link to="/"><Icon type="back" /><FormattedMessage id="settingsPage.back" /></Link>
                    <Box>
                        <Form >
                            <FormattedMessage tagName="h1" id="settingsPage.email" />

                            <RoleComponent requiredRoles={[Role.PARENT]}>
                                <Form.Item label={intl.formatMessage({ id: "settingsPage.email" })}>
                                    {form.getFieldDecorator("email", {
                                        initialValue: user && user.email,
                                        rules: [{ required: true, type: "email", message: intl.formatMessage({ id: "settingsPage.emailError" }) }]
                                    })(
                                        <Input size="large" />
                                    )}
                                </Form.Item>
                                {submitEmailError && <Alert type="error"><FormattedMessage id={submitEmailError} /></Alert>}
                                {submitEmailSuccess && <Alert type="success"><FormattedMessage id={submitEmailSuccess} /></Alert>}
                                <Button loading={submittingEmail} onClick={this.saveEmail} type="primary"><FormattedMessage id="settingsPage.saveEmail" /></Button>
                            </RoleComponent>
                            <RoleComponent requiredRoles={[Role.ORGANISER]}>
                                Je huidige emailadres is {user && user.email}
                                <FormattedMessage tagName="em" id="settingsPage.contactKandG" />
                            </RoleComponent>

                            <FormattedMessage tagName="h1" id="settingsPage.changePassword" />

                            <RoleComponent requiredRoles={[Role.PARENT, Role.ORGANISER]}>
                                <Form.Item label={intl.formatMessage({ id: "settingsPage.oldPassword" })}>
                                    {form.getFieldDecorator("oldPassword", {
                                        rules: [{ required: true, message: intl.formatMessage({ id: "settingsPage.passwordError" }) }]
                                    })(
                                        <Input type="password" size="large" />
                                    )}
                                </Form.Item>
                                <Form.Item label={intl.formatMessage({ id: "settingsPage.newPassword" })}>
                                    {form.getFieldDecorator("newPassword", {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ id: "settingsPage.passwordError" }) }
                                        ]
                                    })(
                                        <Input type="password" size="large" />
                                    )}
                                </Form.Item>
                            </RoleComponent>
                            {submitPasswordError && <Alert type="error"><FormattedMessage id={submitPasswordError} /></Alert>}
                            {submitPasswordSuccess && <Alert type="success"><FormattedMessage id={submitPasswordSuccess} /></Alert>}
                            <Button loading={submittingPassword} onClick={this.savePassword} type="primary"><FormattedMessage id="settingsPage.savePassword" /></Button>
                        </Form>
                    </Box>
                </InnerContainer>
            </SettingsPageStyle>
        );
    }

    private saveEmail(e: React.MouseEvent) {
        e.preventDefault();

        const { form, changeEmail, intl } = this.props;

        form.validateFields(["email"], async (errors, values: FormValues) => {
            try {
                if (!errors) {

                    this.setState({ submittingEmail: true, submitEmailError: null, submitEmailSuccess: null });

                    const { email } = values;
                    const result = await changeEmail({
                        email
                    });

                    if (result) {
                        this.setState({ submittingEmail: false, submitEmailSuccess: "settingsPage.emailChangeSuccessfull" });
                        setTimeout(() => this.setState({ submitEmailSuccess: null }), 3000);
                    } else {
                        this.setState({ submittingEmail: false, submitEmailError: "settingsPage.emailChangeError" });
                    }
                }
            } catch (error) {
                this.setState({ submittingEmail: false, submitEmailError: parseError(error) });
            }
        });
    }

    private savePassword(e: React.MouseEvent) {
        e.preventDefault();

        const { form, changePassword, intl } = this.props;

        form.validateFields(["oldPassword", "newPassword"], async (errors, values: FormValues) => {
            try {
                if (!errors) {

                    this.setState({ submittingPassword: true, submitPasswordError: null, submitPasswordSuccess: null });

                    const { oldPassword, newPassword } = values;
                    const result = await changePassword({
                        oldPassword,
                        newPassword
                    });

                    if (result) {
                        this.setState({ submittingPassword: false, submitPasswordSuccess: "settingsPage.passwordChangeSuccessfull" });
                        form.setFieldsValue({
                            oldPassword: "",
                            newPassword: ""
                        });

                        setTimeout(() => this.setState({ submitPasswordSuccess: null }), 3000);
                    } else {
                        this.setState({ submittingPassword: false, submitPasswordError: "settingsPage.passwordChangeError" });
                    }
                }
            } catch (error) {
                this.setState({ submittingPassword: false, submitPasswordError: parseError(error) });
            }
        });
    }
}
