import styled from "styled-components";

import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";
import { Colors } from "@style/colors";

export const DaycareKeypadLoginStyle = styled.div`
    background: ${Colors.primary()};
    height: 100%;
    overflow-y: auto;

    span.back {
        z-index: 1000;
    }

    ${InnerContainer} {
        padding-top: ${Padding.large};
        padding-bottom: ${Padding.large};
        position: relative;
    }

    .logout {
        position: absolute;
        top: ${Padding.large};
        right: ${Padding.medium};
        color: ${Colors.white};
        font-size: 16px;
        cursor: pointer;
    }

    .ant-spin-blur {
        opacity: 0.3
    }

    .ant-spin-dot {
        width: 40px;
        height: 40px;
    }
    .ant-spin-dot i {
        background-color: ${Colors.white};
        width: 16px;
        height: 16px;
    }


    &:not(.online) {
    }

    .offlineCover {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        color: ${Colors.white};
        background-color: rgba(0,0,0,0.5);

        h1 {
            color: ${Colors.white};
            font-size: 30px;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
        }
    }
`;
