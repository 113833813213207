import * as moment from "moment";

import { AddEntryVariables, DeleteLastEntryVariables } from "@models/graphql/types";

import { OperationQueueEntry } from "../queueLink";
import { HandleMutationResult, MutationHandler } from "./mutationHandler";

export class DeleteLastEntryHandler extends MutationHandler {
    public mutationName: string = "deleteLastEntry";

    public handleMutation(observer: ZenObservable.SubscriptionObserver<any>, operationQueueEntry: OperationQueueEntry, operationQueue: OperationQueueEntry[]): HandleMutationResult {

        const { operation } = operationQueueEntry;

        const vars = operation.variables as DeleteLastEntryVariables;

        const lastEntryIndex = operationQueue.sort((a, b) => moment(a.time).isBefore(b.time) ? 1 : -1).findIndex(op => {
            if (op.operation.operationName === "addEntry") {
                const addEntryVars = op.operation.variables as AddEntryVariables;

                if (addEntryVars.childCode === vars.childCode) {
                    return true;
                }

            }

            return false;

        });

        observer.next({ data: { deleteLastEntry: true } });

        return {
            enqueueMutation: false,
            operationQueue: operationQueue.slice(lastEntryIndex, 1)
        };

    }

    public initialize() {

    }
}
