import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";

export const RegistrationErrorPageStyle = styled.div`
    background: ${Colors.error()};
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${Colors.white};
    text-align: center;

    h1, h2, h3 {
        color: ${Colors.white};
    }

    img {
        margin-bottom: ${Padding.large};
        width: 80px;
        opacity: 0.8;
    }

    h1 {
        font-size: 50px;
        font-weight: 200;
        margin-bottom: ${Padding.medium};
    }

    h2 {
        font-size: 30px;
        font-weight: 600;
    }

    ${Button} {
        width: 100%;
        max-width: 350px;
        height: 50px;
        color: ${Colors.error()};
        margin: 100px 0 ${Padding.large};
        font-weight: 600;
        font-size: 14px;

        &:hover {
            color: ${Colors.text};
        }
    }

    a {
        color: ${Colors.white};
        &:hover {
            color: ${Colors.text};
        }
    }

`;
