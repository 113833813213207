import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { AlertStyle } from "@components/alert/alertStyle";

export const ChildSearchStyle = styled.div`
    padding: ${Padding.medium};

    h1 {
        font-size: 45px;
        color: ${Colors.primary()};
        font-weight: 200;
        margin-bottom: ${Padding.large};
    }

    .ant-form-item-label {
        line-height: inherit;
        padding-bottom: 8px;
        label {
            margin: 0;
            color: ${Colors.primary()};
        }
    }

    label {
        color: ${Colors.primary()};
        display: block;
        margin-top: ${Padding.small};
    }

    form {
        display: flex;
        align-items: flex-start;

        ${Button} {
            margin-top: 28px;
        }
    }

    h2 + h3 {
        margin-bottom: ${Padding.medium};
    }
    .ant-input, .ant-select {
        height: 38px;
        width: 100%;
    }

    .ant-select-selection {
        height: 38px;
    }

    .ant-select-selection__rendered {
        line-height: 35px;
    }

    .ant-calendar-picker {
        width: 100%;
    }

    .ant-form-item-with-help {
        margin-bottom: 0;
    }
    .ant-form-item {
        margin-bottom: 0px;
        width: 100%;
        margin-right: 15px;
    }

    .ant-input-number {
        width: 100%;
    }

    .ant-input-number-handler-wrap {
        opacity: 1;
    }

    ${Button} {
        width: auto;
        height: 38px;
        .icon {
            padding: 0;
        }
    }

    ${AlertStyle} {
        margin-top: ${Padding.medium};
    }


    .result {
        margin-top: ${Padding.medium};
        width: 100%;

        h2 {
            color: ${Colors.primary()};
            font-size: 20px;
            margin-bottom: ${Padding.large};
        }

        &.empty {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100px;
            opacity: 0.5;
            font-size: 14px;
        }
    }
`;

export const ChildItem = styled.div`
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
        margin-bottom: ${Padding.large};
    }

    h3 {
        font-size: 18px;
        color: ${Colors.primary()};
    }

    &.wrong {
        background: ${Colors.lightGrey};

        margin: 0 -${Padding.medium} -17px -${Padding.medium};
        padding: 50px ${Padding.medium};
    }

    .left {
        display: flex;
        flex-direction: column;

        span:first-of-type {
            font-weight: 200;
            font-size: 20px;
            padding-bottom: 13px;
        }
        span:last-of-type {
            font-weight: 200;
            color: ${Colors.grey};
            font-size: 14px;
        }
    }

    ${Button} {
        height: 50px;
        width: 200px;
    }
`;
