import styled from "styled-components";

import { Placeholder } from "@style/placeholder";
import { Padding } from "@style/padding";

export const RegistrationTableStyle = styled.div`
    margin-bottom: ${Padding.large};
    ${Placeholder} {
        height: 75px;
        margin-top: 8px;
    }
`;
