import { graphql } from "react-apollo";

import { RefreshLoginMutation, RefreshLoginRefreshLogin, RefreshLoginVariables } from "@models/graphql/types";
import { GraphQL } from "@graphql/graphQL";

export interface RefreshLoginMutationResult {
    refreshLogin(variables: RefreshLoginVariables): Promise<RefreshLoginRefreshLogin>;
}

export const createRefreshLoginMutation = <TProps = {}>() => {
    return graphql<TProps, RefreshLoginMutation, RefreshLoginVariables, RefreshLoginMutationResult>(GraphQL.refreshLogin, {
        props({ mutate }): RefreshLoginMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async refreshLogin(variables: RefreshLoginVariables): Promise<RefreshLoginRefreshLogin> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("No result or data");
                        }

                        return result.data.refreshLogin;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        }
    });
};
