import { Form, Input } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { AuthService } from "@utils/authService";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { ParentLoginFormStyle } from "@components/parentLoginForm/parentLoginFormStyle";
import { ParentLoginFormProps } from "@components/parentLoginForm/parentLoginFormContainer";
import { Alert } from "@components/alert/alert";

export interface ParentLoginFormState {
    submitting: boolean;
    submitError: string | null;
}

@autobind
export class ParentLoginForm extends React.Component<ParentLoginFormProps, ParentLoginFormState> {
    public state: ParentLoginFormState = {
        submitError: null,
        submitting: false
    };

    public render() {
        const { intl, form, match } = this.props;

        const { submitError, submitting } = this.state;

        let showSuccess = false;

        if (match.params.status === "success" && !submitError && !submitting) {
            showSuccess = true;
        }

        return (
            <ParentLoginFormStyle>
                <InnerContainer>
                    <h1><FormattedMessage id="parentLoginForm.title" /></h1>
                    <Box>
                        <Form onSubmit={this.submitForm}>
                            <Form.Item label={intl.formatMessage({ id: "parentLoginForm.email" })}>
                                {form.getFieldDecorator("email", {
                                    validateTrigger: "onBlur",
                                    rules: [{ required: true, type: "email", message: intl.formatMessage({ id: "parentLoginForm.emailError" }) }]
                                })(
                                    <Input size="large" />
                                )}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage({ id: "parentLoginForm.password" })}>

                                {form.getFieldDecorator("password", {
                                    rules: [{ required: true, message: intl.formatMessage({ id: "parentLoginForm.passwordError" }) }]
                                })(
                                    <Input size="large" type="password" />
                                )}
                            </Form.Item>
                            <Alert type="error">{submitError}</Alert>
                            <Alert type="success">{showSuccess && <FormattedMessage id="setPassword.success" />}</Alert>
                            <Button htmlType="submit" loading={submitting} onClick={this.submitForm} type="primary"><FormattedMessage id="parentLoginForm.login" /></Button>
                        </Form>
                        <div className="bottomBar">
                            <Link className="back" to="/login"><Icon type="back" /><FormattedMessage id="parentLoginForm.back" /></Link>
                            <Link tabIndex={-1} className="resetPassword" to="/login/reset-password"><FormattedMessage id="parentLoginForm.resetPassword" /></Link>
                        </div>
                    </Box>
                </InnerContainer>
            </ParentLoginFormStyle>
        );
    }

    private submitForm(e) {
        e.preventDefault();
        const { form, intl, history, login } = this.props;

        form.validateFields(async (errors, values) => {
            if (!errors) {

                try {
                    this.setState({
                        submitting: true,
                        submitError: null
                    });

                    const loginResult = await login({
                        username: values.email,
                        password: values.password
                    });

                    const user = AuthService.login(loginResult);

                    if (user) {
                        this.setState({
                            submitting: false,
                            submitError: null
                        });

                        history.push("/");
                    }
                } catch (err) {
                    if (err.graphQLErrors && err.graphQLErrors[0]) {
                        const { message } = err.graphQLErrors[0];

                        if (message && message.startsWith("error.login")) {
                            this.setState({
                                submitError: intl.formatMessage({ id: message }),
                                submitting: false
                            });
                        }
                    } else {
                        this.setState({
                            submitError: intl.formatMessage({ id: "parentLoginForm.somethingWentWrong" }),
                            submitting: false
                        });
                    }
                }
            }
        });
    }
}
