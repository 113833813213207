import * as React from "react";
import { autobind } from "core-decorators";

export type LocalStateProps<TState> = {
    setLocalState(state: Partial<TState>): void;
} & TState;

export const createLocalState = <TState = {}>(initalState: TState) => (Component: React.ComponentClass) =>
    class WithLocalState extends React.Component<any, TState> {
        public state: any = initalState;

        constructor(props) {
            super(props);

            this.setLocalState = this.setLocalState.bind(this);
        }

        public render() {
            return React.createElement(Component, { ...this.props, ...this.state, setLocalState: this.setLocalState });
        }

        private setLocalState(state) {
            this.setState({
                ...state
            });
        }
    };
