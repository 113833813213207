import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { EntryType } from "@models/graphql/types";

interface QrCodeReaderProps {
    width: number;
    height: number;
    type: EntryType;
}

const viewFinderSize = 130;

export const QrCodeReaderStyle = styled.div`
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 100vh;
    background: ${props => props.type === EntryType.IN ? Colors.primary() : Colors.secondary()};

    section {
        width: ${(props: QrCodeReaderProps) => props.width}px !important;
        height: ${(props: QrCodeReaderProps) => props.height}px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a.back {
        align-items: center;
        color: white;
        position: absolute;
        top: 30px;
        left: 30px;
        font-size: 20px;
        display: flex;
        z-index: 10;
        padding: 20px 20px 20px 0;

        .icon {
            font-size: 10px;
            margin-right: ${Padding.small};
        }
    }

    .topRight {
        align-items: center;
        color: white;
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 20px;
        display: flex;
        z-index: 10;
        cursor: pointer;

        .icon {
            font-size: 15px;
            margin-right: ${Padding.small};
        }

        & + .topRight {
            top: 80px;
        }

    }

    &.topRight {
        font-size: 14px;
    }


    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 5;

            .spacer {
                height: ${viewFinderSize * 2}px;
                width: 20px;
                flex-shrink: 0;
            }

            .before, .after {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                width:100%;

                & > a {
                    max-width: 350px;
                    width: 100%;
                }
            }

            .before {
                justify-content: flex-end;
            }

            ${Button} {
                height: 50px;
                max-width: 350px;
                width: 100%;
                font-weight: 600;
                margin-top: ${Padding.medium};


                .icon {
                    position: absolute;
                    left: 20px;
                    font-size: 18px;
                }

                &.ant-btn-primary {
                    background: ${props => props.type === EntryType.IN ? Colors.primary() : Colors.secondary()};
                    color: ${Colors.white};
                }

                color: ${props => props.type === EntryType.IN ? Colors.primary() : Colors.secondary()};

                border-color: ${props => props.type === EntryType.IN ? Colors.primary() : Colors.secondary()};
            }
        }

        .info {
            background: ${Colors.white};
            font-weight: 600;
            display: block;
            flex-grow: 0;
            position: relative;
            padding: ${Padding.medium};
            box-shadow: 0 10px 15px 0 rgba(0,0,0,0.20);
            margin-bottom: 20px;

            &::before {
                display: block;
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                left: calc(50% - 5px);
                bottom: -5px;
                transform: rotate(45deg);
                background: ${Colors.white};
                border-radius: 2px;
            }
        }

        .viewfinder {
            position: absolute;
            bottom: calc(50% - ${viewFinderSize}px);
            left: calc(50% - ${viewFinderSize}px);
            right: calc(50% - ${viewFinderSize}px);
            top: calc(50% - ${viewFinderSize}px);
            border: 2px solid;

            border-color: ${props => props.type === EntryType.IN ? Colors.primary() : Colors.secondary()}
        }


        .top, .bottom, .left, .right {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.2);
        }


        .top {
            bottom: calc(50% + ${viewFinderSize}px);
        }

        .left {
            bottom: calc(50% - ${viewFinderSize}px);
            top: calc(50% - ${viewFinderSize}px);
            right: calc(50% + ${viewFinderSize}px);
        }

        .right {
            bottom: calc(50% - ${viewFinderSize}px);
            top: calc(50% - ${viewFinderSize}px);
            left: calc(50% + ${viewFinderSize}px);
        }

        .bottom {
            top: calc(50% + ${viewFinderSize}px);
        }
    }

    @media (max-width: 400px) {
        .topRight {
            font-size: 14px;

            & + .topRight {
                top: 60px;
            }
        }

        a.back {
            top: 10px;
            left: 10px;
            font-size: 14px;
        }
    }

    @media (max-height: 600px) {


    }

`;

export const ErrorInfo = styled.span`
    max-width: 280px;
    display: block;

    em {
        font-style: oblique;
        margin-top: 10px;
        font-size: 13px;
        display: block;
    }
`;
