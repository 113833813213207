import { Input } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Placeholder } from "@style/placeholder";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { ChildItem, ManualChildEntryPageStyle } from "@pages/manualChildEntryPage/manualChildEntryPageStyle";
import { ManualChildEntryPageProps } from "@pages/manualChildEntryPage/manualChildEntryPageContainer";
import { EntryType } from "@models/graphql/types";
import { ErrorBlock } from "@components/errorBlock/errorBlock";

export interface ManualChildEntryPageState {

}

@autobind
export class ManualChildEntryPage extends React.Component<ManualChildEntryPageProps, ManualChildEntryPageState> {

    public render() {

        const { intl, childSearch } = this.props;

        return (
            <ManualChildEntryPageStyle>
                <InnerContainer>
                    <header>
                        <FormattedMessage id="manualChildEntryPage.title" tagName="h1" />
                        <Link to="/registration"><FormattedMessage id="manualChildEntryPage.cancel" /></Link>
                    </header>

                    <Input value={childSearch} onChange={this.onSearch} placeholder={intl.formatMessage({ id: "manualChildEntryPage.name" })} suffix={<Icon type="search" />} />

                    {this.renderChildren()}
                </InnerContainer>
            </ManualChildEntryPageStyle>
        );
    }

    private renderChildren() {
        const { childrenList: children, intl, childrenLoading, childSearch, entryType } = this.props;

        if (childrenLoading) {
            return (
                <div className="items">
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </div>
            );
        }

        if (!children && childSearch !== "") {
            return (
                <div className="items">
                    <ErrorBlock />
                </div>
            );
        }

        if (children) {
            if (!children.length && childSearch !== "") {
                return (
                    <div className="items">
                        <div className="empty">
                            <FormattedMessage id="manualChildEntryPage.noChildrenFound" />
                        </div>
                    </div>
                );
            }

            return (
                <div className="items">
                    {children.map(child => (
                        <ChildItem key={child.id}>
                            <div className="info">
                                {child.firstName} {child.lastName}
                                <span>{intl.formatMessage({ id: `gender.${child.gender.toLowerCase()}` })} | {moment(child.birthdate, "YYYY-MM-DD").format("L")}</span>
                            </div>
                            <div className="right">
                                <Button onClick={() => this.addEntry(child.code, EntryType.IN)}>
                                    <FormattedMessage id="manualChildEntryPage.checkin" />
                                </Button>
                                <Button onClick={() => this.addEntry(child.code, EntryType.OUT)}>
                                    <FormattedMessage id="manualChildEntryPage.checkout" />
                                </Button>
                            </div>
                        </ChildItem>
                    ))}
                </div>
            );
        }
    }

    private addEntry(code: string, entryType: EntryType) {
        const { setCode, history, setEntryType } = this.props;

        setEntryType({ entryType });
        setCode({ code });

        history.push(`/registration/check${entryType === EntryType.IN ? "in" : "out"}`);
    }

    private onSearch(val: React.ChangeEvent<HTMLInputElement>) {
        if (val.currentTarget.value) {
            this.props.setLocalState({
                childSearch: val.currentTarget.value
            });
        } else {
            this.props.setLocalState({
                childSearch: ""
            });
        }
    }
}
