import { Col, DatePicker, Form, Row, Select, Spin } from "antd";
import * as classNames from "classnames";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Role } from "@utils/user";
import { parseError } from "@utils/parseError";
import { canEditRegistration } from "@utils/canEditRegistration";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Entry, EntryInput, EntryType } from "@models/graphql/types";
import { TimeInput } from "@components/timeInput/timeInput";
import { RegistrationFormStyle, SelectItem } from "@components/registrationForm/registrationFormStyle";
import { RegistrationFormProps } from "@components/registrationForm/registrationFormContainer";
import { Alert } from "@components/alert/alert";

export interface RegistrationFormState {
	entries: Entry[];
	cleared: boolean;

	entryErrors: string[];
	submitting: boolean;
	submitError: string | null;
	disabled: boolean;
}

const day = moment().startOf("day");

interface FormValues {
	date: moment.Moment;
	child: number;
}

@autobind
export class RegistrationForm extends React.Component<RegistrationFormProps, RegistrationFormState> {

	private get childrenDatasource(): JSX.Element[] {

		const { childrenList: children, child } = this.props;
		const items: JSX.Element[] = [];

		if (children) {
			children.forEach(item => {
				items.push(<Select.Option key={String(item.id)} value={item.id}>{item.firstName} {item.lastName}</Select.Option>);
			});
		}

		if (child && (!children || !children.some(c => c.id === child.id))) {
			items.push(<Select.Option key={String(child.id)} value={child.id}>{child.firstName} {child.lastName}</Select.Option>);
		}

		return items;
	}

	public state: RegistrationFormState = {
		entries: [],
		entryErrors: [],
		cleared: true,
		submitting: false,
		submitError: null,
		disabled: false
	};

	public static getDerivedStateFromProps(props: RegistrationFormProps, state: RegistrationFormState): Partial<RegistrationFormState> | null {

		if (state.cleared) {

			if (props.editRegistrationState) {

				if (!canEditRegistration(moment(props.editRegistrationState.date), props.roles)) {
					return {
						cleared: false,
						disabled: true
					};
				}

				const newState = {
					...state,
					cleared: false,
					entries: [...props.editRegistrationState.registration.entries],
					entryErrors: []
				};

				const { entryErrors } = RegistrationForm.validateEntries(props, newState);

				return {
					...newState,
					entryErrors
				};
			}

			if (props.createRegistrationState) {
				if (!canEditRegistration(moment(props.createRegistrationState.date), props.roles)) {
					return {
						cleared: false,
						disabled: true
					};
				}

				return {
					cleared: false,
					entries: [],
					entryErrors: []
				};
			}
		}

		return null;
	}

	private static validateEntries(props: RegistrationFormProps, state: RegistrationFormState) {
		const { form } = props;
		const { entries } = state;

		let date: moment.Moment = form.getFieldValue("date");

		const entryErrors: string[] = [];
		let entriesValid = true;

		if (!date) {
			if (!entries.length) {
				return {
					entryErrors,
					entriesValid
				};
			} else {
				date = moment(entries[0].time);
			}
		}

		if (entries.length > 8) {
			entriesValid = false;
		}

		entries.forEach((entry, index) => {
			const entryDate = moment(entry.time).set({ date: date.date(), year: date.year(), month: date.month() });

			if (index !== 0) {
				let isValid = true;

				for (let i = 0; i < index; i += 1) {
					const previousEntryDate = moment(entries[i].time).set({ date: date.date(), year: date.year(), month: date.month() });

					if (previousEntryDate.isSameOrAfter(entryDate, "minute")) {
						isValid = false;
						entryErrors[index] = "registrationFormModal.beforePrevious";
					}
				}

				if (entries[index - 1].type === entry.type) {
					isValid = false;
				}

				if (!isValid) {
					entriesValid = false;
				}
			} else {
				if (entry.type === EntryType.OUT) {
					entriesValid = false;
				}
			}

			if (entryDate.isAfter(moment(), "minute")) {
				entryErrors[index] = "registrationFormModal.afterNow";
				entriesValid = false;
			}

			if (!moment(entryDate).isSame(moment(), "day") && (entries.length <= 1 || entries.length % 2)) {
				entriesValid = false;
			}
		});

		return {
			entriesValid,
			entryErrors
		};
	}

	public render() {
		const { createRegistrationState, editRegistrationState, intl, form, closeCallback, childLoading } = this.props;
		const { submitError, submitting, entries, disabled } = this.state;

		let initalDate: moment.Moment | undefined;
		let childId: number | undefined;

		if (createRegistrationState) {
			childId = createRegistrationState.childId;
			initalDate = moment(createRegistrationState.date);
		}

		if (editRegistrationState) {
			childId = editRegistrationState.childId;
			initalDate = moment(editRegistrationState.date);
		}

		return (
			<RegistrationFormStyle>
				<Spin spinning={childLoading}>

					<Form>
						<Form.Item required label={intl.formatMessage({ id: "registrationFormModal.child" })}>
							{form.getFieldDecorator("child", {
								initialValue: childId
							})(
								<Select disabled={disabled} filterOption={false} onSearch={this.onChildSearch} showSearch placeholder={intl.formatMessage({ id: "registrationFormModal.child" })}>
									{this.childrenDatasource}
								</Select>
							)}
						</Form.Item>
						<Form.Item required label={intl.formatMessage({ id: "registrationFormModal.date" })}>
							{form.getFieldDecorator("date", {
								initialValue: initalDate,
								rules: [
									{ validator: this.validateDate }
								]
							})(
								<DatePicker allowClear={false} disabled={disabled} disabledDate={cur => cur ? cur.isAfter(moment()) : false} format="L" placeholder={intl.formatMessage({ id: "registrationFormModal.date" })} />
							)}
						</Form.Item>
						<label>Registraties</label>
						{this.renderCheckins()}

						<Button disabled={disabled} className="addEntry" onClick={this.addEntryAtEnd} type="dashed"><FormattedMessage id="registrationFormModal.addCheckinOrCheckout" /></Button>

						{submitError && <Alert type="error">
							<FormattedMessage id={submitError} />
						</Alert>}
						{entries.length > 8 && <Alert type="error">
							<FormattedMessage id="registrationFormModal.tooManyEntries" />
						</Alert>}

						<div className="buttons">
							{closeCallback && <Button onClick={this.close} ><FormattedMessage id="registrationFormModal.cancel" /></Button>}

							<Button disabled={disabled} loading={submitting} type="primary" onClick={this.onSubmit} ><FormattedMessage id="registrationFormModal.save" /></Button>
						</div>
					</Form>
				</Spin>
			</RegistrationFormStyle>

		);
	}

	private validateDate(rule: any, value: moment.Moment, callback: any, source?: any, options?: any) {
		const { roles, intl } = this.props;

		const canEdit = canEditRegistration(value, roles);

		if (!canEdit && roles) {
			if (roles.some(role => role === Role.ORGANISER)) {
				return callback(intl.formatMessage({ id: "registrationForm.noEditOrganiser" }));
			} else {
				return callback(intl.formatMessage({ id: "registrationForm.noEditDaycare" }));
			}
		}

		callback();

	}

	private close() {
		const { closeCallback, form } = this.props;

		form.resetFields();

		this.setState({
			cleared: true,
			entries: [],
			entryErrors: [],
			submitError: null,
			submitting: false
		});

		if (closeCallback) {
			closeCallback();
		}
	}

	private onSubmit() {
		const { form, successCallback, updateRegistration, editRegistrationState, createRegistration } = this.props;

		form.validateFields({ force: true }, async (errors, values: FormValues) => {
			const { entriesValid } = RegistrationForm.validateEntries(this.props, this.state);

			if (!errors && entriesValid) {
				this.setState({
					submitError: null,
					submitting: true
				});

				try {
					const entries: EntryInput[] = this.state.entries.map<EntryInput>((entry, index) => {
						return {
							order: index,
							time: entry.time,
							type: entry.type
						};
					});

					if (editRegistrationState) {
						const result = await updateRegistration(
							{
								input: {
									childId: values.child,
									id: editRegistrationState.registration.id,
									entries,
									date: values.date.format("YYYY-MM-DD")
								}
							},
							{
								daycareId: editRegistrationState.daycareId
							}
						);

						this.close();
						successCallback(result);

					} else {
						const data = {
							input: {
								date: values.date.format("YYYY-MM-DD"),
								childId: values.child,
								entries
							}
						};

						const result = await createRegistration({
							input: {
								date: values.date.format("YYYY-MM-DD"),
								childId: values.child,
								entries
							}
						});

						this.close();
						successCallback(result);
					}
				} catch (error) {

					this.setState({
						submitError: parseError(error),
						submitting: false
					});
				}
			}

		});
	}

	private renderCheckins() {
		const { entries, entryErrors, disabled } = this.state;
		const { intl } = this.props;

		return entries.map((entry, index) => {
			const isInvalid = entryErrors[index];

			return (
				<div className="entry" key={index} >
					{this.renderMissingCheckin(index)}
					<Row gutter={12}>
						<Col span={13}>
							<Form.Item className={classNames({ "has-error": isInvalid })}  >
								<Select disabled={disabled} value={entry.type} onChange={(val: EntryType) => this.onTypeChange(val, index)} size="large" placeholder={intl.formatMessage({ id: "registrationFormModal.child" })}>
									<Select.Option value={EntryType.IN}><SelectItem><Icon type="triangle-right" /><FormattedMessage id="registrationFormModal.checkin" /></SelectItem></Select.Option>
									<Select.Option value={EntryType.OUT}><SelectItem><Icon type="triangle-left" /><FormattedMessage id="registrationFormModal.checkout" /></SelectItem></Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={9}>
							<Form.Item className={classNames({ "has-error": isInvalid })} >
								<TimeInput disabled={disabled} value={moment(entry.time)} onChange={(val) => this.onTimeChange(val, index)} />
							</Form.Item>
						</Col>
						<Col span={2}>
							<Icon onClick={() => this.deleteEntry(index)} type="trash" />
						</Col>
					</Row>
					{isInvalid && <div className="invalid-error">
						<FormattedMessage id={isInvalid} />
					</div>}
					{this.renderMissingCheckout(index)}
				</div>
			);
		});
	}

	private renderMissingCheckout(index: number) {
		const { form } = this.props;
		const { entries } = this.state;

		const date: moment.Moment | undefined = form.getFieldValue("date");

		// const type = form.getFieldValue(`entries[${index}].type`);

		const type = entries[index].type;

		// If it is today or after today (should never be after though)
		if (date && !date.isSameOrAfter(moment(), "day")) {
			// If it is the last entry & it is a checking
			if (entries.length - 1 === index && type === EntryType.IN) {
				return this.renderAddMissing(index + 1, EntryType.OUT);
			}
		}

		if (entries[index + 1] && entries[index + 1].type === type) {
			return this.renderAddMissing(index + 1, type === EntryType.IN ? EntryType.OUT : EntryType.IN);
		}
	}

	private renderMissingCheckin(index: number) {
		const { entries } = this.state;

		const type = entries[index].type;

		if (index === 0 && type === EntryType.OUT) {
			return this.renderAddMissing(0, EntryType.IN);
		}
	}

	private renderAddMissing(index: number, type: EntryType) {
		return (<Row gutter={12}>
			<Col span={13}>
				<div className="error">
					<FormattedMessage id={type === EntryType.IN ? "registrationFormModal.missingCheckin" : "registrationFormModal.missingCheckout"} />
				</div>
			</Col>
			<Col span={9}>
				<Button onClick={() => this.addEntry(type, index)} type="danger">
					<FormattedMessage id={type === EntryType.IN ? "registrationFormModal.addCheckin" : "registrationFormModal.addCheckout"} />
				</Button>
			</Col>
		</Row>);
	}

	private onTypeChange(type: EntryType, index: number) {
		const { entries } = this.state;

		entries[index].type = type;

		this.setStateAndValidateEntries(entries);
	}

	private onTimeChange(value: moment.Moment, index: number) {
		const { entries } = this.state;

		entries[index].time = value.toISOString();

		this.setStateAndValidateEntries(entries);
	}

	private deleteEntry(index: number) {
		const { entries } = this.state;

		entries.splice(index, 1);

		this.setStateAndValidateEntries(entries);
	}

	private onChildSearch(name: string) {
		const { setLocalState } = this.props;

		setLocalState({
			childSearchName: name
		});
	}

	private addEntryAtEnd() {
		const { entries } = this.state;

		const entry = entries[entries.length - 1];

		if (entry) {
			entries.push({
				time: moment().toISOString(),
				type: entry.type === EntryType.IN ? EntryType.OUT : EntryType.IN,
				order: -1,
				id: -1
			});
		} else {
			entries.push({
				time: day.clone().set({ hours: moment().hours(), minutes: moment().minutes() }).toISOString(),
				type: EntryType.IN,
				order: -1,
				id: -1
			});
		}

		this.setStateAndValidateEntries(entries);
	}

	private addEntry(type: EntryType, index: number) {
		const { entries } = this.state;

		entries.splice(index, 0, {
			time: moment().toISOString(),
			type,
			order: -1,
			id: -1
		});

		this.setStateAndValidateEntries(entries);
	}

	private setStateAndValidateEntries(entries: Entry[]) {
		const newState = { ...this.state, entries };
		const { entryErrors } = RegistrationForm.validateEntries(this.props, newState);

		this.setState({
			...newState,
			entryErrors
		});
	}
}
