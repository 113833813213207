import { autobind } from "core-decorators";
import * as React from "react";
import { QRCode } from "react-qr-svg";

import { Colors } from "@style/colors";
import { QrCodeViewerStyle } from "@components/qrCodeViewer/qrCodeViewerStyle";
import { QrCodeViewerProps } from "@components/qrCodeViewer/qrCodeViewerContainer";

export interface QrCodeViewerState {

}

@autobind
export class QrCodeViewer extends React.Component<QrCodeViewerProps, QrCodeViewerState> {

    public render() {

        const { code, fgColor, bgColor } = this.props;

        return (
            <QrCodeViewerStyle>
                <QRCode bgColor={bgColor || Colors.primary()} fgColor={fgColor || Colors.text} value={code} />
                <span>{code}</span>
            </QrCodeViewerStyle>
        );
    }
}
