import { Modal, message } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";

import { RegistrationFormModalProps } from "@components/registrationFormModal/registrationFormModalContainer";
import { RegistrationFormContainer } from "@components/registrationForm/registrationFormContainer";

export interface RegistrationFormModalState {

}

@autobind
export class RegistrationFormModal extends React.Component<RegistrationFormModalProps, RegistrationFormModalState> {

    public render() {

        const { createRegistration, editRegistration } = this.props;
        const visible: boolean = (createRegistration || editRegistration) ? true : false;

        return (
            <Modal onCancel={this.closeModal} footer={null} visible={visible} >
                {visible && <RegistrationFormContainer closeCallback={this.closeModal} successCallback={this.onSuccess} />}
            </Modal>
        );
    }

    private onSuccess() {
        const { intl, updateCreateRegistration, updateEditRegistration } = this.props;

        updateCreateRegistration({ create: null });
        updateEditRegistration({ edit: null });
        message.success(intl.formatMessage({ id: "registrationFormModal.addSuccesfull" }));
        this.closeModal();
    }

    private closeModal() {
        const { updateCreateRegistration, updateEditRegistration } = this.props;

        updateCreateRegistration({ create: null });
        updateEditRegistration({ edit: null });
    }
}
