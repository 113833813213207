import { graphql, OperationOption } from "react-apollo";

import { addRefetchQueries } from "@utils/addRefetchQueries";
import { AddChildAddChild, AddChildMutation, AddChildVariables } from "@models/graphql/types";
import { GraphQL } from "@graphql/graphQL";

export interface AddChildMutationResult {
    addChild(variables: AddChildVariables): Promise<AddChildAddChild>;
}

export const createAddChildMutation = <TProps = {}>(
    args: {
        opOptions?: OperationOption<TProps, AddChildMutation, AddChildVariables, AddChildMutationResult>;
        awaitRefetchQueries?: boolean;
    } = {}
) => {
    const { opOptions, awaitRefetchQueries } = args;
    const options = addRefetchQueries(opOptions, ["getChildrenRegistrationList", "getChildDetail", "getChildrenNames"], awaitRefetchQueries);

    return graphql<TProps, AddChildMutation, AddChildVariables, AddChildMutationResult>(GraphQL.addChild, {
        props({ mutate }): AddChildMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async addChild(variables: AddChildVariables): Promise<AddChildAddChild> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("No result or data");
                        }

                        return result.data.addChild;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
};
