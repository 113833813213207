
import { Layout } from "@components/layout/layout";

import * as React from "react";

import { compose } from "redux";

export interface LayoutContainerProps {

}

export const LayoutContainer = compose<React.ComponentClass<LayoutContainerProps>>(

)(Layout);

export type LayoutProps =
    LayoutContainerProps;
