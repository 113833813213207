import * as React from "react";
import * as moment from "moment";
import { autobind } from "core-decorators";
import { UpdateRegistrationPageProps } from "@pages/updateRegistrationPage/updateRegistrationPageContainer";
import { UpdateRegistrationPageStyle } from "@pages/updateRegistrationPage/updateRegistrationPageStyle";
import { RegistrationFormContainer } from "@components/registrationForm/registrationFormContainer";
import { InnerContainer } from "@style/innerContainer";
import { Box } from "@style/box";
import { Logo } from "@style/logo";
import { FormattedMessage } from "react-intl";
import { ErrorBlock } from "@components/errorBlock/errorBlock";
import { Button } from "@style/button";
import { UpdateRegistrationUpdateRegistration } from "@models/graphql/types";

export interface UpdateRegistrationPageState {

}

@autobind
export class UpdateRegistrationPage extends React.Component<UpdateRegistrationPageProps, UpdateRegistrationPageState> {

    public render() {

        const { registration } = this.props;

        if (!registration) {
            return <UpdateRegistrationPageStyle>
                <InnerContainer>
                    <Box>
                        <ErrorBlock />

                        <Button onClick={this.goBack} type="danger">Terug</Button>
                    </Box>
                </InnerContainer>
            </UpdateRegistrationPageStyle>;
        }

        return (
            <UpdateRegistrationPageStyle>
                <InnerContainer>
                    <Logo size="small" />
                    <img className="invalid" alt="invalid" src={require("@assets/images/invalid-registration.svg")} />

                    <FormattedMessage tagName="h1" id="updateRegistrationPage.title" values={{ date: moment(registration.date).format("dd DD MMM YYYY") }} />
                    <Box>
                        <RegistrationFormContainer successCallback={this.onSucces} />
                    </Box>
                </InnerContainer>

            </UpdateRegistrationPageStyle>

        );
    }

    private onSucces(result: UpdateRegistrationUpdateRegistration) {
        const { entryType, updateEditRegistration, setRegistrationFlowRegistrationResult, history } = this.props;
        updateEditRegistration({ edit: null });
        setRegistrationFlowRegistrationResult({ registrationResult: result });

        history.push(`/registration/check${entryType.toLowerCase()}`);
    }

    private goBack() {
        this.props.history.goBack();
    }
}
