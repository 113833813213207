import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { InvalidRegistrationPageStyle } from "@pages/invalidRegistrationPage/invalidRegistrationPageStyle";
import { InvalidRegistrationPageProps } from "@pages/invalidRegistrationPage/invalidRegistrationPageContainer";
import { CancelRegistrationButtonContainer } from "@components/cancelRegistrationButton/cancelRegistrationButtonContainer";

export interface InvalidRegistrationPageState {

}

@autobind
export class InvalidRegistrationPage extends React.Component<InvalidRegistrationPageProps, InvalidRegistrationPageState> {

    public render() {

        const { resetCode, setRegistrationResult } = this.props;

        return (
            <InvalidRegistrationPageStyle>

                <Link className="back" to="/registration"> <Icon type="back" /> <FormattedMessage id="qrCodeReader.back" /></Link>

                <img src={require("@assets/images/invalid-registration.svg")} alt="error" />

                <FormattedMessage tagName="h1" id="invalidRegistrationPage.title" />
                <FormattedMessage tagName="h2" id="invalidRegistrationPage.subTitle" />

                <Button onClick={this.onSignin}><FormattedMessage id="invalidRegistrationPage.signIn" /></Button>

                <CancelRegistrationButtonContainer endDeleteCallback={this.onCancel} />

                <span style={{ cursor: "pointer" }} onClick={resetCode}><FormattedMessage id="invalidRegistrationPage.back" /></span>
            </InvalidRegistrationPageStyle>
        );
    }

    private onCancel() {
        this.props.setRegistrationResult({ registrationResult: null });
        this.props.editRegistration({ edit: null });
        this.props.resetCode();
    }

    private onSignin() {
        const { editRegistration, registration, history, user } = this.props;

        if (!user) {
            throw new Error("no user detected");
        }

        if (!registration) {
            throw new Error("no registration detected");
        }

        editRegistration({
            edit: {
                registration,
                date: registration.date,
                childId: registration.child.id
            }
        });

        history.push("/registration/update");
    }
}
