import styled from "styled-components";
import { Colors } from "@style/colors";
import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";
import { Logo } from "@style/logo";
import { OrganiserLoginFormStyle } from "@components/organiserLoginForm/organiserLoginFormStyle";
import { AlertStyle } from "@components/alert/alertStyle";

export const ResetPasswordPageStyle = styled(OrganiserLoginFormStyle)`
    h1, h2 {
        color: ${Colors.white};
        font-size: 40px;
        font-weight: 200;
        max-width: 600px;
    }

    h2 {
        font-size: 20px;
        padding: 15px 0 30px 0;
        line-height: 24px;
    }


    ${AlertStyle} {

        &.success {
            margin:  0;
        }
    }



`;
