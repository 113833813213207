import { autobind } from "core-decorators";
import * as React from "react";

import { Placeholder } from "@style/placeholder";
import { ErrorBlock } from "@components/errorBlock/errorBlock";
import { DaycareListItemContainer } from "@components/daycare/daycareListItem/daycareListItemContainer";
import { DaycareListStyle } from "@components/daycare/daycareList/daycareListStyle";
import { DaycareListProps } from "@components/daycare/daycareList/daycareListContainer";

export interface DaycareListState {

}

@autobind
export class DaycareList extends React.Component<DaycareListProps, DaycareListState> {

    public render() {
        const { daycares, daycaresLoading } = this.props;

        if (daycaresLoading) {
            return (
                <DaycareListStyle>
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </DaycareListStyle>
            );
        }

        if (!daycares) {
            return <DaycareListStyle>
                <ErrorBlock />
            </DaycareListStyle>;
        }

        return (
            <DaycareListStyle>
                {daycares.map(daycare => <DaycareListItemContainer key={daycare.id} daycare={daycare} />)}
            </DaycareListStyle>
        );
    }
}
