import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { RegistrationChildMissingPage } from "@pages/registrationChildMissingPage/registrationChildMissingPage";
import { setRegistrationFlowCode } from "@redux/actions/setRegistrationFlowCode";

export interface RegistrationChildMissingPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationChildMissingPageContainerProps) => ({
    code: state.registrationFlow.code,
    entryType: state.registrationFlow.entryType,
    child: state.registrationFlow.registrationResult && state.registrationFlow.registrationResult.child
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    retry() { dispatch(setRegistrationFlowCode({ code: null })); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const RegistrationChildMissingPageContainer = compose<React.ComponentClass<RegistrationChildMissingPageContainerProps>>(
    withRedux
)(RegistrationChildMissingPage);

export type RegistrationChildMissingPageProps =
    RegistrationChildMissingPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
