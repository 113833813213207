import styled from "styled-components";

interface LogoProps {
    size: "small" | "medium" | "large";
}

const smallWidth = "133px";
const mediumWidth = "180px";
const largeWidth = "400px";

export const Logo = styled.img.attrs({ src: require("@assets/images/logo.svg") })`
    max-width: ${(props: LogoProps) => props.size === "small" ? smallWidth : props.size === "medium" ? mediumWidth : largeWidth};
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
`;
