import styled from "styled-components";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";

export const DaycareDetailPageStyle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    & > ${InnerContainer} > nav {
        margin-top: ${Padding.large};

        a {
            color: ${Colors.grey};

            &.active {
                color: ${Colors.primary()};
            }


            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
`;
