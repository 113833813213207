import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { ChildrenOverview } from "@components/childrenOverview/childrenOverview";
import { injectIntl, InjectedIntlProps } from "react-intl";

export interface ChildrenOverviewContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: ChildrenOverviewContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const ChildrenOverviewContainer = compose<React.ComponentClass<ChildrenOverviewContainerProps>>(
    withRedux,
    injectIntl
)(ChildrenOverview);

export type ChildrenOverviewProps =
    ChildrenOverviewContainerProps &
    WithReduxDispatchProps &
    InjectedIntlProps &
    WithReduxStateProps;
