import { InputNumber } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";

import { TimeInputStyle } from "@components/timeInput/timeInputStyle";
import { TimeInputProps } from "@components/timeInput/timeInputContainer";

export interface TimeInputState {

}

@autobind
export class TimeInput extends React.Component<TimeInputProps, TimeInputState> {

    public render() {

        const { value, disabled } = this.props;

        const hours = value ? value.hours() : undefined;
        const minutes = value ? value.minutes() : undefined;

        return (
            <TimeInputStyle>
                <InputNumber disabled={disabled} size="large" onChange={this.onHoursChange} value={hours} placeholder="hh" min={0} max={23} />

                <InputNumber disabled={disabled} size="large" onChange={this.onMinutesChange} value={minutes} placeholder="mm" min={0} max={59} />
            </TimeInputStyle>

        );
    }

    private onHoursChange(val: number) {
        const { value, onChange } = this.props;

        if (onChange && value && val <= 23 && val >= 0) {
            onChange(value.clone().set("hours", val));
        }
    }

    private onMinutesChange(val: number) {
        const { value, onChange } = this.props;

        if (onChange && value && val <= 59 && val >= 0) {
            onChange(value.clone().set("minutes", val));
        }
    }
}
