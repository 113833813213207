import { autobind } from "core-decorators";
import * as React from "react";

import { Icon } from "@style/icon";
import { GetChildrenNamesChildren } from "@models/graphql/types";
import { ChildrenSearchResultListStyle } from "@components/daycare/childrenSearchResultList/childrenSearchResultListStyle";
import {
    ChildrenSearchResultListProps
} from "@components/daycare/childrenSearchResultList/childrenSearchResultListContainer";

export interface ChildrenSearchResultListState {

}

@autobind
export class ChildrenSearchResultList extends React.Component<ChildrenSearchResultListProps, ChildrenSearchResultListState> {

    public render() {

        const { childrenList: children } = this.props;

        if (!children || !children.length) {
            return null;
        }

        return (
            <ChildrenSearchResultListStyle>
                {this.renderContent(children)}
            </ChildrenSearchResultListStyle>

        );
    }

    private renderContent(children: GetChildrenNamesChildren[]) {
        const { history } = this.props;

        return (
            <ul>
                {children.map(child => <li onClick={() => this.openChild(child.id)} key={child.id}>{child.firstName} {child.lastName}<Icon type="chevron-right" /></li>)}
            </ul>
        );
    }

    private openChild(childId: number) {
        const { history, setDaycare, daycareId } = this.props;

        setDaycare({ daycareId });

        history.push(`/child/${childId}`);
    }
}
