import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface SetRegistrationFlowCodeActionOptions {
    code: string | null;
}

export const setRegistrationFlowCode = (data: SetRegistrationFlowCodeActionOptions): SetRegistrationFlowCodeAction => {
    return {
        type: ActionTypes.SetRegistrationFlowCode,
        ...data
    };
};

export type SetRegistrationFlowCodeAction = Action & SetRegistrationFlowCodeActionOptions;
export const isSetRegistrationFlowCodeAction = (action: Action): action is SetRegistrationFlowCodeAction => action.type === ActionTypes.SetRegistrationFlowCode;
