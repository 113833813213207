import { Icon as AntIcon } from "antd";
import * as classNames from "classnames";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { CodeError, ErrorCode, parseError } from "@utils/parseError";
import { InnerContainer } from "@style/innerContainer";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { AddChildPageStyle, Progress } from "@pages/addChildPage/addChildPageStyle";
import { AddChildPageProps } from "@pages/addChildPage/addChildPageContainer";
import { AddChildAddChild, EntryType } from "@models/graphql/types";
import { QrCodeReaderContainer } from "@components/qrCodeReader/qrCodeReaderContainer";
import { ChildSearchContainer } from "@components/addChild/childSearch/childSearchContainer";
import { AddChildFormContainer } from "@components/addChild/addChildForm/addChildFormContainer";
import { AddChildFormValues } from "@components/addChild/addChildForm/addChildForm";

export interface AddChildPageState {
	progress: number;
	currentPage: "search" | "addNew" | "scanQr" | "confirmation";
	code: string | null;
	childValues: AddChildFormValues | null;
	submitting: boolean;
	submitError: string | null;
	resultChild: AddChildAddChild | null;
	duplicateCode: boolean;
	firstName: string;
	lastName: string;
	existingChildId: number | null;
}

@autobind
export class AddChildPage extends React.Component<AddChildPageProps, AddChildPageState> {

	public state: AddChildPageState = {
		progress: 25,
		currentPage: "search",
		childValues: null,
		code: null,
		submitting: false,
		resultChild: null,
		submitError: null,
		duplicateCode: false,
		firstName: "",
		lastName: "",
		existingChildId: null
	};

	public componentWillMount() {
		if (this.props.history.location.state) {
			this.setState({
				firstName: this.props.history.location.state.firstName,
				lastName: this.props.history.location.state.lastName
			});
		}
	}

	public render() {
		const { progress, currentPage, submitError, duplicateCode, firstName, lastName, childValues } = this.state;

		return (
			<AddChildPageStyle className={classNames({ hasError: !!submitError || duplicateCode })}>
				<div className="greenBlock" />

				<InnerContainer>
					<div className="bar">
						<FormattedMessage tagName="h1" id="addChildPage.title" />
						<Link to="/">
							<FormattedMessage tagName="h1" id="addChildPage.back" />
						</Link>
					</div>
					<Box>
						{currentPage === "search" && <ChildSearchContainer initialFirstName={firstName} initialLastName={lastName} addExistingChildCallback={this.addExistingChild} addNewChild={this.addNewChild} />}
						{currentPage === "addNew" && <AddChildFormContainer firstName={firstName} lastName={lastName} gender={this.props.history.location.state ? this.props.history.location.state.gender : undefined} birthdate={this.props.history.location.state ? this.props.history.location.state.birthdate : undefined} submitCallback={this.setFormValues} />}
						{currentPage === "scanQr" && <QrCodeReaderContainer hideRegisterButton entryType={EntryType.IN} scannedCallback={this.onScanQr} />}
						{this.renderComplete()}
						<Progress progress={progress} />
					</Box>
				</InnerContainer>
			</AddChildPageStyle>

		);
	}

	private renderComplete() {
		const { currentPage, submitError, submitting, resultChild, duplicateCode } = this.state;

		if (currentPage === "confirmation") {

			if (submitting) {
				return <div className="confirmation">
					<AntIcon type="loading" />
				</div>;
			}

			if (submitError) {
				return <div className="confirmation">
					<img src={require("@assets/images/error-circle.svg")} alt="checkmark" />

					<FormattedMessage tagName="h1" id="addChildPage.somethingWentWrong" />
					<FormattedMessage tagName="h2" id={submitError} />

					<Button onClick={this.retrySubmit} type="danger"><FormattedMessage id="addChildPage.retry" /></Button>
				</div>;

			}

			if (duplicateCode) {
				return <div className="confirmation">
					<img src={require("@assets/images/error-circle.svg")} alt="checkmark" />
					<FormattedMessage tagName="h1" id="addChildPage.duplicateCode" />
					<FormattedMessage tagName="h2" id="addChildPage.duplicateCodeInfo" />

					<Button type="danger" onClick={this.enterNewCode}><FormattedMessage id="addChildPage.enterOtherCode" /></Button>

				</div>;
			}

			if (resultChild) {
				const { firstName, lastName } = resultChild;

				return <div className="confirmation">
					<img src={require("@assets/images/checkmark-circle.svg")} alt="checkmark" />
					<h1>{firstName} {lastName}</h1>
					<FormattedMessage tagName="h2" id="addChildPage.addedSuccessfully" />

					<Link to="/">
						<Button><FormattedMessage id="addChildPage.backToOverview" /></Button>
					</Link>
				</div>;
			}

		}
	}

	private retrySubmit() {
		const { existingChildId, code } = this.state;

		if (existingChildId) {
			this.submitExistingChild();
		} else {
			this.submitNewChild(code);
		}
	}

	private async submitNewChild(code: string | null) {

		const { addChild, organisationId } = this.props;
		const { childValues } = this.state;

		if (!childValues || !code) {
			throw new Error("No form values present");
		}

		if (!organisationId) {
			throw new Error("No organisationId");
		}

		this.setState({
			submitting: true,
			duplicateCode: false,
			submitError: null
		});

		try {

			const result = await addChild({
				input: {
					...childValues,
					code,
					childBenefitPeriods: childValues.childBenefitPeriods,
					birthdate: childValues.birthdate,
					inSchoolSince: childValues.inSchoolSince,
					childBenefitEntitlement: !childValues.childBenefitEntitlement,
					organisationId
				}
			});

			if (!result) {
				this.setState({
					submitting: false,
					submitError: ErrorCode.UNKNOWN
				});
			} else {

				this.setState({
					submitting: false,
					resultChild: result
				});
			}

		} catch (err) {

			if (err.graphQLErrors && err.graphQLErrors[0]) {
				const error = err.graphQLErrors[0] as CodeError;
				if (error.code === ErrorCode.CODE_ALREADY_USED) {
					this.setState({
						submitting: false,
						duplicateCode: true
					});
				} else {
					this.setState({
						submitting: false,
						submitError: parseError(err)
					});
				}
			} else {

				this.setState({
					submitError: parseError(err),
					submitting: false
				});
			}

		}
	}

	private async submitExistingChild() {
		const { existingChildId } = this.state;
		const { addChildToDaycare } = this.props;

		if (!existingChildId) {
			throw new Error("No existing child ID found");
		}

		try {
			this.setState({
				submitting: true,
				submitError: null
			});

			const result = await addChildToDaycare({
				childId: existingChildId
			});

			if (result) {
				this.setState({
					submitting: false,
					resultChild: result
				});
			} else {
				this.setState({
					submitting: false,
					submitError: ErrorCode.UNKNOWN
				});
			}
		} catch (err) {
			this.setState({
				submitError: parseError(err),
				submitting: false
			});
		}

	}

	private enterNewCode() {
		this.setState({
			code: null,
			currentPage: "scanQr"
		});
	}

	private async onScanQr(code: string) {
		this.setState({
			code,
			currentPage: "confirmation",
			progress: 100
		});

		this.submitNewChild(code);

	}

	private setFormValues(values: AddChildFormValues) {
		this.setState({
			childValues: values,
			currentPage: "scanQr",
			progress: 75
		});
	}

	private addNewChild(firstName: string, lastName: string) {
		this.setState({
			progress: 50,
			currentPage: "addNew",
			firstName,
			lastName
		});
	}

	private addExistingChild(childId: number) {
		this.setState(
			{
				currentPage: "confirmation",
				progress: 100,
				existingChildId: childId
			},
			this.submitExistingChild
		);

	}
}
