import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { TableHeader } from "@components/registrations/tableHeader/tableHeader";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { updateRegistrationsFilter, UpdateRegistrationsFilterActionOptions } from "@redux/actions/updateRegistrationsFilter";

export interface TableHeaderContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: TableHeaderContainerProps) => ({
    startDate: state.registration.startDate,
    endDate: state.registration.endDate,
    searchValue: state.registration.childrenFilter.search,
    aggregation: state.registration.aggregation
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    changeFilter(data: UpdateRegistrationsFilterActionOptions) { dispatch(updateRegistrationsFilter(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const TableHeaderContainer = compose<React.ComponentClass<TableHeaderContainerProps>>(
    withRedux,
    injectIntl
)(TableHeader);

export type TableHeaderProps =
    TableHeaderContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    InjectedIntlProps;
