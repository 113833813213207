import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { createLocalState, LocalStateProps } from "@utils/withLocalState";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { createUpdateUploadStatusMutation, UpdateUploadStatusMutationResult } from "@graphql/hocs/updateUploadStatus";
import { GetValidateDaycareCaseNumberQueryResult } from "@graphql/hocs/getValidateDaycareCaseNumber";
import { createGetUploadsListQuery, GetUploadsListQueryResult } from "@graphql/hocs/getUploadsList";

import { UploadsByDaycare } from "./uploadsByDaycare";
import * as moment from "moment";
import { SortOrder } from "@models/graphql/types";

export interface UploadsByDaycareContainerProps {
    daycareId: string;
    onChange(items): void;
}

interface LocalState {
    caseNumberSearch: string;
}

export const mapStateToProps = (state: StoreState, ownProps: UploadsByDaycareContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    // updateDaycareLogin(data: UpdateDaycareLoginActionOptions) { dispatch(updateDaycareLogin(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetUploads = createGetUploadsListQuery<UploadsByDaycareContainerProps & LocalState>({
    options(ownProps) {
        return {
            variables: {
                filter: {
                    daycareId: ownProps.daycareId,
                    uploadsTo: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD") // last day of previous month
                },
                sort: [
                    {
                        field: "startOfMonth",
                        order: SortOrder.DESC
                    }
                ]
            }
        };
    }
});

const withUpdateUploadStatusMutation = createUpdateUploadStatusMutation({ awaitRefetchQueries: true });

const withLocalState = createLocalState<LocalState>({ caseNumberSearch: "" });

export const UploadsByDaycareContainer = compose<React.ComponentClass<UploadsByDaycareContainerProps>>(
    withRedux,
    withRouter,
    injectIntl,
    withLocalState,
    withGetUploads,
    // withValidateDaycareCaseNumber,
    withUpdateUploadStatusMutation
)(UploadsByDaycare);

export type UploadsByDaycareProps =
    LocalStateProps<LocalState> &
    InjectedIntlProps &
    UploadsByDaycareContainerProps &
    GetValidateDaycareCaseNumberQueryResult &
    UpdateUploadStatusMutationResult &
    GetUploadsListQueryResult &
    WithReduxDispatchProps &
    RouteComponentProps &
    WithReduxStateProps;
