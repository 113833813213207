import styled from "styled-components";

import { Colors } from "@style/colors";
import { Padding } from "@style/padding";

export const DaycareProblemListItemStyle = styled.div`
    h1 {
        height: 75px;
        color: ${Colors.error()};
        background-color: ${Colors.error(0.15)};
        display: flex;
        padding: 0 ${Padding.medium};
        align-items: center;
        font-size: 16px;
        font-weight: 600;
    }

    h2 {
        padding: ${Padding.medium} 0;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        padding: ${Padding.medium} ${Padding.medium} ${Padding.medium};

        .icon {
            color: ${Colors.error()};
            margin-right: 10px;
        }

        & + ul {
            padding-top: 0 !important;
        }
    }

    h3 {
        padding: 8px 0 8px ${Padding.medium};
        font-size: 16px;
        background-color: ${Colors.lightGrey};
    }

    .problems {

        ul {
            padding: ${Padding.small} ${Padding.medium};


        }

        li {
            &:not(:last-of-type) {
                margin-bottom: 8px;
            }

            span:first-of-type {
                width: 125px;
                display: inline-block;
            }

            span:last-of-type {
                color: ${Colors.grey};
                cursor: pointer;

                &:hover {
                    color: ${Colors.primary()};
                }
            }
        }
    }
`;
