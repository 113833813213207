import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Header } from "@components/header/header";

export interface HeaderContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: HeaderContainerProps) => ({
    user: state.auth.user
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

const withRedux = connect(mapStateToProps);

export const HeaderContainer = compose<React.ComponentClass<HeaderContainerProps>>(
    withRedux
)(Header);

export type HeaderProps =
    HeaderContainerProps &
    WithReduxStateProps;
