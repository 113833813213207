import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose, Dispatch } from "redux";

import { createLocalState, LocalStateProps } from "@utils/withLocalState";
import { createGetValidateDaycareCaseNumberQuery, GetValidateDaycareCaseNumberQueryResult } from "@graphql/hocs/getValidateDaycareCaseNumber";
import { DaycareLoginForm } from "@components/daycareLoginForm/daycareLoginForm";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { connect } from "react-redux";
import { updateDaycareLogin, UpdateDaycareLoginActionOptions } from "@redux/actions/updateDaycareLogin";
import { withRouter, RouteComponentProps } from "react-router";

export interface DaycareLoginFormContainerProps {

}

interface LocalState {
    caseNumberSearch: string;
}

export const mapStateToProps = (state: StoreState, ownProps: DaycareLoginFormContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    updateDaycareLogin(data: UpdateDaycareLoginActionOptions) { dispatch(updateDaycareLogin(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withValidateDaycareCaseNumber = createGetValidateDaycareCaseNumberQuery<DaycareLoginFormContainerProps & LocalStateProps<LocalState>>({
    options(ownProps) {

        return {
            variables: {
                caseNumber: ownProps.caseNumberSearch
            }
        };
    },
    skip(ownProps) {
        if (ownProps.caseNumberSearch === "") {
            return true;
        }

        return false;
    }
});

const withLocalState = createLocalState<LocalState>({ caseNumberSearch: "" });

export const DaycareLoginFormContainer = compose<React.ComponentClass<DaycareLoginFormContainerProps>>(
    withRedux,
    withRouter,
    injectIntl,
    withLocalState,
    withValidateDaycareCaseNumber
)(DaycareLoginForm);

export type DaycareLoginFormProps =
    LocalStateProps<LocalState> &
    InjectedIntlProps &
    DaycareLoginFormContainerProps &
    GetValidateDaycareCaseNumberQueryResult &
    WithReduxDispatchProps &
    RouteComponentProps &
    WithReduxStateProps;
