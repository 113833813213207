import styled from "styled-components";

import { Placeholder } from "@style/placeholder";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { ChildPrintViewStyle } from "@components/childPrintView/childPrintViewStyle";

export const ChildrenOverviewListStyle = styled.div`
    padding-top: ${Padding.medium};

    ${Placeholder} {
        height: 25px;
        margin: 25px 0;
    }

    ${ChildPrintViewStyle} {
        display: none;
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 12px;
        font-size: 20px;
    }
`;

export const QrCodePopover = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;

    & > span {
        display: flex;
        align-items: center;
        height: 25px;
        padding: 20px 0;
        cursor: pointer;

        &:last-of-type {
            padding-bottom: 10px;
        }

        &:first-of-type {
            padding-top: 10px;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid ${Colors.lightGrey};
        }

        &:hover {
            color: ${Colors.primary()};
        }
    }
`;

export const ChildListItem = styled.div`


    display: flex;
    align-items: center;
    font-weight: 200;


    &:not(:last-of-type) {
        border-bottom: 1px solid ${Colors.lightGrey};
    }

    a {
        display: flex;
        color: ${Colors.text};
        flex: 1;
        box-sizing: content-box;
        height: 25px;
        padding: ${Padding.medium} 0;
    }


    .name {
        font-weight: 600;
        padding-right: ${Padding.medium};
        width: 180px;

    }

    .gender {
        padding-right: ${Padding.medium};
        width: 100px;
    }

    .birthdate {
        padding-right: ${Padding.medium};
        width: 100px;
    }

    .childBenefits {
        padding-right: ${Padding.medium};
        width: 250px;
    }

    .code {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            opacity: 0.5;
            margin-left: ${Padding.small};
            transition: 0.2s;

            &:hover {
                opacity: 1;
            }
        }
    }

    @media print {
        font-size: 14pt;
        height: 20pt;
        padding: 30pt 50pt;

        &:first-of-type {
            margin-top: 50pt;
        }

        &:nth-child(13n) {
            margin-top: 100pt;
            padding-top: 50pt;
        }

        .name {
            width: 220px;
        }

        .birthdate {
            width: 140px;
        }

        .code {
            img {
                width: 60px !important;
                height: 60px !important;
                opacity: 1;
            }
        }
    }

`;
