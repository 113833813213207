import styled from "styled-components";
import { Colors } from "@style/colors";
import { Padding } from "@style/padding";
import { Aggregation } from "@models/graphql/types";

export const Week = styled.div`
    display: flex;
    flex-wrap: wrap;
    transition: .3s;
    border-radius: 2px;
    margin-bottom: 2px;

    &:not(.disabled) {
        &:hover {
            background-color: ${Colors.primary(0.3)};
            cursor: pointer;
        }

        &.active {
            background-color: ${Colors.primary(0.3)};
        }

    }



`;

export const CalendarStyle = styled.div`
    width: 480px;

    background-color: ${Colors.white};
    padding: ${Padding.medium};
    box-shadow: 0 3px 15px 0 rgba(0,0,0,0.18);
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    top: 28px;
    left: 0;


    .ant-select {
        width: 100%;
    }

    .item {
        width: 100%;

        & > span:first-of-type {
            color: ${Colors.primary()};
            display: block;
            padding-bottom: 8px;
        }
    }

    .row {
        display: flex;

        &:first-of-type{
            margin-bottom: ${Padding.small};
        }

        .item+.item {
            margin-left: ${Padding.small};
        }


    }

    .days {
        margin-top: ${Padding.medium};
    }


    .days.${Aggregation.WEEK} {
        ${Week}:hover:not(.active) {
            background-color: inherit;
            cursor: initial;
        }
    }

    @media (max-width: 600px) {
        left: 0;
        right: 0;
        width: auto;
    }
`;
