import styled from "styled-components";

import { Colors } from "@style/colors";

export const AlertStyle = styled.div`
    border: 1px solid;
    padding: 8px;
    border-radius: 4px;
    display: flex;

    &.success {
        border-color: ${Colors.primary()};
        color: ${Colors.primary()};
        background-color: ${Colors.primary(0.1)};
    }

    &.error {
        border-color: ${Colors.error()};
        color: ${Colors.error()};
        background-color: ${Colors.error(0.1)};
    }

    &.info {
        border-color: ${Colors.secondary()};
        color: ${Colors.secondary()};
        background-color: ${Colors.secondary(0.1)};
    }

    .icon {
        margin-right: 10px;
        font-size: 18px;
        padding: 2px 0 0 0;
    }
`;
