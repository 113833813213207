import * as React from "react";
import { autobind } from "core-decorators";
import { DaycareOverviewPageProps } from "@pages/daycareOverviewPage/daycareOverviewPageContainer";
import { DayCareOverviewPageStyle, BoxContainer } from "@pages/daycareOverviewPage/daycareOverviewPageStyle";
import { FormattedMessage } from "react-intl";
import { InnerContainer } from "@style/innerContainer";
import { DayCareSearchContainer } from "@components/daycare/dayCareSearch/dayCareSearchContainer";
import { DaycareListContainer } from "@components/daycare/daycareList/daycareListContainer";
import { DaycareProblemsListContainer } from "@components/daycare/daycareProblemsList/daycareProblemsListContainer";
import { DaycareSearchResultsContainer } from "@components/daycare/daycareSearchResults/daycareSearchResultsContainer";
import { Select } from "antd";

export interface DaycareOverviewPageState {

}

@autobind
export class DaycareOverviewPage extends React.Component<DaycareOverviewPageProps, DaycareOverviewPageState> {

    public render() {

        const { daycareProblems, searchValue, multiOrganiser, organisationId } = this.props;

        let problems;

        if (daycareProblems && daycareProblems.length) {
            let problemCount = 0;
            daycareProblems.forEach(daycare => {
                problemCount += daycare.invalidRegistrations.length;
                problemCount += daycare.invalidUploads.length;
            });
            problems = <FormattedMessage tagName="h2" id="dayCareOverview.problemsFound" values={{ problemCount }} />;
        }

        return (
            <DayCareOverviewPageStyle>
                <div className="greenBlock" />
                <InnerContainer>
                    <FormattedMessage id="dayCareOverview.welcomeBack" tagName="h1" />
                    {problems}
                    <div className="bar">
                        <DayCareSearchContainer />
                        {multiOrganiser && <Select onChange={this.onOrganisationChange} value={organisationId || undefined}>
                            {multiOrganiser.map(organiser => <Select.Option key={organiser.id} value={organiser.id}>{organiser.name}</Select.Option>)}

                        </Select>}
                    </div>
                </InnerContainer>
                {searchValue ? <InnerContainer><DaycareSearchResultsContainer /></InnerContainer> :
                    <BoxContainer>
                        <DaycareListContainer />
                        <DaycareProblemsListContainer />
                        <span className="spacer">&nbsp;</span>
                    </BoxContainer>}
            </DayCareOverviewPageStyle>
        );
    }

    private onOrganisationChange(val: string) {
        this.props.setOrganisationId({ organisationId: val });
    }
}
