import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { createDeleteLastEntryMutation, DeleteLastEntryMutationResult } from "@graphql/hocs/deleteLastEntry";
import { CancelRegistrationButton } from "@components/cancelRegistrationButton/cancelRegistrationButton";

export interface CancelRegistrationButtonContainerProps {
    initDeleteCallback?(): void;
    endDeleteCallback?(): void;
}

export const mapStateToProps = (state: StoreState, ownProps: CancelRegistrationButtonContainerProps) => ({
    code: state.registrationFlow.code
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withDeleteLastEntryMutation = createDeleteLastEntryMutation();

export const CancelRegistrationButtonContainer = compose<React.ComponentClass<CancelRegistrationButtonContainerProps>>(
    withRedux,
    withRouter,
    injectIntl,
    withDeleteLastEntryMutation
)(CancelRegistrationButton);

export type CancelRegistrationButtonProps =
    CancelRegistrationButtonContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    RouteComponentProps &
    InjectedIntlProps &
    DeleteLastEntryMutationResult;
