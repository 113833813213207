import "reflect-metadata";
import "whatwg-fetch";

import * as OfflinePluginRuntime from "offline-plugin/runtime";
import * as React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";

import { initErrorTracking } from "@utils/errorTracking";
import { BUILD_NUMBER } from "@utils/environment";
import { getClient, queueLink } from "@graphql/client";

import { App } from "./app";
import { QueueLink } from "@graphql/queueLink";

console.log(`Build: ${BUILD_NUMBER}`);

initErrorTracking();

OfflinePluginRuntime.install({
    onUpdateReady: () => {
        OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
        window.location.reload();
    },
    onUpdating: () => {
        console.log("Updating");
    }
});

const reactContainer = document.getElementById("app-container");

getClient({ linksBefore: [queueLink] })
    .then((client) => {

        render(
            <AppContainer>
                <App client={client} />
            </AppContainer>,

            reactContainer
        );

        // Hot; Module; Replacement; API;
        if (module.hot) {
            module.hot.accept(() => {
                const NextApp = require<{ default: typeof App }>("./app").default;
                render(
                    <AppContainer>
                        <NextApp client={client} />
                    </AppContainer>
                    ,
                    reactContainer
                );
            });
        }

        return client;
    });
