import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import {
    updateDaycareSelectedDaycareId,
    UpdateDaycareSelectedDaycareIdActionOptions
} from "@redux/actions/updateDaycareSelectedDaycareId";
import { Action } from "@redux/actions/action";
import { createGetDaycareNamesQuery, GetDaycareNamesQueryResult } from "@graphql/hocs/getDaycareNames";
import { DaycareSearchResultList } from "@components/daycare/daycareSearchResultList/daycareSearchResultList";

export interface DaycareSearchResultListContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: DaycareSearchResultListContainerProps) => ({
    searchValue: state.daycare.overviewSearch,
    organiserId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setDaycare(data: UpdateDaycareSelectedDaycareIdActionOptions) { dispatch(updateDaycareSelectedDaycareId(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetDaycareNames = createGetDaycareNamesQuery<DaycareSearchResultListContainerProps & WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organiserId) {
            throw new Error("No organisation id present");
        }

        return {
            variables: {
                filter: {
                    search: ownProps.searchValue,
                    organisationId: ownProps.organiserId
                }
            }
        };
    },
    skip(ownProps) {
        if (ownProps.searchValue) {
            return false;
        }

        return true;
    }
});

export const DaycareSearchResultListContainer = compose<React.ComponentClass<DaycareSearchResultListContainerProps>>(
    withRedux,
    withRouter,
    withGetDaycareNames
)(DaycareSearchResultList);

export type DaycareSearchResultListProps =
    DaycareSearchResultListContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    RouteComponentProps &
    GetDaycareNamesQueryResult;
