import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { createGetRegistrationsMutation, GetRegistrationsMutationResult } from "@graphql/hocs/getRegistrations";

import { ExportExcelButton } from "./exportExcelButton";
import { createGetChildrenRegistrationListQuery, GetChildrenRegistrationListQueryResult } from "@graphql/hocs/getChildrenRegistrationList";

export interface ExportExcelButtonContainerProps {
    month: number;
    year: number;
}

export const mapStateToProps = (state: StoreState, ownProps: ExportExcelButtonContainerProps) => ({
    selectedDaycareId: state.daycare.selectedDaycareId,
    organisationId: state.auth.organisationId,
    ...state.registration
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetRegistrations = createGetRegistrationsMutation<ExportExcelButtonContainerProps & WithReduxStateProps>();

const withGetChildrenRegistrationList = createGetChildrenRegistrationListQuery<ExportExcelButtonContainerProps & WithReduxStateProps>({
    options(ownProps) {
        const { aggregation, childrenFilter } = ownProps;

        return {
            variables: {
                aggregation,
                filter: {
                    ...childrenFilter,
                    isActive: true
                }
            }
        };
    }
});
// get all of month registrations + child

export const ExportExcelButtonContainer = compose<React.ComponentClass<ExportExcelButtonContainerProps>>(
    withRedux,
    injectIntl,
    withGetChildrenRegistrationList,
    withGetRegistrations
)(ExportExcelButton);

export type ExportExcelButtonProps =
    ExportExcelButtonContainerProps &
    WithReduxDispatchProps &
    GetRegistrationsMutationResult &
    WithReduxStateProps &
    GetChildrenRegistrationListQueryResult &
    InjectedIntlProps;
