import styled from "styled-components";
import { Colors } from "@style/colors";

export const DayStyle = styled.div`
    width: calc(100% / 7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    font-weight: 600;

    &.selected {
        background-color: ${Colors.primary(0.2)}
    }

    &.disabled {
        color: #787878;
        opacity: 0.3;
    }
`;
