import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface UpdateCreateRegistrationActionOptions {
    create: {
        childId: number;
        date: string;
    } | null;
}

export const updateCreateRegistration = (data: UpdateCreateRegistrationActionOptions): UpdateCreateRegistrationAction => {
    return {
        type: ActionTypes.UpdateCreateRegistration,
        ...data
    };
};

export type UpdateCreateRegistrationAction = Action & UpdateCreateRegistrationActionOptions;
export const isUpdateCreateRegistrationAction = (action: Action): action is UpdateCreateRegistrationAction => action.type === ActionTypes.UpdateCreateRegistration;
