import * as React from "react";
import { compose } from "redux";

import { MoreActionsButton } from "@components/moreActionsButton/moreActionsButton";

export interface MoreActionsButtonContainerProps {

}

export const MoreActionsButtonContainer = compose<React.ComponentClass<MoreActionsButtonContainerProps>>(

)(MoreActionsButton);

export type MoreActionsButtonProps =
    MoreActionsButtonContainerProps;
