import { autobind } from "core-decorators";
import * as React from "react";

import { Icon } from "@style/icon";
import { RegistrationSearchBarStyle } from "@components/registrations/registrationSearchBar/registrationSearchBarStyle";
import { RegistrationSearchBarProps } from "@components/registrations/registrationSearchBar/registrationSearchBarContainer";

export interface RegistrationSearchBarState {

}

@autobind
export class RegistrationSearchBar extends React.Component<RegistrationSearchBarProps, RegistrationSearchBarState> {

    public render() {

        const { searchValue, intl } = this.props;

        return (
            <RegistrationSearchBarStyle>

                <input
                    value={searchValue || ""}
                    onChange={this.onSearchChange}
                    placeholder={intl.formatMessage({ id: "tableHeader.search" })}
                    type="text"
                />
                { searchValue === "" || searchValue === undefined ? <Icon type="search" /> : <Icon type="cross" onClick={this.onClickClear} className="remove-btn" />}
            </RegistrationSearchBarStyle>

        );
    }

    private onClickClear() {
        this.props.changeFilter({
            filter: {
                search: ""
            }
        });
    }

    private onSearchChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.props.changeFilter({
            filter: {
                search: ev.currentTarget.value
            }
        });
    }
}
