import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { DaycareProblemsList } from "@components/daycare/daycareProblemsList/daycareProblemsList";
import { createGetDaycareProblemsQuery, GetDaycareProblemsQueryResult } from "@graphql/hocs/getDaycareProblems";

export interface DaycareProblemsListContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: DaycareProblemsListContainerProps) => ({
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetDaycareProblems = createGetDaycareProblemsQuery<WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organisationId) {
            throw new Error("No organisation ID found");
        }

        return {
            variables: {
                filter: {
                    organisationId: ownProps.organisationId
                }
            }
        };
    }
});

export const DaycareProblemsListContainer = compose<React.ComponentClass<DaycareProblemsListContainerProps>>(
    withRedux,
    withGetDaycareProblems
)(DaycareProblemsList);

export type DaycareProblemsListProps =
    DaycareProblemsListContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    GetDaycareProblemsQueryResult;
