import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { IntroLayout } from "@components/introLayout/introLayout";
import { withRouter, RouteComponentProps } from "react-router";

export interface IntroLayoutContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: IntroLayoutContainerProps) => ({
    user: state.auth.user
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const IntroLayoutContainer = compose<React.ComponentClass<IntroLayoutContainerProps>>(
    withRedux,
    withRouter
)(IntroLayout);

export type IntroLayoutProps =
    IntroLayoutContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    RouteComponentProps;
