import * as React from "react";
import { autobind } from "core-decorators";
import { DaycareProblemsListProps } from "@components/daycare/daycareProblemsList/daycareProblemsListContainer";
import { DaycareProblemsListStyle } from "@components/daycare/daycareProblemsList/daycareProblemsListStyle";
import { DaycareProblemListItemContainer } from "@components/daycare/daycareProblemListItem/daycareProblemListItemContainer";

export interface DaycareProblemsListState {

}

@autobind
export class DaycareProblemsList extends React.Component<DaycareProblemsListProps, DaycareProblemsListState> {

    public render() {

        const { daycareProblems } = this.props;

        if (!daycareProblems || !daycareProblems.length) {
            return null;
        }

        return (
            <DaycareProblemsListStyle>
                {daycareProblems.map(daycare => <DaycareProblemListItemContainer key={daycare.id} daycare={daycare} />)}
            </DaycareProblemsListStyle>
        );
    }
}
