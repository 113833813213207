import * as React from "react";
import { autobind } from "core-decorators";
import { DayCareSearchProps } from "@components/daycare/dayCareSearch/dayCareSearchContainer";
import { DayCareSearchStyle } from "@components/daycare/dayCareSearch/dayCareSearchStyle";
import { Icon } from "@style/icon";

export interface DayCareSearchState {

}

@autobind
export class DayCareSearch extends React.Component<DayCareSearchProps, DayCareSearchState> {

    public render() {

        const { searchValue, intl } = this.props;

        return (
            <DayCareSearchStyle>
                <input
                    value={searchValue || ""}
                    onChange={this.onSearchChange}
                    placeholder={intl.formatMessage({ id: "daycareSearch.search" })}
                    type="text"
                />
                { searchValue === "" || searchValue === undefined ? <Icon type="search" /> : <Icon type="cross" onClick={this.onClickClear} className="remove-btn" />}
            </DayCareSearchStyle>

        );
    }

    private onSearchChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.props.changeSearch({

            search: ev.currentTarget.value

        });
    }

    private onClickClear() {
        this.props.changeSearch({
                search: ""
        });
    }
}
