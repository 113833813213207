import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";

import { AuthService } from "@utils/authService";
import { TokenHandlerProps } from "@components/tokenHandler/tokenHandlerContainer";

export interface TokenHandlerState {
    initialRefresh: boolean;
}

@autobind
export class TokenHandler extends React.Component<TokenHandlerProps, TokenHandlerState> {
    public state: TokenHandlerState = {
        initialRefresh: true
    };

    private interval: NodeJS.Timer | null;

    public render() {
        const { children } = this.props;
        const { initialRefresh } = this.state;

        if (initialRefresh) {
            return null;
        }

        return children;
    }

    public componentWillMount() {
        const { user } = this.props;

        if (!user && AuthService.isRefreshTokenValid()) {
            console.log("No user found, but refresh token present");
            this.handleTokenRefetch();
        } else if (user && !user.isValid() && AuthService.isRefreshTokenValid()) {
            console.log("User not valid, refetching token...");
            this.handleTokenRefetch();
        } else {
            console.log("User valid");
            this.setState({
                initialRefresh: false
            });
        }

        this.interval = setInterval(this.handleInterval, 15000);
    }

    public componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    private async handleTokenRefetch() {
        const refreshToken = AuthService.refreshToken;
        if (!AuthService.isRefreshTokenValid() || !refreshToken) {
            console.log("Refresh token not valid");

            this.setState({
                initialRefresh: false
            });

            return;
        }

        const { refreshLogin } = this.props;

        try {
            console.log("Refreshing...");
            const loginResult = await refreshLogin({
                refreshToken
            });

            const user = AuthService.login(loginResult);

            this.props.setUser({ user });
            this.setState({
                initialRefresh: false
            });
        } catch (error) {
            // something wrong while refreshing login => logout
            AuthService.logout();
        }
    }

    private handleInterval() {

        const user = AuthService.getUser();

        if (user) {
            if (user.expires_at.diff(moment(), "s") < 60) {
                this.handleTokenRefetch();
            }
        }
    }
}
