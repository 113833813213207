import { NormalizedCacheObject } from "apollo-cache-inmemory";
import ApolloClient, { ApolloError } from "apollo-client";
import { ApolloLink, createOperation, FetchResult, NextLink, Observable, Operation } from "apollo-link";
import { autobind } from "core-decorators";
import * as localForage from "localforage";
import * as moment from "moment";
import * as uuid from "uuid";

import { AddEntryHandler } from "./queueLinkHandlers/addEntryHandler";
import { DeleteLastEntryHandler } from "./queueLinkHandlers/deleteLastEntryHandler";
import { MutationHandler } from "./queueLinkHandlers/mutationHandler";
import { QueueLinkState } from "./queueLinkProvider";

export interface OperationQueueEntry {
    id: string;
    time: moment.Moment;
    operation: Operation;
    forward?: NextLink;
    completed: boolean;
    error?: ApolloError;
    subscription?: { unsubscribe(): void };
}

export interface ProcessQueueArgs {
    batch: boolean;
    completedCallback?(): void;
}

@autobind
export class QueueLink extends ApolloLink {
    private _isOpen: boolean = navigator.onLine;
    private _completed: boolean = false;
    private _processing: boolean = false;
    private _opQueue: OperationQueueEntry[] = [];
    private _initialized: boolean = false;
    private opQueueCache: LocalForage = localForage.createInstance({
        name: "mutations-queue",
        driver: localForage.INDEXEDDB
    });
    private listener?: (state: Partial<QueueLinkState>) => void;
    private mutationHandlers: MutationHandler[] = [];
    private client?: ApolloClient<NormalizedCacheObject>;

    constructor(private clientFactory: () => Promise<ApolloClient<NormalizedCacheObject>>) {
        super();

        window.addEventListener("offline", this.close);
        window.addEventListener("online", this.open);
    }

    public setListener(listener: (state: Partial<QueueLinkState>) => void) {
        this.listener = listener;
    }

    public async processQueue(args: ProcessQueueArgs) {
        if (!this.initialized || !this.client) {
            return;
        }

        const { batch, completedCallback } = args;
        this.processing = true;
        this.completed = false;

        for (const [i, op] of this.opQueue.entries()) {
            if (op.completed) {
                continue;
            }

            const { operation } = op;
            if (!batch) {
                operation.setContext({ noBatch: true });
            }

            try {
                await this.client.mutate({
                    variables: operation.variables,
                    mutation: operation.query
                });

                op.completed = true;
                op.error = undefined;
            } catch (error) {
                op.error = error;
            } finally {
                // change local opQueue
                this.opQueue[i] = op;
            }
        }

        // updates laten gebeuren na loop
        this.updateProvider({ mutationsPending: this.opQueue });
        this.setOperationQueueCache(this.opQueue);

        if (completedCallback) {
            completedCallback();
        }

        this.completed = true;
        this.processing = false;
    }

    public request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {
        if (!forward) {
            return null;
        }

        if (this.isOpen) {
            return forward(operation);
        }

        if (operation.getContext().skipQueue) {
            return forward(operation);
        }

        return new Observable(observer => {
            const operationEntry = this.handleOperation(observer, operation, forward);

            if (operationEntry) {
                this.enqueue(operationEntry);
            }

            return () => { };
        });
    }

    public async initializeLink() {
        this.client = await this.clientFactory();

        const cachedOps = (await this.getOperationQueueCache()) || [];

        const ops = cachedOps
            .filter(op => !op.completed)
            .map<OperationQueueEntry>(op => ({
                ...op,
                operation: createOperation({}, op.operation)
            }));

        // Add mutation handlers here.
        this.mutationHandlers.push(new AddEntryHandler(this.client));
        this.mutationHandlers.push(new DeleteLastEntryHandler());

        this.opQueue = ops;
        this.initialized = true;

        if (navigator.onLine) {
            this.processQueue({ batch: false });
        }
    }

    public async reInitialize() {
        this.mutationHandlers.forEach(handler => handler.initialize());
    }

    public open() {
        this.isOpen = true;
        this.processQueue({ batch: false });
    }

    public close() {
        this.isOpen = false;
    }

    public removeOperation(id: string) {
        this.opQueue = this.opQueue.filter(op => op.id !== id);
        this.setOperationQueueCache(this.opQueue);
    }

    public purgeCompleted() {
        this.opQueue = this.opQueue.filter(op => !op.completed);
    }

    private handleOperation(observer: ZenObservable.SubscriptionObserver<any>, operation: Operation, forward?: NextLink) {
        const operationEntry: OperationQueueEntry = {
            id: uuid.v1(),
            operation,
            forward,
            time: moment(),
            completed: false
        };

        const handler = this.mutationHandlers.find(h => h.mutationName === operation.operationName);
        let enqueue = false;

        if (handler) {
            const { enqueueMutation, operationQueue } = handler.handleMutation(observer, operationEntry, this.opQueue);
            enqueue = enqueueMutation;

            if (operationQueue) {
                this.opQueue = operationQueue;
            }
        } else {
            // Return empty data object, Apollo will ignore it and fetch from cache.
            observer.next({ data: {} });
        }

        observer.complete();

        if (enqueue) {
            return operationEntry;
        }
    }

    private async getOperationQueueCache(): Promise<OperationQueueEntry[] | undefined> {
        return JSON.parse(await this.opQueueCache.getItem<string>("mutationQueue"));
    }

    // get keys
    private async getOperationQueueCacheKeys(): Promise<string[] | undefined> {
        return JSON.parse(await this.opQueueCache.getItem<string>("mutationQueueKeys"));
    }

    private async setOperationQueueCache(val: OperationQueueEntry[]) {
        return this.opQueueCache.setItem("mutationQueue", JSON.stringify(val));
    }

    // set keys
    private async setOperationQueueCacheKeys(val: string[]) {
        return this.opQueueCache.setItem("mutationQueueKeys", JSON.stringify(val));
    }

    private enqueue(entry: OperationQueueEntry) {
        // add entry to opQueue
        this.opQueue.push(entry);
        this.setOperationQueueCache(this.opQueue);

        this.updateProvider({ mutationsPending: this.opQueue });
    }

    private updateProvider(state: Partial<QueueLinkState>) {
        if (this.listener) {
            this.listener(state);
        }
    }

    public get isOpen(): boolean {
        return this._isOpen;
    }

    public set isOpen(val: boolean) {
        this._isOpen = val;
        this.updateProvider({
            isOpen: val
        });
    }

    public get completed(): boolean {
        return this._completed;
    }

    public set completed(val: boolean) {
        this._completed = val;
        this.updateProvider({
            completed: val
        });
    }

    public get processing(): boolean {
        return this._processing;
    }
    public set processing(val: boolean) {
        this._processing = val;
        this.updateProvider({
            processing: val
        });
    }

    public get opQueue(): OperationQueueEntry[] {
        return this._opQueue;
    }

    public set opQueue(val: OperationQueueEntry[]) {
        this._opQueue = val;
        this.updateProvider({
            mutationsPending: val
        });
    }

    public get initialized(): boolean {
        return this._initialized;
    }

    public set initialized(val: boolean) {
        this._initialized = val;
        this.updateProvider({
            initialized: val
        });
    }
}
