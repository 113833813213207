import { GetInactiveMonthsQuery, GetInactiveMonthsVariables, GetInactiveMonthsInactiveMonths } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";

export interface GetInactiveMonthsQueryResult {
    inactiveMonths?: GetInactiveMonthsInactiveMonths[];
    inactiveMonthsLoading: boolean;
}

export const createGetInactiveMonthsQuery = <TProps = {}>(options?: OperationOption<TProps, GetInactiveMonthsQuery, GetInactiveMonthsVariables, GetInactiveMonthsQueryResult>) =>
    graphql<TProps, GetInactiveMonthsQuery, GetInactiveMonthsVariables, GetInactiveMonthsQueryResult>(GraphQL.getInactiveMonths, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                inactiveMonths: data.inactiveMonths,
                inactiveMonthsLoading: data.loading
            };
        },
        ...options
    });
