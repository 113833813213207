import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Placeholder } from "@style/placeholder";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Aggregation } from "@models/graphql/types";
import { RegistrationListItemContainer } from "@components/registrations/registrationListItem/registrationListItemContainer";
import {
    EmptyBlock,
    ListHeader,
    RegistrationListStyle
} from "@components/registrations/registrationList/registrationListStyle";
import { RegistrationListProps } from "@components/registrations/registrationList/registrationListContainer";
import { ErrorBlock } from "@components/errorBlock/errorBlock";

export interface RegistrationListState {
    refetched: boolean;
}

@autobind
export class RegistrationList extends React.Component<RegistrationListProps, RegistrationListState> {
    public state: RegistrationListState = {
        refetched: false
    };

    public static getDerivedStateFromProps(props: RegistrationListProps, state: RegistrationListState): Partial<RegistrationListState> | null {
        if (!state.refetched && props.completed) {
            props.refetch();

            return {
                refetched: true
            };
        }

        if (state.refetched && !props.completed) {
            return {
                refetched: false
            };
        }

        return null;
    }
    public render() {

        const { children, childrenLoading } = this.props;

        if (childrenLoading && !children) {
            return (
                <RegistrationListStyle>
                    <InnerContainer>
                        <Placeholder />
                        <Placeholder />
                        <Placeholder />
                        <Placeholder />
                    </InnerContainer>
                </RegistrationListStyle>
            );
        }

        if (!children) {
            return (
                <RegistrationListStyle >
                    <InnerContainer>
                        <ErrorBlock />
                    </InnerContainer>
                </RegistrationListStyle>
            );
        }

        return (
            <RegistrationListStyle>
                {this.renderEmptyBlock()}
                {this.renderHeaderBlocks()}
            </RegistrationListStyle>
        );
    }

    private renderEmptyBlock() {
        const { children, childrenFilter } = this.props;

        if (children && !children.length) {
            return (
                <InnerContainer>
                    <EmptyBlock >
                        <img src={require("@assets/images/kids-circle.svg")} alt="circle-kids" />

                        {!childrenFilter.search ?
                            <>
                                <FormattedMessage id="registrationTable.noChildren" />
                                <Button size="large" type="primary"><Icon type="add-user" /><FormattedMessage id="subHeader.addChild" /></Button> {/* TODO: ADD ONCLICK */}
                            </>
                            :
                            <FormattedMessage id="registrationTable.noChildrenSearch" />
                        }

                    </EmptyBlock>
                </InnerContainer>
            );
        }
    }

    private renderHeaderBlocks() {
        const { startDate, endDate, aggregation, children } = this.props;

        if (!children || !children.length) {
            return null;
        }

        const date = startDate.clone();

        const result: JSX.Element[] = [];

        if (aggregation === Aggregation.DAY) {

            let i = 0;

            while (i < 7) {

                result.push(<div key={i}>
                    <ListHeader>
                        <InnerContainer>
                            {date.format("dddd D MMMM")}
                        </InnerContainer>
                    </ListHeader>
                    <InnerContainer className="children">
                        {this.renderChildren(date)}
                    </InnerContainer>
                </div>);

                date.add(1, "d");
                i += 1;
            }
        } else {
            const weeks = Math.abs(startDate.diff(endDate, "weeks")) + 1;

            let i = 0;
            while (i < weeks) {
                result.push(<div key={i}>
                    <ListHeader >
                        <InnerContainer>
                            <FormattedMessage id="tableHeader.week" /> {date.clone().add(i, "weeks").week()}
                        </InnerContainer>
                    </ListHeader>
                    <InnerContainer className="children">
                        {this.renderChildren(date.clone().add(i, "weeks"))}
                    </InnerContainer>
                </div>);
                i += 1;
            }

        }

        return result;
    }

    private renderChildren(date: moment.Moment) {
        const { children } = this.props;
        let result: (JSX.Element | null)[] = [];

        if (children) {
            result = children.map(child => {
                return <RegistrationListItemContainer key={child.id} child={child} date={date.clone()} />;
            });
        }

        return result;
    }
}
