import styled from "styled-components";
import { Colors } from "@style/colors";

export const QrCodeViewerStyle = styled.div`
    background: ${Colors.primary()};
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${Colors.white};

    span {
        margin-top: 40px;
        font-size: 40px;
        font-weight: 200;
        letter-spacing: 15px;
    }

    svg {
        width: 200px;
    }
`;
