import styled from "styled-components";
import { Colors } from "@style/colors";

export const RegistrationCheckinPageStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .container {

    }
`;
