import { graphql, OperationOption } from "react-apollo";

import { GetDaycareNamesDaycares, GetDaycareNamesQuery, GetDaycareNamesVariables } from "@models/graphql/types";
import { GraphQL } from "@graphql/graphQL";

export interface GetDaycareNamesQueryResult {
    daycares?: GetDaycareNamesDaycares[];
    daycaresLoading: boolean;
    refetch(): void;
}

export const createGetDaycareNamesQuery = <TProps = {}>(options?: OperationOption<TProps, GetDaycareNamesQuery, GetDaycareNamesVariables, GetDaycareNamesQueryResult>) =>
    graphql<TProps, GetDaycareNamesQuery, GetDaycareNamesVariables, GetDaycareNamesQueryResult>(GraphQL.getDaycareNames, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                daycares: data.daycares,
                daycaresLoading: data.loading,
                refetch: data.refetch
            };
        },
        ...options
    });
