import { combineReducers } from "redux";
import { mainReducer, MainReducerState } from "@redux/reducers/mainReducer";
import { registrationReducer, RegistrationReducerState } from "@redux/reducers/registrationReducer";
import { DaycareReducerState, daycareReducer } from "@redux/reducers/daycareReducer";
import { authReducer, AuthReducerState } from "@redux/reducers/authReducer";
import { RegistrationFlowReducerState, registrationFlowReducer } from "@redux/reducers/registrationFlowReducer";

export interface StoreState {
    main: MainReducerState;
    registration: RegistrationReducerState;
    registrationFlow: RegistrationFlowReducerState;
    daycare: DaycareReducerState;
    auth: AuthReducerState;
}

export const rootReducer = combineReducers<StoreState>({
    main: mainReducer,
    auth: authReducer,
    registration: registrationReducer,
    registrationFlow: registrationFlowReducer,
    daycare: daycareReducer
});
