import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";

import { Role } from "@utils/user";
import { BUILD_NUMBER } from "@utils/environment";
import { AuthService } from "@utils/authService";
import { Logo } from "@style/logo";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { RoleComponent } from "@components/roleComponent/roleComponent";
import { HeaderStyle, OverlayNavigation } from "@components/header/headerStyle";
import { HeaderProps } from "@components/header/headerContainer";

export interface HeaderState {
    navOpen: boolean;
}

@autobind
export class Header extends React.Component<HeaderProps, HeaderState> {

    public state: HeaderState = {
        navOpen: false
    };

    public render() {

        const { user } = this.props;

        return (
            <HeaderStyle>
                <InnerContainer>
                    <Link to="/">
                        <Logo size="small" />
                    </Link>

                    {this.renderMenu()}
                </InnerContainer>

                {this.renderOverlay()}
            </HeaderStyle>
        );
    }

    private get role(): Role | null {
        const { user } = this.props;
        let role: Role | null = null;

        if (user) {
            if (user.roles.some(r => r === Role.DAYCARE)) {
                role = Role.DAYCARE;
            } else if (user.roles.some(r => r === Role.ORGANISER)) {
                role = Role.ORGANISER;
            } else if (user.roles.some(r => r === Role.PARENT)) {
                role = Role.PARENT;
            }
        }

        return role;
    }

    private renderMenu() {
        const { navOpen } = this.state;
        const { user } = this.props;

        return (
            <div>
                <span className="info">Ingelogd als {this.renderName()}</span>
                {navOpen ? <Icon onClick={this.closeMenu} type="cross" /> : <Icon onClick={this.openMenu} type="menu" />}
            </div>
        );

    }

    private renderName() {
        const { user } = this.props;

        if (!user) {
            return;
        }

        if (user.roles.includes(Role.DAYCARE)) {
            return <><FormattedMessage id={`role.${Role.DAYCARE.toLowerCase()}`} />: {user.username}</>;
        } else if (user.roles.includes(Role.PARENT)) {
            if (user.firstName && user.lastName) {
                return `${user.firstName} ${user.lastName}`;
            } else {
                return user.username;
            }
        } else if (user.roles.includes(Role.MANUAL_SYNC)) {
            let displayName = user.username;

            if (user.firstName && user.lastName) {
                displayName = `${user.firstName} ${user.lastName}`;
            }

            return <><FormattedMessage id={`role.${Role.MANUAL_SYNC.toLowerCase()}`} />: {displayName}</>;
        } else {
            let displayName = user.username;

            if (user.firstName && user.lastName) {
                displayName = `${user.firstName} ${user.lastName}`;
            }

            return <><FormattedMessage id={`role.${Role.ORGANISER.toLowerCase()}`} />: {displayName}</>;
        }
    }

    private renderOverlay() {
        const { navOpen } = this.state;

        return (
            <OverlayNavigation open={navOpen}>
                <div className="version">{BUILD_NUMBER}</div>
                <InnerContainer>
                    <ul>
                        <RoleComponent requiredRoles={[Role.DAYCARE, Role.PARENT]}>
                            <li>
                                <NavLink onClick={this.closeMenu} to="/"><FormattedMessage id="header.dashboard" /></NavLink>
                            </li>
                        </RoleComponent>
                        <RoleComponent requiredRoles={[Role.ORGANISER]}>
                            <li>
                                <NavLink onClick={this.closeMenu} to="/daycare-overview"><FormattedMessage id="header.dashboard" /></NavLink>
                            </li>
                        </RoleComponent>

                        <RoleComponent requiredRoles={[Role.ORGANISER, Role.PARENT]}>
                            <li>
                                <NavLink onClick={this.closeMenu} to="/settings"><FormattedMessage id="header.profileSettings" /></NavLink>
                            </li>
                        </RoleComponent>
                    </ul>
                    <span className="divider" />

                    <ul>
                        <RoleComponent requiredRoles={[Role.ORGANISER, Role.DAYCARE]}>
                            <li>
                                <a role="button" target="_blank" rel="noopener noreferrer" href="https://www.kindengezin.be/kinderopvang/sector-babys-en-peuters/aaron" onClick={this.closeMenu} ><FormattedMessage id="header.faq" /></a>
                            </li>
                        </RoleComponent>
                        <RoleComponent requiredRoles={[Role.PARENT]}>
                            <li>
                                <a role="button" target="_blank" rel="noopener noreferrer" href="https://www.kindengezin.be/kinderopvang/ouder/aaron" onClick={this.closeMenu}><FormattedMessage id="header.faq" /></a>
                            </li>
                        </RoleComponent>
                        <li>
                            <a href="mailto: vragen@aaron.support">
                                vragen@aaron.support
                            </a>
                        </li>

                        <li>
                            <a href="tel:052262145">
                                052 26 21 45
                            </a>
                        </li>

                        <li>
                            <a rel="noopener noreferrer" target="_blank" href="https://www.kindengezin.be/sites/default/files/2021-06/privacy-aaron.pdf">
                                Privacy
                            </a>
                        </li>
                    </ul>
                    <span className="divider" />
                    <ul>
                        <li className="logout" onClick={() => AuthService.logout()}>
                            <FormattedMessage id="header.logout" />
                        </li>
                    </ul>
                </InnerContainer>
            </OverlayNavigation>
        );
    }

    private openMenu() {
        this.setState({ navOpen: true });

        const appContainer: HTMLElement | null = document.querySelector(`#main-layout`);

        if (appContainer) {
            appContainer.scrollTo({ top: 0 });
            appContainer.style.overflow = "hidden";
        }
    }

    private closeMenu() {
        this.setState({ navOpen: false });

        const appContainer: HTMLElement | null = document.querySelector(`#main-layout`);

        if (appContainer) {
            appContainer.scrollTo({ top: 0 });
            appContainer.style.overflow = "initial";
        }
    }
}
