import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { updateRegistrationsFilter, UpdateRegistrationsFilterActionOptions } from "@redux/actions/updateRegistrationsFilter";
import { Action } from "@redux/actions/action";
import { RegistrationSearchBar } from "@components/registrations/registrationSearchBar/registrationSearchBar";
import { injectIntl, InjectedIntlProps } from "react-intl";

export interface RegistrationSearchBarContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationSearchBarContainerProps) => ({
    searchValue: state.registration.childrenFilter.search

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    changeFilter(data: UpdateRegistrationsFilterActionOptions) { dispatch(updateRegistrationsFilter(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const RegistrationSearchBarContainer = compose<React.ComponentClass<RegistrationSearchBarContainerProps>>(
    withRedux,
    injectIntl
)(RegistrationSearchBar);

export type RegistrationSearchBarProps =
    RegistrationSearchBarContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    InjectedIntlProps;
