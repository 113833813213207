import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";

import { CodeNumberInput } from "@components/codeNumberInput/codeNumberInput";

export interface CodeNumberInputContainerProps {
    codeLength?: number;
    title?: string;
    subTitle?: string;
    hideCode?: boolean;
    backButton?: boolean;
    errorMessage?: string;
    submitCallback(code: string): Promise<{ valid: boolean; errorMessage?: string }> | { valid: boolean; errorMessage?: string };
}

export const CodeNumberInputContainer = compose<React.ComponentClass<CodeNumberInputContainerProps>>(
    withRouter
)(CodeNumberInput);

export type CodeNumberInputProps =
    CodeNumberInputContainerProps &
    RouteComponentProps;
