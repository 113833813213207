import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { ChildSearchStyle } from "@components/addChild/childSearch/childSearchStyle";

export const AddChildFormStyle = styled(ChildSearchStyle)`

    form {
        flex-wrap: wrap;
    }

    .ant-checkbox-wrapper {
        margin-top: 0;
        margin-bottom: 6px;
    }

    .ant-form-item {
        width: calc(50% - 7.5px);
        margin-right: 0;
        margin-top: 15px;

        &:nth-of-type(odd) {
            margin-right: 15px;
        }
    }

    .child-benefit-field-wrapper {

        .ant-row, .ant-form-item {
            margin: 0 !important;
            width: 45%;
        }
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .until {
            padding: 10px;
        }

        .icon {
            color: ${Colors.error()};
            opacity: 0.5;
            margin-top: 10px;
            margin-left: 5px;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }

        .info-check {
            opacity: 0;
            cursor: default;

            &:hover {
                opacity: 0;
            }
        }
    }

    .add-field-btn {
        margin-top: 0 !important;
    }

    .extra {
        width: 100%;
        display: flex;
        align-items: flex-start;

    }

    ${Button} {
        &.ant-btn-primary {
            margin-left: auto;
            height: 50px;
            width: 150px;
            font-weight: 600;
            font-size: 16px;
        }
    }

    .addContact {
        font-size: 16px;
        margin-top: ${Padding.small};
        cursor: pointer;

        &:hover {
            opacity: .6;
        }
    }


    label.ant-checkbox-wrapper {
        color: ${Colors.text};
    }

    h2 {
        color: ${Colors.primary()};
        margin: ${Padding.large} 0;
        font-size: 20px;
        font-weight: 200;
        width: 100%;
    }

    h3 {
        margin: ${Padding.medium} 0 0;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
    }

    h3 + span {
        width: 100%;
        margin-bottom: ${Padding.medium};
    }
`;

export const Contact = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: ${Padding.small};

    .ant-form-item {
        flex: 1;
    }

    .ant-form-item:not(:last-of-type) {
        margin-right: 15px;
    }

    .ant-form-item:last-of-type {
        flex: 2;
    }

    .trash {
        margin-top: 41px;
        color: ${Colors.error()};
        cursor: pointer;
        height: 20px;

        &:hover {
            opacity: .7;
        }
    }
`;
