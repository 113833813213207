import styled from "styled-components";
import { Box } from "@style/box";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { Placeholder } from "@style/placeholder";

export const InactiveMonthsOverviewStyle = styled.div`
    margin-top: ${Padding.large};

    ${Box} {
        padding: ${Padding.medium};
    }

    h1 {
        font-size: 20px;
        font-weight: 200;
        margin-bottom: ${Padding.medium};
    }

    h2 {
        font-weight: 600;
        margin-top: ${Padding.large};
        margin-bottom: ${Padding.medium};
    }

    p {
        line-height: 25px;
        font-weight: 200;
    }

    label {
        display: block;
        color: ${Colors.primary()};
        margin-top: ${Padding.medium};
        margin-bottom: 10px;
    }

    .ant-select {
        width: 100%;
    }

    .form {
        display: flex;

        ${Button} {
            margin-left: ${Padding.medium};
            width: 120px;
        }
    }

    em {
        color: ${Colors.grey};
    }

    ${Placeholder} {
        height: 30px;
        margin: 15px 0;
    }
`;

export const InactiveMonth = styled.div`
    height: 30px;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;

    &:not(:last-of-type) {
        border-bottom: 1px solid ${Colors.lightGrey};
    }

    .icon {
        color: ${Colors.grey};
        cursor:pointer;

        &:hover {
            color: ${Colors.primary()};
        }
    }
`;
