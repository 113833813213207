import styled from "styled-components";
import { Colors } from "@style/colors";
import { Padding } from "@style/padding";
import { Button } from "@style/button";
import { AlertStyle } from "@components/alert/alertStyle";

export const RegistrationFormStyle = styled.div`
.ant-form-item-label {
    line-height: inherit;
    padding-bottom: 8px;
    label {
        margin: 0;
        color: ${Colors.primary()};
    }
}

label {
    color: ${Colors.primary()};
    display: block;
    margin-top: ${Padding.small};
}

.ant-form-item:not(:first-of-type) {
    margin-top: 10px;
}

.ant-calendar-picker {
    width: 100%;
}

.ant-form-item-with-help {
    margin-bottom: 0;
}
.ant-form-item {
    margin-bottom: 0px;
}

.ant-input-number {
    width: 100%;
}

.ant-input-number-handler-wrap {
    opacity: 1;
}

${Button} {
    width: 100%;
    height: 38px;

    &.addEntry {
        margin-top: 12px;
    }
}

${AlertStyle} {
    margin-top: ${Padding.medium};
}

.entry {
    & > .ant-row {
        margin-top: 12px;
    }

    .invalid-error {
        color: ${Colors.error()};
        padding: 5px 0 10px 0;
    }

    .error {
        background-color: ${Colors.error(0.2)};
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        color: ${Colors.error()};
        padding: 0 10px;
        border-radius: 5px;
        border: 2px solid ${Colors.error()};
    }

    .ant-col-2 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 38px;

        .icon {
            color: ${Colors.error()};
            opacity: 0.5;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.buttons {
    margin-top: ${Padding.medium};
    display: flex;

    button {
        height: 50px;
    }

    button:first-of-type:not(:last-of-type) {
        margin-right: ${Padding.medium};
    }
}
`;

export const SelectItem = styled.span`
display: inline-flex;
align-items: center;

.icon {
    margin-right: 10px;
    font-size: 10px;

    &.triangle-right {
        color: ${Colors.primary()};
    }

    &.triangle-left {
        color: ${Colors.secondary()};
    }
}
`;
