export const Colors = {
    primary(opacity: number = 1) {
        return `rgba(37, 185, 189, ${opacity})`;
    },
    secondary(opacity: number = 1) {
        return `rgba(130, 111, 160, ${opacity})`;
    },
    error(opacity: number = 1) {
        return `rgba(255, 96, 96, ${opacity})`;
    },
    grey: "#C5C5C5",
    lightGrey: "#F5F5F5",
    white: "#FFFFFF",
    text: "#1D1D1D"

};
