import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { UnauthorizedPage } from "@pages/unauthorizedPage/unauthorizedPage";
import { withRouter, RouteComponentProps } from "react-router";

export interface UnauthorizedPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: UnauthorizedPageContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const UnauthorizedPageContainer = compose<React.ComponentClass<UnauthorizedPageContainerProps>>(
    withRedux,
    withRouter
)(UnauthorizedPage);

export type UnauthorizedPageProps =
    UnauthorizedPageContainerProps &
    WithReduxDispatchProps &
    RouteComponentProps &
    WithReduxStateProps;
