import styled from "styled-components";

import { Colors } from "@style/colors";
import { Button } from "@style/button";

export const CancelRegistrationButtonStyle = styled.div`
    ${Button} {
        &.ant-btn-ghost {
        border-color: ${Colors.error()};
        color: ${Colors.error()};

        &:hover {
            background-color: ${Colors.error()};
            color: ${Colors.white};
        }

        &:focus:not(:hover) {
            background-color: transparent;
            border-color: ${Colors.error()};
            color: ${Colors.error()};
        }
    }
    }
`;
