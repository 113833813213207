import styled from "styled-components";
import { InnerContainer } from "@style/innerContainer";
import { Padding } from "@style/padding";

export const RegistrationsOverviewStyle = styled.div`
    .bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: ${Padding.medium};
    }



`;

export const TableBlock = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    text-align: center;

    &:not(.infoColumn):not(:last-of-type) {
        margin-right: 7px;
    }

    &.infoColumn {
        flex-basis: 270px;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: flex-start;
    }

`;
