import * as React from "react";
import { Redirect, Route } from "react-router-dom";

import { Role } from "@utils/user";
import { STORE } from "@redux/setupStore";
import { UnauthorizedPageContainer } from "@pages/unauthorizedPage/unauthorizedPageContainer";

export interface RedirectRouteProps {
    parentRedirect: string;
    organiserRedirect: string;
    daycareRedirect: string;
    adminRedirect: string;
    from: string;
}

export class RedirectRoute extends React.Component<RedirectRouteProps> {
    public render() {
        const { daycareRedirect, organiserRedirect, parentRedirect, adminRedirect, from } = this.props;

        return <Route path={from} exact render={props => {

            const { auth } = STORE.getState();

            if (!auth.user) {
                return <Redirect
                    to={{
                        pathname: "/login"
                    }}
                />;
            } else {

                if (auth.user.roles.some(r => r === Role.DAYCARE)) {

                    return <Redirect
                        exact
                        to={daycareRedirect}
                    />;

                } else if (auth.user.roles.some(r => r === Role.PARENT)) {
                    return <Redirect
                        exact
                        to={parentRedirect}
                    />;
                } else if (auth.user.roles.some(r => r === Role.ORGANISER)) {
                    return <Redirect
                        exact
                        to={organiserRedirect}
                    />;
                } else if (auth.user.roles.some(r => r === Role.MANUAL_SYNC)) {
                    return <Redirect
                        exact
                        to={adminRedirect}
                    />;
                } else {
                    return <UnauthorizedPageContainer />;
                }
            }
        }} />;

    }

}
