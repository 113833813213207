import styled from "styled-components";

import { Placeholder } from "@style/placeholder";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { ErrorBlockStyle } from "@components/errorBlock/errorBlockStyle";

export const ManualChildEntryPageStyle = styled.div`
    background-color: ${Colors.primary()};
    width: 100vw;
    height: 100vh;
    color: ${Colors.white};
    padding: 70px 0;

    h1 {
        color: ${Colors.white};
        font-size: 24px;
        font-weight: 200;
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        a {
            color: ${Colors.white};
            font-size: 14px;
        }
        margin-bottom: ${Padding.large};
    }

    .ant-input {
        background-color: transparent;
        border:none;
        border-radius: 0;
        border-bottom: 1px solid ${Colors.white} !important;
        color: ${Colors.white};
        padding: 10px 0;
        font-size: 30px;
        height: 70px;
        font-weight: 200;

        ::placeholder {
            color: rgba(255,255,255,0.3);
        }
    }

    .icon.search {
        color: ${Colors.white};
        font-size: 20px;
    }

    ${ErrorBlockStyle} {
        background: transparent;

        * {
            color: white;
        }
    }

    .items {
        margin: ${Padding.large} 0;
        overflow-y: auto;
        height: calc(100vh - 260px);
    }

    .empty {
        display: flex;
        width: 100%;
        height: 150px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
    }

    ${Placeholder} {
        height: 50px;
        opacity: 0.1;
        &:not(:last-of-type) {
            margin-bottom: ${Padding.large};
        }
    }

`;

export const ChildItem = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-of-type) {
        margin-bottom: ${Padding.large};
    }

    ${Button} {
        width: 150px;
        height: 50px;

        &:not(:last-of-type) {
            margin-right: 15px;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        font-size: 20px;
        font-weight: 200;

        span {
            font-size: 14px;
        }
    }
`;
