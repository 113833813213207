import { createStore, Store } from "redux";
import { StoreState, rootReducer } from "@redux/reducers/root";

declare const module: any;

export const setupStore = (initialState: Partial<StoreState> = {}) => {

    const store = createStore(rootReducer, initialState, window["__REDUX_DEVTOOLS_EXTENSION__"] && window["__REDUX_DEVTOOLS_EXTENSION__"]());

    if (module.hot) {

        module.hot.accept("./reducers/root", () => {
            const nextRootReducer = require("./reducers/root").root;
            store.replaceReducer(nextRootReducer);
        });

    }

    return store;
};

export const STORE = setupStore();
