import styled from "styled-components";

import { Padding } from "@style/padding";

export const DaycareChildrenListStyle = styled.div`
    width: 100%;

    .item:not(:first-of-type) {
        margin-top: 15px;
    }

    h1 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: ${Padding.medium};
    }

    h2 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;

        :only-child {
            display: none;
        }
    }
`;
