import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { Calendar } from "@components/registrations/calendar/calendar";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { setRegistrationAggregation, SetRegistrationAggregationActionOptions } from "@redux/actions/setRegistrationAggregation";
import { SetRegistrationSelectedDateActionOptions, setRegistrationSelectedDate } from "@redux/actions/setRegistrationSelectedDate";

export interface CalendarContainerProps {
}

export const mapStateToProps = (state: StoreState, ownProps: CalendarContainerProps) => ({
    startDate: state.registration.startDate,
    endDate: state.registration.endDate,
    aggregation: state.registration.aggregation
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setAggregation(opts: SetRegistrationAggregationActionOptions) { dispatch(setRegistrationAggregation(opts)); },
    setDate(data: SetRegistrationSelectedDateActionOptions) { dispatch(setRegistrationSelectedDate(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const CalendarContainer = compose<React.ComponentClass<CalendarContainerProps>>(
    withRedux,
    injectIntl
)(Calendar);

export type CalendarProps =
    CalendarContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    InjectedIntlProps;
