import styled from "styled-components";

import { Colors } from "@style/colors";
import { EntryType } from "@models/graphql/types";

export const PendingRegistrationsStyle = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.17);
    border-radius: 3px;
    z-index: 100;
    width: 300px;

    &.hidden {
        transition: 0.3s;
        width: 220px;
        h1 {
            margin: 0;
            cursor: pointer;

            .icon {
                margin-right: 10px;
            }
        }
    }

    .ant-btn {
        width: 100%;
        margin-top: 10px;
    }

    .ant-progress {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .ant-progress-success-bg, .ant-progress-bg {
        background-color: ${Colors.primary()};
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.5;
        padding: 10px;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }


    h1 {
        color: ${Colors.primary()};
        font-size: 16px;
        margin-bottom: 10px;
    }

    p {
        line-height: 140%;
    }

    em {
        display: block;
        padding-top: 10px;
        font-style: oblique;
    }

    .item {
        margin: 5px 0;
        padding: 8px;
        display: flex;
        align-items: center;
        border: 1px solid ${Colors.grey};
        border-radius: 3px;
        transition: 0.5s;
        height: 40px;

        .icon.entryType {
            font-size: 8px;
            margin-right: 8px;
        }

        .icon.status {
            margin-left: auto;
            font-size: 10px;
        }

        .time {
            display: inline-block;
            width: 70px;
        }

        &.${EntryType.IN} {
            color: ${Colors.primary()};
        }

        &.${EntryType.OUT} {
            color: ${Colors.secondary()};
        }

        &.completed {
            color: ${Colors.grey};
            opacity: 0.5;
        }

        &.error {
            color: ${Colors.error()};
            border-color: ${Colors.error()};
        }

        .trash {
            margin-left: auto;
            color: ${Colors.error()};
            cursor: pointer;
            padding: 5px;

            &:hover {
                color: ${Colors.error(0.8)};
            }
        }
    }

    .list {
        margin: 10px 0 0 0;
        display: block;
        overflow-y: auto;

        max-height: 250px;
    }
`;
