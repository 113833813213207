import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { AlertStyle } from "@components/alert/alertStyle";
import { Contact } from "@components/addChild/addChildForm/addChildFormStyle";

export const EditContactsModalStyle = styled.div`

    h1 {
        font-size: 20px;
        color: ${Colors.primary()};
        font-weight: 200;
        margin-bottom: 15px;
    }

    .addContact {
        display: block;
    }

    .buttons {

        button {
            width: calc(50% - 7px);
            height: 40px;
            margin-top: ${Padding.medium};
            font-size: 16px;

            &:not(:last-of-type) {
                margin-right: 14px;
            }
        }
    }


    .ant-form-item-label {
        line-height: inherit;
        padding-bottom: 8px;
        label {
            margin: 0;
            color: ${Colors.primary()};
        }
    }

    .ant-form-item {
        margin-bottom: 0px;
        width: 100%;

        &:not(:last-of-type){
        margin-right: 15px;
        }
    }

    ${AlertStyle} {
        margin-top: ${Padding.small};
    }

`;

export const EditContact = styled(Contact)`
    margin: 0 0 15px 0;
    padding: 15px;
    border: 1px dotted ${Colors.grey};
    border-radius:4px;
    position: relative;

    .trash {
        margin: 0;
        position: absolute;
        top: 15px;
        right: 15px;
    }

`;
