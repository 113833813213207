import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { UploadsOverview } from "@components/uploadsOverview/uploadsOverview";
import { createGetUploadsListQuery, GetUploadsListQueryResult } from "@graphql/hocs/getUploadsList";
import { SortOrder } from "@models/graphql/types";
import * as moment from "moment";

export interface UploadsOverviewContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: UploadsOverviewContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetUploads = createGetUploadsListQuery({
    options: {
        variables: {
            filter: {
                uploadsTo: moment.utc().endOf("month").format("YYYY-MM-DD") // last day of current month
            },
            sort: [
                {
                    field: "startOfMonth",
                    order: SortOrder.DESC
                }
            ]
        }
    }
});

export const UploadsOverviewContainer = compose<React.ComponentClass<UploadsOverviewContainerProps>>(
    withRedux,
    withGetUploads
)(UploadsOverview);

export type UploadsOverviewProps =
    UploadsOverviewContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    GetUploadsListQueryResult;
