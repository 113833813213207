import { graphql, OperationOption } from "react-apollo";

import { addRefetchQueries } from "@utils/addRefetchQueries";
import {
    CreateRegistrationCreateRegistration,
    CreateRegistrationMutation,
    CreateRegistrationVariables
} from "@models/graphql/types";
import { GraphQL } from "@graphql/graphQL";

export interface CreateRegistrationMutationResult {
    createRegistration(variables: CreateRegistrationVariables): Promise<CreateRegistrationCreateRegistration | null | undefined>;
}

export const createCreateRegistrationMutation = <TProps = {}>(
    args: {
        opOptions?: OperationOption<TProps, CreateRegistrationMutation, CreateRegistrationVariables, CreateRegistrationMutationResult>;
        awaitRefetchQueries?: boolean;
    } = {}
) => {
    const { opOptions, awaitRefetchQueries } = args;
    const options = addRefetchQueries(opOptions, ["getChildrenRegistrationList", "getChildDetail", "getChildrenNames"], awaitRefetchQueries);

    return graphql<TProps, CreateRegistrationMutation, CreateRegistrationVariables, CreateRegistrationMutationResult>(GraphQL.createRegistration, {
        props({ mutate }): CreateRegistrationMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async createRegistration(variables: CreateRegistrationVariables): Promise<CreateRegistrationCreateRegistration | null | undefined> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        return result.data.createRegistration;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
};
