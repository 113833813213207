import * as React from "react";
import { ChildBenefitPeriod } from "@models/graphql/types";
import * as moment from "moment";
import { FormattedMessage } from "react-intl";

export interface ChildBenefitCheckProps {
    childBenefitPeriods: ChildBenefitPeriod[];
}

export const ChildBenefitCheck: React.StatelessComponent<ChildBenefitCheckProps> = (props: ChildBenefitCheckProps) => {

    const { childBenefitPeriods } = props;
    if (childBenefitPeriods.length > 0) {

        // zoekt voor match in periodes
        const match = childBenefitPeriods.find(item => {
            const now = moment();
            const start = moment(item.start);

            if (item.end) {
                // als er een einddatum is op periode
                const end = moment(item.end);

                // nu tussen start en einde: match
                if (now.isBetween(start, end)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                // geen einddatum op periode => nu na start
                if (now.isAfter(start)) {
                    return true;
                } else {
                    return false;
                }
            }
        });

        if (match) {
            return <>&nbsp;&nbsp;|&nbsp;&nbsp; <FormattedMessage id="registrationItem.noChildBenifit" /></>;
        }
    }

    return null;
};
