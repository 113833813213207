import styled from "styled-components";
import { Box } from "@style/box";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { AlertStyle } from "@components/alert/alertStyle";

export const ChildDetailsFormStyle = styled(Box)`
    padding: ${Padding.medium};
    flex-basis: 350px;

    .ant-form-item-label {
        line-height: inherit;
        padding-bottom: 8px;
        label {
            color: ${Colors.primary()};
        }
    }

    .error-message {
        color: #ff6060;
    }

    .child-benefit-field-wrapper {

        .ant-row, .ant-form-item {
            margin: 0 !important;
            width: 45%;
        }
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .until {
            padding: 10px;
        }

        .icon {
            color: ${Colors.error()};
            opacity: 0.5;
            margin-top: 10px;
            margin-left: 5px;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }

        .info-check {
            opacity: 0;
            cursor: default;

            &:hover {
                opacity: 0;
            }
        }
    }

    .ant-form-item:not(:first-of-type) {
        margin-top: 10px;
    }

    .ant-calendar-picker {
        width: 100%;
    }

    .ant-form-item-with-help {
        margin-bottom: 0;
    }
    .ant-form-item {
        margin-bottom: 0px;
    }

    ${Button} {
        width: 100%;
        margin-top: ${Padding.small};
    }

    ${AlertStyle} {
        margin-top: 14px;
    }
`;
