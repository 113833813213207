import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { AuthService } from "@utils/authService";
import { Logo } from "@style/logo";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { RegistrationPageStyle } from "@pages/registrationPage/registrationPageStyle";
import { RegistrationPageProps } from "@pages/registrationPage/registrationPageContainer";

export interface RegistrationPageState {

}

@autobind
export class RegistrationPage extends React.Component<RegistrationPageProps, RegistrationPageState> {

    public render() {

        const { } = this.props;

        return (
            <RegistrationPageStyle>
                <div onClick={this.openCheckin} className="checkin">
                    <div className="top">
                        <Logo size="small" />
                    </div>
                    <div className="center">
                        <img src={require("@assets/images/checkin.svg")} alt="checkin" />
                        <FormattedMessage tagName="h1" id="registrationPage.checkin" />
                    </div>
                    <Button type="ghost"><FormattedMessage id="registrationPage.registerCheckin" /></Button>
                </div>
                <div onClick={this.openCheckout} className="checkout">
                    <div className="top">
                        <Icon onClick={this.toDaycare} type="house" />
                    </div>
                    <div className="center">
                        <img src={require("@assets/images/checkout.svg")} alt="checkout" />
                        <FormattedMessage tagName="h1" id="registrationPage.checkout" />
                    </div>
                    <Button type="ghost"><FormattedMessage id="registrationPage.registerCheckout" /></Button>
                </div>
            </RegistrationPageStyle>
        );
    }

    public componentDidMount() {
        AuthService.isAuthorized = false;

        this.props.setRegistrationResult({ registrationResult: null });
        this.props.setCode({ code: null });
        this.props.setChildNotFound({ childNotFound: false });
    }

    private toDaycare(e: React.MouseEvent) {
        e.stopPropagation();
        e.preventDefault();

        const { history } = this.props;
        history.push("/daycare");
    }

    private openCheckin() {
        const { history } = this.props;
        history.push("/registration/checkin");
    }

    private openCheckout() {
        const { history } = this.props;
        history.push("/registration/checkout");
    }
}
