import { Icon as AntIcon } from "antd";
import * as classNames from "classnames";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@style/icon";
import { Code } from "@pages/confirmRegistrationPage/confirmRegistrationPageStyle";
import { CodeNumberInputStyle } from "@components/codeNumberInput/codeNumberInputStyle";
import { CodeNumberInputProps } from "@components/codeNumberInput/codeNumberInputContainer";

export interface CodeNumberInputState {
    code: string;
    invalid: boolean;
    errorMessage?: string;
    submitting: boolean;
}

@autobind
export class CodeNumberInput extends React.Component<CodeNumberInputProps, CodeNumberInputState> {
    public state: CodeNumberInputState = {
        code: "",
        invalid: false,
        submitting: false
    };

    public render() {

        const { code, invalid, submitting, errorMessage } = this.state;

        const { title, subTitle, hideCode, backButton } = this.props;

        return (
            <CodeNumberInputStyle>
                {backButton && <span className="back" onClick={this.goBack} ><Icon type="back" /><FormattedMessage id="codeNumberInput.back" /></span>}
                {title && <FormattedMessage tagName="h1" id={title} />}
                {subTitle && <FormattedMessage tagName="h2" id={subTitle} />}
                <Code>
                    {[...code].map((char, i) => <span className="char" key={i}>{hideCode ? <>&bull;</> : char}</span>)}
                    {this.renderPlaceholders()}
                </Code>
                {invalid && <span className="invalid"><FormattedMessage id={errorMessage ? errorMessage : "codeNumberInput.invalid"} /></span>}
                <div className={classNames("keypad", { submitting })}>
                    {submitting && <AntIcon type="loading" />}
                    <span onClick={() => this.addNumber("1")}>1</span>
                    <span onClick={() => this.addNumber("2")}>2</span>
                    <span onClick={() => this.addNumber("3")}>3</span>
                    <span onClick={() => this.addNumber("4")}>4</span>
                    <span onClick={() => this.addNumber("5")}>5</span>
                    <span onClick={() => this.addNumber("6")}>6</span>
                    <span onClick={() => this.addNumber("7")}>7</span>
                    <span onClick={() => this.addNumber("8")}>8</span>
                    <span onClick={() => this.addNumber("9")}>9</span>
                    <span onClick={this.removeNumber}><Icon type="backspace" /></span>
                    <span onClick={() => this.addNumber("0")}>0</span>
                    <span onClick={this.onSubmit}><Icon type="checkmark" /></span>
                </div>
            </CodeNumberInputStyle>
        );
    }

    public componentWillMount() {
        window.addEventListener("keydown", this.onKeyDown);
    }

    public componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);

    }

    private goBack() {
        this.props.history.goBack();
    }

    private onKeyDown(ev: KeyboardEvent) {
        if (ev.key.match(/\d/)) {
            this.addNumber(ev.key);
        }

        if (ev.key === "Backspace") {
            this.removeNumber();
        }

        if (ev.key === "Enter") {
            this.onSubmit();
        }

    }

    private renderPlaceholders() {
        const { code } = this.state;
        const { codeLength } = this.props;

        if (codeLength) {
            const returnVal: JSX.Element[] = [];
            for (let i = codeLength; i > code.length; i -= 1) {
                returnVal.push(<span className="char" key={i}>&nbsp;</span>);
            }

            return returnVal;
        }
    }

    private async onSubmit() {
        const { submitCallback } = this.props;
        const { code } = this.state;

        const result = submitCallback(code);

        if (result instanceof Promise) {
            this.setState({ submitting: true });
            const { valid, errorMessage } = await result;

            this.setState({
                submitting: false,
                invalid: !valid,
                errorMessage
            });

        } else {

            this.setState({
                invalid: !result.valid,
                errorMessage: result.errorMessage
            });
        }

    }

    private removeNumber() {
        this.setState({
            code: this.state.code.slice(0, this.state.code.length - 1)
        });
    }

    private addNumber(number: string) {
        const { codeLength } = this.props;
        const { code } = this.state;

        if ((codeLength && code.length < codeLength) || code.length < 6) {
            this.setState({
                code: code + number
            });
        }
    }
}
