import styled from "styled-components";
import { Colors } from "@style/colors";
import { Padding } from "@style/padding";
import { Button } from "@style/button";

export const RegistrationPageStyle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;

    .checkin, .checkout {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
        text-align: center;
        padding: 0 20px;

        h1 {
            color: ${Colors.white};
            font-size: 40px;
            margin-top: 55px;
            font-weight: 200;
        }
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .top {
        padding: 40px 40px 0 40px;
        width: 100%;
    }

    ${Button} {
        height: 58px;
        max-width: 350px;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 75px;
    }

    .checkin {
        background-color: ${Colors.primary()};
    }

    .checkout {
        background-color: ${Colors.secondary()};

        .top {
            text-align: right;
            color: ${Colors.white};
            font-size: 30px;
        }
    }


    @media (max-width: 500px) {
        flex-direction: column;

        .checkin, .checkout {

            .center img {
                height: 100px;
            }

            h1 {
                margin: 20px 0 20px 0;
                font-size: 30px;
            }

            ${Button} {
                margin-bottom: 30px;
            }

            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .top {
            position: absolute;
            top: 0;
        }

    }
`;
