import * as React from "react";
import { autobind } from "core-decorators";
import { DayProps } from "@components/registrations/calendar/day/dayContainer";
import { DayStyle } from "@components/registrations/calendar/day/dayStyle";

export interface DayState {

}

@autobind
export class Day extends React.Component<DayProps, DayState> {

    public render() {

        const { day, selected, disabled } = this.props;

        return (
            <DayStyle className={selected && day ? "selected" : disabled ? "disabled" : ""}>
                {day.format("D")}
            </DayStyle>

        );
    }
}
