import styled from "styled-components";
import { Placeholder } from "@style/placeholder";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";

export const DaycareSearchResultListStyle = styled.div`
    width: 100%;

    h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: ${Padding.medium};
    }

    ${Placeholder} {
        height: 25px;
        margin-bottom: 10px;
    }

    ul {
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 20px;
            cursor: pointer;

            .icon {
                font-size: 12px;
            }

            &:hover {
                color: ${Colors.primary()};
                font-weight: 600;
            }

            &:not(:last-of-type) {
                margin-bottom: 10px;

            }
        }
    }
`;
