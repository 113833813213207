import { graphql } from "react-apollo";

import { LoginLogin, LoginMutation, LoginVariables } from "@models/graphql/types";
import { GraphQL } from "@graphql/graphQL";

export interface LoginMutationResult {
    login(variables: LoginVariables): Promise<LoginLogin>;
}

export const createLoginMutation = <TProps = {}>() => {
    return graphql<TProps, LoginMutation, LoginVariables, LoginMutationResult>(GraphQL.login, {
        props({ mutate }): LoginMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async login(variables: LoginVariables): Promise<LoginLogin> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("No result or data");
                        }

                        return result.data.login;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        }
    });
};
