import styled from "styled-components";
import { OrganiserLoginFormStyle } from "@components/organiserLoginForm/organiserLoginFormStyle";
import { Button } from "@style/button";
import { Padding } from "@style/padding";

export const DaycareLoginFormStyle = styled(OrganiserLoginFormStyle)`

    .bottomBar {
        display: flex;
        justify-content: space-between;

        ${Button} {
            margin-left: ${Padding.medium};
            max-width: 200px;

        }
    }

    .search-with-icon {
        display: flex;
        align-items: center;
    }

    .remove-btn {
        cursor: pointer;
        color: rgba(255,96,96,1);
        opacity: 0.5;
        margin-left: 1rem;

        :hover {
            opacity: 1;
        }
    }

    .result {
        font-weight: 600;
        padding-top: ${Padding.medium};
    }

`;
