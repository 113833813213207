import { Form, Icon as AntIcon, Input } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { debounce } from "@utils/debounce";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { DaycareLoginFormStyle } from "@components/daycareLoginForm/daycareLoginFormStyle";
import { DaycareLoginFormProps } from "@components/daycareLoginForm/daycareLoginFormContainer";

export interface DaycareLoginFormState {
    searchValue: string;
}

@autobind
export class DaycareLoginForm extends React.Component<DaycareLoginFormProps, DaycareLoginFormState> {

    public state: DaycareLoginFormState = {
        searchValue: ""
    };

    private debouncedSearch: (caseNumber: string) => void = debounce(this.setCaseNumber, 300);

    public render() {

        const { intl, validDaycareLoading, validDaycare } = this.props;
        const suffix = validDaycareLoading ? <AntIcon style={{ color: Colors.primary() }} type="loading" /> : validDaycare ? <Icon style={{ color: Colors.primary() }} type="checkmark" /> : undefined;

        const { searchValue } = this.state;

        return (
            <DaycareLoginFormStyle>
                <InnerContainer>

                    <h1><FormattedMessage id="daycareLoginForm.title" /></h1>
                    <Box>
                        <Form>
                            <Form.Item key={"inputformitem"} label={intl.formatMessage({ id: "daycareLoginForm.caseNumber" })}>
                                <div className="search-with-icon">
                                <Input value={searchValue} key="input" onChange={this.onCasenumberChange} size="large" suffix={suffix} />
                                { searchValue === "" || searchValue === undefined ? null : <Icon type="cross" onClick={this.onClickClear} className="remove-btn" />}
                                </div>
                            </Form.Item>

                            <div className="result">
                                {validDaycareLoading && <FormattedMessage id="daycareLoginForm.searching" />}
                                {!validDaycareLoading && validDaycare && `${validDaycare.name} (${validDaycare.address})`}
                            </div>

                            <div className="bottomBar">
                                <Link className="back" to="/login"><Icon type="back" /><FormattedMessage id="daycareLoginForm.back" /></Link>
                                <Button disabled={!validDaycare} htmlType="submit" onClick={this.submitForm} type="primary"><FormattedMessage id="daycareLoginForm.continue" /></Button>
                            </div>
                        </Form>
                    </Box>
                </InnerContainer>
            </DaycareLoginFormStyle>

        );
    }

    private onClickClear() {
        this.setState({
            searchValue: ""
        });

        this.debouncedSearch("");
    }

    private onCasenumberChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchValue: ev.target.value
        });

        if (ev.target.value) {
            this.debouncedSearch(ev.target.value);
        }
    }

    private setCaseNumber(val: string) {
        this.props.setLocalState({
            caseNumberSearch: val
        });
    }

    private submitForm(e: React.FormEvent | React.MouseEvent) {
        e.preventDefault();

        const { updateDaycareLogin, caseNumberSearch, history } = this.props;

        updateDaycareLogin({
            daycareLogin: {
                caseNumber: caseNumberSearch,
                redirectPath: "/"
            }
        });

        history.push("/daycare-login");

    }
}
