import { autobind } from "core-decorators";
import * as React from "react";

import { Icon } from "@style/icon";
import { Aggregation } from "@models/graphql/types";
import { DateSelectorStyle } from "@components/registrations/dateSelector/dateSelectorStyle";
import { DateSelectorProps } from "@components/registrations/dateSelector/dateSelectorContainer";
import { CalendarContainer } from "@components/registrations/calendar/calendarContainer";

export interface DateSelectorState {
    calendarOpen: boolean;
}

@autobind
export class DateSelector extends React.Component<DateSelectorProps, DateSelectorState> {

    public state: DateSelectorState = {
        calendarOpen: false
    };

    public render() {
        const { calendarOpen } = this.state;

        const date = this.formatDate();

        return (
            <DateSelectorStyle>
                <div >
                    <span className="anchor">{calendarOpen && <CalendarContainer />}</span>
                    <span onClick={this.toggleCalendar} className={`date calendar-button ${calendarOpen ? "open" : ""}`}>{date} <Icon type="calendar" /></span>
                    <i className="divider" />
                    <Icon onClick={this.onPreviousClick} type="previous" /><Icon onClick={this.onNextClick} type="next" />
                </div>
            </DateSelectorStyle>
        );
    }

    public componentWillMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);

    }

    private formatDate() {
        const { aggregation, startDate, intl } = this.props;

        // tslint:disable-next-line:switch-default
        switch (aggregation) {
            case Aggregation.DAY:
                return `${intl.formatMessage({ id: "dateSelector.week" })} ${startDate.isoWeek()} - ${startDate.isoWeekYear()}`;
            case Aggregation.WEEK:
                return `${startDate.clone().format("MMMM")} ${startDate.year()}`;
        }
    }

    private toggleCalendar() {
        this.setState({
            calendarOpen: !this.state.calendarOpen
        });
    }

    private handleClick(e: MouseEvent) {
        const el = document.querySelector(".date-selector-calendar");
        if (e.target && el) {
            console.log(e.target["attributes"]);
            if (typeof (e.target as HTMLDivElement).className === "string" && (
                el.contains(e.target as any) ||
                (e.target as HTMLDivElement).className.includes("ant-select") ||
                (e.target as HTMLDivElement).className.includes("calendar-button") ||
                e.target["data-icon"] === "down"
            )
            ) {
                return;
            }
        }

        this.setState({ calendarOpen: false });
    }

    private onPreviousClick() {

        const { setDate, aggregation, startDate, endDate } = this.props;
        let newStartDate = startDate.clone();
        let newEndDate = endDate.clone();

        // tslint:disable-next-line:switch-default
        switch (aggregation) {
            case Aggregation.DAY:
                newStartDate = newStartDate.subtract(1, "week");
                newEndDate = newEndDate.subtract(1, "week");
                break;
            case Aggregation.WEEK:
                newStartDate = newStartDate.subtract(1, "month").startOf("month");
                newEndDate = newStartDate.clone().endOf("month");
                // tslint:disable-next-line:switch-final-break
                break;
        }

        setDate({ startDate: newStartDate, endDate: newEndDate });
    }

    private onNextClick() {
        const { setDate, aggregation, startDate, endDate } = this.props;
        let newStartDate = startDate.clone();
        let newEndDate = endDate.clone();

        // tslint:disable-next-line:switch-default
        switch (aggregation) {
            case Aggregation.DAY:
                newStartDate = newStartDate.add(1, "week");
                newEndDate = newEndDate.add(1, "week");
                break;
            case Aggregation.WEEK:
                newStartDate = newStartDate.add(1, "month").startOf("month");
                newEndDate = newStartDate.clone().endOf("month");
                // tslint:disable-next-line:switch-final-break
                break;
        }

        setDate({ startDate: newStartDate, endDate: newEndDate });
    }
}
