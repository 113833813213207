import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { SetPasswordPage } from "@pages/setPasswordPage/setPasswordPage";
import { createSetPasswordMutation, SetPasswordMutationResult } from "@graphql/hocs/setPassword";

export interface SetPasswordPageContainerProps {

}

interface RouteParams {

}

export const mapStateToProps = (state: StoreState, ownProps: SetPasswordPageContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

const withSetPasswordMutation = createSetPasswordMutation();

export const SetPasswordPageContainer = compose<React.ComponentClass<SetPasswordPageContainerProps>>(
    withRedux,
    withForm,
    withRouter,
    injectIntl,
    withSetPasswordMutation
)(SetPasswordPage);

export type SetPasswordPageProps =
    SetPasswordPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    FormComponentProps &
    InjectedIntlProps &
    SetPasswordMutationResult &
    RouteComponentProps;
