import { LogoutDaycareVariables, LogoutDaycareMutation } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";
import { addRefetchQueries } from "@utils/addRefetchQueries";

export interface LogoutDaycareMutationResult {
    logoutDaycare(variables: LogoutDaycareVariables): Promise<boolean>;
}

export const createLogoutDaycareMutation = <TProps = {}>(
    args: {
        opOptions?: OperationOption<TProps, LogoutDaycareMutation, LogoutDaycareVariables, LogoutDaycareMutationResult>;
        awaitRefetchQueries?: boolean;
    } = {}
) => {
    const { opOptions, awaitRefetchQueries } = args;
    const options = addRefetchQueries(opOptions, [], awaitRefetchQueries);

    return graphql<TProps, LogoutDaycareMutation, LogoutDaycareVariables, LogoutDaycareMutationResult>(GraphQL.logoutDaycare, {
        props({ mutate }): LogoutDaycareMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async logoutDaycare(variables: LogoutDaycareVariables): Promise<boolean> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        return result.data.logoutDaycare;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
};
