import { Popover } from "antd";
import classNames from "classnames";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { minutesToHm } from "@utils/minutesToHm";
import { isSuspended } from "@utils/isSupsended";
import { isDayAggregation, isWeekAggregation } from "@utils/aggregatedRegistrationHelper";
import { Placeholder } from "@style/placeholder";
import { Icon } from "@style/icon";
import {
    Aggregation,
    EntryType,
    GetChildrenRegistrationListAggregatedRegistrations,
    GetChildrenRegistrationListEntries,
    GetChildrenRegistrationListRegistration
} from "@models/graphql/types";
import {
    CheckinDisplay,
    EmptyBlock,
    InvalidBlock,
    NowPresentBlock,
    ValidBlock
} from "@components/registrations/registrationItem/registrationItemStyle";
import { DateSelectorContainer } from "@components/registrations/dateSelector/dateSelectorContainer";
import { ErrorBlock } from "@components/errorBlock/errorBlock";
import {
    ChildDetailRegistrationsListStyle,
    Day,
    ListItem,
    Week
} from "@components/childDetail/childDetailRegistrationsList/childDetailRegistrationsListStyle";
import {
    ChildDetailRegistrationsListProps
} from "@components/childDetail/childDetailRegistrationsList/childDetailRegistrationsListContainer";

export interface ChildDetailRegistrationsListState {

}

@autobind
export class ChildDetailRegistrationsList extends React.Component<ChildDetailRegistrationsListProps, ChildDetailRegistrationsListState> {

    public render() {
        const { children } = this.props;

        let name = "";

        if (children && children[0]) {
            name = `${children[0].firstName} ${children[0].lastName}`;
        }

        return (
            <ChildDetailRegistrationsListStyle>
                <div className="topbar">
                    <h1>
                        Registraties voor {name}
                    </h1>
                    <div className="legend">
                        <span className="in">
                            <Icon type="triangle-right" /> In
                    </span>
                        <span className="out">
                            <Icon type="triangle-left" /> Uit
                    </span>

                    </div>
                </div>
                <DateSelectorContainer />
                <div className="days">
                    {this.renderRegistrations()}
                </div>
            </ChildDetailRegistrationsListStyle>
        );
    }

    private renderRegistrations() {
        const { children, childrenLoading, aggregation, startDate, endDate } = this.props;

        if (childrenLoading && !children) {
            return (
                <>
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </>
            );
        }

        if (!children) {
            return (
                <ErrorBlock />
            );
        }

        let aggregatedRegistrations: null | GetChildrenRegistrationListAggregatedRegistrations[] = null;
        if (children[0]) {
            aggregatedRegistrations = children[0].aggregatedRegistrations;
        }

        if (aggregation === Aggregation.DAY) {
            const date = startDate.clone();
            const result: JSX.Element[] = [];
            let i = 0;

            while (i < 7) {

                const match = aggregatedRegistrations && aggregatedRegistrations.find(reg => date.isSame(reg.date, "day"));

                result.push(<ListItem key={i}>
                    <div className="date">
                        <span>{date.format("DD")}</span>
                        <span>{date.format("dd")}</span>
                    </div>

                    {this.renderCheckins(match)}
                    {this.renderBlock(match, date)}
                </ListItem>);

                date.add(1, "d");
                i += 1;
            }

            return result;
        } else {

            return (
                <>
                    <Week className="header">
                        <span className="header" >Ma</span>
                        <span className="header" >Di</span>
                        <span className="header" >Wo</span>
                        <span className="header" >Do</span>
                        <span className="header" >Vr</span>
                        <span className="header" >Za</span>
                        <span className="header" >Zo</span>
                    </Week>
                    {this.weeks.map((week, index) => {
                        const hasActiveDays = week.filter(day => day.disabled === true).length >= 7 ? false : true;

                        if (hasActiveDays) {
                            return <Week key={index} >
                                {week.map(day => {

                                    const match = aggregatedRegistrations && aggregatedRegistrations.find(reg => {
                                        const date = moment(reg.date, "YYYY-MM-DD");

                                        return day.day.isSame(date, "day");
                                    });

                                    if (day.disabled) {
                                        return <Day key={day.day.toISOString()} className="disabled"><span>{day.day.format("DD")}</span> </Day>;
                                    }

                                    return (
                                        <Day key={day.day.toISOString()}>
                                            <span>{day.day.format("DD")}</span>
                                            {this.renderBlock(match, day.day)}
                                        </Day>
                                    );
                                })}
                            </Week>;
                        }
                    })}
                </>
            );
        }

    }

    private get weeks() {
        const { startDate } = this.props;
        const currentMonth = startDate.endOf("week").month();
        const currentYear = startDate.endOf("week").year();

        const currentMonthMoment = moment().set("M", currentMonth).set("y", currentYear);

        const daysInMonth = currentMonthMoment.daysInMonth();
        const firstDay = currentMonthMoment.startOf("month").day() - 1 === -1 ? 6 : currentMonthMoment.startOf("month").day() - 1;

        const weeks: ({ day: moment.Moment; disabled: boolean }[])[] = [];

        let date = 1;
        for (let i = 0; i < 6; i += 1) {
            const week: { day: moment.Moment; disabled: boolean }[] = [];

            for (let j = 0; j < 7; j += 1) {
                if (i === 0 && j < firstDay) {
                    const lastMonth = currentMonthMoment.clone().subtract(1, "M");

                    week.push({ day: lastMonth.clone().endOf("M").subtract(firstDay - 1 - j, "d"), disabled: true });
                } else if (date > daysInMonth) {
                    const nextMonth = currentMonthMoment.clone().add(1, "M");

                    week.push({ day: nextMonth.clone().set("date", date % daysInMonth), disabled: true });
                    date += 1;
                } else {
                    week.push({ day: currentMonthMoment.clone().set("date", date), disabled: false });

                    date += 1;
                }
            }

            weeks.push(week);
        }

        return weeks;
    }

    private renderBlock(reg: GetChildrenRegistrationListAggregatedRegistrations | undefined | null, date: moment.Moment) {
        const { childId, isParent, daycares } = this.props;

        const dateString = date.toISOString();
        if (reg) {

            if (!isDayAggregation(reg)) {
                throw new Error("Returned registration is not correct");
            }

            if (reg.registration.valid) {

                let content = (
                    <ValidBlock onClick={() => this.editRegistration(childId, dateString, reg.registration)} >
                        {minutesToHm(reg.amountOfMinutes)}
                    </ValidBlock>
                );

                if (date.isSame(moment(), "day") && reg.registration.entries.length > 0) {
                    const lastEntry = reg.registration.entries[reg.registration.entries.length - 1];
                    if (lastEntry.type === EntryType.IN) {
                        content = (
                            <NowPresentBlock>
                                <FormattedMessage id="registrationItem.nowPresent" />
                            </NowPresentBlock>
                        );
                    }
                }

                if (reg.registration.entries) {
                    return <Popover content={this.renderCheckins(reg)}>{content}</Popover>;
                }

                return content;
            } else {
                const content = (
                    <InvalidBlock onClick={() => this.editRegistration(childId, dateString, reg.registration)} >
                        {!isParent && <div className="circle"><Icon type="edit" /></div>}
                        <FormattedMessage id="registrationItem.invalid" />
                    </InvalidBlock>
                );

                if (reg.registration.entries) {
                    return <Popover content={this.renderCheckins(reg)}>{content}</Popover>;
                }

                return content;
            }
        } else {

            const disabled = date.isAfter(moment(), "day") || (daycares && daycares.length && isSuspended(daycares[0].activeMonths, date)) || isParent;

            return (
                <EmptyBlock onClick={() => this.createRegistration(childId, dateString)} className={classNames({ disabled })}>
                    +
                </EmptyBlock>
            );
        }
    }

    private createRegistration(childId: number, date: string) {
        const { createRegistration, isParent } = this.props;
        if (!isParent) {
            createRegistration({ create: { childId, date } });
        }
    }

    private editRegistration(childId: number, date: string, registration: GetChildrenRegistrationListRegistration) {
        const { editRegistration, isParent } = this.props;
        if (!isParent) {
            editRegistration({ edit: { childId, date, registration } });
        }

    }

    private renderCheckins(match: GetChildrenRegistrationListAggregatedRegistrations | undefined | null) {

        if (match && isWeekAggregation(match)) {

            return null;
        }

        if (!match || !match.registration.entries) {
            return <div className="empty"><FormattedMessage id="childDetailRegistrationsList.noRegistrations" /></div>;
        }

        const { entries } = match.registration;

        const filledEntries: GetChildrenRegistrationListEntries[] = [];

        entries.forEach((entry, index) => {
            if (index === 0 && entry.type === EntryType.OUT) {
                filledEntries.push({
                    type: EntryType.IN,
                    time: "",
                    order: -1,
                    id: -1
                });
                filledEntries.push(entry);

                return;
            } else if (entries[index + 1] && entries[index + 1].type === entry.type) {
                filledEntries.push(entry);
                filledEntries.push({
                    type: entry.type === EntryType.IN ? EntryType.OUT : EntryType.IN,
                    time: "",
                    order: -1,
                    id: -1
                });

                return;
            } else {
                filledEntries.push(entry);
            }

        });

        return (
            <CheckinDisplay>

                {filledEntries.map((entry, index) => {

                    if (!moment(entry.time).isValid()) {
                        return (
                            <span key={index} className="error">
                                <Icon type="cross" /> --:--
                    </span>
                        );
                    }

                    if (entry.type === EntryType.IN) {
                        return (
                            <span key={index} className="checkin">
                                <Icon type="triangle-right" /> {moment(entry.time).format("HH:mm")}
                            </span>
                        );
                    }

                    if (entry.type === EntryType.OUT) {
                        return (
                            <span key={index} className="checkout">
                                <Icon type="triangle-left" /> {moment(entry.time).format("HH:mm")}
                            </span>
                        );
                    }

                })}
            </CheckinDisplay>
        );

    }
}
