import { GetFirstUploadQuery, GetFirstUploadVariables } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";

export interface GetFirstUploadQueryResult {
    firstUpload?: string;
    firstUploadLoading: boolean;
}

export const createGetFirstUploadQuery = <TProps = {}>(options?: OperationOption<TProps, GetFirstUploadQuery, GetFirstUploadVariables, GetFirstUploadQueryResult>) =>
    graphql<TProps, GetFirstUploadQuery, GetFirstUploadVariables, GetFirstUploadQueryResult>(GraphQL.getFirstUpload, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                firstUpload: data.firstUpload,
                firstUploadLoading: data.loading
            };
        },
        ...options
    });
