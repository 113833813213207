import { Select } from "antd";
import classNames from "classnames";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import MediaQuery from "react-responsive";

import { Placeholder } from "@style/placeholder";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Status } from "@models/graphql/types";
import { NavItem, UploadsOverviewStyle } from "@components/uploadsOverview/uploadsOverviewStyle";
import { UploadsOverviewProps } from "@components/uploadsOverview/uploadsOverviewContainer";
import { UploadDetailContainer } from "@components/uploadDetail/uploadDetailContainer";

export interface UploadsOverviewState {
    activeMonthId?: number;
}

@autobind
export class UploadsOverview extends React.Component<UploadsOverviewProps, UploadsOverviewState> {

    public state: UploadsOverviewState = {

    };

    public static getDerivedStateFromProps(props: UploadsOverviewProps, state: UploadsOverviewState): UploadsOverviewState | null {
        if (!state.activeMonthId && props.uploads && props.uploads[0]) {
            return {
                activeMonthId: props.uploads[0].id
            };
        }

        return null;
    }

    public render() {

        const { activeMonthId } = this.state;

        return (
            <UploadsOverviewStyle>
                <InnerContainer>
                    <MediaQuery minWidth={575}>
                        {this.renderNormalNav()}
                    </MediaQuery>
                    <MediaQuery maxWidth={574}>
                        {this.renderMobileNav()}
                    </MediaQuery>
                    <UploadDetailContainer activeMonthId={activeMonthId} />
                </InnerContainer>
            </UploadsOverviewStyle>
        );
    }

    private renderMobileNav() {
        const { uploads } = this.props;
        const { activeMonthId } = this.state;

        return (
            <Select size="large" onChange={this.onSelectChange} value={activeMonthId}>
                {uploads && uploads.map(upload => {
                    const date = moment(upload.startOfMonth);

                    return (
                        <Select.Option key={String(upload.id)} value={upload.id}>
                            <NavItem className={classNames(upload.status, { active: upload.id === activeMonthId, hasRemarks: (upload.error || []).length === 0 && upload.hasRemarks }, "mobile")} key={upload.startOfMonth}>
                                <div className="content">
                                    <span className="status">
                                        {upload.status === Status.PENDING_FEEDBACK ? <Icon type="cross" /> : <Icon type="checkmark" />}
                                    </span>
                                    {date.format("MMMM YYYY")}

                                </div>
                            </NavItem>
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }

    private onSelectChange(val: number) {
        this.setMonth(val);
    }

    private renderNormalNav() {

        const { uploads, uploadsLoading } = this.props;
        const { activeMonthId } = this.state;

        if (uploadsLoading) {
            return (
                <nav>
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </nav>
            );
        }

        if (uploads) {
            return (
                <nav>
                    {uploads.map(upload => {
                        const date = moment(upload.startOfMonth);

                        return <NavItem onClick={() => this.setMonth(upload.id)} className={classNames(upload.status, { active: upload.id === activeMonthId, hasRemarks: (upload.error || []).length === 0 && upload.hasRemarks })} key={upload.startOfMonth}>
                            <div className="content">
                                <span className="status">
                                    {upload.status === Status.PENDING_FEEDBACK ? <Icon type="cross" /> : <Icon type="checkmark" />}
                                </span>
                                {date.format("MMMM YYYY")}
                            </div>
                        </NavItem>;
                    })}
                </nav>
            );
        }
    }

    private setMonth(id: number) {
        this.setState({
            activeMonthId: id
        });
    }
}
