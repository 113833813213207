import styled from "styled-components";

import { Placeholder } from "@style/placeholder";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Box } from "@style/box";
import {
    CheckinDisplay,
    EmptyBlock,
    InvalidBlock,
    ValidBlock
} from "@components/registrations/registrationItem/registrationItemStyle";

export const ChildDetailRegistrationsListStyle = styled(Box)`
    padding: ${Padding.medium};
    flex: 1;
    margin-bottom: ${Padding.large};

    ${Placeholder}{
        margin-top: 20px;
    }

    .topbar {
        display: flex;
        justify-content: space-between;
        padding-bottom: ${Padding.medium};
        margin-bottom: ${Padding.medium};
        border-bottom: 1px solid ${Colors.lightGrey};

        h1 {
            font-size: 16px;
            font-weight: 600;
        }
    }

    .legend {
        .icon {
            font-size: 10px;
            margin-right: 5px;
        }
        span {
            display: inline-flex;
            align-items: center;
        }
        .in {
            color: ${Colors.primary()};
            margin-right: 15px;
        }
        .out {
            color: ${Colors.secondary()};
        }
    }
`;

export const ListItem = styled.div`
    display: flex;
    height: 45px;
    padding: 25px 0;
    box-sizing: content-box;
    align-items: center;

    &:not(:last-of-type) {
        border-bottom: 1px solid ${Colors.lightGrey};
    }

    &:last-of-type {
        padding-bottom: 0;
    }

    .empty {
        color: ${Colors.grey};
    }

    .date {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: ${Colors.primary()};
        width: 60px;

        span:first-of-type {
            font-size: 24px;
            font-weight: 600;
        }
        span:last-of-type {
            font-size: 16px;
            font-weight: 200;
            padding-top: 5px;
            text-transform: capitalize;
        }
    }

    ${CheckinDisplay} {
        font-size: 16px;
    }

    ${ValidBlock}, ${InvalidBlock}, ${EmptyBlock} {
        width: 85px;
        height: 45px;
        justify-self: flex-end;
        margin-left: auto;
    }
`;

export const Week = styled.div`
    display: flex;

    &:not(:last-of-type) {
        margin-bottom: ${Padding.medium};
    }

    &.header {
        margin: ${Padding.medium} 0;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    & > div {
        &:not(:last-of-type) {
            margin-right: 10px;
        }
    }
`;

export const Day = styled.div`
    width: 100%;

    & > span {
        color: ${Colors.grey};
        padding-bottom: 8px;
        display: block;
        font-weight: 400;
        text-align: center;
    }

    &.disabled {
        opacity: .4;
    }
`;
