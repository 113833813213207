import * as React from "react";
import { connect } from "react-redux";
import { RouterProps } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { AddChildPage } from "@pages/addChildPage/addChildPage";
import { AddChildToDaycareMutationResult, createAddChildToDaycareMutation } from "@graphql/hocs/addChildToDaycare";
import { AddChildMutationResult, createAddChildMutation } from "@graphql/hocs/addChild";

export type AddChildPageContainerProps = RouterProps;

export const mapStateToProps = (state: StoreState, ownProps: AddChildPageContainerProps) => ({
	organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withAddChildMutation = createAddChildMutation({
	awaitRefetchQueries: true, opOptions: {
		options: {
			errorPolicy: "all"
		}
	}
});

const withAddChildToDaycareMutation = createAddChildToDaycareMutation();

export const AddChildPageContainer = compose<React.ComponentClass<AddChildPageContainerProps>>(
	withRedux,
	withAddChildMutation,
	withAddChildToDaycareMutation
)(AddChildPage);

export type AddChildPageProps =
	AddChildPageContainerProps &
	WithReduxDispatchProps &
	WithReduxStateProps &
	AddChildMutationResult &
	AddChildToDaycareMutationResult;
