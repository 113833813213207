import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Placeholder } from "@style/placeholder";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { TableHeaderContainer } from "@components/registrations/tableHeader/tableHeaderContainer";
import { RegistrationTableStyle } from "@components/registrations/registrationTable/registrationTableStyle";
import { RegistrationTableProps } from "@components/registrations/registrationTable/registrationTableContainer";
import { EmptyBlock } from "@components/registrations/registrationList/registrationListStyle";
import { RegistrationItemContainer } from "@components/registrations/registrationItem/registrationItemContainer";
import { ErrorBlock } from "@components/errorBlock/errorBlock";

export interface RegistrationTableState {
    refetched: boolean;
}

@autobind
export class RegistrationTable extends React.Component<RegistrationTableProps, RegistrationTableState> {
    public state: RegistrationTableState = {
        refetched: false
    };

    public static getDerivedStateFromProps(props: RegistrationTableProps, state: RegistrationTableState): Partial<RegistrationTableState> | null {
        if (!state.refetched && props.completed) {
            props.refetch();

            return {
                refetched: true
            };
        }

        if (state.refetched && !props.completed) {
            return {
                refetched: false
            };
        }

        return null;
    }

    public render() {

        const { children, childrenLoading, childrenFilter, refetch } = this.props;

        if (childrenLoading) {
            return (
                <RegistrationTableStyle>
                    <TableHeaderContainer />
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </RegistrationTableStyle>
            );
        }

        if (!children) {
            return (
                <RegistrationTableStyle >
                    <TableHeaderContainer />
                    <ErrorBlock />
                </RegistrationTableStyle>
            );
        }

        return (
            <RegistrationTableStyle >
                <TableHeaderContainer />
                {children && !children.length ?

                    <EmptyBlock>
                        <img src={require("@assets/images/kids-circle.svg")} alt="circle-kids" />
                        {!childrenFilter.search ?
                            <>
                                <FormattedMessage id="registrationTable.noChildren" />
                                <Link to="/add-child" >
                                    <Button size="large" type="primary"><Icon type="add-user" /><FormattedMessage id="subHeader.addChild" /></Button>
                                </Link>
                            </>
                            :
                            <FormattedMessage id="registrationTable.noChildrenSearch" />
                        }
                    </EmptyBlock>
                    :
                    children && children.map(child => <RegistrationItemContainer key={child.id} child={child} />)
                }
            </RegistrationTableStyle>
        );
    }
}
