import { Checkbox, Form, Input, Select, Spin } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { isValidDateString, parseDate } from "@utils/parseDate";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Gender } from "@models/graphql/types";
import { EditContactsModalContainer } from "@components/editContactsModal/editContactsModalContainer";
import { ChildDetailsFormStyle } from "@components/childDetail/childDetailsForm/childDetailsFormStyle";
import { ChildDetailsFormProps } from "@components/childDetail/childDetailsForm/childDetailsFormContainer";
import { Alert } from "@components/alert/alert";

export interface ChildDetailsFormState {
	submitting: boolean;
	successMessage?: string;
	errorMessage?: string;
	showEditContacts: boolean;
	childBenefitError?: boolean;
	mainUpdatedFields: string[];
	tooManyFieldsUpdatedError?: boolean;
}

let cbfId = 1;
let touchedChildBenefits: boolean = false;

interface ChildBenefitField {
	start: string;
	end: string;
}

interface FormValues {
	inSchoolSince?: string | null;
	childBenefitEntitlement?: boolean;
	isActive?: boolean;
	firstName: string;
	lastName: string;
	gender: Gender;
	birthdate: string;
	notCbEntitled: boolean;
	childBenefitFields: ChildBenefitField[];
}

@autobind
export class ChildDetailsForm extends React.Component<ChildDetailsFormProps, ChildDetailsFormState> {
	public state: ChildDetailsFormState = {
		submitting: false,
		showEditContacts: false,
		mainUpdatedFields: []
	};

	// tslint:disable-next-line:max-func-body-length
	public render() {
		const { form, intl, child, childLoading } = this.props;
		const { submitting, successMessage, errorMessage, showEditContacts, tooManyFieldsUpdatedError } = this.state;

		const currentFormValues = form.getFieldsValue();

		return (
			<ChildDetailsFormStyle>
				{child && <EditContactsModalContainer contacts={child.contacts} childId={child.id} visible={showEditContacts} hideCallback={this.hideEditContactsModal} />}
				<Spin spinning={childLoading}>
					<Form onSubmit={this.onSubmit}>
						<Form.Item label={intl.formatMessage({ id: "childDetailsForm.firstName" })}>
							{form.getFieldDecorator(
								"firstName",
								{
									initialValue: child && child.firstName,
									rules: [
										{ required: true, message: intl.formatMessage({ id: "childDetailsForm.required.firstName" }) },
										{ max: 25, message: intl.formatMessage({ id: "childDetailsForm.max.firstName" }, { max: 25 }) },
										{ validator: this.validateChangedMainFields }
									]
								}
							)(
								<Input placeholder={intl.formatMessage({ id: "childDetailsForm.firstName" })} />
							)}
						</Form.Item>
						<Form.Item label={intl.formatMessage({ id: "childDetailsForm.lastName" })}>
							{form.getFieldDecorator(
								"lastName",
								{
									initialValue: child && child.lastName,
									rules: [
										{ required: true, message: intl.formatMessage({ id: "childDetailsForm.required.lastName" }) },
										{ max: 50, message: intl.formatMessage({ id: "childDetailsForm.max.lastName" }, { max: 50 }) },
										{ validator: this.validateChangedMainFields }
									]
								}
							)(
								<Input placeholder={intl.formatMessage({ id: "childDetailsForm.lastName" })} />
							)}
						</Form.Item>
						<Form.Item label={intl.formatMessage({ id: "childDetailsForm.gender" })}>
							{form.getFieldDecorator(
								"gender",
								{
									initialValue: child && child.gender,
									rules: [
										{ required: true, message: intl.formatMessage({ id: "childDetailsForm.required.gender" }) },
										{ validator: this.validateChangedMainFields }
									]
								}
							)(
								<Select placeholder={intl.formatMessage({ id: "childDetailsForm.gender" })}>
									<Select.Option value={Gender.MALE}><FormattedMessage id="gender.male" /></Select.Option>
									<Select.Option value={Gender.FEMALE}><FormattedMessage id="gender.female" /></Select.Option>
								</Select>
							)}
						</Form.Item>
						<Form.Item required label={intl.formatMessage({ id: "childDetailsForm.birthdate" })}>
							{form.getFieldDecorator("birthdate", {
								initialValue: child && child.birthdate ? moment(child.birthdate).format("DD/MM/YYYY") : null,
								rules: [
									{ validator: this.validateBirthdate, message: "" },
									{ validator: this.validateChangedMainFields }
								]
							})(
								<Input placeholder={intl.formatMessage({ id: "addChildForm.datePlaceholder" })} />
							)}
						</Form.Item>
						<Form.Item label={intl.formatMessage({ id: "childDetailsForm.inSchoolSince" }, { firstName: child ? child.firstName : "" })}>
							{form.getFieldDecorator(
								"inSchoolSince",
								{
									initialValue: child && child.inSchoolSince ? moment(child.inSchoolSince).format("DD/MM/YYYY") : null,
									rules: [
										{ validator: this.validateInSchoolSince }
									]
								}
							)(
								<Input placeholder={intl.formatMessage({ id: "childDetailsForm.inSchoolSince" }, { firstName: child ? child.firstName : "" })} />
							)}
						</Form.Item>
						<Form.Item>
							{form.getFieldDecorator(
								"notCbEntitled",
								{
									initialValue: child && child.childBenefitPeriods.length > 0,
									valuePropName: "checked"
								}
							)(
								<Checkbox><FormattedMessage id="childrenOverviewList.noBenefits" /></Checkbox>
							)}
						</Form.Item>
						{form.getFieldValue("notCbEntitled") ? <Form.Item className="ko-fields" label={intl.formatMessage({ id: "childDetailsForm.childBenefitEntitlementTitle" }, { firstName: child ? child.firstName : "" })}>
						</Form.Item> : null}
						{!form.getFieldValue("notCbEntitled") ? null : this.renderChildBenefitFields()}
						{!form.getFieldValue("notCbEntitled") ? null : <Button onClick={this.addChildBenefitField} type="dashed">+ Velden toevoegen</Button>}
						<Form.Item >
							{form.getFieldDecorator(
								"isActive",
								{
									initialValue: child && child.isActive,
									valuePropName: "checked"
								}
							)(
								<Checkbox><FormattedMessage id="childDetailsForm.isActive" /></Checkbox>
							)}
						</Form.Item>
						{successMessage && <Alert type="success">
							{successMessage}
						</Alert>}
						{errorMessage && <Alert type="error">
							<p>{errorMessage}</p>
							{tooManyFieldsUpdatedError ? <Link to={{
								pathname: "/add-child",
								state: {
									firstName: currentFormValues["firstName"],
									lastName: currentFormValues["lastName"],
									gender: currentFormValues["gender"],
									birthdate: currentFormValues["birthdate"]
								}
							}}>
								<FormattedMessage id="childDetailsForm.addNewChild" />
							</Link> : null}
						</Alert>}
						<Button onClick={this.showEditContactsModal} size="large"><FormattedMessage id="childDetailsForm.editContacts" /></Button>
						<Button loading={submitting} onClick={this.onSubmit} size="large" disabled={this.formDisabled()} type="primary"><FormattedMessage id="childDetailsForm.saveChanges" /></Button>
					</Form>
				</Spin>
			</ChildDetailsFormStyle >
		);
	}

	private formDisabled() {
		const { form, child } = this.props;

		if (!form.isFieldsTouched() && child && child.childBenefitPeriods) {
			return !touchedChildBenefits;
		}

		return !form.isFieldsTouched();
	}

	private validateChangedMainFields(rule: { field: string }, value: string | undefined, callback: (error?: string) => void) {
		const { child } = this.props;
		const { mainUpdatedFields } = this.state;

		let oldValue = child && child[rule.field];

		/* Edge case for dates as they get formatted? */
		if (moment(oldValue).isValid()) {
			oldValue = child && moment(child[rule.field]).format("DD/MM/YYYY");
		}

		const matches = oldValue === value;

		if (matches) {
			if (mainUpdatedFields.includes(rule.field)) {
				this.setState((oldState) => ({ mainUpdatedFields: oldState.mainUpdatedFields.filter((v) => v !== rule.field) }));
			}
		} else {
			if (!mainUpdatedFields.includes(rule.field)) {
				this.setState((oldState) => ({ mainUpdatedFields: [...oldState.mainUpdatedFields, rule.field] }));
			}
		}

		callback();
	}

	private validateBirthdate(rule: any, value: string | undefined, callback: (error?: string) => void) {
		const { intl } = this.props;

		if (!value) {
			return callback(intl.formatMessage({ id: "addChildForm.birthdateRequired" }));
		}

		if (!isValidDateString(value)) {
			return callback(intl.formatMessage({ id: "addChildForm.invalidDate" }));
		}

		const date = moment(parseDate(value), "YYYY/MM/DD");

		if (!date.isValid()) {
			return callback(intl.formatMessage({ id: "addChildForm.dateDoesNotExist" }));
		}

		if (date.isBefore(moment().subtract(15, "y"))) {
			return callback(intl.formatMessage({ id: "addChildForm.childTooOld" }));
		}

		if (date.isSameOrAfter(moment())) {
			return callback(intl.formatMessage({ id: "addChildForm.dateAfterToday" }));
		}

		return callback();
	}

	private validateInSchoolSince(rule: any, value: string | undefined, callback: (error?: string) => void) {
		const { intl, form } = this.props;

		const birthdateString = form.getFieldValue("birthdate");

		if (!value) {
			return callback();
		}

		if (!isValidDateString(value)) {
			return callback(intl.formatMessage({ id: "addChildForm.invalidDate" }));
		}

		if (birthdateString && isValidDateString(birthdateString)) {

			const birthdate = moment(parseDate(birthdateString), "YYYY/MM/DD");
			const date = moment(parseDate(value), "YYYY/MM/DD");

			if (date.isSameOrBefore(birthdate)) {
				return callback(intl.formatMessage({ id: "addChildForm.beforeBirthdate" }));
			}
		}

		return callback();
	}

	private validateChildBenefitDate(field: number, isStart: boolean, rule: any, value: string | undefined, callback: (error?: string) => void) {
		const { intl, form } = this.props;

		const error = this.validateChildBenefitFields(field, isStart);
		if (error) {
			return callback(error);
		}

		if (value && !isValidDateString(value)) {
			return callback(intl.formatMessage({ id: "addChildForm.invalidDate" }));
		}

		return callback();
	}

	private addChildBenefitField() {
		const { form } = this.props;

		const cbf = form.getFieldValue("childBenefitFieldKeys");
		const nextCbf = cbf.concat(cbfId++);

		form.setFieldsValue({
			childBenefitFieldKeys: nextCbf
		});
	}

	private renderChildBenefitFields() {
		const { form, child } = this.props;

		let initialValue;

		if (child && child.childBenefitPeriods.length > 0) {
			initialValue = child.childBenefitPeriods.map((item, index) => {
				return index;
			});

			cbfId === 1 ? cbfId += child.childBenefitPeriods.length : null;
		} else {
			cbfId = 1;
			initialValue = [0];
		}

		form.getFieldDecorator("childBenefitFieldKeys", { initialValue });
		const cbfs = form.getFieldValue("childBenefitFieldKeys");

		return cbfs.map((field, index) => {

			return (
				<>
					<div className="child-benefit-field-wrapper" key={index}>
						<Form.Item className="ko-fields">
							{form.getFieldDecorator(
								`childBenefitFields[${field}].start`,
								{
									initialValue: child && child.childBenefitPeriods[field] ? this.renderFormattedDate(child.childBenefitPeriods[field].start) : "",
									rules: [{
										validator: (rule, value, callback) => this.validateChildBenefitDate(field, true, rule, value, callback)
									}]
								}
							)(<Input placeholder="01/01/2015" />)}
						</Form.Item>
						<span className="until">tot</span>
						<Form.Item>
							{form.getFieldDecorator(
								`childBenefitFields[${field}].end`,
								{
									initialValue: child && child.childBenefitPeriods[field] ? this.renderFormattedDate(child.childBenefitPeriods[field].end) : "",
									rules: [{
										validator: (rule, value, callback) => this.validateChildBenefitDate(field, false, rule, value, callback)
									}]
								}
							)(
								<Input placeholder="01/01/2015" />
							)}
						</Form.Item>
						{field !== 0 ? <Icon onClick={() => this.deleteChildBenefitField(field)} type="trash" /> : <Icon type="info" className="info-check" />}
					</div>
				</>
			);
		});
	}

	private renderFormattedDate(cbf) {
		if (cbf) {
			return moment(cbf, "YYYY-MM-DD").format("DD/MM/YYYY");
		}

		return "";
	}

	private validateChildBenefitFields(index: number, isStart: boolean) {
		const { form } = this.props;

		const keys: number[] = form.getFieldValue("childBenefitFieldKeys");
		const currentFieldKeyIndex = keys.findIndex(key => key === index);
		const childBenefitFields = form.getFieldValue("childBenefitFields");

		if (currentFieldKeyIndex < 0) {
			return;
		}

		if (childBenefitFields[keys[currentFieldKeyIndex]] && childBenefitFields[keys[currentFieldKeyIndex]].end === undefined) {
			const length = childBenefitFields.length - 1;
			for (let i = index; i < length; i++) {
				this.deleteChildBenefitField(i + 1);
			}
		}

		if (keys[currentFieldKeyIndex] === 0 && isStart) {
			const start = moment(childBenefitFields[keys[currentFieldKeyIndex]].start, "DD/MM/YYYY");

			if (start.isBefore(moment(form.getFieldValue("birthdate"), "DD/MM/YYYY"))) {
				return "Dit veld kan niet groter zijn dan de geboortedatum.";
			}
		}

		if (childBenefitFields[keys[currentFieldKeyIndex]]) {

			// check if fields are correct dates
			const start = moment(childBenefitFields[keys[currentFieldKeyIndex]].start, "DD/MM/YYYY");
			const end = moment(childBenefitFields[keys[currentFieldKeyIndex]].end, "DD/MM/YYYY");

			if (isStart) {
				if (!start.isValid()) {
					return "Dit veld is verplicht.";
				}

				if (currentFieldKeyIndex > 0) {
					const previousEnd = moment(childBenefitFields[keys[currentFieldKeyIndex - 1]].end, "DD/MM/YYYY");

					if (!previousEnd || !previousEnd.isValid()) {
						return "Het vorige veld moet een einddatum hebben";
					}
				}
			}

			// end is valid: do first check
			if (end.isValid()) {
				if (start >= end) {
					return "De startdatum kan niet groter zijn dan de einddatum";
				}
			}

			if (currentFieldKeyIndex > 0) {

				const previousBenefitFieldKey = keys[currentFieldKeyIndex - 1];
				const previousBenefitField = form.getFieldValue(`childBenefitFields[${previousBenefitFieldKey}]`);

				if (moment(previousBenefitField.end, "DD/MM/YYYY") > start) {
					return "De datums mogen niet overlappen.";
				}

				const nextBenefitFieldKey = keys[currentFieldKeyIndex + 1];
				const nextBenefitField = form.getFieldValue(`childBenefitFields[${nextBenefitFieldKey}]`);

				if (nextBenefitField) {
					if (end > moment(nextBenefitField.start, "DD/MM/YYYY")) {
						return "De datums mogen niet overlappen.";
					}
				}
			}

		}
	}

	private deleteChildBenefitField(cbf) {
		const { form } = this.props;
		const cbfs = form.getFieldValue("childBenefitFieldKeys");

		touchedChildBenefits = true;

		if (cbfs.length === 1) {
			return;
		}

		form.setFieldsValue({
			childBenefitFieldKeys: cbfs.filter(field => field !== cbf)
		});
	}

	private onSubmit() {
		const { form, updateChild, childId, child, intl } = this.props;
		const { mainUpdatedFields, tooManyFieldsUpdatedError } = this.state;

		if (!child) {
			throw new Error("No child loaded");
		}

		const tooManyFieldsChanged = mainUpdatedFields.length > 2;

		if (tooManyFieldsChanged) {
			this.setState({
				errorMessage: intl.formatMessage({ id: "childDetailsForm.tooManyFieldsUpdated" }),
				tooManyFieldsUpdatedError: true
			});
		} else {
			if (tooManyFieldsUpdatedError) {
				this.setState({
					tooManyFieldsUpdatedError: false
				});
			}
		}

		form.validateFieldsAndScroll({ force: true }, async (errors, values: FormValues) => {
			if (!errors && !tooManyFieldsChanged) {

				try {
					const { firstName, gender, lastName, isActive, inSchoolSince, birthdate, childBenefitFields, notCbEntitled } = values;

					let parsedChildBenefits;
					if (notCbEntitled) {
						const filteredChildBenefits = childBenefitFields.filter(Boolean);

						parsedChildBenefits = filteredChildBenefits.map(item => {
							return {
								start: moment(parseDate(item.start), "YYYY-MM-DD").format("YYYY-MM-DD"),
								end: item.end !== "" ? moment(parseDate(item.end), "YYYY-MM-DD").format("YYYY-MM-DD") : null
							};
						});
					} else {
						parsedChildBenefits = [];
					}

					const result = await updateChild({
						id: childId,
						input: {
							firstName,
							lastName,
							childBenefitPeriods: parsedChildBenefits,
							isActive: isActive,
							gender,
							inSchoolSince: inSchoolSince ? moment(parseDate(inSchoolSince), "YYYY-MM-DD").format("YYYY-MM-DD") : null,
							birthdate: moment(parseDate(birthdate), "YYYY-MM-DD").format("YYYY-MM-DD")

						}
					});

					if (!result) {
						this.setState({
							submitting: false,
							errorMessage: intl.formatMessage({ id: "childDetailsForm.errorMessage" })
						});

					} else {
						this.setState({
							errorMessage: undefined,
							submitting: false,
							successMessage: intl.formatMessage({ id: "childDetailsForm.successMessage" })
						});
						form.resetFields();
						// Remove message after 3s;
						setTimeout(() => this.setState({ successMessage: undefined }), 3000);
					}
				} catch (err) {
					console.error("catch", err);
					this.setState({
						submitting: false,
						errorMessage: intl.formatMessage({ id: "childDetailsForm.errorMessage" })
					});
				}
			}
		});
	}

	private showEditContactsModal() {
		this.setState({ showEditContacts: true });
	}

	private hideEditContactsModal() {
		this.setState({ showEditContacts: false });
	}
}
