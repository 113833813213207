import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Status } from "@models/graphql/types";

export const UploadDetailStyle = styled.div`
    h1 {
        font-size: 24px;
        font-weight: 200;
        color: ${Colors.grey};
        margin-bottom: ${Padding.medium};
    }

    padding-bottom: 50px;


    &.${Status.PROCESSED} {
        h1, h2 {
            color: ${Colors.primary()};
        }

    }

    &.${Status.PENDING_FEEDBACK} {
        h1, h2 {
            color: ${Colors.error()};
        }
    }
    &.hasRemarks {
        h1, h2 {
            color: #cf9942;
        }
    }


    h2 {
        font-size: 18px;
        margin: ${Padding.large} 0 ${Padding.medium};
        font-weight: 200;
    }

    p {
        line-height: 140%;
    }

    ul {
        margin: ${Padding.medium} 0;
        li {
            &:not(:last-of-type) {
                margin-bottom: ${Padding.small};
            }

            span:first-of-type {
                width: 125px;
                display: inline-block;
            }

            span:last-of-type {
                color: ${Colors.grey};
                cursor: pointer;

                &:hover {
                    color: ${Colors.primary()};
                }
            }
        }
    }
`;
