import { CreateInactiveMonthMutation, CreateInactiveMonthVariables, CreateInactiveMonthCreateInactiveMonth } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";
import { addRefetchQueries } from "@utils/addRefetchQueries";

export interface CreateInactiveMonthMutationResult {
    createInactiveMonth(variables: CreateInactiveMonthVariables): Promise<CreateInactiveMonthCreateInactiveMonth>;
}

export const createCreateInactiveMonthMutation = <TProps = {}>(
    args: {
        opOptions?: OperationOption<TProps, CreateInactiveMonthMutation, CreateInactiveMonthVariables, CreateInactiveMonthMutationResult>;
        awaitRefetchQueries?: boolean;
    } = {}
) => {
    const { opOptions, awaitRefetchQueries } = args;
    const options = addRefetchQueries(opOptions, ["getInactiveMonths"], awaitRefetchQueries);

    return graphql<TProps, CreateInactiveMonthMutation, CreateInactiveMonthVariables, CreateInactiveMonthMutationResult>(GraphQL.createInactiveMonth, {
        props({ mutate }): CreateInactiveMonthMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async createInactiveMonth(variables: CreateInactiveMonthVariables): Promise<CreateInactiveMonthCreateInactiveMonth> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        return result.data.createInactiveMonth;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
};
