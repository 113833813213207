import { Input } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ReactToPrint from "react-to-print";

import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { ChildrenOverviewListContainer } from "@components/childrenOverviewList/childrenOverviewListContainer";
import { ChildrenOverviewStyle } from "@components/childrenOverview/childrenOverviewStyle";
import { ChildrenOverviewProps } from "@components/childrenOverview/childrenOverviewContainer";

export interface ChildrenOverviewState {
    searchValue: string;
}

@autobind
export class ChildrenOverview extends React.Component<ChildrenOverviewProps, ChildrenOverviewState> {
    public state: ChildrenOverviewState = {
        searchValue: ""
    };

    private printEl: null | HTMLDivElement;

    public render() {

        const { intl } = this.props;
        const { searchValue } = this.state;

        return (
            <ChildrenOverviewStyle>
                <InnerContainer>
                    <Box>
                        <div className="bar">
                            <FormattedMessage id="childrenOverview.title" tagName="h1" />
                            <ReactToPrint
                                trigger={() => <Button size="large" type="primary"><FormattedMessage id="childrenOverview.printList" /></Button>}
                                content={() => this.printEl}
                            />

                        </div>
                        <Input value={searchValue} onChange={this.onSearchChange} placeholder={intl.formatMessage({ id: "childrenOverview.search" })} suffix={<Icon type="search" />} />

                        <ChildrenOverviewListContainer refCallback={(el) => this.printEl = el} filter={{ search: searchValue }} />
                    </Box>
                </InnerContainer>
            </ChildrenOverviewStyle>
        );
    }

    private onSearchChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ searchValue: ev.target.value });
    }
}
