import styled from "styled-components";
import { Colors } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";
import { Box } from "@style/box";
import { Padding } from "@style/padding";
import { Button } from "@style/button";
import { AlertStyle } from "@components/alert/alertStyle";

export const SettingsPageStyle = styled.div`
position: relative;

    .greenBlock {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${Colors.primary()};
        height: 100px;
        z-index: 0;
    }

    & > ${InnerContainer} {
        z-index: 1;
        position: relative;

        & > a {
            color: white;
            font-size: 16px;
            font-weight: 200;
            display: flex;
            align-items:center;
            margin-top: ${Padding.small};
            margin-bottom: ${Padding.large};
            .icon {
                margin-right: 10px;
                font-size: 10px;
            }
        }
    }

    ${Box} {
        position: relative;
        margin-bottom: ${Padding.large};
        padding: ${Padding.medium};

        h1 {
            font-size: 24px;
            font-weight: 200;
            margin-bottom: ${Padding.medium};

            &:not(:first-child) {
                margin-top: ${Padding.medium};
            }
        }

        em {
            color: ${Colors.primary()};
            padding-top: 10px;
            display: block;
        }

        .ant-form-item-label {
            line-height: inherit;
            padding-bottom: 8px;
            label {
                color: ${Colors.primary()};
            }
        }

        .ant-form-item:not(:first-of-type) {
            margin-top: 10px;
        }

        .ant-calendar-picker {
            width: 100%;
        }

        .ant-form-item-with-help {
            margin-bottom: 0;
        }
        .ant-form-item {
            margin-bottom: 0px;
        }

        ${Button} {
            width: 100%;
            margin-top: ${Padding.small};
            height: 50px;
        }

        ${AlertStyle} {
            margin-top: 14px;
        }
    }
`;
