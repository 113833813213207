import styled from "styled-components";
import { Colors } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";

export const UnauthorizedPageStyle = styled(InnerContainer)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 100px;
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 20px;
        font-size: 18px;
        color: ${Colors.error()};
        font-weight: 600;
        max-width: 400px;
        text-align: center;
        line-height: 24px;
    }
`;
