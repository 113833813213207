import { Popover } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";

import { Icon } from "@style/icon";
import { HiddenButtonsButtonStyle } from "./hiddenButtonsButtonStyle";
import { HiddenButtonsButtonProps } from "./hiddenButtonsButtonContainer";

export interface HiddenButtonsButtonState {

}

@autobind
export class HiddenButtonsButton extends React.Component<HiddenButtonsButtonProps, HiddenButtonsButtonState> {

    public render() {
        const { children } = this.props;

        return (
            <Popover content={children} trigger="click" overlayClassName="hiddenButtonsPopover" placement="bottomRight">
                <HiddenButtonsButtonStyle>
                    <Icon type="three-dots" />
                </HiddenButtonsButtonStyle>
            </Popover>

        );
    }
}
