import styled from "styled-components";
import { Colors } from "@style/colors";
import { Box } from "@style/box";
import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";

export const UpdateRegistrationPageStyle = styled.div`
    background: ${Colors.error()};
    width: 100%;
    height: 100%;
    padding: ${Padding.medium} 0;
    overflow-y: auto;

    ${InnerContainer} {
        text-align: center;
        h1 {
            font-size: 45px;
            font-weight: 200;
            margin: ${Padding.large} 0;
            color: ${Colors.white};
        }

        .invalid {
            opacity: .8;
        }
    }



    .ant-btn-primary {
        border-color: ${Colors.error()};
        background-color: ${Colors.error()};
        color: ${Colors.white};

        &:hover {
            background-color: ${Colors.white};
            color: ${Colors.error()};
            border-color: ${Colors.error()};
        }

        &:focus:not(:hover) {
            background-color: transparent;
            border-color: ${Colors.error()};
            color: ${Colors.error()};
        }
    }


    ${Box} {
        text-align: left;
        padding: ${Padding.medium};
    }
`;
