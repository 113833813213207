import { Popover } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import QrReader from "react-qr-reader";
import { Link } from "react-router-dom";

import { validateCode } from "@utils/validateCode";
import { isIosPwa } from "@utils/isIosPwa";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { ErrorInfo, QrCodeReaderStyle } from "@components/qrCodeReader/qrCodeReaderStyle";
import { QrCodeReaderProps } from "@components/qrCodeReader/qrCodeReaderContainer";
import { CodeNumberInputContainer } from "@components/codeNumberInput/codeNumberInputContainer";

export interface QrCodeReaderState {
    width: number;
    height: number;
    isNumberInput: boolean;
    cameraError: string | null;
    facingMode: FacingMode;
    isMultiCamera: boolean;
}

type FacingMode = "user" | "environment";

@autobind
export class QrCodeReader extends React.Component<QrCodeReaderProps, QrCodeReaderState> {

    public state: QrCodeReaderState = {
        width: window.innerWidth,
        height: window.innerHeight,
        isNumberInput: false,
        cameraError: null,
        facingMode: this.facingMode,
        isMultiCamera: false
    };

    public async componentDidMount() {
        const devices = await navigator.mediaDevices.enumerateDevices();

        if (devices.filter(device => device.kind === "videoinput").length > 1) {
            this.setState({ isMultiCamera: true });
        }
    }

    public render() {
        const { width, height, isNumberInput, facingMode, isMultiCamera, cameraError } = this.state;
        const { entryType, hideRegisterButton } = this.props;

        let errorContent: string | JSX.Element = "";

        if (isNumberInput) {
            if (cameraError) {
                if (isIosPwa()) {
                    errorContent = <ErrorInfo>
                        De webcam is niet beschikbaar in fullscreen modus (of een andere browser dan safari) op iPad &amp; iPhone wegens limitaties van Apple zelf. Om toch de webcam te gebruiken gelieve AARON rechtstreeks in safari te openen.
                    </ErrorInfo>;
                } else {
                    errorContent = <ErrorInfo>
                        {cameraError}
                        <br />
                        <em>
                            Controleer je instelling en vernieuw de pagina.
                    </em>
                    </ErrorInfo>;
                }
            }

            return (
                <QrCodeReaderStyle type={entryType} width={width} height={height}>
                    <CodeNumberInputContainer title="qrCodeReader.title" subTitle="qrCodeReader.subTitle" submitCallback={this.onSubmit} codeLength={6} />

                    <div className="content">
                        <Link className="back" to="/registration"> <Icon type="back" /> <FormattedMessage id="qrCodeReader.back" /></Link>

                        {!hideRegisterButton && <Link className="topRight" to="/registration/manual-child-entry">Kind registreren als verantwoordelijke</Link>}

                        {cameraError ? <Popover trigger="click" placement="bottomRight" content={errorContent}>
                            <span onClick={this.closeNumberInput} className="topRight"><Icon type="camera" /><FormattedMessage id="qrCodeReader.openCamera" /></span>
                        </Popover> :
                            <span onClick={this.closeNumberInput} className="topRight"><Icon type="camera" /><FormattedMessage id="qrCodeReader.openCamera" /></span>
                        }
                    </div>
                </QrCodeReaderStyle>
            );
        }

        return (
            <QrCodeReaderStyle type={entryType} width={width} height={height}>
                <QrReader facingMode={facingMode} resolution={600} delay={100} onScan={this.onSan} onError={this.onError} showViewFinder={false} />
                <div className="overlay">
                    <div className="top" />
                    <div className="bottom" />
                    <div className="left" />
                    <div className="right" />
                    <div className="viewfinder" />

                    <Link className="back" to="/registration"> <Icon type="back" /> <FormattedMessage id="qrCodeReader.back" /></Link>

                    <div className="content">
                        <div className="before">
                            <span className="info">Hou je QR-code voor de camera</span>
                        </div>

                        <div className="spacer" />

                        {isMultiCamera && <span onClick={this.changeCamera} className="topRight"><Icon style={{ fontSize: 30 }} type="rotate-camera" /><FormattedMessage id="qrCodeReader.rotateCamera" /></span>}

                        <div className="after">
                            <Button onClick={this.openNumberInput} type="primary"> <Icon type="keypad" /><span>Ik wil mijn code manueel ingeven</span></Button>
                            {!hideRegisterButton && <Link to="/registration/manual-child-entry"><Button >Kind registreren als verantwoordelijke</Button></Link>}
                        </div>
                    </div>
                </div>
            </QrCodeReaderStyle>

        );
    }

    public componentDidCatch(e) {
        this.onError(e);
    }

    public componentWillMount() {
        window.addEventListener("resize", this.onResize);
    }
    public componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    private changeCamera() {
        if (this.state.facingMode === "environment") {
            this.facingMode = "user";
        } else {
            this.facingMode = "environment";
        }
    }

    private closeNumberInput() {
        if (!this.state.cameraError) {
            this.setState({
                isNumberInput: false
            });
        }
    }

    private openNumberInput() {
        this.setState({
            isNumberInput: true
        });
    }

    private onResize(ev: Event) {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    private get facingMode() {
        const facingMode: FacingMode = localStorage.getItem("facingMode") as FacingMode;

        if (facingMode) {
            return facingMode;
        }

        if (this.state) {
            return this.state.facingMode;
        }

        return "user";
    }

    private set facingMode(facingMode: FacingMode) {
        this.setState({ facingMode });
        localStorage.setItem("facingMode", facingMode);
    }

    private onSubmit(code: string): { valid: boolean; errorMessage?: string } {
        if (validateCode(code)) {
            this.props.scannedCallback(code);

            return {
                valid: true
            };
        }

        return {
            valid: false
        };
    }

    private onSan(data: string | null) {
        if (data && validateCode(data)) {
            this.props.scannedCallback(data);
        }
    }

    private onError(err) {
        console.error(err);

        let cameraError = "Er kon geen webcam worden gedetecteerd.";

        if (err.name === "NotReadableError") {
            //webcam or mic are already in use
            cameraError = "De webcam is al in gebruik door een andere toepassing.";
        } else if (err.name === "NotAllowedError") {
            //permission denied in browser
            cameraError = "De toepassing heeft geen toegang tot de webcam.";
        }

        this.setState({
            cameraError,
            isNumberInput: true
        });
    }
}
