import styled from "styled-components";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";

export const DaycareListItemStyle = styled.div`
    height: 65px;
    display: flex;
    align-items: center;
    cursor: pointer;



    .errorBlock {
        width: 5px;
        background-color: ${Colors.error()};
        height: 100%;
    }

    .info {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-left:${Padding.medium};

        span:first-of-type {
            font-weight: 600;
            margin-bottom: 8px;
        }

    }

    .status {
        padding-right:${Padding.medium};
        font-size: 18px;
        color: ${Colors.primary()};
        i:not(:last-of-type) {
            margin-right: 20px;
        }
        .fail {
            color: ${Colors.error()};
        }
    }


    &.invalid {
        background-color: ${Colors.error(0.07)};
        &:hover {
            background-color: ${Colors.error(0.15)};
        }
    }

    &.isSuspended, &.isInactive {
        color: ${Colors.grey};
        .status {
            color: ${Colors.grey};
            font-size: 14px;
        }
    }

    &:hover {
        background-color: ${Colors.lightGrey};
    }
`;
