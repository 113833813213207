import { autobind } from "core-decorators";
import * as React from "react";

import { DaycareChildrenListStyle } from "@components/daycareChildrenList/daycareChildrenListStyle";
import { DaycareChildrenListProps } from "@components/daycareChildrenList/daycareChildrenListContainer";
import {
    ChildrenSearchResultListContainer
} from "@components/daycare/childrenSearchResultList/childrenSearchResultListContainer";

export interface DaycareChildrenListState {

}

@autobind
export class DaycareChildrenList extends React.Component<DaycareChildrenListProps, DaycareChildrenListState> {

    public render() {

        const { daycares } = this.props;

        return (
            <DaycareChildrenListStyle>
                <h1>Kinderen</h1>
                {daycares && daycares.map(daycare => {

                    return <div key={daycare.id} className="item">
                        <h2>{daycare.name} - {daycare.caseNumber}</h2>
                        <ChildrenSearchResultListContainer daycareId={daycare.id} />
                    </div>;
                })}
            </DaycareChildrenListStyle>

        );
    }
}
