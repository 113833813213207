import { Button as AntButton } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled, { StyledComponentClass } from "styled-components";
import { Colors } from "@style/colors";

export const Button: StyledComponentClass<ButtonProps, {}> = styled(AntButton as any)`

    &.ant-btn {
        color: ${Colors.primary()};
        .icon {
            display: inline-block;
            padding-right: 10px;
        }
    }

    &.ant-btn-primary {
        color: ${Colors.white};
    }

    &.ant-btn-ghost {
        border-color: ${Colors.white};
        color: ${Colors.white};

        &:hover {
            background-color: ${Colors.white};
            color: ${Colors.primary()};
        }

        &:focus:not(:hover) {
            background-color: transparent;
            border-color: ${Colors.white};
            color: ${Colors.white};
        }
    }

    &.ant-btn-danger {
        border-color: ${Colors.error()};
        background-color: ${Colors.error()};
        color: ${Colors.white};

        &:hover {
            background-color: ${Colors.white};
            border-color: ${Colors.error()};
            color: ${Colors.error()};
        }

        &:focus:not(:hover) {
            background-color: transparent;
            border-color: ${Colors.error()};
            color: ${Colors.error()};
        }
    }


`;
