import styled from "styled-components";
import { Box } from "@style/box";
import { Padding } from "@style/padding";

export const DaycareSearchResultsStyle = styled(Box)`
    padding: ${Padding.medium};

    .results {
        display: flex;

        & > div:not(:last-of-type) {
            margin-right: ${Padding.medium};
        }
    }

    .title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding-bottom: ${Padding.large};

        h1 {
            font-weight: 600;
            font-size: 18px;
        }

        span {
            opacity: 0.7;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    @media (max-width: 650px) {
        .results {
            flex-wrap: wrap;

            & > div:not(:last-of-type) {
                margin-right: 0;
                margin-bottom: ${Padding.medium};
            }
        }
    }
`;
