import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import {
    setRegistrationFlowRegistrationResult,
    SetRegistrationFlowRegistrationResultActionOptions
} from "@redux/actions/setRegistrationFlowRegistrationResult";
import { setRegistrationFlowCode, SetRegistrationFlowCodeActionOptions } from "@redux/actions/setRegistrationFlowCode";
import {
    setRegistrationFlowChildNotFound,
    SetRegistrationFlowChildNotFoundActionOptions
} from "@redux/actions/setRegistrationFlowChildNotFound";
import { Action } from "@redux/actions/action";
import { RegistrationPage } from "@pages/registrationPage/registrationPage";

export interface RegistrationPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationPageContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setRegistrationResult(data: SetRegistrationFlowRegistrationResultActionOptions) { dispatch(setRegistrationFlowRegistrationResult(data)); },
    setCode(data: SetRegistrationFlowCodeActionOptions) { dispatch(setRegistrationFlowCode(data)); },
    setChildNotFound(data: SetRegistrationFlowChildNotFoundActionOptions) { dispatch(setRegistrationFlowChildNotFound(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const RegistrationPageContainer = compose<React.ComponentClass<RegistrationPageContainerProps>>(
    withRouter,
    withRedux
)(RegistrationPage);

export type RegistrationPageProps =
    RouteComponentProps &
    RegistrationPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
