import styled from "styled-components";
import { Colors } from "@style/colors";

export const Box = styled.div`
    background: ${Colors.white};
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.17);
    border-radius: 4px;
    color: ${Colors.text};
    overflow: hidden;
`;
