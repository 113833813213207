import { autobind } from "core-decorators";
import { UserManager } from "oidc-client";
import * as React from "react";

import { KEYCLOAK_REALM } from "@utils/environment";
import { AuthService } from "@utils/authService";

export interface OAuthLoginHandleState {

}

const mgr = new UserManager({
    authority: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/auth/realms/${KEYCLOAK_REALM}`,
    client_id: "app",
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/login/oauth`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`,
    response_type: "id_token token"
});

@autobind
export class OAuthLoginHandler extends React.Component {

    public render() {
        let token: string | null = null;

        const match = window.location.hash.match(/(&access_token=)(.*)(&token_type=bearer)/);
        if (match) {
            token = match[2];
        }

        if (!token) {
            mgr.signinRedirect();
        } else {
            AuthService.oAuthLogin(token).then(user => {
                location.replace("/");
            });
        }

        return null;
    }

}
