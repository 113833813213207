import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";

import { ChildDetailPage } from "@pages/childDetailPage/childDetailPage";
import { createSendQrCodeMutation, SendQrCodeMutationResult } from "@graphql/hocs/sendQrCode";
import { createGetChildDetailQuery, GetChildDetailQueryResult } from "@graphql/hocs/getChildDetail";

export interface ChildDetailPageContainerProps {

}

interface RouteParams {
    childId: string;
}

const withGetChildDetail = createGetChildDetailQuery<ChildDetailPageContainerProps & RouteComponentProps<RouteParams>>({
    options(ownProps) {
        return {
            variables: {
                filter: {
                    childIds: [parseInt(ownProps.match.params.childId, 10)]
                }
            }
        };
    }
});

const withSendQrCodeMutation = createSendQrCodeMutation();

export const ChildDetailPageContainer = compose<React.ComponentClass<ChildDetailPageContainerProps>>(
    withRouter,
    withGetChildDetail,
    withSendQrCodeMutation
)(ChildDetailPage);

export type ChildDetailPageProps =
    ChildDetailPageContainerProps &
    RouteComponentProps<RouteParams> &
    GetChildDetailQueryResult &
    SendQrCodeMutationResult;
