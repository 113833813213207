import { ActionTypes } from "@redux/actions/types";
import { Action } from "@redux/actions/action";
import { GetChildrenRegistrationListRegistration } from "@models/graphql/types";

export interface UpdateEditRegistrationActionOptions {
    edit: {
        registration: GetChildrenRegistrationListRegistration;
        date: string;
        childId: number;
        daycareId?: string;
    } | null;
}

export const updateEditRegistration = (data: UpdateEditRegistrationActionOptions): UpdateEditRegistrationAction => {
    return {
        type: ActionTypes.UpdateEditRegistrationId,
        ...data
    };
};

export type UpdateEditRegistrationAction = Action & UpdateEditRegistrationActionOptions;
export const isUpdateEditRegistrationIdAction = (action: Action): action is UpdateEditRegistrationAction => action.type === ActionTypes.UpdateEditRegistrationId;
