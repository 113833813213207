import { Modal, message } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";

import { Role } from "@utils/user";
// typo isSuspended / isSupsended
import { isSuspended } from "@utils/isSupsended";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { RegistrationsOverviewContainer } from "@pages/registrationsOverview/registrationsOverviewContainer";
import { DaycareDetailPageStyle } from "@pages/daycareDetailPage/daycareDetailPageStyle";
import { DaycareDetailPageProps } from "@pages/daycareDetailPage/daycareDetailPageContainer";
import { ChangePinCodePageContainer } from "@pages/changePinCodePage/changePinCodePageContainer";
import { UploadsOverviewContainer } from "@components/uploadsOverview/uploadsOverviewContainer";
import { SubHeaderContainer } from "@components/subHeader/subHeaderContainer";
import { RoleComponent } from "@components/roleComponent/roleComponent";
import { QrCodeViewerContainer } from "@components/qrCodeViewer/qrCodeViewerContainer";
import { MoreActionsButtonContainer } from "@components/moreActionsButton/moreActionsButtonContainer";
import { InactiveMonthsOverviewContainer } from "@components/inactiveMonthsOverview/inactiveMonthsOverviewContainer";
import { ChildrenOverviewContainer } from "@components/childrenOverview/childrenOverviewContainer";

import { InnerContainer } from "../../style/innerContainer";
import { AuthService } from "@utils/authService";

export interface DaycareDetailPageState {
    showChangePincode: boolean;
}

@autobind
export class DaycareDetailPage extends React.Component<DaycareDetailPageProps, DaycareDetailPageState> {
    public state: DaycareDetailPageState = {
        showChangePincode: false
    };

    public render() {

        const { history, showQrCode, hideQrCode, selectedDaycareId, user, daycares, daycaresLoading } = this.props;
        const { showChangePincode } = this.state;

        if (!user || !selectedDaycareId) {
            throw new Error("Not authenticated");
        }

        let daycareName;
        let caseNumber;
        let pincodeSet = false;

        if (daycares && daycares[0]) {
            daycareName = `${daycares[0].name} — ${daycares[0].caseNumber}`;
            caseNumber = daycares[0].caseNumber;
            pincodeSet = daycares[0].pincodeSet;
        }

        return (
            <DaycareDetailPageStyle>
                <ChangePinCodePageContainer open={showChangePincode} closeCallback={this.closeChangePincode} daycareName={daycareName} caseNumber={caseNumber} pincodeSet={pincodeSet} />
                <SubHeaderContainer title={daycareName} backPath="/daycare-overview" showBackButton={user.roles.includes(Role.ORGANISER)} titleLoading={daycaresLoading}>
                    {showQrCode ?
                        <span onClick={hideQrCode}><FormattedMessage id="childDetailPage.close" /></span>
                        :
                        <>
                            <MediaQuery minWidth={775}>
                                <Button onClick={this.openRegistrationPage} type="ghost"><Icon type="camera" /><FormattedMessage id="subHeader.registrationScreen" /></Button>
                                <Button onClick={() => history.push("/add-child")} type="ghost"><Icon type="add-user" /><FormattedMessage id="subHeader.addChild" /></Button>
                            </MediaQuery>
                            <MediaQuery maxWidth={775}>
                                {(render) => {
                                    if (render) {

                                        return (
                                            <MoreActionsButtonContainer>
                                                <span onClick={this.openRegistrationPage} className="item"><FormattedMessage id="subHeader.registrationScreen" /><Icon type="chevron-right" /></span>
                                                <span onClick={() => history.push("/add-child")} className="item"><FormattedMessage id="subHeader.addChild" /><Icon type="chevron-right" /></span>

                                                <RoleComponent requiredRoles={[Role.ORGANISER]}>
                                                    <span onClick={this.openChangePincode} className="item"><FormattedMessage id="moreActionsButton.editPin" /><Icon type="chevron-right" /></span>
                                                    <span onClick={this.logoutAllDaycares} className="item"><FormattedMessage id="changePincode.logoutUsers" /></span>
                                                </RoleComponent>
                                            </MoreActionsButtonContainer>
                                        );
                                    } else {
                                        return (
                                            <RoleComponent requiredRoles={[Role.ORGANISER]}>
                                                <MoreActionsButtonContainer>
                                                    <span onClick={this.openChangePincode} className="item"><FormattedMessage id="moreActionsButton.editPin" /><Icon type="chevron-right" /></span>
                                                    <span onClick={this.logoutAllDaycares} className="item"><FormattedMessage id="changePincode.logoutUsers" /></span>
                                                </MoreActionsButtonContainer>
                                            </RoleComponent>
                                        );
                                    }
                                }}
                            </MediaQuery>
                        </>
                    }
                </SubHeaderContainer>

                {showQrCode ?
                    <QrCodeViewerContainer code={showQrCode} />
                    :
                    <>
                        <InnerContainer>
                            <nav>
                                <NavLink to={`/daycare/registrations`}><FormattedMessage id="daycareDetailPage.registrations" /></NavLink>
                                <NavLink to={`/daycare/uploads`}><FormattedMessage id="daycareDetailPage.uploads" /></NavLink>
                                <NavLink to={`/daycare/inactive-months`}><FormattedMessage id="daycareDetailPage.inactiveMonths" /></NavLink>
                                <NavLink to={`/daycare/children`}><FormattedMessage id="daycareDetailPage.children" /></NavLink>
                            </nav>
                        </InnerContainer>
                        <Switch>
                            <Redirect exact from="/daycare" to="/daycare/registrations" />
                            <Route path="/daycare/registrations" component={RegistrationsOverviewContainer} />
                            <Route path="/daycare/inactive-months" component={InactiveMonthsOverviewContainer} />
                            <Route path="/daycare/uploads" component={UploadsOverviewContainer} />
                            <Route path="/daycare/children" component={ChildrenOverviewContainer} />
                        </Switch>
                    </>
                }
            </DaycareDetailPageStyle>
        );
    }

    private openRegistrationPage() {
        const { selectedDaycareId, user, daycares } = this.props;

        if (!user || !selectedDaycareId) {
            throw new Error("Not authenticated");
        }

        if (!daycares || !daycares[0]) {
            return location.reload();
        }

        // TODO: comment back in
        if (isSuspended(daycares[0].activeMonths)) {
            return Modal.confirm({
                okCancel: false,
                title: "Locatie stopgezet",
                content: "Deze locatie is stopgezet of werkt nu met inkomenstarief. Je kan geen nieuwe registraties meer toevoegen.",
                iconType: "info-circle"
            });
        }

        if (!daycares[0].pincodeSet) {
            this.openChangePincode();
        } else {
            this.props.history.push("/registration");
        }

    }

    private async logoutAllDaycares() {
        const { daycares, logoutDaycare, intl } = this.props;

        if (daycares) {
            const caseNumber = daycares[0].caseNumber;
            const succeeded = await logoutDaycare({
                caseNumber: caseNumber
            });

            if (succeeded) {
                message.success(intl.formatMessage({ id: "logout.successful"}));
            }
        }
    }

    private closeChangePincode() {
        this.setState({
            showChangePincode: false
        });
    }

    private openChangePincode() {
        this.setState({
            showChangePincode: true
        });
    }
}
