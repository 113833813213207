import styled from "styled-components";

import { Placeholder } from "@style/placeholder";
import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";

export const RegistrationListStyle = styled.div`
    margin-top: ${Padding.medium};
    margin-bottom: 75px;

    .children {
        background-color: ${Colors.white};
        padding-top: 12px;
        padding-bottom: 12px;
    }

    ${Placeholder} {
        margin-bottom: 15px;
        height: 60px;
    }

`;

export const EmptyBlock = styled.div`
    background-color: ${Colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    border-radius: 2px;
    font-weight: 200;
    text-align: center;
    line-height: 140%;

    img {
        margin-bottom: 40px;
    }

    ${Button}{
        margin-top: ${Padding.large};
    }
`;

export const ListHeader = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${Colors.primary()};
    color: ${Colors.white};
    font-weight: 600;
`;
