import styled from "styled-components";

import { RegistrationErrorPageStyle } from "@pages/registrationErrorPage/registrationErrorPageStyle";
import { Padding } from "@style/padding";
import { Code } from "@pages/confirmRegistrationPage/confirmRegistrationPageStyle";
import { EntryType } from "@models/graphql/types";
import { Colors } from "@style/colors";
import { RegistrationErrorPageContainerProps } from "@pages/registrationErrorPage/registrationErrorPageContainer";
import { Button } from "@style/button";

interface RegistrationChildMissingPageStyleProps {
    type?: EntryType;
}

export const RegistrationChildMissingPageStyle = styled(RegistrationErrorPageStyle)`

    background-color: ${(props: RegistrationChildMissingPageStyleProps) => !props.type ? Colors.error() : props.type === EntryType.IN ? Colors.primary() : Colors.secondary()};

    h1 {
        margin-bottom: 20px
    }

    h3 {
        font-size: 18px;
        margin-top: ${Padding.large};
    }

    .signIn {
        margin-top: ${Padding.medium};
    }

    .retry {
        cursor: pointer;
    }

    ${Button} {
        color: ${(props: RegistrationChildMissingPageStyleProps) => !props.type ? Colors.error() : props.type === EntryType.IN ? Colors.primary() : Colors.secondary()};
    }

    ${Code} {
        margin-top: 50px
        margin-bottom: ${Padding.medium};
    }
`;
