import * as moment from "moment";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { updateEditRegistration, UpdateEditRegistrationActionOptions } from "@redux/actions/updateEditRegistrationId";
import { updateCreateRegistration, UpdateCreateRegistrationActionOptions } from "@redux/actions/updateCreateRegistration";
import {
    setRegistrationSelectedDate,
    SetRegistrationSelectedDateActionOptions
} from "@redux/actions/setRegistrationSelectedDate";
import {
    setRegistrationAggregation,
    SetRegistrationAggregationActionOptions
} from "@redux/actions/setRegistrationAggregation";
import { Action } from "@redux/actions/action";
import { GetChildrenRegistrationListChildren } from "@models/graphql/types";
import { createGetDaycareNamesQuery, GetDaycareNamesQueryResult } from "@graphql/hocs/getDaycareNames";
import { RegistrationListItem } from "@components/registrations/registrationListItem/registrationListItem";

export interface RegistrationListItemContainerProps {
    child: GetChildrenRegistrationListChildren;
    date: moment.Moment;
}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationListItemContainerProps) => ({
    aggregation: state.registration.aggregation,
    selectedDaycareId: state.daycare.selectedDaycareId,
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setDate(data: SetRegistrationSelectedDateActionOptions) { dispatch(setRegistrationSelectedDate(data)); },
    setAggregation(opts: SetRegistrationAggregationActionOptions) { dispatch(setRegistrationAggregation(opts)); },
    editRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); },
    createRegistration(data: UpdateCreateRegistrationActionOptions) { dispatch(updateCreateRegistration(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withgetDaycare = createGetDaycareNamesQuery<WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organisationId) {
            throw new Error("No organisation ID found");
        }

        return {
            variables: {
                filter: {
                    id: ownProps.selectedDaycareId,
                    organisationId: ownProps.organisationId
                }
            }
        };
    }
});

export const RegistrationListItemContainer = compose<React.ComponentClass<RegistrationListItemContainerProps>>(
    withRedux,
    injectIntl,
    withRouter,
    withgetDaycare
)(RegistrationListItem);

export type RegistrationListItemProps =
    RegistrationListItemContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    RouteComponentProps &
    InjectedIntlProps &
    GetDaycareNamesQueryResult;
