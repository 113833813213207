import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { RegistrationCheckinPage } from "@pages/registrationCheckinPage/registrationCheckinPage";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { connect } from "react-redux";
import { setRegistrationFlowEntryType, SetRegistrationFlowEntryTypeActionOptions } from "@redux/actions/setRegistrationFlowEntryType";
import { setRegistrationFlowCode, SetRegistrationFlowCodeActionOptions } from "@redux/actions/setRegistrationFlowCode";

export interface RegistrationCheckinPageContainerProps {

}

interface RouteParams {
    type: string;
}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationCheckinPageContainerProps) => ({
    code: state.registrationFlow.code,
    entryType: state.registrationFlow.entryType
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setEntryType(data: SetRegistrationFlowEntryTypeActionOptions) { dispatch(setRegistrationFlowEntryType(data)); },
    setCode(data: SetRegistrationFlowCodeActionOptions) { dispatch(setRegistrationFlowCode(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const RegistrationCheckinPageContainer = compose<React.ComponentClass<RegistrationCheckinPageContainerProps>>(
    withRedux,
    withRouter
)(RegistrationCheckinPage);

export type RegistrationCheckinPageProps =
    RegistrationCheckinPageContainerProps &
    WithReduxStateProps &
    WithReduxDispatchProps &
    RouteComponentProps<RouteParams>;
