import * as Sentry from "@sentry/browser";

import { BUILD_NUMBER, RELEASE, SENTRY_DSN } from "./environment";

export const initErrorTracking = () => {
    if (SENTRY_DSN) {
        Sentry.init({
            dsn: SENTRY_DSN,
            release: BUILD_NUMBER,
            enabled: false,
            environment: RELEASE
        });
    }
};
