import { Select } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Aggregation } from "@models/graphql/types";
import { DayStyle } from "@components/registrations/calendar/day/dayStyle";
import { DayContainer, DayContainerProps } from "@components/registrations/calendar/day/dayContainer";
import { CalendarStyle, Week } from "@components/registrations/calendar/calendarStyle";
import { CalendarProps } from "@components/registrations/calendar/calendarContainer";

export interface CalendarState {
    currentMonth: number;
    currentYear: number;
}

const initialzeState = (props: CalendarProps): CalendarState => ({
    currentMonth: props.startDate.clone().endOf("week").month(),
    currentYear: props.startDate.clone().endOf("week").year()
});

@autobind
export class Calendar extends React.Component<CalendarProps, CalendarState> {
    public state: CalendarState = initialzeState(this.props);

    public render() {

        const { currentMonth, currentYear } = this.state;
        const { aggregation } = this.props;

        return (
            <CalendarStyle className="date-selector-calendar" >

                <div className="row">
                    <div className="item">
                        <FormattedMessage id="calendar.month" />
                        <Select onChange={this.onMonthChange} value={currentMonth}>
                            <Select.Option value={0}>Januari</Select.Option>
                            <Select.Option value={1}>Februari</Select.Option>
                            <Select.Option value={2}>Maart</Select.Option>
                            <Select.Option value={3}>April</Select.Option>
                            <Select.Option value={4}>Mei</Select.Option>
                            <Select.Option value={5}>Juni</Select.Option>
                            <Select.Option value={6}>Juli</Select.Option>
                            <Select.Option value={7}>Augustus</Select.Option>
                            <Select.Option value={8}>September</Select.Option>
                            <Select.Option value={9}>Oktober</Select.Option>
                            <Select.Option value={10}>November</Select.Option>
                            <Select.Option value={11}>December</Select.Option>
                        </Select>
                    </div>
                    <div className="item">
                        <FormattedMessage id="calendar.year" />
                        <Select onChange={this.onYearChange} value={currentYear}>
                            {this.renderYearOptions()}
                        </Select>
                    </div>
                </div>
                <div className="row">
                    <div className="item">
                        <FormattedMessage id="calendar.overview" />
                        <Select onChange={this.onAggregationChange} value={aggregation}>
                            <Select.Option value={Aggregation.DAY}>Eén week</Select.Option>
                            <Select.Option value={Aggregation.WEEK}>Eén maand</Select.Option>
                        </Select>
                    </div>
                </div>

                <div className={`days ${aggregation}`}>
                    <Week className="disabled">
                        <DayStyle className="disabled">Ma</DayStyle>
                        <DayStyle className="disabled">Di</DayStyle>
                        <DayStyle className="disabled">Wo</DayStyle>
                        <DayStyle className="disabled">Do</DayStyle>
                        <DayStyle className="disabled">Vr</DayStyle>
                        <DayStyle className="disabled">Za</DayStyle>
                        <DayStyle className="disabled">Zo</DayStyle>
                    </Week>
                    {this.renderDays()}
                </div>
            </CalendarStyle >

        );
    }

    private onYearChange(currentYear: number) {
        const { currentMonth } = this.state;
        const { aggregation } = this.props;
        this.setState({
            currentYear
        });

        this.handleChange(aggregation, currentMonth, currentYear);
    }

    private onMonthChange(currentMonth: number) {
        const { aggregation } = this.props;

        const { currentYear } = this.state;

        this.setState({
            currentMonth
        });

        this.handleChange(aggregation, currentMonth, currentYear);

    }

    private onAggregationChange(aggregation: Aggregation) {
        const { currentMonth, currentYear } = this.state;

        this.props.setAggregation({ aggregation });

        this.handleChange(aggregation, currentMonth, currentYear);
    }

    private renderDays() {
        const { startDate, endDate } = this.props;

        return this.weeks.map((week, index) => {
            const isActive = this.isOverlapping(startDate, endDate, week[0].day, week[week.length - 1].day);

            return <Week key={index} className={`${isActive ? "active" : ""}`} onClick={() => this.onWeekClick(week[0].day, week[6].day)}>
                {week.map(day => <DayContainer key={day.day.dayOfYear()} {...day} />)}
            </Week>;
        });
    }

    private isOverlapping(start1: moment.Moment, end1: moment.Moment, start2: moment.Moment, end2: moment.Moment) {
        return (start1.valueOf() < end2.valueOf()) && (end1.valueOf() > start2.valueOf());
    }

    private onWeekClick(startDate: moment.Moment, endDate: moment.Moment) {
        if (this.props.aggregation !== Aggregation.WEEK) {
            this.props.setDate({
                startDate,
                endDate
            });
        }
    }

    private get weeks() {
        const { currentMonth, currentYear } = this.state;

        const currentMonthMoment = moment().set({
            date: 1,
            month: currentMonth,
            year: currentYear
        });

        const currentDay = currentMonthMoment.clone().startOf("isoWeek");
        const weeks: (DayContainerProps[])[] = [];
        let currentWeek: DayContainerProps[] = [];
        let isFinished = false;

        while (!isFinished) {
            const day = currentDay.clone();

            currentWeek.push({
                day,
                disabled: day.month() !== currentMonth
            });

            currentDay.add(1, "day");
            if (currentDay.isoWeek() !== day.isoWeek()) {
                weeks.push(currentWeek);
                currentWeek = [];

                isFinished = currentDay.month() !== currentMonth;
            }
        }

        return weeks;
    }

    private renderYearOptions() {
        const years: JSX.Element[] = [];
        // There is no data before 2018, hence the start is 2018
        const currentYear = moment().year();
        for (let i = 2018; i < currentYear + 3; i += 1) {
            years.push(<Select.Option key={String(i)} value={i}>{i}</Select.Option>);
        }

        return years;
    }

    private handleChange(aggregation: Aggregation, currentMonth: number, currentYear: number) {
        if (aggregation === Aggregation.DAY) {
            this.props.setDate({
                startDate: moment().set("year", currentYear).set("month", currentMonth).set("date", 1).startOf("isoWeek"),
                endDate: moment().set("year", currentYear).set("month", currentMonth).set("date", 1).endOf("isoWeek")
            });
        } else {
            this.props.setDate({
                startDate: moment().set("year", currentYear).set("month", currentMonth).set("date", 1).startOf("month"),
                endDate: moment().set("year", currentYear).set("month", currentMonth).set("date", 1).endOf("month")
            });
        }
    }
}
