import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { TableBlock } from "@pages/registrationsOverview/registrationsOverviewStyle";
import { Aggregation } from "@models/graphql/types";
import { TableHeaderStyle } from "@components/registrations/tableHeader/tableHeaderStyle";
import { TableHeaderProps } from "@components/registrations/tableHeader/tableHeaderContainer";
import {
    RegistrationSearchBarContainer
} from "@components/registrations/registrationSearchBar/registrationSearchBarContainer";

export interface TableHeaderState {

}

@autobind
export class TableHeader extends React.Component<TableHeaderProps, TableHeaderState> {

    public render() {
        const { intl, searchValue } = this.props;

        return (
            <TableHeaderStyle>
                <TableBlock className="infoColumn">
                    <RegistrationSearchBarContainer />
                </TableBlock>

                <div className="divider" />

                {this.renderHeaderBlocks()}

            </TableHeaderStyle>
        );
    }

    private renderHeaderBlocks() {
        const { startDate, endDate, aggregation } = this.props;

        const date = startDate.clone();

        const result: JSX.Element[] = [];

        if (aggregation === Aggregation.DAY) {

            let i = 0;

            while (i < 7) {

                if (date.isSame(moment(), "d")) {
                    result.push(<TableBlock key={i}>
                        <span className="today">
                            <FormattedMessage id="tableHeader.today" />
                        </span>
                    </TableBlock>);
                } else {

                    result.push(<TableBlock key={i}>
                        <span>
                            {date.format("dd D MMM")}
                        </span>
                    </TableBlock>);
                }

                date.add(1, "d");
                i += 1;
            }
        } else {
            const weeks = Math.abs(startDate.diff(endDate, "weeks")) + 1;

            let i = 0;
            while (i < weeks) {
                result.push(<TableBlock key={i}>
                    <span>
                        <FormattedMessage id="tableHeader.week" /> {date.clone().add(i, "weeks").week()}
                    </span>
                </TableBlock>);
                i += 1;
            }

        }

        return result;
    }
}
