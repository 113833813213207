import styled from "styled-components";
import { Colors } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";
import { Padding } from "@style/padding";
import { Logo } from "@style/logo";

export const IntroLayoutStyle = styled.div`
    height: 100%;
    width: 100%;

    .green {
        position: absolute;
        background-color: ${Colors.primary()};
        left: 0;
        right: 0;
        height: 450px;
    }

    .overGreen {
        position: relative;
        z-index: 1;
        padding-top: ${Padding.large};

        & > ${InnerContainer} > ${Logo} {
            margin-bottom: 90px;
        }
    }
`;
