import { autobind } from "core-decorators";
import * as React from "react";

import { AuthService } from "@utils/authService";
import { RegistrationCheckinPageStyle } from "@pages/registrationCheckinPage/registrationCheckinPageStyle";
import { RegistrationCheckinPageProps } from "@pages/registrationCheckinPage/registrationCheckinPageContainer";
import { ConfirmRegistrationPageContainer } from "@pages/confirmRegistrationPage/confirmRegistrationPageContainer";
import { EntryType } from "@models/graphql/types";
import { QrCodeReaderContainer } from "@components/qrCodeReader/qrCodeReaderContainer";

export interface RegistrationCheckinPageState {

}

@autobind
export class RegistrationCheckinPage extends React.Component<RegistrationCheckinPageProps, RegistrationCheckinPageState> {

    public state: RegistrationCheckinPageState = {

    };

    public static getDerivedStateFromProps(props: RegistrationCheckinPageProps, state: RegistrationCheckinPageState): Partial<RegistrationCheckinPageState> | null {
        const { match, entryType, setEntryType } = props;

        let type = EntryType.OUT;

        if (match.params.type === "in") {
            type = EntryType.IN;
        }

        if (type !== entryType) {
            setEntryType({ entryType: type });
        }

        return null;
    }

    public render() {

        const { code, entryType } = this.props;

        return (
            <RegistrationCheckinPageStyle>
                {code ? <ConfirmRegistrationPageContainer /> : <QrCodeReaderContainer entryType={entryType} scannedCallback={this.onScan} />}
            </RegistrationCheckinPageStyle>
        );
    }

    public componentDidMount() {
        AuthService.isAuthorized = false;

    }

    private onScan(code: string) {
        this.props.setCode({ code });
    }

}
