import { Button, Progress, Spin } from "antd";
import classNames from "classnames";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";

import { Icon } from "@style/icon";
import { AddEntryVariables, EntryType } from "@models/graphql/types";
import { PendingRegistrationsStyle } from "@components/pendingRegistrations/pendingRegistrationsStyle";
import { PendingRegistrationsProps } from "@components/pendingRegistrations/pendingRegistrationsContainer";

export interface PendingRegistrationsState {
    visible: boolean;
}

@autobind
export class PendingRegistrations extends React.Component<PendingRegistrationsProps, PendingRegistrationsState> {
    public state: PendingRegistrationsState = {
        visible: true
    };

    public static getDerivedStateFromProps(props: PendingRegistrationsProps, state: PendingRegistrationsState): Partial<PendingRegistrationsState> | null {
        if (!props.initialized && props.selectedDaycare) {
            props.initializeLink();
        }

        return null;
    }

    public render() {

        const { mutationsPending, isOpen, hide, childrenList, processing, removeOperation, childrenLoading } = this.props;
        const { visible } = this.state;

        const completedCount = mutationsPending.filter(mutation => mutation.completed).length;
        const totalCount = mutationsPending.length;
        const hasErrors = mutationsPending.some(op => !!op.error);

        if (!mutationsPending.length || hide || (completedCount === totalCount && !visible)) {
            return null;
        }

        if (childrenLoading) {
            return (
                <PendingRegistrationsStyle>
                    <Spin />
                </PendingRegistrationsStyle>
            );
        }

        if (!childrenList) {
            return (
                <PendingRegistrationsStyle>
                    <h1>Offline registraties</h1>

                    <p>Er ging iets mis tijdens het ophalen van de data, vernieuw de pagina om het nog eens te proberen</p>
                </PendingRegistrationsStyle>
            );
        }

        if (!visible) {
            return (
                <PendingRegistrationsStyle className="hidden">
                    <h1 onClick={this.show}><Icon type="info" />Toon offline registraties</h1>
                </PendingRegistrationsStyle>
            );
        }

        return (
            <PendingRegistrationsStyle>
                <Icon onClick={this.hide} className="close" type="cross" />
                <h1>Offline registraties</h1>
                {!hasErrors && <p>De registraties hieronder zijn gebeurd terwijl je offline was en zijn doorgestuurd naar de server.</p>}
                {hasErrors && <p>De registraties hieronder zijn gebeurd terwijl je offline maar konden niet doorgestuurd worden, klik op de knop onderaan om het nog eens te proberen.</p>}
                <div className="list">
                    {mutationsPending.map((mutation, index) => {

                        const { completed, error, operation, id } = mutation;

                        const vars = operation.variables as AddEntryVariables;

                        const childMatch = childrenList.find(child => child.code === vars.childCode);

                        if (!childMatch) {
                            return null;
                        }

                        return (
                            <span className={classNames("item", vars.type, { completed, error })} key={index}>
                                <Icon className="entryType" type={vars.type === EntryType.IN ? "triangle-right" : "triangle-left"} />
                                <span className="time">
                                    {moment(vars.time).format("DD-MM HH:mm")}
                                </span>
                                {childMatch.firstName} {childMatch.lastName}

                                {mutation.completed && <Icon className="status" type="checkmark" />}
                                {mutation.error && <Icon className="status" type="cross" />}
                                {/* {!mutation.completed && <Popconfirm onConfirm={() => removeOperation(id)} title="Ben je zeker dat je deze registratie wil verwijderen?"><Icon type="trash" /></Popconfirm>} */}
                            </span>
                        );
                    })}
                </div>

                {processing && <Progress showInfo={false} percent={Math.ceil(completedCount / totalCount * 100)} />}
                {!isOpen && <em>Je bent nog offline, je kan de registraties enkel maar doorsturen als je online bent.</em>}
                {hasErrors && <Button disabled={(completedCount === totalCount) || !isOpen} loading={processing} type="primary" onClick={this.processQueue}>Opnieuw doorsturen</Button>}
            </PendingRegistrationsStyle>
        );
    }

    private hide() {
        const { mutationsPending, purgeCompleted } = this.props;

        this.setState({ visible: false });
        const completedCount = mutationsPending.filter(mutation => mutation.completed).length;
        const totalCount = mutationsPending.length;

        if (completedCount === totalCount) {
            purgeCompleted();
        }
    }

    private show() {
        this.setState({ visible: true });
    }

    private processQueue() {
        this.props.processQueue({
            batch: false
        });
    }
}
