import * as React from "react";
import * as moment from "moment";
import { autobind } from "core-decorators";
import { InactiveMonthsOverviewProps } from "@components/inactiveMonthsOverview/inactiveMonthsOverviewContainer";
import { InactiveMonthsOverviewStyle, InactiveMonth } from "@components/inactiveMonthsOverview/inactiveMonthsOverviewStyle";
import { InnerContainer } from "@style/innerContainer";
import { Box } from "@style/box";
import { FormattedMessage } from "react-intl";
import { Select, Popconfirm, message, DatePicker } from "antd";
import { Button } from "@style/button";
import { Placeholder } from "@style/placeholder";
import { Icon } from "@style/icon";

const { MonthPicker } = DatePicker;

export interface InactiveMonthsOverviewState {
    selectedMonth?: moment.Moment;
    submitting: boolean;
}

@autobind
export class InactiveMonthsOverview extends React.Component<InactiveMonthsOverviewProps, InactiveMonthsOverviewState> {

    public state: InactiveMonthsOverviewState = {

        submitting: false
    };

    public render() {

        const { intl } = this.props;
        const { submitting } = this.state;

        return (
            <InactiveMonthsOverviewStyle>
                <InnerContainer>

                    <Box>
                        <FormattedMessage tagName="h1" id="inactiveMonthsOverview.title" />
                        <FormattedMessage tagName="p" id="inactiveMonthsOverview.description" />

                        <label htmlFor="month"><FormattedMessage id="inactiveMonthsOverview.inactiveMonth" /></label>
                        <div className="form">
                            <MonthPicker disabledDate={this.disabledDate} format={"MMMM YYYY"} onChange={this.onMonthChange} placeholder={intl.formatMessage({ id: "inactiveMonthsOverview.inactiveMonth" })} id="month" size="large" />
                            <Button loading={submitting} onClick={this.addInactiveMonth} type="primary" size="large">
                                <FormattedMessage id="inactiveMonthsOverview.save" />
                            </Button>
                        </div>

                        <FormattedMessage tagName="h2" id="inactiveMonthsOverview.plannedInactiveMonths" />
                        {this.renderInactiveMonths()}

                    </Box>
                </InnerContainer>
            </InactiveMonthsOverviewStyle>
        );
    }

    private disabledDate(currentDate: moment.Moment) {

        const { firstUpload } = this.props;

        if (firstUpload) {
            if (moment(firstUpload).isBefore(currentDate)) {

                return false;
            }

            return true;
        }

        return false;
    }

    private onMonthChange(selectedMonth: moment.Moment) {
        this.setState({
            selectedMonth: selectedMonth.startOf("month")
        });

        console.log(selectedMonth);
    }

    private async addInactiveMonth() {
        const { createInactiveMonth, intl } = this.props;
        const { selectedMonth } = this.state;

        try {

            if (!selectedMonth) {
                return;
            }

            this.setState({ submitting: true });
            const result = await createInactiveMonth({
                startOfMonth: selectedMonth.format("YYYY-MM-DD")
            });

            if (result) {
                message.success(intl.formatMessage({ id: "inactiveMonthsOverview.createSuccess" }));
            } else {
                message.error(intl.formatMessage({ id: "inactiveMonthsOverview.createFail" }));
            }
            this.setState({ submitting: false, selectedMonth: undefined });
        } catch (err) {
            message.error(intl.formatMessage({ id: "inactiveMonthsOverview.createFail" }));
            this.setState({ submitting: false });
        }
    }

    private renderInactiveMonths() {
        const { inactiveMonths, inactiveMonthsLoading, intl } = this.props;

        if (inactiveMonthsLoading) {
            return (
                <>
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </>
            );
        }

        if (!inactiveMonths || !inactiveMonths.length) {
            return <FormattedMessage tagName="em" id="inactiveMonthsOverview.noInactiveMonthsPlanned" />;
        }

        return inactiveMonths.map(month => {
            return <InactiveMonth key={month.id}>
                {moment(month.startOfMonth).format("MMMM YYYY")}
                <Popconfirm onConfirm={async () => this.onDeleteMonth(month.id)} okText="Verwijder" cancelText="Annuleer" placement="left" title={intl.formatMessage({ id: "inactiveMonthsOverview.confirmDelete" })}>
                    <Icon type="trash" />
                </Popconfirm>
            </InactiveMonth>;
        });
    }

    private async onDeleteMonth(monthId: number) {
        const { deleteInactiveMonth, intl } = this.props;

        try {
            const result = await deleteInactiveMonth({
                monthId
            });

            if (result) {
                message.success(intl.formatMessage({ id: "inactiveMonthsOverview.deleteSuccess" }));
            } else {
                message.error(intl.formatMessage({ id: "inactiveMonthsOverview.deleteFail" }));
            }
        } catch (err) {
            message.error(intl.formatMessage({ id: "inactiveMonthsOverview.deleteFail" }));
        }
    }
}
