import styled from "styled-components";

import { Colors } from "@style/colors";

export const LayoutStyle = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`;

export const OfflineMessage = styled.div`
    position: fixed;
    bottom: 10px;
    left: 10px;
    margin-right: 10px;
    z-index: 15;
    font-size: 16px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.17);
    color: ${Colors.white};
    background-color: ${Colors.error()};


    .icon {
        margin-right: 10px;
    }
`;

export const TimeZoneMessage = styled(OfflineMessage)`
    left: initial;
    right: 10px;
`;
