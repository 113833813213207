import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { InnerContainer } from "@style/innerContainer";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { LoginOverviewPageStyle, OptionsContainer } from "@pages/loginOverviewPage/loginOverviewPageStyle";
import { LoginOverviewPageProps } from "@pages/loginOverviewPage/loginOverviewPageContainer";

export interface LoginOverviewPageState {

}

@autobind
export class LoginOverviewPage extends React.Component<LoginOverviewPageProps, LoginOverviewPageState> {

    public render() {

        const { } = this.props;

        return (
            <LoginOverviewPageStyle>
                <InnerContainer>
                    <FormattedMessage tagName="h1" id="loginOverviewPage.title" />
                    <FormattedMessage tagName="h2" id="loginOverviewPage.subTitle" />
                </InnerContainer>

                <OptionsContainer>
                    <Box>
                        <Link to="/login/daycare">
                            <img alt="daycare" src={require("@assets/images/daycare.jpg")} />
                            <Button type="primary"><FormattedMessage id="loginOverviewPage.daycare" /></Button>
                        </Link>
                    </Box>
                    <Box>
                        <Link to="/login/organiser">
                            <img alt="organiser" src={require("@assets/images/organiser.jpg")} />
                            <Button type="primary"><FormattedMessage id="loginOverviewPage.organiser" /></Button>
                        </Link>
                    </Box>
                    <Box>
                        <Link to="/login/parent">
                            <img alt="parent" src={require("@assets/images/parent.jpg")} />
                            <Button type="primary"><FormattedMessage id="loginOverviewPage.parent" /></Button>
                        </Link>
                    </Box>
                    <span className="spacer">&nbsp;</span>
                </OptionsContainer>

                <InnerContainer>
                    <div className="links">
                        <a href="mailto:vragen@aaron.support">vragen@aaron.support</a>
                        <span>&bull;</span>
                        <a href="tel:052262145">052 26 21 45</a>
                        <span>&bull;</span>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.kindengezin.be/kinderopvang/sector-babys-en-peuters/aaron/">Veelgestelde vragen</a>
                        <span>&bull;</span>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.kindengezin.be/sites/default/files/2021-06/privacy-aaron.pdf">Privacy</a>
                    </div>
                </InnerContainer>

            </LoginOverviewPageStyle>
        );
    }
}
