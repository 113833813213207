import styled from "styled-components";
import { Colors } from "@style/colors";
import { Padding } from "@style/padding";

export const ExportSelectorStyle = styled.div`
    & > div {
        display: flex;
        align-items: center;
        height: 30px;

        .item {
            margin-left: 10px;
        }
    }

    @media (max-width: 600px) {

        background-color: ${Colors.white};
        flex: 1;
        border: 1px solid ${Colors.grey};
        height: 45px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        border-radius: 4px;

        & > div {
            width: 100%;
        }

        position: relative;
    }
`;
