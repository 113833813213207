import * as React from "react";
import { compose } from "redux";

import { HiddenButtonsButton } from "./hiddenButtonsButton";

export interface HiddenButtonsButtonContainerProps {

}

export const HiddenButtonsButtonContainer = compose<React.ComponentClass<HiddenButtonsButtonContainerProps>>(

)(HiddenButtonsButton);

export type HiddenButtonsButtonProps =
HiddenButtonsButtonContainerProps;
