import { Reducer } from "redux";
import { Action } from "@redux/actions/action";
import { isUpdateDaycareLoginAction } from "@redux/actions/updateDaycareLogin";

const INITIAL_STATE: MainReducerState = {
    daycareLogin: null
};

export interface MainReducerState {
    daycareLogin: {
        caseNumber: string;
        redirectPath: string;
    } | null;
}

export const mainReducer: Reducer<MainReducerState> = (state: MainReducerState = INITIAL_STATE, action: Action) => {

    if (isUpdateDaycareLoginAction(action)) {
        return {
            ...state,
            daycareLogin: action.daycareLogin
        };
    }

    return state;
};
