import { ActionTypes } from "@redux/actions/types";
import { Action } from "@redux/actions/action";
import { AddEntryRegistration } from "@models/graphql/types";

export interface SetRegistrationFlowRegistrationResultActionOptions {
    registrationResult?: AddEntryRegistration | null;
}

export const setRegistrationFlowRegistrationResult = (data: SetRegistrationFlowRegistrationResultActionOptions): SetRegistrationFlowRegistrationResultAction => {
    return {
        type: ActionTypes.SetRegistrationFlowRegistrationResult,
        ...data
    };
};

export type SetRegistrationFlowRegistrationResultAction = Action & SetRegistrationFlowRegistrationResultActionOptions;
export const isSetRegistrationFlowRegistrationResultAction = (action: Action): action is SetRegistrationFlowRegistrationResultAction => action.type === ActionTypes.SetRegistrationFlowRegistrationResult;
