import styled from "styled-components";

import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";
import { Colors } from "@style/colors";

export const HeaderStyle = styled.div`
    width: 100vw;
    background-color: ${Colors.primary()};
    padding: ${Padding.medium} 0;
    color: ${Colors.white};
    height: 90px;

    & > ${InnerContainer} {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
            display: flex;
            align-items: center;
        }
    }

    .logout {
        cursor: pointer;
    }

    .icon {
        margin-left: ${Padding.medium};
        cursor: pointer;
        opacity: 0.5;
        transition: opacity .2s;

        &:hover {
            opacity: 1;
        }
    }

    @media (max-width: 600px) {
        .info {
            display: none;
        }
    }
`;

interface OverlayNavigationProps {
    open: boolean;
}

export const OverlayNavigation = styled.div`
    display: ${(props: OverlayNavigationProps) => props.open ? "block" : "none"};
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${Colors.primary()};
    z-index: 50;
    text-align: right;
    color: ${Colors.white};


    .version {
        position: absolute;
        bottom: 15px;
        left: 15px;
    }

    ${InnerContainer} {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding-top: 50px;
        padding-bottom: 50px;
        overflow-y: auto;
    }

    li {
        font-size: 30px;
        font-weight: 200;
        &:not(:last-of-type) {
            margin-bottom: ${Padding.large};
        }
    }

    .divider {
        width: 90px;
        height: 1px;
        background-color: ${Colors.white};
        margin: 50px 0;
        opacity: 0.5;
    }


    a, .logout {
        color: ${Colors.white};
        opacity: 0.5;
        transition: 0.2s;

        &:hover, &.active {
            opacity: 1;
            color: ${Colors.white};
        }
    }

`;
