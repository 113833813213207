import { ApolloError } from "apollo-client/errors/ApolloError";

export enum ErrorCode {
    UNKNOWN = "error.unknown",

    INVALID_CREDENTIALS = "error.login.invalidCredentials",
    INVALID_TOKEN = "error.login.invalidToken",

    NO_DAYCARE_SELECTED = "error.noDaycareSelected",

    EMAIL_IN_USE = "error.login.email_in_use",
    USER_NOT_FOUND = "error.login.user_not_found",
    PASSWORDS_DONT_MATCH = "error.login.passwordsDontMatch",
    CONTACT_ALREADY_EXISTS = "error.contactAlreadyExists",

    REGISTRATION_NOT_FOUND = "error.registrationNotFound",

    CHILD_NOT_FOUND = "error.childNotFound",
    CODE_ALREADY_USED = "error.codeAlreadyUsed",

    UNAUTHORIZED = "error.unauthorized"
}

export class CodeError extends Error {

    public code: ErrorCode;

    constructor(code: ErrorCode) {
        super(code);

        this.code = code;
    }
}

export const parseError = (error: ApolloError): ErrorCode => {
    const match = error.message.match(/error\..*$/);

    if (match && match.length) {
        return match[0] as ErrorCode;
    }

    return ErrorCode.UNKNOWN;
};
