import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@style/icon";
import { DaycareProblemListItemStyle } from "@components/daycare/daycareProblemListItem/daycareProblemListItemStyle";
import { DaycareProblemListItemProps } from "@components/daycare/daycareProblemListItem/daycareProblemListItemContainer";

export interface DaycareProblemListItemState {

}

@autobind
export class DaycareProblemListItem extends React.Component<DaycareProblemListItemProps, DaycareProblemListItemState> {

    public render() {

        const { daycare } = this.props;
        const { caseNumber, name } = daycare;

        return (
            <DaycareProblemListItemStyle>
                <h1>{name} {caseNumber}</h1>
                {this.renderInvalidRegistrations()}
                {this.renderInvalidUploads()}
            </DaycareProblemListItemStyle>

        );
    }

    private renderInvalidUploads() {
        const { daycare } = this.props;
        const { invalidUploads } = daycare;

        if (invalidUploads.length) {

            return (
                <div className="problems">
                    <h2><Icon type="upload-fail" /> <FormattedMessage id="daycareProblemListItem.invalidUploads" /></h2>
                    <ul>
                        {invalidUploads.map((upload, index) => <li key={index}>
                            <span>{moment(upload.startOfMonth).format("MMMM Y")}</span>
                            <span onClick={this.goToUploadDetail}>

                                <FormattedMessage id="daycareProblemListItem.toDetails" />
                            </span>
                        </li>)}

                    </ul>
                </div>
            );
        }
    }

    private goToUploadDetail() {
        const { setDaycare, history, daycare } = this.props;

        setDaycare({ daycareId: daycare.id });
        history.push("/daycare/uploads");
    }

    private renderInvalidRegistrations() {
        const { daycare, editRegistration } = this.props;
        const { invalidRegistrations } = daycare;

        if (invalidRegistrations.length) {

            const children: Set<number> = new Set();
            invalidRegistrations.forEach(reg => children.add(reg.child.id));

            const errors: JSX.Element[] = [];

            {
                children.forEach(childId => {
                    const invalidRegs = invalidRegistrations.filter(reg => reg.child.id === childId);
                    const { firstName, lastName } = invalidRegs[0].child;

                    errors.push(<div key={childId} className="child">
                        <h3>{firstName} {lastName}</h3>
                        <ul>
                            {invalidRegs.map(reg => <li key={reg.id}>
                                <span>{moment(reg.date).format("DD MMMM")}</span>
                                <span onClick={() => editRegistration({ edit: { childId, date: reg.date, registration: reg, daycareId: daycare.id } })}>
                                    <FormattedMessage id="daycareProblemListItem.edit" />
                                </span>
                            </li>)}
                        </ul>
                    </div>);
                });

                return (
                    <div className="problems">
                        <h2><Icon type="time" /> <FormattedMessage id="daycareProblemListItem.invalidRegistrations" /></h2>
                        {errors}
                    </div>
                );
            }
        }
    }
}
