import * as React from "react";
import { compose, Dispatch } from "redux";

import { RegistrationsOverview } from "@pages/registrationsOverview/registrationsOverview";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { connect } from "react-redux";

export interface RegistrationsOverviewContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationsOverviewContainerProps) => ({
    ...state.registration
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const RegistrationsOverviewContainer = compose<React.ComponentClass<RegistrationsOverviewContainerProps>>(
    withRedux
)(RegistrationsOverview);

export type RegistrationsOverviewProps =
    RegistrationsOverviewContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
