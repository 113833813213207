import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { DaycareOverviewPage } from "@pages/daycareOverviewPage/daycareOverviewPage";
import { createGetDaycareProblemsQuery, GetDaycareProblemsQueryResult } from "@graphql/hocs/getDaycareProblems";
import { setOrganisationId, SetOrganisationIdActionOptions } from "@redux/actions/setOrganisationId";

export interface DaycareOverviewPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: DaycareOverviewPageContainerProps) => ({
    searchValue: state.daycare.overviewSearch,
    multiOrganiser: state.auth.user && state.auth.user.multiOrganiser,
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setOrganisationId(data: SetOrganisationIdActionOptions) { dispatch(setOrganisationId(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetDaycareProblems = createGetDaycareProblemsQuery<WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organisationId) {
            throw new Error("No organisation ID found");
        }

        return {
            variables: {
                filter: {
                    organisationId: ownProps.organisationId
                }
            }
        };
    }
});

export const DayCareOverviewPageContainer = compose<React.ComponentClass<DaycareOverviewPageContainerProps>>(
    withRedux,
    withGetDaycareProblems
)(DaycareOverviewPage);

export type DaycareOverviewPageProps =
    DaycareOverviewPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    GetDaycareProblemsQueryResult;
