import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { createLoginMutation, LoginMutationResult } from "@graphql/hocs/loginMutation";
import { createGetDaycareNamesQuery, GetDaycareNamesQueryResult } from "@graphql/hocs/getDaycareNames";
import { DaycareKeypadLogin } from "@components/daycareKeypadLogin/daycareKeypadLogin";

export interface DaycareKeypadLoginContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: DaycareKeypadLoginContainerProps) => ({
    daycareLogin: state.main.daycareLogin,
    user: state.auth.user,
    selectedDaycareId: state.daycare.selectedDaycareId,
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withgetDaycare = createGetDaycareNamesQuery<WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organisationId) {
            throw new Error("No organisation ID found");
        }

        return {
            variables: {
                filter: {
                    id: ownProps.selectedDaycareId,
                    organisationId: ownProps.organisationId
                }
            }
        };
    },
    skip(ownProps) {
        return !ownProps.organisationId;
    }
});

const withLoginMutation = createLoginMutation();

export const DaycareKeypadLoginContainer = compose<React.ComponentClass<DaycareKeypadLoginContainerProps>>(
    withRedux,
    withRouter,
    withgetDaycare,
    withLoginMutation
)(DaycareKeypadLogin);

export type DaycareKeypadLoginProps =
    DaycareKeypadLoginContainerProps &
    WithReduxDispatchProps &
    RouteComponentProps &
    WithReduxStateProps &
    GetDaycareNamesQueryResult &
    LoginMutationResult;
