import styled from "styled-components";
import { Colors } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";
import { Padding } from "@style/padding";
import { Box } from "@style/box";

export const FaqPageStyle = styled.div`
    position: relative;

    .greenBlock {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${Colors.primary()};
        height: 100px;
        z-index: 0;
    }

    ${InnerContainer} {
        position: relative;
        z-index: 1;

        & > a {
            color: ${Colors.white};
            font-size: 16px;
            font-weight: 200;
            display: flex;
            align-items: center;
            margin-bottom: ${Padding.medium};
            margin-top: ${Padding.medium};

            &:hover {
                opacity: 0.6;
            }

            .icon {
                font-size: 10px;
                margin-right: 10px;
            }
        }

        ${Box} {
            padding: ${Padding.medium};

            h1 {
                font-size: 24px;
                font-weight: 200;
                margin-bottom: ${Padding.large};
            }

            .ant-collapse-header  {
                padding: 0 0 10px 0 !important;
            }

            .ant-collapse-content-box {
                padding: 0 !important;


                p:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }

            .ant-collapse-header {
                font-size: 18px;
                font-weight: 200;
                opacity: 0.5;
            }

            .ant-collapse-item-active {
                .ant-collapse-header {
                    color: ${Colors.primary()};
                    font-weight: 600;
                    opacity: 1;
                }
            }

            .ant-collapse-borderless > .ant-collapse-item {
                border: none;

                &:not(:last-of-type) {
                    margin-bottom: ${Padding.small};
                }
            }
        }
    }
`;
