const actionPrefix = "KENG";

export const ActionTypes = {
    Login: `${actionPrefix}/AUTH/LOGIN`,
    Logout: `${actionPrefix}/AUTH/LOGOUT`,
    SetRegistrationSelectedDate: `${actionPrefix}/SET_REGISTRATION_SELECTED_DATE`,
    UpdateRegistrationsFilter: `${actionPrefix}/UPDATE_REGISTRATIONS_FILTER`,
    SetRegistrationAggregation: `${actionPrefix}/SET_REGISTRATION_AGGREGATION`,
    UpdateDaycareFilter: `${actionPrefix}/UPDATE_DAYCARE_FILTER`,
    UpdateDaycareOverviewSearch: `${actionPrefix}/UPDATE_DAYCARE_OVERVIEW_SEARCH`,
    UpdateDaycareSelectedDaycareId: `${actionPrefix}/UPDATE_DAYCARE_SELECTED_DAYCARE_ID`,
    UpdateShowQrCode: `${actionPrefix}/UPDATE_SHOW_QR_CODE`,
    UpdateCreateRegistration: `${actionPrefix}/UPDATE_CREATE_REGISTRATION`,
    UpdateEditRegistrationId: `${actionPrefix}/UPDATE_EDIT_REGISTRATION_ID`,
    SetUser: `${actionPrefix}/SET_USER`,
    UpdateDaycareLogin: `${actionPrefix}/UPDATE_DAYCARE_LOGIN`,
    SetRegistrationFlowEntryType: `${actionPrefix}/SET_REGISTRATION_FLOW_ENTRY_TYPE`,
    SetRegistrationFlowRegistrationResult: `${actionPrefix}/SET_REGISTRATION_FLOW_REGISTRATION_RESULT`,
    SetRegistrationFlowCode: `${actionPrefix}/SET_REGISTRATION_FLOW_CODE`,
    SetRegistrationFlowChildNotFound: `${actionPrefix}/SET_REGISTRATION_FLOW_CHILD_NOT_FOUND`,
    SetOrganisationId: `${actionPrefix}/SET_ORGANISATION_ID`,
    SetRegistrationFlowManualEntry: `${actionPrefix}/SET_REGISTRATION_FLOW_MANUAL_ENTRY`
};
