import * as React from "react";
import { autobind } from "core-decorators";
import { UnauthorizedPageProps } from "@pages/unauthorizedPage/unauthorizedPageContainer";
import { UnauthorizedPageStyle } from "@pages/unauthorizedPage/unauthorizedPageStyle";
import { Button } from "@style/button";
import { FormattedMessage } from "react-intl";

export interface UnauthorizedPageState {

}

@autobind
export class UnauthorizedPage extends React.Component<UnauthorizedPageProps, UnauthorizedPageState> {

    public render() {

        return (
            <UnauthorizedPageStyle>
                <img src={require("@assets/images/error.svg")} alt="error" />
                <FormattedMessage tagName="p" id="unauthorizedPage.info" />
                <Button onClick={this.toHome} size="large" type="danger">Terug naar home</Button>
            </UnauthorizedPageStyle>

        );
    }

    private toHome() {
        const { history } = this.props;
        history.push("/");
    }
}
