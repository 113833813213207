import { DocumentNode } from "graphql";

export class GraphQL {
    public static login: DocumentNode = require("@graphql/documents/login.graphql");
    public static refreshLogin: DocumentNode = require("@graphql/documents/refreshLogin.graphql");

    public static getChildrenRegistrationList: DocumentNode = require("@graphql/documents/getChildrenRegistrationList.graphql");
    public static getChildrenNames: DocumentNode = require("@graphql/documents/getChildrenNames.graphql");
    public static getChildDetail: DocumentNode = require("@graphql/documents/getChildDetail.graphql");
    public static updateChild: DocumentNode = require("@graphql/documents/updateChild.graphql");
    public static addChild: DocumentNode = require("@graphql/documents/addChild.graphql");
    public static addChildToDaycare: DocumentNode = require("@graphql/documents/addChildToDaycare.graphql");
    public static sendQrCode: DocumentNode = require("@graphql/documents/sendQrCode.graphql");

    public static getDaycareList: DocumentNode = require("@graphql/documents/getDaycareList.graphql");
    public static getDaycareNames: DocumentNode = require("@graphql/documents/getDaycareNames.graphql");
    public static getDaycareProblems: DocumentNode = require("@graphql/documents/getDaycareProblems.graphql");

    public static getInactiveMonths: DocumentNode = require("@graphql/documents/getInactiveMonths.graphql");
    public static createInactiveMonth: DocumentNode = require("@graphql/documents/createInactiveMonth.graphql");
    public static deleteInactiveMonth: DocumentNode = require("@graphql/documents/deleteInactiveMonth.graphql");

    public static getUploadsList: DocumentNode = require("@graphql/documents/getUploadsList.graphql");
    public static getUploadDetail: DocumentNode = require("@graphql/documents/getUploadDetail.graphql");
    public static getFirstUpload: DocumentNode = require("@graphql/documents/getFirstUpload.graphql");

    public static getValidateDaycareCaseNumber: DocumentNode = require("@graphql/documents/getValidateDaycareCaseNumber.graphql");

    public static addEntry: DocumentNode = require("@graphql/documents/addEntry.graphql");
    public static deleteLastEntry: DocumentNode = require("@graphql/documents/deleteLastEntry.graphql");

    public static changePassword: DocumentNode = require("@graphql/documents/changePassword.graphql");
    public static resetPassword: DocumentNode = require("@graphql/documents/resetPassword.graphql");
    public static changePincode: DocumentNode = require("@graphql/documents/changePincode.graphql");
    public static setPassword: DocumentNode = require("@graphql/documents/setPassword.graphql");
    public static changeEmail: DocumentNode = require("@graphql/documents/changeEmail.graphql");
    public static logoutDaycare: DocumentNode = require("@graphql/documents/logoutDaycare.graphql");

    public static getRegistrations: DocumentNode = require("@graphql/documents/getRegistrations.graphql");
    public static updateRegistration: DocumentNode = require("@graphql/documents/updateRegistration.graphql");
    public static createRegistration: DocumentNode = require("@graphql/documents/createRegistration.graphql");

    public static updateUploadStatus: DocumentNode = require("@graphql/documents/updateUploadStatus.graphql");

}
