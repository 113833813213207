import { ActionTypes } from "@redux/actions/types";
import { Action } from "@redux/actions/action";

export interface SetRegistrationFlowManualEntryActionOptions {
    manualEntry: boolean;
}

export const setRegistrationFlowManualEntry = (data: SetRegistrationFlowManualEntryActionOptions): SetRegistrationFlowManualEntryAction => {
    return {
        type: ActionTypes.SetRegistrationFlowManualEntry,
        ...data
    };
};

export type SetRegistrationFlowManualEntryAction = Action & SetRegistrationFlowManualEntryActionOptions;
export const isSetRegistrationFlowManualEntryAction = (action: Action): action is SetRegistrationFlowManualEntryAction => action.type === ActionTypes.SetRegistrationFlowManualEntry;
