import "moment/locale/nl";

import { NormalizedCacheObject } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import * as React from "react";
import { ApolloProvider } from "react-apollo";
import { addLocaleData, IntlProvider } from "react-intl";
import * as nl from "react-intl/locale-data/nl";
import { Provider } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";

import "@assets/icons/app.font.js";
import { Role } from "@utils/user";
import { STORE } from "@redux/setupStore";
import { UpdateRegistrationPageContainer } from "@pages/updateRegistrationPage/updateRegistrationPageContainer";
import { SettingsPageContainer } from "@pages/settingsPage/settingsPageContainer";
import { RegistrationPageContainer } from "@pages/registrationPage/registrationPageContainer";
import { RegistrationCheckinPageContainer } from "@pages/registrationCheckinPage/registrationCheckinPageContainer";
import { ParentOverviewPageContainer } from "@pages/parentOverviewPage/parentOverviewPageContainer";
import { ManualChildEntryPageContainer } from "@pages/manualChildEntryPage/manualChildEntryPageContainer";
import { FaqPageContainer } from "@pages/faqPage/faqPageContainer";
import { DayCareOverviewPageContainer } from "@pages/daycareOverviewPage/daycareOverviewPageContainer";
import { DaycareDetailPageContainer } from "@pages/daycareDetailPage/daycareDetailPageContainer";
import { ChildDetailPageContainer } from "@pages/childDetailPage/childDetailPageContainer";
import { AddChildPageContainer } from "@pages/addChildPage/addChildPageContainer";
import { TokenHandlerContainer } from "@components/tokenHandler/tokenHandlerContainer";
import { RegistrationFormModalContainer } from "@components/registrationFormModal/registrationFormModalContainer";
import { RedirectRoute } from "@components/redirectRoute/redirectRoute";
import { PrivateRoute } from "@components/privateRoute/privateRoute";
import { PendingRegistrationsContainer } from "@components/pendingRegistrations/pendingRegistrationsContainer";
import { OAuthLoginHandler } from "@components/oAuthLoginHandler/oAuthLoginHandler";
import { LayoutContainer } from "@components/layout/layoutContainer";
import { IntroLayoutContainer } from "@components/introLayout/introLayoutContainer";
import { DaycareKeypadLoginContainer } from "@components/daycareKeypadLogin/daycareKeypadLoginContainer";
import { ManualSyncFormContainer } from "@components/manualSyncForm/manualSyncFormContainer";

const messages = require("./translations/nl.json");

addLocaleData([...nl]);
export interface AppProps {
    client: ApolloClient<NormalizedCacheObject>;
}

export const App: React.StatelessComponent<AppProps> = (props: AppProps) => {
    const { client } = props;

    const isLoginCallback = window.location.pathname.includes("oauth");

    if (!isLoginCallback) {
        /* Because the switch to hashrouter, some people may still be on the subpath system. This prevents that. Crude but it works. */
        if (window.location.pathname !== "/") {
            window.location.replace("/");
        }
    }

    return (
        // <QueueLinkProvider>
        <Provider store={STORE}>
            <ApolloProvider client={client}>
                <TokenHandlerContainer>
                    <IntlProvider messages={messages} locale="nl-BE">
                        {isLoginCallback ?
                            <OAuthLoginHandler />
                            :
                            <HashRouter>
                                <Switch>
                                    <Route path="/registration" render={() => {
                                        return <>
                                            <PrivateRoute exact needsDaycareId requiredRoles={[Role.DAYCARE, Role.ORGANISER]} path="/registration" component={RegistrationPageContainer} />
                                            <PrivateRoute exact needsCode needsDaycareId requiredRoles={[Role.DAYCARE, Role.ORGANISER]} path="/registration/update" component={UpdateRegistrationPageContainer} />
                                            <PrivateRoute exact needsCode needsDaycareId requiredRoles={[Role.DAYCARE, Role.ORGANISER]} path="/registration/manual-child-entry" component={ManualChildEntryPageContainer} />
                                            <PrivateRoute exact needsDaycareId requiredRoles={[Role.DAYCARE, Role.ORGANISER]} path="/registration/check:type" component={RegistrationCheckinPageContainer} />
                                            <PendingRegistrationsContainer hide />
                                        </>;
                                    }} />

                                    <Route path="/daycare-login" component={DaycareKeypadLoginContainer} />
                                    <Route path="/login" component={IntroLayoutContainer} />

                                    <PrivateRoute requiredRoles={[Role.MANUAL_SYNC]} path="/manual-sync" component={ManualSyncFormContainer} />

                                    <PrivateRoute needsCode render={() =>
                                        <LayoutContainer>
                                            <RegistrationFormModalContainer />
                                            <PendingRegistrationsContainer />
                                            <Switch>
                                                <PrivateRoute requiredRoles={[Role.ORGANISER]} exact path="/daycare-overview" component={DayCareOverviewPageContainer} />
                                                <PrivateRoute requiredRoles={[Role.ORGANISER, Role.PARENT]} exact path="/settings" component={SettingsPageContainer} />
                                                <PrivateRoute needsDaycareId requiredRoles={[Role.DAYCARE, Role.ORGANISER]} path="/child/:childId" component={ChildDetailPageContainer} />
                                                <PrivateRoute needsDaycareId requiredRoles={[Role.DAYCARE, Role.ORGANISER]} path="/daycare" component={DaycareDetailPageContainer} />
                                                <PrivateRoute needsDaycareId requiredRoles={[Role.DAYCARE, Role.ORGANISER]} path="/add-child" component={AddChildPageContainer} />
                                                <PrivateRoute requiredRoles={[Role.DAYCARE, Role.ORGANISER]} path="/faq" component={FaqPageContainer} />
                                                <PrivateRoute requiredRoles={[Role.PARENT]} path="/parent-overview" component={ParentOverviewPageContainer} />
                                                <RedirectRoute from="/*" parentRedirect="/parent-overview" daycareRedirect="/daycare" organiserRedirect="/daycare" adminRedirect="/manual-sync" />
                                            </Switch>
                                        </LayoutContainer>
                                    } />
                                </Switch>
                            </HashRouter>
                        }
                    </IntlProvider>
                </TokenHandlerContainer>
            </ApolloProvider>
        </Provider>
        // </QueueLinkProvider>
    );
};
