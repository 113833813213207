import styled from "styled-components";
import { Padding } from "@style/padding";
import { Box } from "@style/box";
import { Button } from "@style/button";
import { Colors } from "@style/colors";

export const ChildrenOverviewStyle = styled.div`
    margin-top: ${Padding.large};
    margin-bottom: ${Padding.large};

    ${Box} {
        padding: ${Padding.medium};
    }

    h1 {
        font-size: 20px;
        font-weight: 200;

    }

    .bar {
        display: flex;
        justify-content: space-between;
        margin-bottom: ${Padding.large};
        align-items: center;
        ${Button} {
            width: 175px;
        }
    }

    .ant-input {
        border:none;
        border-bottom: 1px solid ${Colors.grey};
        border-radius: 0;
        padding-left: 0;

        &:focus {
            box-shadow: none;
        }
    }
`;
