import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface UpdateShowQrCodeActionOptions {
    qrCode: string | null;
}

export const updateShowQrCode = (data: UpdateShowQrCodeActionOptions): UpdateShowQrCodeAction => {
    return {
        type: ActionTypes.UpdateShowQrCode,
        ...data
    };
};

export type UpdateShowQrCodeAction = Action & UpdateShowQrCodeActionOptions;
export const isUpdateShowQrCodeAction = (action: Action): action is UpdateShowQrCodeAction => action.type === ActionTypes.UpdateShowQrCode;
