import { autobind } from "core-decorators";
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Role } from "@utils/user";
import { StoreState } from "@redux/reducers/root";

export interface RoleComponentContainerProps {
    requiredRoles: Role[];
}

@autobind
export class RoleComponentDumb extends React.Component<RoleComponentProps> {

    public render() {

        const { roles, children, requiredRoles } = this.props;

        if (roles && roles.some(role => requiredRoles.some(reqRole => role === reqRole))) {
            return children;
        }

        return null;
    }
}

export const mapStateToProps = (state: StoreState, ownProps: RoleComponentContainerProps) => ({
    roles: state.auth.user ? state.auth.user.roles : null
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

const withRedux = connect(mapStateToProps);

export const RoleComponent = compose<React.ComponentClass<RoleComponentContainerProps>>(
    withRedux
)(RoleComponentDumb);

export type RoleComponentProps =
    RoleComponentContainerProps &
    WithReduxStateProps;
