declare const KEYCLOAK_REALM: string;
declare const SENTRY_DSN: string;
declare const BUILD_NUMBER: string;
declare const RELEASE: string;

const _KEYCLOAK_REALM = KEYCLOAK_REALM;
const _SENTRY_DSN = SENTRY_DSN;
const _BUILD_NUMBER = BUILD_NUMBER;
const _RELEASE = RELEASE;

export { _KEYCLOAK_REALM as KEYCLOAK_REALM };
export { _SENTRY_DSN as SENTRY_DSN };
export { _BUILD_NUMBER as BUILD_NUMBER };
export { _RELEASE as RELEASE };
