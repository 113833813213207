import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { DaycareList } from "@components/daycare/daycareList/daycareList";
import { createGetDaycareListQuery, GetDaycareListQueryResult } from "@graphql/hocs/getDaycareList";

export interface DaycareListContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: DaycareListContainerProps) => ({
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetDaycareList = createGetDaycareListQuery<WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organisationId) {
            throw new Error("No organisation ID found");
        }

        return {
            variables: {
                filter: {
                    organisationId: ownProps.organisationId
                }
            }
        };
    }

});

export const DaycareListContainer = compose<React.ComponentClass<DaycareListContainerProps>>(
    withRedux,
    withGetDaycareList
)(DaycareList);

export type DaycareListProps =
    DaycareListContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    GetDaycareListQueryResult;
