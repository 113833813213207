import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { createLocalState, LocalStateProps } from "@utils/withLocalState";
import { StoreState } from "@redux/reducers/root";
import { updateEditRegistration, UpdateEditRegistrationActionOptions } from "@redux/actions/updateEditRegistrationId";
import { updateCreateRegistration, UpdateCreateRegistrationActionOptions } from "@redux/actions/updateCreateRegistration";
import { Action } from "@redux/actions/action";
import { UpdateRegistrationUpdateRegistration } from "@models/graphql/types";
import { createUpdateRegistrationMutation, UpdateRegistrationMutationResult } from "@graphql/hocs/updateRegistration";
import { createGetChildrenNamesQuery, GetChildrenNamesQueryResult } from "@graphql/hocs/getChildrenNames";
import { createGetChildDetailQuery, GetChildDetailQueryResult } from "@graphql/hocs/getChildDetail";
import { createCreateRegistrationMutation, CreateRegistrationMutationResult } from "@graphql/hocs/createRegistration";
import { RegistrationForm } from "@components/registrationForm/registrationForm";

export interface RegistrationFormContainerProps {
    closeCallback?(): void;
    successCallback(result?: UpdateRegistrationUpdateRegistration | null): void;
}

interface LocalState {
    childSearchName?: string;
}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationFormContainerProps) => ({
    editRegistrationState: state.registration.editRegistration,
    createRegistrationState: state.registration.createRegistration,
    roles: state.auth.user && state.auth.user.roles
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    updateEditRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); },
    updateCreateRegistration(data: UpdateCreateRegistrationActionOptions) { dispatch(updateCreateRegistration(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

const withGetChildDetail = createGetChildDetailQuery<RegistrationFormContainerProps & WithReduxStateProps>({
    options(ownProps) {
        const { editRegistrationState, createRegistrationState } = ownProps;

        if (!editRegistrationState && !createRegistrationState) {
            throw new Error("Should've skipped");
        }

        let childId: number | undefined;

        if (createRegistrationState) {
            childId = createRegistrationState.childId;
        }
        if (editRegistrationState) {
            childId = editRegistrationState.childId;
        }

        if (!childId) {
            throw new Error("Should've skipped");
        }

        return {
            variables: {
                filter: {
                    childIds: [childId]
                }
            }
        };
    },
    skip(ownProps) {

        if (ownProps.editRegistrationState || ownProps.createRegistrationState) {
            return false;
        }

        return true;
    }
});

const withGetChildren = createGetChildrenNamesQuery<RegistrationFormContainerProps & WithReduxStateProps & LocalState>({
    options(ownProps) {
        return {
            variables: {
                filter: {
                    search: ownProps.childSearchName
                }
            }
        };
    },
    skip(ownProps) {

        if (ownProps.childSearchName && ownProps.childSearchName !== "") {
            return false;
        }

        return true;
    }
});

const withLocalState = createLocalState<LocalState>({});

const withUpdateRegistrationMutation = createUpdateRegistrationMutation({ awaitRefetchQueries: true });
const withCreateRegistrationMutation = createCreateRegistrationMutation({ awaitRefetchQueries: true });

export const RegistrationFormContainer = compose<React.ComponentClass<RegistrationFormContainerProps>>(
    withRedux,
    withForm,
    withLocalState,
    injectIntl,
    withGetChildDetail,
    withGetChildren,
    withUpdateRegistrationMutation,
    withCreateRegistrationMutation
)(RegistrationForm);

export type RegistrationFormProps =
    RegistrationFormContainerProps &
    WithReduxDispatchProps &
    FormComponentProps &
    InjectedIntlProps &
    GetChildrenNamesQueryResult &
    LocalStateProps<LocalState> &
    GetChildDetailQueryResult &
    WithReduxStateProps &
    UpdateRegistrationMutationResult &
    CreateRegistrationMutationResult;
