import { Form, Input, message, Modal, Checkbox } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { parseError } from "@utils/parseError";
import { Button } from "@style/button";
import { ChangePinCodePageStyle } from "@pages/changePinCodePage/changePinCodePageStyle";
import { ChangePinCodePageProps } from "@pages/changePinCodePage/changePinCodePageContainer";
import { Alert } from "@components/alert/alert";

export interface ChangePinCodePageState {
    submitting: boolean;
    submitError: string | null;
}

@autobind
export class ChangePinCodePage extends React.Component<ChangePinCodePageProps, ChangePinCodePageState> {
    public state: ChangePinCodePageState = {
        submitting: false,
        submitError: null
    };

    public render() {

        const { daycareName, intl, form, open, pincodeSet } = this.props;

        const { submitting, submitError } = this.state;

        return (
            <Modal onCancel={this.close} footer={null} title={intl.formatMessage({ id: "changePinCode.title" }, { daycareName })} visible={open}>
                <ChangePinCodePageStyle>
                    {!pincodeSet && <Alert type="info"><FormattedMessage id="changePincode.noPincodeSetYet" /></Alert>}
                    <Form onSubmit={this.onSubmit}>
                        <Form.Item label={intl.formatMessage({ id: "changePinCode.pincodeLabel" })}>
                            {form.getFieldDecorator("pincode", {
                                validateTrigger: "onSubmit",
                                rules: [
                                    { required: true, pattern: /^\d{4,6}$/, message: intl.formatMessage({ id: "changePincode.pincodeError" }) },
                                    { validator: this.validatePincode }
                                ]
                            })(
                                <Input type="password" placeholder={intl.formatMessage({ id: "changePinCode.pincodePlaceholder" })} />
                            )}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage({ id: "changePinCode.pincodeLabelRepeat" })}>
                            {form.getFieldDecorator("pincodeRepeat", {
                                validateTrigger: "onSubmit",
                                rules: [
                                    { pattern: /^\d{4,6}$/, message: intl.formatMessage({ id: "changePincode.pincodeError" }) },
                                    { validator: this.validatePincode }
                                ]
                            })(
                                <Input type="password" placeholder={intl.formatMessage({ id: "changePinCode.pincodePlaceholder" })} />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {form.getFieldDecorator("logoutUsers", {})(
                                <Checkbox><FormattedMessage id="changePincode.logoutUsers" /></Checkbox>
                            )}
                        </Form.Item>
                        {submitError && <Alert type="error"><FormattedMessage id={submitError} /></Alert>}
                        <div className="buttons">
                            <Button onClick={this.close}>Annuleer</Button>
                            <Button loading={submitting} htmlType="submit" onClick={this.onSubmit} type="primary">Wijzig</Button>
                        </div>
                    </Form>
                </ChangePinCodePageStyle>
            </Modal>
        );
    }

    private validatePincode(rule: any, value: any, callback: (err?: string) => void) {
        const { form } = this.props;
        const pinCode = form.getFieldValue("pincode");
        const pinCodeRepeat = form.getFieldValue("pincodeRepeat");

        if (pinCode !== pinCodeRepeat) {
            callback("De pincodes komen niet overeen");
        } else {
            callback();
        }

    }

    private close() {
        const { form, closeCallback } = this.props;
        this.setState({
            submitError: null,
            submitting: false
        });

        form.resetFields();
        closeCallback();
    }

    private onSubmit(e: React.FormEvent | React.MouseEvent) {
        e.preventDefault();

        const { form, intl, daycareName, caseNumber } = this.props;

        form.validateFields({ force: true }, async (errors, values) => {
            if (!errors) {
                try {
                    this.setState({
                        submitError: null,
                        submitting: true
                    });

                    await this.props.changePincode({
                        newPincode: values.pincode
                    });

                    if (values.logoutUsers) {
                        await this.props.logoutDaycare({
                            caseNumber
                        });
                    }

                    message.success(intl.formatMessage({ id: "changePincode.success" }, { daycareName }));
                    this.close();

                } catch (err) {
                    this.setState({
                        submitError: parseError(err),
                        submitting: false
                    });
                }
            }
        });
    }
}
