import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { GetChildDetailContacts } from "@models/graphql/types";
import { createUpdateChildMutation, UpdateChildMutationResult } from "@graphql/hocs/updateChild";
import { EditContactsModal } from "@components/editContactsModal/editContactsModal";

export interface EditContactsModalContainerProps {
    contacts: GetChildDetailContacts[];
    childId: number;
    visible: boolean;
    hideCallback(): void;
}

export const mapStateToProps = (state: StoreState, ownProps: EditContactsModalContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

const withUpdateChildMutation = createUpdateChildMutation({ awaitRefetchQueries: true });

export const EditContactsModalContainer = compose<React.ComponentClass<EditContactsModalContainerProps>>(
    withRedux,
    withForm,
    injectIntl,
    withUpdateChildMutation
)(EditContactsModal);

export type EditContactsModalProps =
    EditContactsModalContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    FormComponentProps &
    InjectedIntlProps &
    UpdateChildMutationResult;
