import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";
import { EntryType } from "@models/graphql/types";

export interface SetRegistrationFlowEntryTypeActionOptions {
    entryType: EntryType;
}

export const setRegistrationFlowEntryType = (data: SetRegistrationFlowEntryTypeActionOptions): SetRegistrationFlowEntryTypeAction => {
    return {
        type: ActionTypes.SetRegistrationFlowEntryType,
        ...data
    };
};

export type SetRegistrationFlowEntryTypeAction = Action & SetRegistrationFlowEntryTypeActionOptions;
export const isSetRegistrationFlowEntryTypeAction = (action: Action): action is SetRegistrationFlowEntryTypeAction => action.type === ActionTypes.SetRegistrationFlowEntryType;
