import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose, Dispatch } from "redux";

import { createLocalState, LocalStateProps } from "@utils/withLocalState";
import { createGetValidateDaycareCaseNumberQuery, GetValidateDaycareCaseNumberQueryResult } from "@graphql/hocs/getValidateDaycareCaseNumber";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { ManualSyncForm } from "./manualSyncForm";
import { createUpdateUploadStatusMutation, UpdateUploadStatusMutationResult } from "@graphql/hocs/updateUploadStatus";

export interface ManualSyncFormContainerProps {

}

interface LocalState {
    caseNumberSearch: string;
}

export const mapStateToProps = (state: StoreState, ownProps: ManualSyncFormContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    // updateDaycareLogin(data: UpdateDaycareLoginActionOptions) { dispatch(updateDaycareLogin(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withValidateDaycareCaseNumber = createGetValidateDaycareCaseNumberQuery<ManualSyncFormContainerProps & LocalStateProps<LocalState>>({
    options(ownProps) {

        return {
            variables: {
                caseNumber: ownProps.caseNumberSearch
            }
        };
    },
    skip(ownProps) {
        if (ownProps.caseNumberSearch === "") {
            return true;
        }

        return false;
    }
});

const withUpdateUploadStatusMutation = createUpdateUploadStatusMutation({ awaitRefetchQueries: true });

const withLocalState = createLocalState<LocalState>({ caseNumberSearch: "" });

export const ManualSyncFormContainer = compose<React.ComponentClass<ManualSyncFormContainerProps>>(
    withRedux,
    withRouter,
    injectIntl,
    withLocalState,
    withValidateDaycareCaseNumber,
    withUpdateUploadStatusMutation
)(ManualSyncForm);

export type ManualSyncFormProps =
    LocalStateProps<LocalState> &
    InjectedIntlProps &
    ManualSyncFormContainerProps &
    GetValidateDaycareCaseNumberQueryResult &
    UpdateUploadStatusMutationResult &
    WithReduxDispatchProps &
    RouteComponentProps &
    WithReduxStateProps;
