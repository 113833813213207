import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { withQueueLinkState } from "@graphql/withQueueLinkState";
import { QueueLinkState } from "@graphql/queueLinkProvider";
import {
    createGetChildrenRegistrationListQuery,
    GetChildrenRegistrationListQueryResult
} from "@graphql/hocs/getChildrenRegistrationList";
import { RegistrationTable } from "@components/registrations/registrationTable/registrationTable";

export interface RegistrationTableContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationTableContainerProps) => ({
    ...state.registration
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetChildrenRegistrationList = createGetChildrenRegistrationListQuery<RegistrationTableContainerProps & WithReduxStateProps>({
    options(ownProps) {
        const { aggregation, childrenFilter } = ownProps;

        return {
            variables: {
                aggregation,
                filter: {
                    ...childrenFilter,
                    isActive: true
                }
            }
        };
    }
});

export const RegistrationTableContainer = compose<React.ComponentClass<RegistrationTableContainerProps>>(
    withRedux,
    withQueueLinkState,
    withGetChildrenRegistrationList
)(RegistrationTable);

export type RegistrationTableProps =
    RegistrationTableContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    GetChildrenRegistrationListQueryResult &
    QueueLinkState;
