import { Reducer } from "redux";

import { isUpdateShowQrCodeAction } from "@redux/actions/updateShowQrCode";
import { isUpdateDaycareSelectedDaycareIdAction } from "@redux/actions/updateDaycareSelectedDaycareId";
import { isUpdateDaycareOverviewSearchAction } from "@redux/actions/updateDaycareOverviewSearch";
import { isUpdateDaycareFilterAction } from "@redux/actions/updateDaycareFilter";
import { Action } from "@redux/actions/action";
import { DaycareFilterInput } from "@models/graphql/types";
import { queueLink } from "@graphql/client";

const INITIAL_STATE: DaycareReducerState = {
    daycareFilter: {

    },
    overviewSearch: null,
    selectedDaycareId: localStorage.getItem("selectedDaycareId"),
    showQrCode: null
};

export interface DaycareReducerState {
    selectedDaycareId: string | null;
    overviewSearch: string | null;
    daycareFilter: Partial<DaycareFilterInput>;
    showQrCode: string | null;
}

export const daycareReducer: Reducer<DaycareReducerState> = (state: DaycareReducerState = INITIAL_STATE, action: Action) => {

    if (isUpdateDaycareFilterAction(action)) {
        return {
            ...state,
            daycareFilter: {
                ...state.daycareFilter,
                ...action.filter
            }
        };
    }

    if (isUpdateDaycareOverviewSearchAction(action)) {
        return {
            ...state,
            overviewSearch: action.search
        };
    }

    if (isUpdateShowQrCodeAction(action)) {
        return {
            ...state,
            showQrCode: action.qrCode
        };
    }

    if (isUpdateDaycareSelectedDaycareIdAction(action)) {
        // const reset = client.clearStore();
        localStorage.setItem("selectedDaycareId", action.daycareId);
        queueLink.reInitialize();

        return {
            ...state,
            selectedDaycareId: action.daycareId
        };
    }

    return state;
};
