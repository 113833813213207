import styled from "styled-components";
import { Colors } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";
import { Box } from "@style/box";
import { Padding } from "@style/padding";
import { QrCodeReaderStyle } from "@components/qrCodeReader/qrCodeReaderStyle";
import { Button } from "@style/button";

export const Progress = styled.div`
    position: absolute;
    height: 7px;
    background: #DBDBDB;
    bottom: 0;
    left: 0;
    right: 0;

    &::before {
        position: absolute;
        transition: width 0.4s;
        content: "";
        height: 7px;
        background: ${Colors.primary()};
        bottom: 0;
        left: 0;
        width: ${(props: ProgressProps) => props.progress}%;
    }
`;

export const AddChildPageStyle = styled.div`
    position: relative;

    .greenBlock {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${Colors.primary()};
        height: 300px;
        z-index: 0;
    }

    .bar {
        display: flex;
        justify-content: space-between;

        padding: ${Padding.medium} 0;

        h1 {
            font-size: 24px;
            font-weight: 200;
        }

        * {
            color: ${Colors.white};
        }
    }

    .confirmation {
        width: 100%;
        min-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            margin-bottom: 40px;
            width: 64px;
        }

        h1 {
            font-size: 45px;
            color: ${Colors.primary()};
            font-weight: 200;
            margin-bottom: ${Padding.medium};
        }

        h2 {
            font-size: 20px;
            font-weight: 200;
            margin-bottom: ${Padding.large};
        }

        .anticon {
            font-size: 40px;
            color: ${Colors.primary()};
        }

        ${Button} {
            width: 200px;
            height: 50px;
        }
    }


    & > ${InnerContainer} {
        z-index: 1;
        position: relative;
    }

    ${Box} {

        position: relative;
        margin-bottom: ${Padding.large};
    }

    ${QrCodeReaderStyle} {
        width: 100%;
        height: 700px;

        a.back {
            display:none;
        }
    }

    &.hasError {
        ${Progress} {
            &::before {
                background-color: ${Colors.error()};
            }
        }

        .confirmation {
            h1 {
                color: ${Colors.error()};
            }

            .ant-btn-danger {
                background-color: ${Colors.white};
                color: ${Colors.error()};

                &:hover {
                    background-color: ${Colors.error(0.05)};
                }
            }
        }
    }
`;

interface ProgressProps {
    progress: number;
}
