import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface UpdateDaycareSelectedDaycareIdActionOptions {
    daycareId: string;
}

export const updateDaycareSelectedDaycareId = (data: UpdateDaycareSelectedDaycareIdActionOptions): UpdateDaycareSelectedDaycareIdAction => {
    return {
        type: ActionTypes.UpdateDaycareSelectedDaycareId,
        ...data
    };
};

export type UpdateDaycareSelectedDaycareIdAction = Action & UpdateDaycareSelectedDaycareIdActionOptions;
export const isUpdateDaycareSelectedDaycareIdAction = (action: Action): action is UpdateDaycareSelectedDaycareIdAction => action.type === ActionTypes.UpdateDaycareSelectedDaycareId;
