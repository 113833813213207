import styled from "styled-components";

import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";
import { Box } from "@style/box";
import { QrCodeViewerStyle } from "@components/qrCodeViewer/qrCodeViewerStyle";
import { ChildPrintViewStyle } from "@components/childPrintView/childPrintViewStyle";

export const ParentChildListStyle = styled(InnerContainer)`
    .spacer {
        display: none;
        flex-shrink: 0;
    }

    @media (max-width: 850px) {
        .spacer {
            display:block;
            width: 25px;
        }
    }

    ${ChildPrintViewStyle} {
        display: none;
    }

    ${Box} {
        padding: ${Padding.medium};
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 325px;
        flex-shrink: 0;

        &:not(.active) {
            /* opacity: 0.5; */
            color: #ddd;

            ${QrCodeViewerStyle} {
                display: none;
            }
        }

        &:not(:last-of-type) {
            margin-bottom: ${Padding.medium};
        }

        .name {
            display: block;
            font-weight: 600;
            margin-bottom: ${Padding.medium};
        }

        .info {
            margin-bottom: 8px;
            display: block;
        }

        .left {
            flex: 1;
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            & > div {
                margin-bottom: 10px;
            }
        }

        ${QrCodeViewerStyle} {
            background: none;
            justify-content: flex-end;
            flex: initial;

            span {
                display: none;
            }
            svg {
                width: 60px;
            }
        }
    }
`;
