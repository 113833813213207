import { message } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";

import { parseError } from "@utils/parseError";
import { Button } from "@style/button";
import { CancelRegistrationButtonStyle } from "@components/cancelRegistrationButton/cancelRegistrationButtonStyle";
import { CancelRegistrationButtonProps } from "@components/cancelRegistrationButton/cancelRegistrationButtonContainer";

export interface CancelRegistrationButtonState {
    submitting: boolean;
}

@autobind
export class CancelRegistrationButton extends React.Component<CancelRegistrationButtonProps, CancelRegistrationButtonState> {
    public state: CancelRegistrationButtonState = {
        submitting: false
    };

    public render() {

        const { submitting } = this.state;

        return (
            <CancelRegistrationButtonStyle>
                <Button onClick={this.deleteEntry} loading={submitting} type="danger">Annuleer registratie</Button>
            </CancelRegistrationButtonStyle>
        );
    }

    private async deleteEntry() {
        const { code, deleteLastEntry, history, intl, endDeleteCallback, initDeleteCallback } = this.props;

        if (!code) {
            return;
        }

        try {
            this.setState({ submitting: true });

            if (initDeleteCallback) {
                initDeleteCallback();
            }

            await deleteLastEntry({
                childCode: code,
                time: moment().toISOString()
            });

            this.setState({ submitting: false });
            history.push("/registration");
            message.success("Registratie is succesvol geannuleerd");
            if (endDeleteCallback) {
                endDeleteCallback();
            }
        } catch (err) {
            console.log(err);
            if (endDeleteCallback) {
                endDeleteCallback();
            }

            message.error(intl.formatMessage({ id: parseError(err) }));
        }
    }
}
