import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";

export const CodeNumberInputStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    color: ${Colors.white};


    h1, h2 {
        color: ${Colors.white};
    }

    h1 {
        font-size: 45px;
        font-weight: 200;
        margin-bottom: ${Padding.large};
    }

    .invalid {
        background-color: ${Colors.error()};
        padding: 10px;
        border-radius: 2px;
        margin-top: ${Padding.medium};
    }

    h2 {
        font-size: 16px;
        font-weight: 200;
        margin-bottom: ${Padding.large};
    }

    .invalid + .keypad {
        margin-top: 0;
    }

    span.back {
        align-items: center;
        color: white;

        align-self: flex-start;
        margin-top: ${Padding.small};
        font-size: 20px;
        display: flex;
        z-index: 10;
        cursor: pointer;

        .icon {
            font-size: 10px;
            margin-right: ${Padding.small};
        }
    }

    .keypad {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 350px;
        margin-top: ${Padding.large};
        position: relative;

        &.submitting {
            span {
                opacity: 0.4;
                pointer-events: none;
            }
        }

        .anticon {
            position: absolute;
            left: calc(50% - 40px);
            top: calc(50% - 40px);
            font-size: 40px;
            padding: 20px;
            background-color: rgba(0,0,0,.2);
            display: block;
            z-index: 10;
            border-radius: 4px;
        }

        span {
            width: calc(33% - 20px);
            font-size: 40px;
            color: white;
            display: flex;
            height: 80px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 10px;
            border-radius: 4px;
            &:active {
                background-color: rgba(255,255,255,0.3);
            }

        }

        .icon {
            font-size: 20px;
        }
    }


    @media (max-height: 700px) {
        .keypad {
            margin-top: 10px;
            span {
                height: 60px;
                font-size: 32px;
            }
        }

        h1 {
            margin-bottom: 20px;
            font-size: 36px;
        }
    }

    @media (max-width: 400px) {
        h1 {
            font-size: 20px;
        }

        .keypad {
            span {
                height: 50px;
            }
        }
    }




`;
