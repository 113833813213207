import { Popconfirm, Spin } from "antd";
import * as classNames from "classnames";
import { autobind } from "core-decorators";
import * as React from "react";

import { AuthService } from "@utils/authService";
import { Logo } from "@style/logo";
import { InnerContainer } from "@style/innerContainer";
import { DaycareKeypadLoginStyle } from "@components/daycareKeypadLogin/daycareKeypadLoginStyle";
import { DaycareKeypadLoginProps } from "@components/daycareKeypadLogin/daycareKeypadLoginContainer";
import { CodeNumberInputContainer } from "@components/codeNumberInput/codeNumberInputContainer";

export interface DaycareKeypadLoginState {
    online: boolean;
}

@autobind
export class DaycareKeypadLogin extends React.Component<DaycareKeypadLoginProps, DaycareKeypadLoginState> {
    public state: DaycareKeypadLoginState = {
        online: navigator.onLine
    };

    public render() {
        const { daycaresLoading } = this.props;
        const { online } = this.state;

        return (
            <DaycareKeypadLoginStyle className={classNames({ online })}>
                <InnerContainer>
                    {!online && <div className="offlineCover">
                        <h1>Je bent offline</h1>
                        <p>Om in te loggen moet je verbonden zijn met het internet.</p>
                    </div>}
                    <Logo size="small" />
                    <Spin spinning={daycaresLoading === true}>
                        <CodeNumberInputContainer backButton hideCode title="daycareKeypadLogin.title" submitCallback={this.onSubmit} />
                    </Spin>
                    <Popconfirm onConfirm={AuthService.logout} placement="leftBottom" title="Ben je zeker dat je wil uitloggen?">
                        <div className="logout">
                            Logout
                        </div>
                    </Popconfirm>
                </InnerContainer>
            </DaycareKeypadLoginStyle>

        );
    }

    public componentWillMount() {
        const { daycareLogin, history, user } = this.props;
        if (!daycareLogin && !user) {
            history.push("/");

            return false;
        }
    }

    public componentDidMount() {
        window.addEventListener("offline", this.setOffline);
        window.addEventListener("online", this.setOnline);
    }

    public componentWillUnmount() {
        window.removeEventListener("offline", this.setOffline);
        window.removeEventListener("online", this.setOnline);
    }

    private setOffline() {
        this.setState({ online: false });
    }

    private setOnline() {
        this.setState({ online: true });

        if (!this.props.daycares || !this.props.daycares.length) {
            this.props.refetch();
        }
    }

    private async onSubmit(loginCode: string): Promise<{ valid: boolean; errorMessage?: string }> {
        const { daycareLogin, history, user, daycares, login } = this.props;

        if (!daycareLogin && !user) {
            history.push("/");

            return {
                valid: false
            };
        }

        try {
            let username = daycareLogin ? daycareLogin.caseNumber : user ? user.username : "";

            if (daycares && daycares.length) {
                username = daycares[0].caseNumber;
            }

            const loginResult = await login({
                username,
                password: loginCode
            });

            const authUser = AuthService.login(loginResult);

            if (authUser) {
                AuthService.isAuthorized = true;
                history.push(daycareLogin ? daycareLogin.redirectPath : "/");

                return {
                    valid: true
                };
            }

            return {
                valid: false
            };
        } catch (err) {
            if (err.graphQLErrors && err.graphQLErrors[0]) {
                const { message } = err.graphQLErrors[0];

                if (message === "error.login.locked") {
                    return {
                        valid: false,
                        errorMessage: message
                    };
                }
            }

            return {
                valid: false
            };
        }
    }
}
