import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { ParentOverviewPage } from "@pages/parentOverviewPage/parentOverviewPage";
import { createGetChildrenNamesQuery, GetChildrenNamesQueryResult } from "@graphql/hocs/getChildrenNames";

export interface ParentOverviewPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: ParentOverviewPageContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetChildrenNamesQuery = createGetChildrenNamesQuery();

export const ParentOverviewPageContainer = compose<React.ComponentClass<ParentOverviewPageContainerProps>>(
    withRedux,
    withGetChildrenNamesQuery
)(ParentOverviewPage);

export type ParentOverviewPageProps =
    ParentOverviewPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    GetChildrenNamesQueryResult;
