import styled from "styled-components";

import { Colors } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";
import { Padding } from "@style/padding";

export const RegistrationSearchBarStyle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    input {
        display: block;
        border: none;
        width: calc(100% - 30px);
        color: ${Colors.text};

        ::placeholder {
            opacity: .5;
        }
        outline: none;
    }

    .remove-btn {
        cursor: pointer;
        color: rgba(255,96,96,1);
        opacity: 0.5;

        :hover {
            opacity: 1;
        }
    }

    @media (max-width: 900px) {
        background-color: ${Colors.white};
        height: 45px;
        border-radius: 4px;
        border: 1px solid ${Colors.grey};
        padding: 0 0 0 12px;
        margin-top: ${Padding.medium};
    }

    @media (max-width: 650px) {
        margin-top: ${Padding.small};
    }



`;
