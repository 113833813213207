import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { updateEditRegistration, UpdateEditRegistrationActionOptions } from "@redux/actions/updateEditRegistrationId";
import { updateCreateRegistration, UpdateCreateRegistrationActionOptions } from "@redux/actions/updateCreateRegistration";
import {
    setRegistrationAggregation,
    SetRegistrationAggregationActionOptions
} from "@redux/actions/setRegistrationAggregation";
import { Action } from "@redux/actions/action";
import { GetChildrenRegistrationListChildren } from "@models/graphql/types";
import { createGetDaycareNamesQuery, GetDaycareNamesQueryResult } from "@graphql/hocs/getDaycareNames";
import { RegistrationItem } from "@components/registrations/registrationItem/registrationItem";

import {
    setRegistrationSelectedDate,
    SetRegistrationSelectedDateActionOptions
} from "../../../redux/actions/setRegistrationSelectedDate";
import { StoreState } from "../../../redux/reducers/root";

export interface RegistrationItemContainerProps {
    child: GetChildrenRegistrationListChildren;
}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationItemContainerProps) => {
    return {
        startDate: state.registration.startDate.clone(),
        endDate: state.registration.endDate.clone(),
        aggregation: state.registration.aggregation,
        selectedDaycareId: state.daycare.selectedDaycareId,
        organisationId: state.auth.organisationId
    };
};

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setDate(data: SetRegistrationSelectedDateActionOptions) { dispatch(setRegistrationSelectedDate(data)); },
    setAggregation(data: SetRegistrationAggregationActionOptions) { dispatch(setRegistrationAggregation(data)); },
    editRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); },
    createRegistration(data: UpdateCreateRegistrationActionOptions) { dispatch(updateCreateRegistration(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withgetDaycare = createGetDaycareNamesQuery<WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organisationId) {
            throw new Error("No organisation ID found");
        }

        return {
            variables: {
                filter: {
                    id: ownProps.selectedDaycareId,
                    organisationId: ownProps.organisationId
                }
            }
        };
    }
});

export const RegistrationItemContainer = compose<React.ComponentClass<RegistrationItemContainerProps>>(
    withRedux,
    withgetDaycare,
    injectIntl,
    withRouter
)(RegistrationItem);

export type RegistrationItemProps =
    RegistrationItemContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    RouteComponentProps &
    InjectedIntlProps &
    GetDaycareNamesQueryResult;
