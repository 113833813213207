import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { GetDaycareListDaycares } from "@models/graphql/types";
import { DaycareListItem } from "@components/daycare/daycareListItem/daycareListItem";
import { withRouter, RouteComponentProps } from "react-router";
import { updateDaycareSelectedDaycareId, UpdateDaycareSelectedDaycareIdActionOptions } from "@redux/actions/updateDaycareSelectedDaycareId";
import { createLogoutDaycareMutation, LogoutDaycareMutationResult } from "@graphql/hocs/logoutDaycare";
import { injectIntl, InjectedIntlProps } from "react-intl";

export interface DaycareListItemContainerProps {
    daycare: GetDaycareListDaycares;
}

export const mapStateToProps = (state: StoreState, ownProps: DaycareListItemContainerProps) => ({

});

const withLogoutDaycare = createLogoutDaycareMutation({});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setDaycare(data: UpdateDaycareSelectedDaycareIdActionOptions) { dispatch(updateDaycareSelectedDaycareId(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const DaycareListItemContainer = compose<React.ComponentClass<DaycareListItemContainerProps>>(
    injectIntl,
    withRedux,
    withRouter,
    withLogoutDaycare
)(DaycareListItem);

export type DaycareListItemProps =
    InjectedIntlProps &
    DaycareListItemContainerProps &
    WithReduxDispatchProps &
    LogoutDaycareMutationResult &
    WithReduxStateProps &
    RouteComponentProps;
