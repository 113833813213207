import styled from "styled-components";
import { Colors } from "@style/colors";
import { Padding } from "@style/padding";

export const DayCareSearchStyle = styled.div`
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #67C9CC;
    width: 50vw;
    max-width: 450px;
    min-width: 220px;
    margin-top: ${Padding.large};

    input {
        display: block;
        border: none;
        background-color: transparent;
        color: ${Colors.white};
        width: 100%;
        font-size: 14px;

        padding-bottom: 4px;


        ::placeholder {
            color: ${Colors.white};
            opacity: .5;
        }
        outline: none;
    }

    .remove-btn {
        cursor: pointer;
        color: white;
        opacity: 0.5;

        :hover {
            opacity: 1;
        }
        padding-bottom: 10px;
    }

    .search {
        padding-bottom: 10px;
        color: ${Colors.white};
        opacity: 0.5;
    }
`;
