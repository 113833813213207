import { Checkbox, Form, Input, Select } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { isValidDateString, parseDate } from "@utils/parseDate";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Gender } from "@models/graphql/types";
import { Contact } from "@components/editContactsModal/editContactsModal";
import { AddChildFormStyle, Contact as ContactComponent } from "@components/addChild/addChildForm/addChildFormStyle";
import { AddChildFormProps } from "@components/addChild/addChildForm/addChildFormContainer";

export interface AddChildFormState {
	contacts: number[];
}

let cbfId = 1;

interface ChildBenefitField {
	start: string;
	end: string;
}

interface ChildBenefitFormField {
	start: string;
	end: string | null;
}

export interface AddChildFormValues {
	birthdate: string;
	inSchoolSince?: string;
	firstName: string;
	lastName: string;
	gender: Gender;
	contacts?: { firstName: string; lastName: string; email: string }[];
	childBenefitEntitlement: boolean | undefined;
	childBenefitFieldKeys: number[];
	childBenefitPeriods: ChildBenefitFormField[];
	childBenefitFields: ChildBenefitFormField[];
	notCbEntitled: boolean;
}

@autobind
export class AddChildForm extends React.Component<AddChildFormProps, AddChildFormState> {
	public state: AddChildFormState = {
		contacts: []
	};

	private contactCount: number = 0;

	// tslint:disable-next-line: max-func-body-length
	public render() {

		const { form, intl, firstName, lastName, gender, birthdate } = this.props;

		return (
			<AddChildFormStyle>
				<FormattedMessage tagName="h1" id="addChildForm.title" />

				<Form layout="vertical" onSubmit={this.onSubmit}>
					<Form.Item label={intl.formatMessage({ id: "addChildForm.firstName" })}>
						{form.getFieldDecorator("firstName", {
							initialValue: firstName,
							rules: [{ required: true, message: intl.formatMessage({ id: "addChildForm.firstNameRequired" }) }]
						})(
							<Input placeholder={intl.formatMessage({ id: "addChildForm.firstName" })} />
						)}
					</Form.Item>
					<Form.Item label={intl.formatMessage({ id: "addChildForm.lastName" })}>
						{form.getFieldDecorator("lastName", {
							initialValue: lastName,
							rules: [{ required: true, message: intl.formatMessage({ id: "addChildForm.lastNameRequired" }) }]
						})(
							<Input placeholder={intl.formatMessage({ id: "addChildForm.lastName" })} />
						)}
					</Form.Item>
					<Form.Item label={intl.formatMessage({ id: "addChildForm.gender" })}>

						{form.getFieldDecorator("gender", {
							initialValue: gender,
							rules: [{ required: true, message: intl.formatMessage({ id: "addChildForm.genderRequired" }) }]
						})(
							<Select placeholder={intl.formatMessage({ id: "addChildForm.gender" })}>
								<Select.Option value={Gender.MALE}><FormattedMessage id="gender.male" /></Select.Option>
								<Select.Option value={Gender.FEMALE}><FormattedMessage id="gender.female" /></Select.Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item required label={intl.formatMessage({ id: "addChildForm.birthdate" })}>
						{form.getFieldDecorator("birthdate", {
							initialValue: birthdate,
							rules: [
								{ validator: this.validateBirthdate }
							]
						})(
							<Input placeholder={intl.formatMessage({ id: "addChildForm.datePlaceholder" })} />
						)}
					</Form.Item>
					<div className="extra">
						<Form.Item label={intl.formatMessage({ id: "addChildForm.inSchoolSince" })}>
							{form.getFieldDecorator("inSchoolSince", {
								rules: [{
									validator: this.validateInSchoolSince
								}]

							})(
								<Input placeholder={intl.formatMessage({ id: "addChildForm.datePlaceholder" })} />
							)}
						</Form.Item>
						<Form.Item required={true} className="ko-fields" label={intl.formatMessage({ id: "addChildPage.childBenefitEntitlement" })}>
							{form.getFieldDecorator(
								"notCbEntitled",
								{
									initialValue: false,
									valuePropName: "checked"
								}
							)(
								<Checkbox><FormattedMessage id="childrenOverviewList.noBenefits" /></Checkbox>
							)}

							{form.getFieldValue("notCbEntitled") ? this.renderChildBenefitFields() : null}
							{form.getFieldValue("notCbEntitled") ? <Button className="add-field-btn" onClick={this.addChildBenefitField} type="dashed">+ Velden toevoegen</Button> : null}
						</Form.Item>
						{/* <Form.Item >
                            {form.getFieldDecorator("childBenefitEntitlement", {
                                valuePropName: "checked"
                            })(
                                <Checkbox><FormattedMessage id="addChildForm.childBenefitEntitlement" /></Checkbox>
                            )}
                        </Form.Item> */}
					</div>

					<FormattedMessage tagName="h3" id="addChildForm.contact" />
					<FormattedMessage id="addChildForm.contactInfo" />

					{this.renderContacts()}

					<span onClick={this.addContact} className="addContact"><FormattedMessage id="addChildForm.addContact" /></span>

					<Button htmlType="submit" type="primary"><FormattedMessage id="addChildForm.submit" /></Button>
				</Form>
			</AddChildFormStyle>

		);
	}

	private addChildBenefitField() {

		const { form } = this.props;

		const cbf = form.getFieldValue("childBenefitFieldKeys");
		const nextCbf = cbf.concat(cbfId++);

		form.setFieldsValue({
			childBenefitFieldKeys: nextCbf
		});
	}

	private renderChildBenefitFields() {
		const { form, intl } = this.props;

		form.getFieldDecorator("childBenefitFieldKeys", { initialValue: [0] });
		const cbfs = form.getFieldValue("childBenefitFieldKeys");

		return cbfs.map((field, index) => {
			return (
				<div className="child-benefit-field-wrapper" key={index}>
					<Form.Item className="ko-fields">
						{form.getFieldDecorator(
							`childBenefitFields[${field}].start`,
							{
								initialValue: index === 0 ? this.renderFormattedDate(form.getFieldValue("birthdate")) : "",
								rules: [{
									validator: (rule, value, callback) => this.validateChildBenefitDate(field, true, rule, value, callback)
								}]
							}
						)(<Input placeholder="01/01/2015" />)}
					</Form.Item>
					<span className="until">tot</span>
					<Form.Item>
						{form.getFieldDecorator(
							`childBenefitFields[${field}].end`,
							{
								rules: [{
									validator: (rule, value, callback) => this.validateChildBenefitDate(field, false, rule, value, callback)
								}]
							}
						)(
							<Input placeholder="01/01/2015" />
						)}
					</Form.Item>
					{field !== 0 ? <Icon onClick={() => this.deleteChildBenefitField(field)} type="trash" /> : <Icon type="info" className="info-check" />}
				</div>
			);
		});
	}

	private renderFormattedDate(cbf) {
		if (cbf) {
			return moment(cbf, "DD-MM-YYYY").format("DD/MM/YYYY");
		}

		return "";
	}

	private validateChildBenefitDate(field: number, isStart: boolean, rule: any, value: string | undefined, callback: (error?: string) => void) {
		const { intl } = this.props;

		const error = this.validateChildBenefitFields(field, isStart);
		if (error) {
			return callback(error);
		}

		if (value && !isValidDateString(value)) {
			return callback(intl.formatMessage({ id: "addChildForm.invalidDate" }));
		}

		return callback();
	}

	private validateChildBenefitFields(index: number, isStart: boolean) {
		const { form } = this.props;

		const keys: number[] = form.getFieldValue("childBenefitFieldKeys");
		const currentFieldKeyIndex = keys.findIndex(key => key === index);
		const childBenefitFields = form.getFieldValue("childBenefitFields");

		if (currentFieldKeyIndex < 0) {
			return;
		}

		if (childBenefitFields[keys[currentFieldKeyIndex]] && childBenefitFields[keys[currentFieldKeyIndex]].end === undefined) {
			const length = childBenefitFields.length - 1;
			for (let i = index; i < length; i++) {
				this.deleteChildBenefitField(i + 1);
			}
		}

		if (keys[currentFieldKeyIndex] === 0 && isStart) {
			const start = moment(childBenefitFields[keys[currentFieldKeyIndex]].start, "DD/MM/YYYY");

			if (start.isBefore(moment(form.getFieldValue("birthdate"), "DD/MM/YYYY"))) {
				return "Dit veld kan niet groter zijn dan de geboortedatum.";
			}
		}

		if (childBenefitFields[keys[currentFieldKeyIndex]]) {

			// check if fields are correct dates
			const start = moment(childBenefitFields[keys[currentFieldKeyIndex]].start, "DD/MM/YYYY");
			const end = moment(childBenefitFields[keys[currentFieldKeyIndex]].end, "DD/MM/YYYY");

			if (isStart) {
				if (!start.isValid()) {
					return "Dit veld is verplicht.";
				}

				if (currentFieldKeyIndex > 0) {
					const previousEnd = moment(childBenefitFields[keys[currentFieldKeyIndex - 1]].end, "DD/MM/YYYY");

					if (!previousEnd || !previousEnd.isValid()) {
						return "Het vorige veld moet een einddatum hebben";
					}

				}
			}

			// end is valid: do first check
			if (end.isValid()) {
				if (start >= end) {
					return "De startdatum kan niet groter zijn dan de einddatum";
				}
			}

			if (currentFieldKeyIndex > 0) {

				const previousBenefitFieldKey = keys[currentFieldKeyIndex - 1];
				const previousBenefitField = form.getFieldValue(`childBenefitFields[${previousBenefitFieldKey}]`);

				if (moment(previousBenefitField.end, "DD/MM/YYYY") > start) {
					return "De datums mogen niet overlappen.";
				}

				const nextBenefitFieldKey = keys[currentFieldKeyIndex + 1];
				const nextBenefitField = form.getFieldValue(`childBenefitFields[${nextBenefitFieldKey}]`);

				if (nextBenefitField) {
					if (end > moment(nextBenefitField.start, "DD/MM/YYYY")) {
						return "De datums mogen niet overlappen.";
					}
				}
			}
		}
	}

	private deleteChildBenefitField(cbf) {
		const { form } = this.props;
		const cbfs = form.getFieldValue("childBenefitFieldKeys");

		console.log(cbfs, cbf);

		if (cbfs.length === 1) {
			return;
		}

		form.setFieldsValue({
			childBenefitFieldKeys: cbfs.filter(field => field !== cbf)
		});
	}

	private renderContacts() {
		const { contacts } = this.state;
		const { form, intl } = this.props;

		return contacts.map(contact => {
			return (
				<ContactComponent key={contact}>
					<Form.Item label={intl.formatMessage({ id: "addChildForm.firstName" })}>

						{form.getFieldDecorator(`contacts[${contact}].firstName`, {
							rules: [{ required: true, message: intl.formatMessage({ id: "addChildForm.firstNameRequired" }) }]
						})(
							<Input placeholder={intl.formatMessage({ id: "addChildForm.firstName" })} />
						)}
					</Form.Item>
					<Form.Item label={intl.formatMessage({ id: "addChildForm.lastName" })}>
						{form.getFieldDecorator(`contacts[${contact}].lastName`, {
							rules: [{ required: true, message: intl.formatMessage({ id: "addChildForm.lastNameRequired" }) }]
						})(
							<Input placeholder={intl.formatMessage({ id: "addChildForm.lastName" })} />
						)}
					</Form.Item>
					<Form.Item label={intl.formatMessage({ id: "addChildForm.email" })}>
						{form.getFieldDecorator(`contacts[${contact}].email`, {
							validateTrigger: "onSubmit",
							rules: [
								{ required: true, type: "email", message: intl.formatMessage({ id: "addChildForm.emailNotValid" }) },
								{ validator: this.validateContactEmail }
							]
						})(
							<Input placeholder={intl.formatMessage({ id: "addChildForm.email" })} />
						)}
					</Form.Item>
					<Icon onClick={() => this.removeContact(contact)} type="trash" />
				</ContactComponent>
			);
		});
	}

	private validateContactEmail(rule: { field: string }, value: string, callback: (err?: string) => void, source?: any, options?: any) {
		const { form, intl } = this.props;

		if (value && rule.field.match(/^.*\[\d+\]\..*$/)) {
			const index = parseInt(rule.field.replace(/^.*\[/, "").replace(/\]\..*$/, ""), 10);

			const contacts: Contact[] = form.getFieldValue("contacts");
			if (contacts.some((contact, i) => contact.email.toLowerCase().trim() === value.toLowerCase().trim() && i !== index)) {
				return callback(intl.formatMessage({ id: "addChildForm.duplicateEmail" }));
			}

		}

		callback();
	}

	private removeContact(id: number) {
		const { contacts } = this.state;

		this.setState({
			contacts: contacts.filter(contact => contact !== id)
		});
	}

	private addContact() {
		const { contacts } = this.state;

		contacts.push(this.contactCount);

		this.setState({
			contacts
		});

		this.contactCount += 1;
	}

	private validateBirthdate(rule: any, value: string | undefined, callback: (error?: string) => void) {
		const { intl } = this.props;

		if (!value) {
			return callback(intl.formatMessage({ id: "addChildForm.birthdateRequired" }));
		}

		if (!isValidDateString(value)) {
			return callback(intl.formatMessage({ id: "addChildForm.invalidDate" }));
		}

		const date = moment(parseDate(value), "YYYY/MM/DD");

		if (!date.isValid()) {
			return callback(intl.formatMessage({ id: "addChildForm.dateDoesNotExist" }));
		}

		if (date.isBefore(moment().subtract(15, "y"))) {
			return callback(intl.formatMessage({ id: "addChildForm.childTooOld" }));
		}

		if (date.isSameOrAfter(moment())) {
			return callback(intl.formatMessage({ id: "addChildForm.dateAfterToday" }));
		}

		return callback();
	}

	private validateInSchoolSince(rule: any, value: string | undefined, callback: (error?: string) => void) {
		const { intl, form } = this.props;

		const birthdateString = form.getFieldValue("birthdate");

		if (!value) {
			return callback();
		}

		if (!isValidDateString(value)) {
			return callback(intl.formatMessage({ id: "addChildForm.invalidDate" }));
		}

		if (birthdateString && isValidDateString(birthdateString)) {

			const birthdate = moment(parseDate(birthdateString), "YYYY/MM/DD");
			const date = moment(parseDate(value), "YYYY/MM/DD");

			if (date.isSameOrBefore(birthdate)) {
				return callback(intl.formatMessage({ id: "addChildForm.beforeBirthdate" }));
			}
		}

		return callback();
	}

	private onSubmit(e: React.MouseEvent | React.FormEvent) {
		e.preventDefault();

		const { form, submitCallback } = this.props;

		form.validateFields({ force: true }, (errors, values: AddChildFormValues) => {

			if (!errors) {
				if (values.contacts) {
					values.contacts = values.contacts.filter(val => val !== null);
				}

				let parsedChildBenefits;

				if (values.notCbEntitled) {
					const filteredChildBenefits = values.childBenefitFields.filter(Boolean);

					parsedChildBenefits = filteredChildBenefits.map(item => {
						return {
							start: moment(parseDate(item.start), "YYYY-MM-DD").format("YYYY-MM-DD"),
							end: item.end !== null && item.end !== "" && item.end !== undefined ? moment(parseDate(item.end), "YYYY-MM-DD").format("YYYY-MM-DD") : null
						};
					});
				} else {
					parsedChildBenefits = [];
				}

				values.childBenefitPeriods = parsedChildBenefits;
				delete values.childBenefitFields;
				delete values.childBenefitFieldKeys;
				delete values.notCbEntitled;

				values.birthdate = moment(parseDate(values.birthdate), "YYYY-MM-DD").format("YYYY-MM-DD");

				if (values.inSchoolSince) {
					values.inSchoolSince = moment(parseDate(values.inSchoolSince), "YYYY-MM-DD").format("YYYY-MM-DD");
				}

				submitCallback(values);
			}
		});
	}
}
