import * as jwtDecode from "jwt-decode";
import * as moment from "moment";

import { AuthService, Organiser, TokenData } from "@utils/authService";

export enum Role {
    DAYCARE = "daycare",
    PARENT = "parent",
    ORGANISER = "organiser",
    API = "api",
    MANUAL_SYNC = "manual-sync"
}

export class User {
    public token: string;
    public username: string;
    public email: string;
    public expires_at: moment.Moment;
    public organiser?: Organiser;
    public isValid: () => boolean;
    public roles: Role[];
    public daycareName: string;
    public address: string;
    public id: string;
    public firstName?: string;
    public lastName?: string;
    public multiOrganiser?: Organiser[];

    constructor(accessToken: string) {

        this.token = accessToken;

        try {
            const data = jwtDecode<TokenData>(accessToken);

            const roles = [...data.realm_access.roles];

            if (data.resource_access.account) {
                roles.push(...data.resource_access.account.roles);
            }

            if (data.resource_access.app) {
                roles.push(...data.resource_access.app.roles);
            }

            this.username = data.preferred_username;
            this.email = data.email;
            this.expires_at = moment.unix(data.exp);
            this.organiser = data.organiser;
            this.isValid = () => this.expires_at.isAfter(moment());
            this.daycareName = data.daycareName;
            this.address = data.address;
            this.id = data.sub;
            this.roles = roles.filter(role => {
                return Object.keys(Role).some(roleKey => Role[roleKey] === role);
            }) as Role[];
            this.firstName = data.given_name;
            this.lastName = data.family_name;
            this.multiOrganiser = data.multiOrganiser;
        } catch (err) {
            AuthService.logout();
        }

    }
}
