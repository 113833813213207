import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";
import { User } from "@utils/user";

export interface SetUserActionOptions {
    user: User | null;
}

export const setUser = (data: SetUserActionOptions): SetUserAction => {
    return {
        type: ActionTypes.SetUser,
        ...data
    };
};

export type SetUserAction = Action & SetUserActionOptions;
export const isSetUserAction = (action: Action): action is SetUserAction => action.type === ActionTypes.SetUser;
