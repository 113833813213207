import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { Gender } from "@models/graphql/types";
import { AddChildForm, AddChildFormValues } from "@components/addChild/addChildForm/addChildForm";

export interface AddChildFormContainerProps {
	firstName: string;
	lastName: string;
	gender?: Gender;
	birthdate?: string;
	submitCallback(values: AddChildFormValues): void;
}

export const mapStateToProps = (state: StoreState, ownProps: AddChildFormContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

export const AddChildFormContainer = compose<React.ComponentClass<AddChildFormContainerProps>>(
	withRedux,
	withForm,
	injectIntl
)(AddChildForm);

export type AddChildFormProps =
	AddChildFormContainerProps &
	WithReduxDispatchProps &
	WithReduxStateProps &
	InjectedIntlProps &
	FormComponentProps;
