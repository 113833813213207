import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { EmptyBlock, ValidBlock, InvalidBlock } from "@components/registrations/registrationItem/registrationItemStyle";
import { Button } from "@style/button";

export const RegistrationListItemStyle = styled.div`
    background-color: ${Colors.lightGrey};
    height: 60px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 0 0 ${Padding.small};
    justify-content: space-between;

    .name {
        font-weight: 600;
        margin-bottom: 7px;
    }

    .info {
        font-size: 12px;
    }

    .left {
        cursor: pointer;

        &:hover {
            color: ${Colors.primary()};
        }
    }


    ${EmptyBlock}, ${ValidBlock}, ${InvalidBlock} {
        flex-basis: 125px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${Colors.primary()};
        font-weight: 600;
        border-radius: 4px;
    }

    ${InvalidBlock} {
        color: ${Colors.error()};
        border-width: 1px;



        .circle {
            right: intial;
            z-index: 1;
            top: calc(50% - 15px);
            left: -15px;
        }
    }

    ${EmptyBlock} {
        background-color: #EAEAEA;
        color: ${Colors.text};
        font-weight: 200;

        &:hover {
            background-color: #E0E0E0;
        }
    }

    &:not(:last-of-type) {
        margin-bottom: 12px;
    }
`;
