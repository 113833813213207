import * as moment from "moment";

export const isSuspended = (months: string[], currentDate?: moment.Moment): boolean => {
    let returnVal = true;

    if (months.some(date => moment(date, "DD-MM-YYYY").add(2, "day").isSame(currentDate || moment(), "month"))) {
        returnVal = false;
    }

    return returnVal;
};
