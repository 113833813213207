import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { createLocalState, LocalStateProps } from "@utils/withLocalState";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { createGetChildrenNamesQuery, GetChildrenNamesQueryResult } from "@graphql/hocs/getChildrenNames";
import { ChildSearch } from "@components/addChild/childSearch/childSearch";

export interface ChildSearchContainerProps {
	initialFirstName?: string;
	initialLastName?: string;
	addExistingChildCallback(childId: number): void;
	addNewChild(firstName: string, lastName: string): void;
}

interface LocalState {
	searchFirstName: string;
	searchLastName: string;
}

export const mapStateToProps = (state: StoreState, ownProps: ChildSearchContainerProps) => ({
	organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

const withLocalState = createLocalState<LocalState>({
	searchFirstName: "",
	searchLastName: ""
});

const withGetChildrenNames = createGetChildrenNamesQuery<ChildSearchContainerProps & WithReduxStateProps & LocalStateProps<LocalState>>({
	options(ownProps) {
		return {
			variables: {
				filter: {
					firstNameFuzzy: ownProps.searchFirstName,
					lastNameFuzzy: ownProps.searchLastName,
					organisationId: ownProps.organisationId
				}
			}
		};
	},
	skip(ownProps) {
		if (ownProps.searchFirstName !== "" && ownProps.searchLastName !== "") {
			return false;
		}

		return true;
	}
});

export const ChildSearchContainer = compose<React.ComponentClass<ChildSearchContainerProps>>(
	withRedux,
	injectIntl,
	withForm,
	withLocalState,
	withGetChildrenNames
)(ChildSearch);

export type ChildSearchProps =
	ChildSearchContainerProps &
	WithReduxDispatchProps &
	WithReduxStateProps &
	InjectedIntlProps &
	GetChildrenNamesQueryResult &
	LocalStateProps<LocalState> &
	FormComponentProps;
