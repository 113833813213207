import { Form, Input } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { AuthService } from "@utils/authService";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { Alert } from "@components/alert/alert";
import { AdminLoginFormProps } from "./adminLoginFormContainer";
import { AdminLoginFormStyle } from "./adminLoginFormStyle";

export interface AdminLoginFormState {
    submitting: boolean;
    submitError: string | null;
}

@autobind
export class AdminLoginForm extends React.Component<AdminLoginFormProps, AdminLoginFormState> {
    public state: AdminLoginFormState = {
        submitError: null,
        submitting: false
    };

    public render() {

        const { intl, form, match } = this.props;

        const { submitError, submitting } = this.state;

        let showSuccess = false;

        if (match.params.status === "success" && !submitError && !submitting) {
            showSuccess = true;
        }

        return (
            <AdminLoginFormStyle>
                <InnerContainer>
                    <h1><FormattedMessage id="adminLoginForm.title" /></h1>
                    <Box>
                        <Form onSubmit={this.submitForm}>
                            <Form.Item label={intl.formatMessage({ id: "organiserLoginForm.email" })}>
                                {form.getFieldDecorator("email", {
                                    validateTrigger: "onBlur",
                                    rules: [{ required: true, type: "email", message: intl.formatMessage({ id: "organiserLoginForm.emailError" }) }]
                                })(
                                    <Input size="large" />
                                )}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage({ id: "organiserLoginForm.password" })}>

                                {form.getFieldDecorator("password", {
                                    rules: [{ required: true, message: intl.formatMessage({ id: "organiserLoginForm.passwordError" }) }]
                                })(
                                    <Input size="large" type="password" />
                                )}
                            </Form.Item>
                            <Alert type="error">{submitError}</Alert>
                            <Alert type="success">{showSuccess && <FormattedMessage id="setPassword.success" />}</Alert>
                            <Button htmlType="submit" loading={submitting} onClick={this.submitForm} type="primary"><FormattedMessage id="organiserLoginForm.login" /></Button>
                        </Form>
                        {/* <div className="bottomBar">
                            <Link tabIndex={-1} className="resetPassword" to="/login/reset-password"><FormattedMessage id="organiserLoginForm.resetPassword" /></Link>
                        </div> */}
                    </Box>
                </InnerContainer>
            </AdminLoginFormStyle>
        );
    }

    private submitForm(e) {
        e.preventDefault();
        const { form, intl, history, match, login } = this.props;

        form.validateFields(async (errors, values) => {
            if (!errors) {

                try {
                    this.setState({
                        submitting: true,
                        submitError: null
                    });

                    const loginResult = await login({
                        username: values.email,
                        password: values.password
                    });

                    const user = AuthService.login(loginResult);

                    if (user) {

                        this.setState({
                            submitting: false,
                            submitError: null
                        });

                        history.push("/manual-sync");
                    }

                } catch (err) {
                    console.log(err);
                    if (err.message === "invalid_grant") {
                        this.setState({
                            submitError: intl.formatMessage({ id: "organiserLoginForm.invalidCredentials" }),
                            submitting: false
                        });
                    } else {
                        this.setState({
                            submitError: intl.formatMessage({ id: "organiserLoginForm.somethingWentWrong" }),
                            submitting: false
                        });
                    }
                }
            }

        });

    }
}
