import { captureException } from "@sentry/core";
import { Icon } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Button } from "@style/button";
import { RegistrationErrorPageContainer } from "@pages/registrationErrorPage/registrationErrorPageContainer";
import {
    RegistrationChildMissingPageContainer
} from "@pages/registrationChildMissingPage/registrationChildMissingPageContainer";
import { InvalidRegistrationPageContainer } from "@pages/invalidRegistrationPage/invalidRegistrationPageContainer";
import { Code, ConfirmRegistrationPageStyle } from "@pages/confirmRegistrationPage/confirmRegistrationPageStyle";
import { ConfirmRegistrationPageProps } from "@pages/confirmRegistrationPage/confirmRegistrationPageContainer";
import { EntryType } from "@models/graphql/types";
import { CancelRegistrationButtonContainer } from "@components/cancelRegistrationButton/cancelRegistrationButtonContainer";


export interface ConfirmRegistrationPageState {
    timeRemaining: number;
    error: boolean;
    paused: boolean;
}

@autobind
export class ConfirmRegistrationPage extends React.Component<ConfirmRegistrationPageProps, ConfirmRegistrationPageState> {

    public state: ConfirmRegistrationPageState = {
        timeRemaining: 10,
        error: false,
        paused: false
    };
    private submitting: boolean = false;

    private timer: NodeJS.Timer;

    public render() {

        const { entryType, childNotFound, registrationResult } = this.props;

        const { error } = this.state;

        if (error) {
            return <RegistrationErrorPageContainer />;
        }

        if (childNotFound) {
            return <RegistrationChildMissingPageContainer />;
        }

        if (registrationResult && !registrationResult.valid) {
            return <InvalidRegistrationPageContainer />;
        }

        return (
            <ConfirmRegistrationPageStyle type={entryType}>
                {this.renderSuccess()}
                {this.renderProcessing()}
            </ConfirmRegistrationPageStyle>
        );
    }

    public componentWillMount() {
        const { registrationResult } = this.props;

        if (!registrationResult) {
            this.processEntry();
        } else if (registrationResult.valid) {
            this.timer = setInterval(this.countDown, 1000);
        }

    }

    public componentWillUnmount() {
        const { registrationResult, childNotFound, setRegistrationResult, setCode, setChildNotFound } = this.props;

        if (registrationResult && registrationResult.valid && !childNotFound) {
            setRegistrationResult({ registrationResult: null });
            setCode({ code: null });
            setChildNotFound({ childNotFound: false });
        }
        clearInterval(this.timer);
    }

    private renderProcessing() {
        const { code, registrationResult } = this.props;

        if (registrationResult) {
            return null;
        }

        return (
            <>
                <Code>
                    {[...code].map((char, i) => <span className="char" key={i}>{char}</span>)}
                </Code>

                <span className="processing">
                    <FormattedMessage id="confirmCheckinPage.processing" />
                </span>

                <Icon type="loading" />
            </>
        );
    }

    private countDown() {
        if (!this.state.paused) {

            const newTime = this.state.timeRemaining - 1;

            if (newTime > 0) {
                this.setState({
                    timeRemaining: newTime
                });
            } else {
                this.props.history.push("/registration");
            }
        }
    }

    private renderSuccess() {

        const { timeRemaining } = this.state;
        const { entryType, registrationResult } = this.props;

        if (!registrationResult || !registrationResult.child) {
            return null;
        }
        const { child, entries } = registrationResult;

        const { time } = entries[entries.length - 1];

        return (
            <>
                <h1>{child.firstName} {child.lastName}</h1>
                <FormattedMessage tagName="h2" id={entryType === EntryType.IN ? "confirmRegistrationPage.checkin" : "confirmRegistrationPage.checkout"} />

                <div className="time">{moment(time).format("HH:mm")}</div>

                <Link to="/registration">
                    <Button type="ghost"><FormattedMessage id="confirmRegistrationPage.back" /></Button>
                </Link>

                <FormattedMessage tagName="h3" id="confirmRegistrationPage.countdownInfo" />
                <span className="countdown">{timeRemaining}</span>
                <CancelRegistrationButtonContainer initDeleteCallback={this.pause} endDeleteCallback={this.unpause} />
            </>
        );
    }

    private pause() {
        this.setState({
            paused: true
        });
    }

    private unpause() {
        this.setState({
            paused: false
        });
    }

    private async processEntry() {
        const { code, addEntry, entryType, setRegistrationResult, setChildNotFound } = this.props;

        if (this.submitting) {
            return;
        }

        this.submitting = true;

        try {
            this.setState({
                error: false,
                timeRemaining: 10
            });

            if (!code) {
                throw new Error("No code detected");
            }

            const addEntryResult = await addEntry({
                childCode: code,
                time: moment().toISOString(),
                type: entryType
            });

            setRegistrationResult({ registrationResult: addEntryResult.registration });
            setChildNotFound({ childNotFound: addEntryResult.childNotFound });

            if (addEntryResult.registration && addEntryResult.registration.child && addEntryResult.registration.valid) {
                this.timer = setInterval(this.countDown, 1000);
            }

        } catch (error) {
            console.error(error);
            captureException(error);
            this.setState({ error: true });
        }

        this.submitting = false;
    }
}
