import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { EntryType } from "@models/graphql/types";
import { CancelRegistrationButtonStyle } from "@components/cancelRegistrationButton/cancelRegistrationButtonStyle";

interface ConfirmRegistrationPageStyleProps {
    type: EntryType;

}

export const ConfirmRegistrationPageStyle = styled.div`
    background: ${(props: ConfirmRegistrationPageStyleProps) => props.type === EntryType.IN ? Colors.primary() : Colors.secondary()};
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${Colors.white};
    text-align: center;

    h1, h2, h3 {
        color: ${Colors.white};
    }

    h1 {
        font-size: 50px;
        font-weight: 200;
        margin-bottom: ${Padding.medium};
    }

    h2 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: ${Padding.large};
    }

    h3 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: ${Padding.small};
    }

    .time {
        font-size: 120px;
        font-weight: 200;
        margin-bottom: 80px;
    }

    .countdown {
        font-size: 60px;
        font-weight: 200;
    }

    ${Button} {
        width: 100%;
        max-width: 350px;
        height: 50px;
        margin-bottom: ${Padding.medium};
    }

    .processing {
        font-size: 40px;
        margin-top: 80px;
    }

    & > .anticon {
        font-size: 40px;
        margin-top: 40px;
    }

    ${CancelRegistrationButtonStyle} {
        margin-top: 20px;
    }

    @media (max-height: 600px) {
        .time {
            margin-bottom: 20px;
            font-size: 100px;
        }

        h1 {
            font-size: 40px;
        }

        h2 {
            font-size: 25px;
        }

        .countdown {
            font-size: 40px;
        }

    }


`;

export const Code = styled.div`
    display: flex;
    height: 70px;

    .char {
        color: ${Colors.white};
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255,255,255,0.15);
        border-radius: 4px;
        padding: 5px 10px;
        width: 50px;
        height: 70px;

        &:not(:last-of-type) {
            margin-right: 10px;
        }
    }

     @media (max-width: 400px) {
         height: 56px;
         .char {
             width: 40px;
             height: 56px;
             font-size: 40px;
         }
     }
`;
