import { GetUploadsListQuery, GetUploadsListVariables, GetUploadsListUploads } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";

export interface GetUploadsListQueryResult {
    uploads?: GetUploadsListUploads[];
    uploadsLoading: boolean;
}

export const createGetUploadsListQuery = <TProps = {}>(options?: OperationOption<TProps, GetUploadsListQuery, GetUploadsListVariables, GetUploadsListQueryResult>) =>
    graphql<TProps, GetUploadsListQuery, GetUploadsListVariables, GetUploadsListQueryResult>(GraphQL.getUploadsList, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                uploads: data.uploads,
                uploadsLoading: data.loading
            };
        },
        ...options
    });
