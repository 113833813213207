import { autobind } from "core-decorators";
import * as React from "react";
import MediaQuery from "react-responsive";

import { InnerContainer } from "@style/innerContainer";
import { RegistrationsOverviewStyle } from "@pages/registrationsOverview/registrationsOverviewStyle";
import { RegistrationsOverviewProps } from "@pages/registrationsOverview/registrationsOverviewContainer";
import { RegistrationTableContainer } from "@components/registrations/registrationTable/registrationTableContainer";
import {
    RegistrationSearchBarContainer
} from "@components/registrations/registrationSearchBar/registrationSearchBarContainer";
import { RegistrationListContainer } from "@components/registrations/registrationList/registrationListContainer";
import { DateSelectorContainer } from "@components/registrations/dateSelector/dateSelectorContainer";
import { ExportSelectorContainer } from "@components/registrations/exportSelector/exportSelectorContainer";

export interface RegistrationsOverviewState {

}

@autobind
export class RegistrationsOverview extends React.Component<RegistrationsOverviewProps, RegistrationsOverviewState> {

    public render() {
        return (
            <RegistrationsOverviewStyle>
                <InnerContainer>
                    <div className="bar">
                        <DateSelectorContainer />
                        <ExportSelectorContainer />
                    </div>
                </InnerContainer>
                <MediaQuery minWidth={900}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <InnerContainer>
                                    <RegistrationTableContainer />
                                </InnerContainer>
                            );
                        } else {
                            return <>
                                <InnerContainer>
                                    <RegistrationSearchBarContainer />
                                </InnerContainer>
                                <RegistrationListContainer />
                            </>;
                        }
                    }}
                </MediaQuery>
            </RegistrationsOverviewStyle>
        );
    }
}
