import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { Role } from "@utils/user";
import { StoreState } from "@redux/reducers/root";
import { updateEditRegistration, UpdateEditRegistrationActionOptions } from "@redux/actions/updateEditRegistrationId";
import { updateCreateRegistration, UpdateCreateRegistrationActionOptions } from "@redux/actions/updateCreateRegistration";
import { Action } from "@redux/actions/action";
import { Aggregation } from "@models/graphql/types";
import { createGetDaycareNamesQuery, GetDaycareNamesQueryResult } from "@graphql/hocs/getDaycareNames";
import {
    createGetChildrenRegistrationListQuery,
    GetChildrenRegistrationListQueryResult
} from "@graphql/hocs/getChildrenRegistrationList";
import {
    ChildDetailRegistrationsList
} from "@components/childDetail/childDetailRegistrationsList/childDetailRegistrationsList";

export interface ChildDetailRegistrationsListContainerProps {
    childId: number;
}

export const mapStateToProps = (state: StoreState, ownProps: ChildDetailRegistrationsListContainerProps) => ({
    childrenFilter: state.registration.childrenFilter,
    aggregation: state.registration.aggregation,
    startDate: state.registration.startDate.clone(),
    endDate: state.registration.endDate.clone(),
    isParent: state.auth.user && state.auth.user.roles.includes(Role.PARENT),
    selectedDaycareId: state.daycare.selectedDaycareId,
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    editRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); },
    createRegistration(data: UpdateCreateRegistrationActionOptions) { dispatch(updateCreateRegistration(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetChildrenRegistrationList = createGetChildrenRegistrationListQuery<ChildDetailRegistrationsListContainerProps & WithReduxStateProps>({
    options(ownProps) {
        const { childrenFilter, childId } = ownProps;

        return {
            variables: {
                aggregation: Aggregation.DAY,
                filter: {
                    childIds: [childId],
                    registrationsFrom: childrenFilter.registrationsFrom,
                    registrationsTo: childrenFilter.registrationsTo
                }
            }
        };
    }
});

const withgetDaycare = createGetDaycareNamesQuery<WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organisationId) {
            throw new Error("No organisation ID found");
        }

        return {
            variables: {
                filter: {
                    id: ownProps.selectedDaycareId,
                    organisationId: ownProps.organisationId
                }
            }
        };
    },
    skip(ownProps) {
        return !!ownProps.isParent;
    }
});

export const ChildDetailRegistrationsListContainer = compose<React.ComponentClass<ChildDetailRegistrationsListContainerProps>>(
    withRedux,
    withGetChildrenRegistrationList,
    withgetDaycare
)(ChildDetailRegistrationsList);

export type ChildDetailRegistrationsListProps =
    ChildDetailRegistrationsListContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    GetChildrenRegistrationListQueryResult &
    GetDaycareNamesQueryResult;
