import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface SetOrganisationIdActionOptions {
    organisationId: string;
}

export const setOrganisationId = (data: SetOrganisationIdActionOptions): SetOrganisationIdAction => {
    return {
        type: ActionTypes.SetOrganisationId,
        ...data
    };
};

export type SetOrganisationIdAction = Action & SetOrganisationIdActionOptions;
export const isSetOrganisationIdAction = (action: Action): action is SetOrganisationIdAction => action.type === ActionTypes.SetOrganisationId;
