import styled from "styled-components";
import { Colors } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";
import { Padding } from "@style/padding";
import { DaycareListStyle } from "@components/daycare/daycareList/daycareListStyle";
import { DaycareProblemsListStyle } from "@components/daycare/daycareProblemsList/daycareProblemsListStyle";
import { DaycareSearchResultsStyle } from "@components/daycare/daycareSearchResults/daycareSearchResultsStyle";
import { DaycareList } from "@components/daycare/daycareList/daycareList";

export const DayCareOverviewPageStyle = styled.div`
    position: relative;

    .greenBlock {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${Colors.primary()};
        height: 210px;
        z-index: 0;
    }

    .bar {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .ant-select {
            width: 100%;
            max-width: 250px;

            .ant-select-selection {
                background-color: transparent;
                border-radius: 0px;
                color: ${Colors.white};
                border: none;
            }

            .anticon {
                color: ${Colors.white};
            }
        }
    }

    & > ${InnerContainer} {
        z-index: 1;
        position: relative;
    }

    ${DaycareSearchResultsStyle} {
        margin-top: ${Padding.large};
    }

    & > ${InnerContainer} > h1 {
        color: ${Colors.white};
        font-weight: 200;
        font-size: 48px;
        padding: ${Padding.large} 0 0 0;
    }

    & > ${InnerContainer} > h2 {
        color: ${Colors.white};
        font-weight: 200;
        font-size: 18px;
        padding: ${Padding.large} 0 0 0;
    }


`;

export const BoxContainer = styled(InnerContainer)`
    display: flex;
    align-items: flex-start;
    z-index: 1;
    position: relative;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .spacer {
        display: none;
    }


    @media (max-width: 425px) {
        ${DaycareListStyle} {
            min-width: calc(100vw - 60px);
        }

        ${DaycareProblemsListStyle} {
            min-width: calc(100vw - 70px);
        }
    }

    @media (max-width: 750px) {
        ${DaycareProblemsListStyle} {
            margin-right: 20px;
        }

        ${DaycareListStyle} + .spacer {
            display: none;
        }

        .spacer {
            width: 20px;
            display: block;
        }
    }

    & > div:not(:last-of-type) {
        margin-right:${Padding.medium};
    }
`;
