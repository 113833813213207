export const parseDate = (date: string): string => {
    const [val, day, month, year] = date.match(/(0?[1-9]|01|[12]\d|3[01])\/(0?[1-9]|1[0-2])\/([12]\d{3}|\d{2})$/);

    return `${year}-${month}-${day}`;
};

export const isValidDateString = (date: string): boolean => !!date.match(/(0?[1-9]|01|[12]\d|3[01])\/(0?[1-9]|1[0-2])\/([12]\d{3}|\d{2})$/);

// regex string that allows
// 1/2/2019 - 1/2/19 - 01/02/2019 - etc
// (0?[1-9]|01|[12]\d|3[01])\/(0?[1-9]|1[0-2])\/([12]\d{3}|\d{2})$

// original /(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/([12]\d{3})/
