import styled from "styled-components";

export const TimeInputStyle = styled.div`
    display: inline-flex;

    .ant-input-number:first-of-type {
            margin-right: 10px;
        }
    }
`;
