import * as moment from "moment";
import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface SetRegistrationSelectedDateActionOptions {
    startDate: moment.Moment;
    endDate: moment.Moment;
}

export const setRegistrationSelectedDate = (data: SetRegistrationSelectedDateActionOptions): SetRegistrationSelectedDateAction => {
    return {
        type: ActionTypes.SetRegistrationSelectedDate,
        ...data
    };
};

export type SetRegistrationSelectedDateAction = Action & SetRegistrationSelectedDateActionOptions;
export const isSetRegistrationSelectedDateAction = (action: Action): action is SetRegistrationSelectedDateAction => action.type === ActionTypes.SetRegistrationSelectedDate;
