import styled from "styled-components";

import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";
import { Colors } from "@style/colors";
import { ParentChildListStyle } from "@components/parentChildList/parentChildListStyle";
import {
    ChildDetailRegistrationsListStyle
} from "@components/childDetail/childDetailRegistrationsList/childDetailRegistrationsListStyle";
import { Box } from "@style/box";

export const ParentOverviewPageStyle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .overlay {
        position: relative;
        z-index: 1;
    }

    .greenBlock {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${Colors.primary()};
        height: 100px;
        z-index: 0;
    }

    ${InnerContainer} {
        & > h1 {
            font-size: 24px;
            font-weight: 200;
            color: ${Colors.white};
        }
    }

    .content {
        display: flex;
        padding-top: ${Padding.large};
        padding-bottom: ${Padding.large};
        flex: 1;
        align-items: flex-start;
    }

    @media (min-width: 974px) {
        .content ${InnerContainer} {
            margin: 0;
            width: auto;
            padding-left: 0;
            padding-right: 0;

            &:last-of-type {
                width: 100%;
                margin-left: ${Padding.medium};
            }
        }
    }

    @media (max-width:975px ) {
        .content {
            flex-direction: column;
            margin: 0;
            padding-left: 0;
            padding-right: 0;

            ${ChildDetailRegistrationsListStyle} {
                margin-left: 0;
            }
        }

        ${ParentChildListStyle} {
            display: flex;
            padding-bottom: ${Padding.medium};
            overflow-x: auto;
            width: 100%;

            ${Box} {
                &:not(:last-of-type) {
                    margin: 0 ${Padding.medium} 0 0;
                }
            }
        }
    }
`;
