import * as React from "react";
import { FormattedMessage } from "react-intl";

import { ErrorBlockStyle } from "@components/errorBlock/errorBlockStyle";

export interface ErrorBlockProps {
    title?: string;
    info?: string;
}

export const ErrorBlock: React.StatelessComponent<ErrorBlockProps> = (props: ErrorBlockProps) => {
    const { info, title } = props;

    return (
        <ErrorBlockStyle>
            <img src={require("@assets/images/error.svg")} alt="error" />
            <h1><FormattedMessage id={title || "errorBlock.title"} /></h1>
            <h2><FormattedMessage id={info || "errorBlock.info"} /></h2>
        </ErrorBlockStyle>
    );
};
