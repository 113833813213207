import * as React from "react";

export interface IconProps extends React.HTMLAttributes<HTMLLIElement> {
    type:
    "add-user" |
    "back" |
    "calendar" |
    "camera" |
    "menu" |
    "next" |
    "previous" |
    "search" |
    "edit" |
    "questionmark" |
    "triangle-right" |
    "triangle-left" |
    "upload-fail" |
    "upload-success" |
    "checkmark" |
    "house" |
    "time" |
    "chevron-right" |
    "chevron-down" |
    "keypad" |
    "backspace" |
    "error" |
    "success" |
    "trash" |
    "info" |
    "rotate-camera" |
    "hourglass" |
    "three-dots" |
    "cross";
}

export const Icon: React.StatelessComponent<IconProps> = (props: IconProps) => {
    const { type, className, style } = props;

    return <i {...props} className={`icon ${type} ${className || ""}`} />;
};
