import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Placeholder } from "@style/placeholder";
import { Icon } from "@style/icon";
import { GetDaycareNamesDaycares } from "@models/graphql/types";
import { DaycareSearchResultListStyle } from "@components/daycare/daycareSearchResultList/daycareSearchResultListStyle";
import { DaycareSearchResultListProps } from "@components/daycare/daycareSearchResultList/daycareSearchResultListContainer";

export interface DaycareSearchResultListState {

}

@autobind
export class DaycareSearchResultList extends React.Component<DaycareSearchResultListProps, DaycareSearchResultListState> {

    public render() {

        return (
            <DaycareSearchResultListStyle>
                <FormattedMessage tagName="h2" id="daycareSearchResultList.daycares" />
                {this.renderContent()}
            </DaycareSearchResultListStyle>
        );

    }

    private renderContent() {
        const { daycares, daycaresLoading, history } = this.props;

        if (daycaresLoading) {

            return (
                <>
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </>
            );
        }

        if (daycares && !daycares.length) {
            return (
                <FormattedMessage id="daycareSearchResultList.noResulst" />
            );
        }

        if (!daycares) {
            return "";
        }

        return (
            <ul>
                {daycares.map(daycare => <li onClick={() => this.redirectToDaycare(daycare)} key={daycare.id}>{daycare.name} - {daycare.caseNumber}<Icon type="chevron-right" /></li>)}
            </ul>
        );
    }

    private redirectToDaycare(daycare: GetDaycareNamesDaycares) {
        const { history, setDaycare } = this.props;
        const { id } = daycare;

        setDaycare({ daycareId: id });

        history.push(`/daycare`);
    }
}
