import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { RegistrationFormModal } from "@components/registrationFormModal/registrationFormModal";
import { Action } from "@redux/actions/action";
import { UpdateEditRegistrationActionOptions, updateEditRegistration } from "@redux/actions/updateEditRegistrationId";
import { UpdateCreateRegistrationActionOptions, updateCreateRegistration } from "@redux/actions/updateCreateRegistration";
import { injectIntl, InjectedIntlProps } from "react-intl";

export interface RegistrationFormModalContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: RegistrationFormModalContainerProps) => ({
    editRegistration: state.registration.editRegistration,
    createRegistration: state.registration.createRegistration
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    updateEditRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); },
    updateCreateRegistration(data: UpdateCreateRegistrationActionOptions) { dispatch(updateCreateRegistration(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const RegistrationFormModalContainer = compose<React.ComponentClass<RegistrationFormModalContainerProps>>(
    withRedux,
    injectIntl
)(RegistrationFormModal);

export type RegistrationFormModalProps =
    RegistrationFormModalContainerProps &
    InjectedIntlProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
