import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { QrCodeReader } from "@components/qrCodeReader/qrCodeReader";
import { EntryType } from "@models/graphql/types";

export interface QrCodeReaderContainerProps {
    entryType: EntryType;
    hideRegisterButton?: boolean;
    scannedCallback(code: string): void;
}

export const mapStateToProps = (state: StoreState, ownProps: QrCodeReaderContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const QrCodeReaderContainer = compose<React.ComponentClass<QrCodeReaderContainerProps>>(
    withRedux
)(QrCodeReader);

export type QrCodeReaderProps =
    QrCodeReaderContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
