import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { updateEditRegistration, UpdateEditRegistrationActionOptions } from "@redux/actions/updateEditRegistrationId";
import { Action } from "@redux/actions/action";
import { createGetUploadDetailQuery, GetUploadDetailQueryResult } from "@graphql/hocs/getUploadDetail";
import { UploadDetail } from "@components/uploadDetail/uploadDetail";

export interface UploadDetailContainerProps {
    activeMonthId?: number;
}

export const mapStateToProps = (state: StoreState, ownProps: UploadDetailContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    editRegistration(data: UpdateEditRegistrationActionOptions) { dispatch(updateEditRegistration(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetUploadDetail = createGetUploadDetailQuery<UploadDetailContainerProps>({
    options(ownProps) {
        return {
            variables: {
                filter: {
                    id: ownProps.activeMonthId
                }
            }
        };
    },
    skip(ownProps) {
        if (ownProps.activeMonthId) {
            return false;
        }

        return true;
    }
});

export const UploadDetailContainer = compose<React.ComponentClass<UploadDetailContainerProps>>(
    withRedux,
    withGetUploadDetail
)(UploadDetail);

export type UploadDetailProps =
    WithReduxDispatchProps &
    WithReduxStateProps &
    UploadDetailContainerProps &
    GetUploadDetailQueryResult;
