import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";

import { createGetInactiveMonthsQuery, GetInactiveMonthsQueryResult } from "@graphql/hocs/getInactiveMonths";
import { createDeleteInactiveMonthMutation, DeleteInactiveMonthMutationResult } from "@graphql/hocs/deleteInactiveMonth";
import { createCreateInactiveMonthMutation, CreateInactiveMonthMutationResult } from "@graphql/hocs/createInactiveMonth";
import { InactiveMonthsOverview } from "@components/inactiveMonthsOverview/inactiveMonthsOverview";
import { createGetFirstUploadQuery, GetFirstUploadQueryResult } from "@graphql/hocs/getFirstUpload";

export interface InactiveMonthsOverviewContainerProps {

}

const withGetFirstUpload = createGetFirstUploadQuery();
const withGetInactiveMonths = createGetInactiveMonthsQuery();
const withDeleteInactiveMonth = createDeleteInactiveMonthMutation();
const withCreateInactiveMonth = createCreateInactiveMonthMutation();

export const InactiveMonthsOverviewContainer = compose<React.ComponentClass<InactiveMonthsOverviewContainerProps>>(

    injectIntl,
    withGetInactiveMonths,
    withDeleteInactiveMonth,
    withCreateInactiveMonth,
    withGetFirstUpload
)(InactiveMonthsOverview);

export type InactiveMonthsOverviewProps =
    InactiveMonthsOverviewContainerProps &

    InjectedIntlProps &
    GetFirstUploadQueryResult &
    GetInactiveMonthsQueryResult &
    DeleteInactiveMonthMutationResult &
    CreateInactiveMonthMutationResult;
