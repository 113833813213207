import styled from "styled-components";
import { Box } from "@style/box";
import { Padding } from "@style/padding";
import { Placeholder } from "@style/placeholder";

export const DaycareListStyle = styled(Box)`
    margin-top: ${Padding.large};
    margin-bottom: ${Padding.large};
    padding: 12px 0;
    flex: 1;
    min-width: 380px;

    ${Placeholder} {
        height: 40px;
        &:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }
`;
