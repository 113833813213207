import Form, { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { AdminLoginForm } from "./adminLoginForm";
import { createLoginMutation, LoginMutationResult } from "@graphql/hocs/loginMutation";

export interface AdminLoginFormContainerProps {

}

interface RouteParams {
    status?: string;
}

export const mapStateToProps = (state: StoreState, ownProps: AdminLoginFormContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withLoginMutation = createLoginMutation();

const withForm = Form.create();

export const AdminLoginFormContainer = compose<React.ComponentClass<AdminLoginFormContainerProps>>(
    withRedux,
    injectIntl,
    withForm,
    withRouter,
    withLoginMutation
)(AdminLoginForm);

export type AdminLoginFormProps =
    AdminLoginFormContainerProps &
    WithReduxDispatchProps &
    LoginMutationResult &
    InjectedIntlProps &
    RouteComponentProps<RouteParams> &
    FormComponentProps &
    WithReduxStateProps;
