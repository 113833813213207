import { GetDaycareProblemsQuery, GetDaycareProblemsVariables, GetDaycareProblemsDaycares } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";

export interface GetDaycareProblemsQueryResult {
    daycareProblems?: GetDaycareProblemsDaycares[];
    daycareProblemsLoading: boolean;
}

export const createGetDaycareProblemsQuery = <TProps = {}>(options?: OperationOption<TProps, GetDaycareProblemsQuery, GetDaycareProblemsVariables, GetDaycareProblemsQueryResult>) =>
    graphql<TProps, GetDaycareProblemsQuery, GetDaycareProblemsVariables, GetDaycareProblemsQueryResult>(GraphQL.getDaycareProblems, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            const withProblems = data.daycares && data.daycares.filter(daycare => daycare.invalidRegistrations.length || daycare.invalidUploads.length);

            return {
                daycareProblems: withProblems,
                daycareProblemsLoading: data.loading
            };
        },
        ...options
    });
