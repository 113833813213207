import { Popover } from "antd";
import classNames from "classnames";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { minutesToHm } from "@utils/minutesToHm";
import { isSuspended } from "@utils/isSupsended";
import { isDayAggregation, isWeekAggregation } from "@utils/aggregatedRegistrationHelper";
import { Icon } from "@style/icon";
import { TableBlock } from "@pages/registrationsOverview/registrationsOverviewStyle";
import {
    Aggregation,
    EntryType,
    GetChildrenRegistrationListEntries,
    GetChildrenRegistrationListRegistration
} from "@models/graphql/types";
import {
    CheckinDisplay,
    EmptyBlock,
    InvalidBlock,
    NowPresentBlock,
    RegistrationItemStyle,
    ValidBlock
} from "@components/registrations/registrationItem/registrationItemStyle";
import { RegistrationItemProps } from "@components/registrations/registrationItem/registrationItemContainer";
import { ChildBenefitCheck } from "@components/childBenefitCheck/childBenefitCheck";

export class RegistrationItem extends React.PureComponent<RegistrationItemProps>  {

    public render() {
        const { child, history } = this.props;
        const { firstName, lastName, gender, birthdate, childBenefitPeriods, id } = child;

        return (
            <RegistrationItemStyle>
                <TableBlock onClick={() => history.push(`/child/${id}`)} className="infoColumn">
                    <div className="name">
                        {firstName} {lastName}
                    </div>
                    <div className="info">
                        <FormattedMessage id={`gender.${gender.toLowerCase()}`} />&nbsp;&nbsp;|&nbsp;&nbsp;{moment(birthdate).format("L")}
                        <ChildBenefitCheck childBenefitPeriods={childBenefitPeriods} />
                    </div>
                </TableBlock>
                {this.renderBlocks()}

            </RegistrationItemStyle>
        );
    }

    // tslint:disable-next-line:cyclomatic-complexity max-func-body-length
    private renderBlocks() {
        const { child, startDate, aggregation, endDate, intl, daycares } = this.props;

        if (!daycares || !daycares.length) {
            return;
        }

        const date = startDate.clone();
        const result: JSX.Element[] = [];

        if (aggregation === Aggregation.DAY) {
            let i = 0;
            while (i < 7) {

                const match = child.aggregatedRegistrations.find(reg => date.isSame(reg.date, "d"));

                if (match && !isDayAggregation(match)) {
                    console.warn("Returned aggregation is not correct");

                    return;
                }

                if (match) {
                    if (match.registration.valid) {

                        let content = (
                            <TableBlock onClick={() => this.onEditRegistration(match.registration, match.date)} key={i}>
                                <ValidBlock>
                                    {minutesToHm(match.amountOfMinutes)}
                                </ValidBlock>
                            </TableBlock>
                        );

                        if (date.isSame(moment(), "d") && match.registration.entries.length > 0) {
                            const lastEntry = match.registration.entries[match.registration.entries.length - 1];

                            if (lastEntry.type === EntryType.IN) {
                                content = (
                                    <TableBlock onClick={() => this.onEditRegistration(match.registration, match.date)} key={i}>
                                        <NowPresentBlock>
                                            <FormattedMessage id="registrationItem.nowPresent" />
                                        </NowPresentBlock>
                                    </TableBlock>
                                );
                            }
                        }

                        if (match.registration.entries && match.registration.entries.length) {
                            result.push(
                                <Popover key={i} content={this.renderPopoverContent(match.registration.entries)}>
                                    {content}
                                </Popover>);
                        } else {
                            result.push(content);
                        }

                    } else {

                        const content = (
                            <TableBlock onClick={() => this.onEditRegistration(match.registration, match.date)} key={i}>
                                <InvalidBlock>
                                    <div className="circle"><Icon type="edit" /></div>
                                    <FormattedMessage id="registrationItem.invalid" />
                                </InvalidBlock>
                            </TableBlock>
                        );

                        if (match.registration.entries && match.registration.entries.length) {
                            result.push(
                                <Popover key={i} content={this.renderPopoverContent(match.registration.entries)}>
                                    {content}
                                </Popover>);
                        } else {
                            result.push(content);
                        }

                    }
                } else {
                    const regDate = date.toISOString();
                    const disabled = date.isAfter(moment(), "day") || isSuspended(daycares[0].activeMonths, date);

                    result.push(<TableBlock key={i}>
                        <EmptyBlock onClick={() => this.onCreateRegistration(regDate)} className={classNames({ disabled })} >
                            +
                        </EmptyBlock>
                    </TableBlock>);
                }

                date.add(1, "d");
                i += 1;
            }

        } else {
            const weeks = Math.abs(startDate.diff(endDate, "weeks")) + 1;

            let i = 0;
            while (i < weeks) {
                const weekDate = date.clone().add(i, "weeks");

                const match = child.aggregatedRegistrations.find(reg => weekDate.isSame(reg.date, "week"));

                if (match && !isWeekAggregation(match)) {
                    console.warn("Returned aggregation is not correct");

                    return;
                }

                if (match) {
                    if (match.allRegistrationsValid) {
                        result.push(
                            <TableBlock onClick={() => this.changeDate(weekDate)} key={i}>
                                <ValidBlock className="week">
                                    {match.amountOfDays}&nbsp;<FormattedMessage id={`registrationItem.days.${intl.formatPlural(match.amountOfDays)}`} />
                                </ValidBlock>
                            </TableBlock>
                        );
                    } else {
                        result.push(
                            <TableBlock onClick={() => this.changeDate(weekDate)} key={i}>
                                <InvalidBlock className="week">
                                    <FormattedMessage id="registrationItem.invalidRegistrations" />
                                </InvalidBlock>
                            </TableBlock>
                        );
                    }
                } else {
                    result.push(<TableBlock key={i}>
                        <EmptyBlock onClick={() => this.changeDate(weekDate)} className="week">
                            <FormattedMessage id="registrationItem.noRegistrations" />
                        </EmptyBlock>
                    </TableBlock>);
                }

                i += 1;
            }
        }

        return result;
    }

    private onEditRegistration(registration: GetChildrenRegistrationListRegistration, date: string) {
        const { editRegistration, child } = this.props;

        editRegistration({
            edit: {
                childId: child.id,
                registration,
                date
            }
        });
    }

    private onCreateRegistration(date: string) {
        const { createRegistration, child } = this.props;

        createRegistration({
            create: {
                childId: child.id,
                date
            }
        });
    }

    private renderPopoverContent(entries: GetChildrenRegistrationListEntries[]) {

        const filledEntries: GetChildrenRegistrationListEntries[] = [];

        entries.forEach((entry, index) => {
            if (index === 0 && entry.type === EntryType.OUT) {
                filledEntries.push({
                    type: EntryType.IN,
                    time: "",
                    order: -1,
                    id: -1
                });
                filledEntries.push(entry);

                return;
            } else if (entries[index + 1] && entries[index + 1].type === entry.type) {
                filledEntries.push(entry);
                filledEntries.push({
                    type: entry.type === EntryType.IN ? EntryType.OUT : EntryType.IN,
                    time: "",
                    order: -1,
                    id: -1
                });

                return;
            } else {
                filledEntries.push(entry);
            }

        });

        return (
            <CheckinDisplay>

                {filledEntries.map((entry, index) => {

                    if (!moment(entry.time).isValid()) {
                        return (
                            <span key={index} className="error">
                                <Icon type="cross" /> --:--
                    </span>
                        );
                    }

                    if (entry.type === EntryType.IN) {
                        return (
                            <span key={index} className="checkin">
                                <Icon type="triangle-right" /> {moment(entry.time).format("HH:mm")}
                            </span>
                        );
                    }

                    if (entry.type === EntryType.OUT) {
                        return (
                            <span key={index} className="checkout">
                                <Icon type="triangle-left" /> {moment(entry.time).format("HH:mm")}
                            </span>
                        );
                    }

                })}
            </CheckinDisplay>
        );
    }

    private changeDate(weekDate: moment.Moment) {

        const { setDate, setAggregation } = this.props;

        setDate({
            startDate: weekDate.clone().startOf("week"),
            endDate: weekDate.clone().endOf("week")
        });

        setAggregation({ aggregation: Aggregation.DAY });
    }
}
