import { ApolloCache } from "apollo-cache";
import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from "apollo-cache-inmemory";
import { CachePersistor } from "apollo-cache-persist";
import { ApolloClient } from "apollo-client";
import { ApolloLink, from } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import * as localForage from "localforage";

// FIXME: circular dependency if AuthService is imported before redux store
import "@redux/setupStore";
import { STORE } from "@redux/setupStore";

import { AuthService } from "@utils/authService";

import { QueueLink } from "./queueLink";

export const cacheInstance = localForage.createInstance({
    name: "apollo-cache",
    driver: localForage.INDEXEDDB
});

export interface GetClientOptions {
    linksBefore?: ApolloLink[];
    linksAfter?: ApolloLink[];
}

let savedCache: ApolloCache<NormalizedCacheObject>;

const loadCache = async (): Promise<ApolloCache<NormalizedCacheObject>> => {
    if (savedCache) {
        return savedCache;
    }

    const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: require("@graphql/fragmentTypes.json")
    });

    savedCache = new InMemoryCache({
        fragmentMatcher
    });

    const persistor = new CachePersistor({
        cache: savedCache,
        storage: cacheInstance as any // Because types
    });

    // const age = localStorage.getItem("age");

    // Refresh cache if it's older than 30 minutes and there is internet detected;
    // if (!navigator.onLine) {
    //     await persistor.restore();
    // } else if (age && moment().subtract(30, "m").isBefore(age)) {
    //     await persistor.restore();
    // } else {
    //     await persistor.purge();
    //     localStorage.setItem("age", moment().toISOString());
    // }

    await persistor.restore();

    return savedCache;
};

export const getClient = async (options: GetClientOptions = {}) => {
    const { linksBefore = [], linksAfter = [] } = options;

    const cache = await loadCache();

    const authLink = setContext((operation, previousContext) => {

        const { headers } = previousContext;

        const { daycare, auth } = STORE.getState();

        const newHeaders = {
            ...headers
        };

        if (daycare.selectedDaycareId) {
            newHeaders.daycareId = daycare.selectedDaycareId;
        } else if (previousContext.daycareId) {
            newHeaders.daycareId = previousContext.daycareId;
        }

        if (auth.user) {
            newHeaders.Authorization = `Bearer ${auth.user.token}`;
        }

        return {
            ...previousContext,
            headers: newHeaders
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError}) => {
        if (graphQLErrors) {
            for (const err of graphQLErrors) {
                if (err && err.extensions) {
                    // tslint:disable-next-line: switch-default
                    switch (err.extensions.code) {
                        case "UNAUTHENTICATED": {
                            AuthService.logout();

                            return;
                        }
                    }
                }
            }
        }
    });

    const link = from([
        authLink,
        ...linksBefore,
        errorLink,
        ...linksAfter,
        new BatchHttpLink({
            uri: "/graphql"
        })
    ]);

    return new ApolloClient({
        link,
        cache,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "cache-and-network"
            },
            query: {
                fetchPolicy: "cache-and-network"
            }
        },
        connectToDevTools: true
    });
};

export const queueLink = new QueueLink(getClient);
