import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { createUpdateChildMutation, UpdateChildMutationResult } from "@graphql/hocs/updateChild";
import { createGetChildDetailQuery, GetChildDetailQueryResult } from "@graphql/hocs/getChildDetail";
import { ChildDetailsForm } from "@components/childDetail/childDetailsForm/childDetailsForm";

export interface ChildDetailsFormContainerProps {
    childId: number;
}

export const mapStateToProps = (state: StoreState, ownProps: ChildDetailsFormContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

const withGetChildDetail = createGetChildDetailQuery<ChildDetailsFormContainerProps>({
    options(ownProps) {
        return {
            variables: {
                filter: {
                    childIds: [ownProps.childId]
                }
            }
        };
    }
});

const withUpdateChildMutation = createUpdateChildMutation({ awaitRefetchQueries: true });

export const ChildDetailsFormContainer = compose<React.ComponentClass<ChildDetailsFormContainerProps>>(
    withRedux,
    withForm,
    injectIntl,
    withGetChildDetail,
    withUpdateChildMutation
)(ChildDetailsForm);

export type ChildDetailsFormProps =
    ChildDetailsFormContainerProps &
    WithReduxDispatchProps &
    FormComponentProps &
    InjectedIntlProps &
    GetChildDetailQueryResult &
    WithReduxStateProps &
    UpdateChildMutationResult;
