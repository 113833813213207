import { Reducer } from "redux";

import { User } from "@utils/user";
import { AuthService } from "@utils/authService";
import { isSetOrganisationIdAction } from "@redux/actions/setOrganisationId";
import { isSetUserAction } from "@redux/actions/auth/setUser";
import { Action } from "@redux/actions/action";

const loadedUser = AuthService.getUser();

const INITIAL_STATE: AuthReducerState = {
    user: loadedUser,
    organisationId: loadedUser && loadedUser.organiser ? loadedUser.organiser.id : null
};

export interface AuthReducerState {
    user: User | null;
    organisationId: string | null;
}

export const authReducer: Reducer<AuthReducerState> = (state = INITIAL_STATE, action: Action) => {

    if (isSetUserAction(action)) {
        return {
            ...state,
            user: action.user,
            organisationId: action.user && action.user.organiser ? action.user.organiser.id : null
        };
    }

    if (isSetOrganisationIdAction(action)) {
        return {
            ...state,
            organisationId: action.organisationId
        };
    }

    return state;
};
