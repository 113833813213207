import * as React from "react";

import { compose, Dispatch } from "redux";

import { DaycareDetailPage } from "@pages/daycareDetailPage/daycareDetailPage";
import { withRouter, RouteComponentProps } from "react-router";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { connect } from "react-redux";
import { updateShowQrCode } from "@redux/actions/updateShowQrCode";
import { createGetDaycareNamesQuery, GetDaycareNamesQueryResult } from "@graphql/hocs/getDaycareNames";
import { createLogoutDaycareMutation, LogoutDaycareMutationResult } from "@graphql/hocs/logoutDaycare";
import { InjectedIntlProps, injectIntl } from "react-intl";

export interface DaycareDetailPageContainerProps {

}

interface RouteParams {
    id: string;
}

export const mapStateToProps = (state: StoreState, ownProps: DaycareDetailPageContainerProps) => ({
    showQrCode: state.daycare.showQrCode,
    user: state.auth.user,
    selectedDaycareId: state.daycare.selectedDaycareId,
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    hideQrCode() { dispatch(updateShowQrCode({ qrCode: null })); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withgetDaycare = createGetDaycareNamesQuery<WithReduxStateProps>({
    options(ownProps) {
        if (!ownProps.organisationId) {
            throw new Error("No organisation ID found");
        }

        return {
            variables: {
                filter: {
                    id: ownProps.selectedDaycareId,
                    organisationId: ownProps.organisationId
                }
            }
        };
    }
});

const withLogoutDaycare = createLogoutDaycareMutation({});

export const DaycareDetailPageContainer = compose<React.ComponentClass<DaycareDetailPageContainerProps>>(
    injectIntl,
    withRedux,
    withRouter,
    withgetDaycare,
    withLogoutDaycare
)(DaycareDetailPage);

export type DaycareDetailPageProps =
    InjectedIntlProps &
    DaycareDetailPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    LogoutDaycareMutationResult &
    GetDaycareNamesQueryResult &
    RouteComponentProps<RouteParams>;
