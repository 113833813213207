import * as React from "react";
import { connect } from "react-redux";
import { compose , Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { LoginOverviewPage } from "@pages/loginOverviewPage/loginOverviewPage";

export interface LoginOverviewPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: LoginOverviewPageContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const LoginOverviewPageContainer = compose<React.ComponentClass<LoginOverviewPageContainerProps>>(
    withRedux
)(LoginOverviewPage);

export type LoginOverviewPageProps =
    LoginOverviewPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
