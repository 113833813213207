import { GetDaycareListQuery, GetDaycareListVariables, GetDaycareListDaycares } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";

export interface GetDaycareListQueryResult {
    daycares?: GetDaycareListDaycares[];
    daycaresLoading: boolean;
}

export const createGetDaycareListQuery = <TProps = {}>(options?: OperationOption<TProps, GetDaycareListQuery, GetDaycareListVariables, GetDaycareListQueryResult>) =>
    graphql<TProps, GetDaycareListQuery, GetDaycareListVariables, GetDaycareListQueryResult>(GraphQL.getDaycareList, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                daycares: data.daycares,
                daycaresLoading: data.loading
            };
        },
        ...options
    });
