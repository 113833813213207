import { Popover } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";

import { Placeholder } from "@style/placeholder";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { GetChildrenNamesChildren } from "@models/graphql/types";
import { EmptyBlock } from "@components/registrations/registrationList/registrationListStyle";
import { ErrorBlock } from "@components/errorBlock/errorBlock";
import {
    ChildListItem,
    ChildrenOverviewListStyle,
    QrCodePopover
} from "@components/childrenOverviewList/childrenOverviewListStyle";
import { ChildrenOverviewListProps } from "@components/childrenOverviewList/childrenOverviewListContainer";
import { ChildPrintView } from "@components/childPrintView/childPrintView";

export interface ChildrenOverviewListState {
    printChild: GetChildrenNamesChildren | null;
}

@autobind
export class ChildrenOverviewList extends React.Component<ChildrenOverviewListProps, ChildrenOverviewListState> {
    public state: ChildrenOverviewListState = {
        printChild: null
    };

    private printEl: null | React.ComponentClass;
    private printTriggerEl: any;

    public render() {
        const { childrenList: children, childrenLoading, refCallback } = this.props;
        const { printChild } = this.state;

        if (childrenLoading && !children) {
            return (
                <ChildrenOverviewListStyle>
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </ChildrenOverviewListStyle>
            );
        }

        if (!children) {
            return (
                <ChildrenOverviewListStyle>
                    <ErrorBlock />
                </ChildrenOverviewListStyle>
            );
        }

        return (
            <ChildrenOverviewListStyle>
                <ReactToPrint
                    ref={(el => this.printTriggerEl = el)}
                    trigger={() => <span></span>}
                    content={() => this.printEl}
                />
                <ChildPrintView child={printChild} refCallback={(el) => this.printEl = el} />
                <div className="list" ref={refCallback} >
                    <FormattedMessage tagName="h2" id={"childrenOverviewList.activeChildren"} />
                    {this.renderChildren(true)}
                    <FormattedMessage tagName="h2" id={"childrenOverviewList.inactiveChildren"} />
                    {this.renderChildren(false)}
                </div>
            </ChildrenOverviewListStyle>
        );
    }

    private renderChildren(active: boolean) {
        const { childrenList: children, updateShowQrCode, filter } = this.props;

        if (children) {

            if (!children.length) {
                return (

                    <EmptyBlock>
                        <img src={require("@assets/images/kids-circle.svg")} alt="circle-kids" />
                        {!filter.search ?
                            <>
                                <FormattedMessage id="childrenOverviewList.noChildren" />
                                <Link to="/add-child" >
                                    <Button size="large" type="primary"><Icon type="add-user" /><FormattedMessage id="subHeader.addChild" /></Button>
                                </Link>
                            </>
                            :
                            <FormattedMessage id="childrenOverviewList.noChildrenSearch" />
                        }
                    </EmptyBlock>
                );
            }

            return children.map(child => {
                const { firstName, lastName, id, gender, birthdate, code, isActive, childBenefitEntitlement, childBenefitPeriods } = child;

                const popoverContent = (
                    <QrCodePopover>
                        <span onClick={() => updateShowQrCode({ qrCode: code })}><FormattedMessage id="childrenOverviewList.showQr" /></span>
                        <span onClick={() => this.setPrintChild(child)}><FormattedMessage id="childrenOverviewList.printQr" /></span>
                    </QrCodePopover>
                );

                if (isActive === active) {
                    return <ChildListItem key={id}>
                    <Link to={`/child/${id}`} >
                        <span className="name">
                            {firstName} {lastName}
                        </span>
                        <div className="gender">
                            <FormattedMessage id={`gender.${gender.toLowerCase()}`} />
                        </div>
                        <div className="birthdate">
                            {moment(birthdate).format("DD-MM-YYYY")}
                        </div>
                        <div className="childBenefits">
                            { this.renderChildBenefitEntitlement(childBenefitPeriods) }
                        </div>
                    </Link>
                    <div className="code">
                        {code}
                        <Popover content={popoverContent}>
                            <img alt={code} src={`https://aaron.kindengezin.be/qr/${code}`} />
                            {/* <QRCode bgColor={Colors.white} fgColor={Colors.text} value={code} /> */}
                        </Popover>
                    </div>
                </ChildListItem>;
                }

            });
        }

    }

    private renderChildBenefitEntitlement(childBenefitPeriods) {
        if (childBenefitPeriods.length > 0) {

            const match = childBenefitPeriods.find(item => {
                const now = moment();
                const start = moment(item.start);

                if (item.end) {
                    const end = moment(item.end);
                    if (now.isBetween(start, end)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (now.isAfter(start)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });

            if (match) {
                if (!match.end) {
                    return <FormattedMessage id="childrenOverviewList.noBenefitsTill" values={{ type: "noDate", startDate: moment(match.start, "YYYY-MM-DD").format("DD/MM/YYYY") }} />;
                } else {
                    return <FormattedMessage id="childrenOverviewList.noBenefitsTill" values={{ type: "date", endDate: moment(match.end, "YYYY-MM-DD").format("DD/MM/YYYY"), startDate: moment(match.start, "YYYY-MM-DD").format("DD/MM/YYYY") }} />;
                }
            } else {
                return <FormattedMessage id={"childrenOverviewList.benefits"} />;

            }
        } else {
            return <FormattedMessage id={"childrenOverviewList.benefits"} />;
        }
    }

    private setPrintChild(child: GetChildrenNamesChildren) {

        this.setState({ printChild: child }, () => {
            if (this.printTriggerEl) {
                this.printTriggerEl.handlePrint();
            }
        });
    }
}
