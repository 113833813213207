import styled from "styled-components";

import { Placeholder } from "@style/placeholder";
import { Padding } from "@style/padding";
import { InnerContainer } from "@style/innerContainer";
import { Colors } from "@style/colors";
import { Status } from "@models/graphql/types";

export const UploadsOverviewStyle = styled.div`
    margin-top: ${Padding.large};

    & > ${InnerContainer} {
        display: flex;
    }

    nav {
        flex-basis: 175px;
        flex-shrink: 0;
        margin-right: ${Padding.large};

        ${Placeholder} {
            height: 40px;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }


        .item {

        }
    }

    @media (max-width: 575px) {
        & > ${InnerContainer} {
            flex-direction: column;
        }
    }


    .ant-select {
        margin-bottom: ${Padding.large};
    }
`;

export const NavItem = styled.div`
    position: relative;
    cursor: pointer;
    height: 50px;

    .content {
        height: 50px;
        display: flex !important;
        align-items: center;
        font-weight: 600;
        z-index: 1;
        position: relative;
    }

    &.mobile {
        height: auto;

        .content {
            height: auto;
        }

        .status {
            margin-right: 8px;
        }
    }

    &:not(.mobile) {
        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: -2000px;
            z-index: 0;
            background-color: #e9e9e9;
            opacity: 0;
            transition: .2s;
            border-radius: 4px;
        }

        &:hover, &.active {
            &::after {
                opacity: 1;
            }
        }
    }

    .status {
        width: 15px;
        height: 15px;
        background-color: ${Colors.grey};
        border-radius: 100%;
        margin-right: ${Padding.medium};
        font-size: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.${Status.PENDING_FEEDBACK} {
        color: ${Colors.error()};
        .status {
            background-color: ${Colors.error()};
            color: ${Colors.white};
        }

    }

    &.${Status.PROCESSED} {
        .status {
            background-color: ${Colors.primary()};
            color: ${Colors.white};
        }
    }

    &.${Status.PLANNED} {
        color: ${Colors.grey};
    }

    &.hasRemarks {
        color: #cf9942 !important;
        .status {
            background-color: #cf9942;
            color: ${Colors.white};
        }
    }


`;
