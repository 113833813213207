import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Box } from "@style/box";
import { FaqPageStyle } from "@pages/faqPage/faqPageStyle";
import { FaqPageProps } from "@pages/faqPage/faqPageContainer";
import { Collapse } from "antd";

export interface FaqPageState {

}

@autobind
export class FaqPage extends React.Component<FaqPageProps, FaqPageState> {

    public render() {

        const { } = this.props;

        return (
            <FaqPageStyle>
                <div className="greenBlock" />
                <InnerContainer>
                    <Link to="/"><Icon type="back" /><FormattedMessage id="faqPage.back" /></Link>
                    <Box>
                        <FormattedMessage tagName="h1" id="faqPage.title" />

                        <Collapse accordion bordered={false} defaultActiveKey={["1"]}>
                            <Collapse.Panel showArrow={false} header="Dit is een veelgestelde vraag?" key="1">
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis dolore quis consequatur nesciunt quibusdam quos id, cupiditate, incidunt quae voluptate est eum exercitationem, tempore explicabo nemo atque deleniti sapiente officia!
                                    Consequatur in fuga vitae nesciunt doloremque exercitationem molestias, labore impedit laborum harum mollitia et optio veritatis? Dolor sapiente quaerat blanditiis reprehenderit quasi quisquam doloremque. Quisquam illo quia tenetur quibusdam voluptas?
                                    Unde, dignissimos. Perspiciatis nam facere enim accusamus amet et dolor fuga. Culpa fugiat facilis aliquid eos delectus praesentium ipsam quia ut sit. Vel quas quidem, mollitia dolores possimus aliquid suscipit.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel showArrow={false} header="In fuga vitae nesciunt" key="2">
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis dolore quis consequatur nesciunt quibusdam quos id, cupiditate, incidunt quae voluptate est eum exercitationem, tempore explicabo nemo atque deleniti sapiente officia!
                                    Consequatur in fuga vitae nesciunt doloremque exercitationem molestias, labore impedit laborum harum mollitia et optio veritatis? Dolor sapiente quaerat blanditiis reprehenderit quasi quisquam doloremque. Quisquam illo quia tenetur quibusdam voluptas?
                                    Unde, dignissimos. Perspiciatis nam facere enim accusamus amet et dolor fuga. Culpa fugiat facilis aliquid eos delectus praesentium ipsam quia ut sit. Vel quas quidem, mollitia dolores possimus aliquid suscipit.
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis dolore quis consequatur nesciunt quibusdam quos id, cupiditate, incidunt quae voluptate est eum exercitationem, tempore explicabo nemo atque deleniti sapiente officia!
                                    Consequatur in fuga vitae nesciunt doloremque exercitationem molestias, labore impedit laborum harum mollitia et optio veritatis? Dolor sapiente quaerat blanditiis reprehenderit quasi quisquam doloremque. Quisquam illo quia tenetur quibusdam voluptas?
                                    Unde, dignissimos. Perspiciatis nam facere enim accusamus amet et dolor fuga. Culpa fugiat facilis aliquid eos delectus praesentium ipsam quia ut sit. Vel quas quidem, mollitia dolores possimus aliquid suscipit.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel showArrow={false} header="Culpa fugiat facilis" key="3">
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis dolore quis consequatur nesciunt quibusdam quos id, cupiditate, incidunt quae voluptate est eum exercitationem, tempore explicabo nemo atque deleniti sapiente officia!
                                    Consequatur in fuga vitae nesciunt doloremque exercitationem molestias, labore impedit laborum harum mollitia et optio veritatis? Dolor sapiente quaerat blanditiis reprehenderit quasi quisquam doloremque. Quisquam illo quia tenetur quibusdam voluptas?
                                    Unde, dignissimos. Perspiciatis nam facere enim accusamus amet et dolor fuga. Culpa fugiat facilis aliquid eos delectus praesentium ipsam quia ut sit. Vel quas quidem, mollitia dolores possimus aliquid suscipit.
                                </p>
                            </Collapse.Panel>
                        </Collapse>
                    </Box>
                </InnerContainer>
            </FaqPageStyle>
        );
    }
}
