import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { SettingsPage } from "@pages/settingsPage/settingsPage";
import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { createChangePasswordMutation, ChangePasswordMutationResult } from "@graphql/hocs/changePassword";
import { createChangeEmailMutation, ChangeEmailMutationResult } from "@graphql/hocs/changeEmail";

export interface SettingsPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: SettingsPageContainerProps) => ({
    user: state.auth.user
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withForm = Form.create();

const withChangePasswordMutation = createChangePasswordMutation();

const withChangeEmailMutation = createChangeEmailMutation();

export const SettingsPageContainer = compose<React.ComponentClass<SettingsPageContainerProps>>(
    withRedux,
    injectIntl,
    withForm,
    withChangePasswordMutation,
    withChangeEmailMutation
)(SettingsPage);

export type SettingsPageProps =
    SettingsPageContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    FormComponentProps &
    InjectedIntlProps &
    ChangePasswordMutationResult &
    ChangeEmailMutationResult;
