import styled from "styled-components";

import { Padding } from "@style/padding";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { AlertStyle } from "@components/alert/alertStyle";

export const AdminLoginFormStyle = styled.div`
    h1 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: ${Padding.medium};
        color: ${Colors.white};
    }

    ${Box} {
        padding: ${Padding.large};
    }


    .ant-form-item-label {
        line-height: inherit;
        padding-bottom: 8px;
        label {
            color: ${Colors.primary()};
        }
    }

    .ant-form-item:not(:first-of-type) {
        margin-top: 10px;
    }

    .ant-calendar-picker {
        width: 100%;
    }

    .ant-form-item-with-help {
        margin-bottom: 0;
    }
    .ant-form-item {
        margin-bottom: 0px;
        position: relative;

    }

    ${Button} {
        width: 100%;
        margin-top: ${Padding.medium};
        height: 45px;
        font-weight: 600;
    }

    ${AlertStyle} {
        margin-top: ${Padding.medium};
    }

    a.back {
        display: flex;
        align-items: center;

        .icon {
            font-size: 8px;
            margin-right: 10px;
        }
    }

    .bottomBar {
        padding-top: ${Padding.medium};
        display: flex;
        justify-content: space-between;
    }
`;
