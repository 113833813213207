import styled from "styled-components";

export const ChildPrintViewStyle = styled.div`
    width: 100vw;
    flex: 1;
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    color: black;


    .name {
        font-size: 40pt;
        font-weight: 600;
        margin-bottom: 40pt;
    }

    .birtdate {
        font-size: 22pt;
    }

    .code {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 30pt;
        letter-spacing: 5pt;
    }

    svg {
        width: 200pt;
        margin-bottom: 20pt;
    }

`;
