import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { QrCodeViewer } from "@components/qrCodeViewer/qrCodeViewer";
import { withRouter, RouteComponentProps } from "react-router";

export interface QrCodeViewerContainerProps {
    code: string;
    fgColor?: string;
    bgColor?: string;
}

export const mapStateToProps = (state: StoreState, ownProps: QrCodeViewerContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const QrCodeViewerContainer = compose<React.ComponentClass<QrCodeViewerContainerProps>>(
    withRedux
)(QrCodeViewer);

export type QrCodeViewerProps =
    QrCodeViewerContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps;
