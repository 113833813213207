import { autobind } from "core-decorators";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Logo } from "@style/logo";
import { InnerContainer } from "@style/innerContainer";
import { SetPasswordPageContainer } from "@pages/setPasswordPage/setPasswordPageContainer";
import { ResetPasswordPageContainer } from "@pages/resetPasswordPage/resetPasswordPageContainer";
import { LoginOverviewPageContainer } from "@pages/loginOverviewPage/loginOverviewPageContainer";
import { ParentLoginFormContainer } from "@components/parentLoginForm/parentLoginFormContainer";
import { OrganiserLoginFormContainer } from "@components/organiserLoginForm/organiserLoginFormContainer";
import { IntroLayoutStyle } from "@components/introLayout/introLayoutStyle";
import { IntroLayoutProps } from "@components/introLayout/introLayoutContainer";
import { DaycareLoginFormContainer } from "@components/daycareLoginForm/daycareLoginFormContainer";
import { ManualSyncFormContainer } from "@components/manualSyncForm/manualSyncFormContainer";
import { AdminLoginFormContainer } from "@components/adminLoginForm/adminLoginFormContainer";

export interface IntroLayoutState {

}

@autobind
export class IntroLayout extends React.Component<IntroLayoutProps, IntroLayoutState> {

    public render() {

        const { } = this.props;

        return (
            <IntroLayoutStyle>
                <div className="green" />
                <div className="overGreen">
                    <InnerContainer>
                        <Logo size="large" />
                    </InnerContainer>
                    <Switch>
                        <Route exact path="/login" component={LoginOverviewPageContainer} />
                        <Route path="/login/organiser/:status?" component={OrganiserLoginFormContainer} />
                        <Route path="/login/daycare/:status?" component={DaycareLoginFormContainer} />
                        <Route path="/login/parent/:status?" component={ParentLoginFormContainer} />

                        <Route path="/login/admin" component={AdminLoginFormContainer} />

                        <Route path="/login/reset-password" component={ResetPasswordPageContainer} />
                        <Route path="/login/set-password" component={SetPasswordPageContainer} />
                        <Redirect to="/login" />
                    </Switch>
                </div>
            </IntroLayoutStyle>
        );
    }

    public componentDidMount() {
        if (this.props.user) {
            this.props.history.push("/");
        }
    }
}
