import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";
import { ChildFilterInput } from "@models/graphql/types";

export interface UpdateRegistrationsFilterActionOptions {
    filter: Partial<ChildFilterInput>;
}

export const updateRegistrationsFilter = (data: UpdateRegistrationsFilterActionOptions): UpdateRegistrationsFilterAction => {
    return {
        type: ActionTypes.UpdateRegistrationsFilter,
        ...data
    };
};

export type UpdateRegistrationsFilterAction = Action & UpdateRegistrationsFilterActionOptions;
export const isUpdateRegistrationsFilterAction = (action: Action): action is UpdateRegistrationsFilterAction => action.type === ActionTypes.UpdateRegistrationsFilter;
