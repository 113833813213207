import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { updateShowQrCode, UpdateShowQrCodeActionOptions } from "@redux/actions/updateShowQrCode";
import { Action } from "@redux/actions/action";
import { ChildFilterInput } from "@models/graphql/types";
import { createGetChildrenNamesQuery, GetChildrenNamesQueryResult } from "@graphql/hocs/getChildrenNames";
import { ChildrenOverviewList } from "@components/childrenOverviewList/childrenOverviewList";

export interface ChildrenOverviewListContainerProps {
    filter: ChildFilterInput;
    refCallback: React.Ref<HTMLDivElement>;
}

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    updateShowQrCode(data: UpdateShowQrCodeActionOptions) { dispatch(updateShowQrCode(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(null, mapDispatchToProps);

const withGetChildrenNames = createGetChildrenNamesQuery<ChildrenOverviewListContainerProps>({
    options(ownProps) {

        return {
            variables: {
                filter: {
                    ...ownProps.filter
                }
            }
        };
    }
});

export const ChildrenOverviewListContainer = compose<React.ComponentClass<ChildrenOverviewListContainerProps>>(
    withGetChildrenNames,
    withRedux
)(ChildrenOverviewList);

export type ChildrenOverviewListProps =
    ChildrenOverviewListContainerProps &
    GetChildrenNamesQueryResult &
    WithReduxDispatchProps;
