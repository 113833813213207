import * as React from "react";
import { autobind } from "core-decorators";
import { RegistrationErrorPageProps } from "@pages/registrationErrorPage/registrationErrorPageContainer";
import { RegistrationErrorPageStyle } from "@pages/registrationErrorPage/registrationErrorPageStyle";
import { FormattedMessage } from "react-intl";
import { Button } from "@style/button";
import { Link } from "react-router-dom";

export interface RegistrationErrorPageState {

}

@autobind
export class RegistrationErrorPage extends React.Component<RegistrationErrorPageProps, RegistrationErrorPageState> {

    public render() {

        const { retry } = this.props;

        return (
            <RegistrationErrorPageStyle>
                <img src={require("@assets/images/error-white.svg")} alt="error" />

                <FormattedMessage tagName="h1" id="registrationErrorPage.title" />
                <FormattedMessage tagName="h2" id="registrationErrorPage.subTitle" />

                <Button onClick={retry}><FormattedMessage id="registrationErrorPage.retry" /></Button>
                <Link to="/registration"><FormattedMessage id="registrationErrorPage.back" /></Link>
            </RegistrationErrorPageStyle>
        );
    }
}
