import styled from "styled-components";
import { Box } from "@style/box";
import { Padding } from "@style/padding";

export const DaycareProblemsListStyle = styled(Box)`
    flex: 0.7;
    margin-top: ${Padding.large};
    margin-bottom: ${Padding.large};
    min-width: 300px;
`;
