import styled from "styled-components";
import { Colors } from "@style/colors";
import { Padding } from "@style/padding";

export const DateSelectorStyle = styled.div`
    & > div {
        display: flex;
        align-items: center;
        height: 30px;
    }


    .date {
        font-size: 30px;
        font-weight: 200;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 2px;

        &.open {
            background-color: ${Colors.white};
            font-size: 25px;
            padding: 10px;
            border: 1px solid ${Colors.primary()}
        }
    }

    .anchor {
        position: absolute;
    }

    .next, .previous {
        color: ${Colors.primary()};
        cursor: pointer;
        font-size: 22px;

        &:active {
            color: ${Colors.secondary()};
        }
    }

    .calendar {
        font-size: 20px;
        margin-left: ${Padding.large};
    }

    .previous {
        margin: 0 ${Padding.small} 0 ${Padding.medium};
    }

    .divider {
        margin-left: ${Padding.medium};
        display: block;
        height: 20px;
        background-color: ${Colors.text};
        width: 1px;

    }

    @media (max-width: 600px) {
        .date {
            font-size: 16px;
            flex: 1;

            &.open {
                font-size: 16px;
                border: none;
                padding: 0;
            }
        }
        .calendar {
            font-size: 14px;
            margin-left: auto;
        }

        .anchor {
            left: 0;
            right: 0;
        }

        background-color: ${Colors.white};
        flex: 1;
        border: 1px solid ${Colors.grey};
        height: 45px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        border-radius: 4px;

        & > div {
            width: 100%;
        }

        position: relative;
    }
`;
