import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface SetRegistrationFlowChildNotFoundActionOptions {
    childNotFound: boolean;
}

export const setRegistrationFlowChildNotFound = (data: SetRegistrationFlowChildNotFoundActionOptions): SetRegistrationFlowChildNotFoundAction => {
    return {
        type: ActionTypes.SetRegistrationFlowChildNotFound,
        ...data
    };
};

export type SetRegistrationFlowChildNotFoundAction = Action & SetRegistrationFlowChildNotFoundActionOptions;
export const isSetRegistrationFlowChildNotFoundAction = (action: Action): action is SetRegistrationFlowChildNotFoundAction => action.type === ActionTypes.SetRegistrationFlowChildNotFound;
