import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { setUser, SetUserActionOptions } from "@redux/actions/auth/setUser";
import { Action } from "@redux/actions/action";
import { createRefreshLoginMutation, RefreshLoginMutationResult } from "@graphql/hocs/refreshLoginMutation";
import { TokenHandler } from "@components/tokenHandler/tokenHandler";

export interface TokenHandlerContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: TokenHandlerContainerProps) => ({
    user: state.auth.user
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setUser(data: SetUserActionOptions) { dispatch(setUser(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withRefreshLoginMutation = createRefreshLoginMutation();

export const TokenHandlerContainer = compose<React.ComponentClass<TokenHandlerContainerProps>>(
    withRefreshLoginMutation,
    withRedux
)(TokenHandler);

export type TokenHandlerProps =
    TokenHandlerContainerProps &
    RefreshLoginMutationResult &
    WithReduxDispatchProps &
    WithReduxStateProps;
