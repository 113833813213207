import * as React from "react";
import { autobind } from "core-decorators";
import { RegistrationChildMissingPageProps } from "@pages/registrationChildMissingPage/registrationChildMissingPageContainer";
import { RegistrationChildMissingPageStyle } from "@pages/registrationChildMissingPage/registrationChildMissingPageStyle";
import { FormattedMessage } from "react-intl";
import { Button } from "@style/button";
import { Link } from "react-router-dom";
import { Code } from "@pages/confirmRegistrationPage/confirmRegistrationPageStyle";

export interface RegistrationChildMissingPageState {

}

@autobind
export class RegistrationChildMissingPage extends React.Component<RegistrationChildMissingPageProps, RegistrationChildMissingPageState> {

    public render() {

        const { child, code, retry, entryType } = this.props;

        if (child) {

            return (
                <RegistrationChildMissingPageStyle type={entryType} >
                    <img src={require("@assets/images/search.svg")} alt="search" />
                    <h1>{child.firstName} {child.lastName}</h1>
                    <FormattedMessage tagName="h2" id="registrationChildMissingPage.notKnownYet" />
                    <FormattedMessage tagName="h3" id="registrationChildMissingPage.askAssistance" values={{ firstName: child.firstName }} />

                    <Button className="signIn"><FormattedMessage id="registrationChildMissingPage.signIn" /></Button>
                    <span className="retry" onClick={retry}><FormattedMessage id="registrationChildMissingPage.tryOther" /></span>
                </RegistrationChildMissingPageStyle>
            );
        }

        return (
            <RegistrationChildMissingPageStyle>
                <FormattedMessage tagName="h1" id="registrationChildMissingPage.codeNotFound" />
                <Code>

                    {[...code].map((char, i) => <span className="char" key={i}>{char}</span>)}
                </Code>
                <FormattedMessage tagName="h3" id="registrationChildMissingPage.askAnEmployee" />

                <Button onClick={retry}><FormattedMessage id="registrationChildMissingPage.retry" /></Button>

            </RegistrationChildMissingPageStyle>

        );

    }
}
