import { Form, Icon as AntIcon, Input, message } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { debounce } from "@utils/debounce";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Colors } from "@style/colors";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { LayoutContainer } from "@components/layout/layoutContainer";

import { ManualSyncFormProps } from "./manualSyncFormContainer";
import { ManualSyncFormStyle } from "./manualSyncFormStyle";
import { UploadsByDaycareContainer } from "./uploadsByDaycare/uploadsByDaycareContainer";

export interface ManualSyncFormState {
    selectedUploads: number[];
    month: number;
    year: number;
    loading: boolean;
    searchValue: string;
}

@autobind
export class ManualSyncForm extends React.Component<ManualSyncFormProps, ManualSyncFormState> {

    public state: ManualSyncFormState = {
        selectedUploads: [],
        month: 0,
        year: 2019,
        loading: false,
        searchValue: ""
    };

    private debouncedSearch: (caseNumber: string) => void = debounce(this.setCaseNumber, 300);

    public render() {

        const { intl, validDaycareLoading, validDaycare } = this.props;
        const suffix = validDaycareLoading ? <AntIcon style={{ color: Colors.primary() }} type="loading" /> : validDaycare ? <Icon style={{ color: Colors.primary() }} type="checkmark" /> : undefined;

        const { searchValue } = this.state;

        return (
            <LayoutContainer>
                <ManualSyncFormStyle>
                    <InnerContainer>

                        <h1><FormattedMessage id="manualSync.title" /></h1>
                        <Box>
                            <Form>
                                <Form.Item key={"inputformitem"} label={intl.formatMessage({ id: "manualSync.caseNumber" })}>
                                    <div className="search-with-icon">
                                    <Input value={searchValue} key="input" onChange={this.onCasenumberChange} size="large" suffix={suffix} />
                                    { searchValue === "" || searchValue === undefined ? null : <Icon type="cross" onClick={this.onClickClear} className="remove-btn" />}
                                    </div>
                                </Form.Item>

                                <div className="result">
                                    {validDaycareLoading && <FormattedMessage id="daycareLoginForm.searching" />}
                                    {!validDaycareLoading && validDaycare && `${validDaycare.name} (${validDaycare.address})`}
                                </div>

                                { validDaycare &&  <UploadsByDaycareContainer daycareId={validDaycare.id} onChange={this.saveSelectedUploads} />}

                                <div className="bottomBar">
                                    <Button loading={this.state.loading} disabled={!validDaycare || this.state.selectedUploads.length === 0} htmlType="submit" onClick={this.submitForm} type="primary"><FormattedMessage id="manualSyncForm.continue" /></Button>
                                </div>
                            </Form>
                        </Box>
                    </InnerContainer>
                </ManualSyncFormStyle>
            </LayoutContainer>
        );
    }

    private onClickClear() {
        this.setState({
            searchValue: ""
        });

        this.debouncedSearch("");

    }

    private saveSelectedUploads(selected: number[]) {
        this.setState({
            selectedUploads: selected
        });
    }

    private onCasenumberChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchValue: ev.target.value
        });

        if (ev.target.value) {
            this.debouncedSearch(ev.target.value);
        }
    }

    private setCaseNumber(val: string) {
        this.props.setLocalState({
            caseNumberSearch: val
        });

    }

    private async submitForm(e: React.FormEvent | React.MouseEvent) {
        e.preventDefault();

        const { caseNumberSearch, updateUploadStatus } = this.props;
        const { selectedUploads } = this.state;

        this.setState({
            loading: true
        });

        const response = await updateUploadStatus({
            input: {
                ids: selectedUploads
            }
        });

        this.setState({
            loading: false
        });

        if (response) {
            // trigger http sync
            // THEN...
            message.success("De sync werd succesvol getriggered.");

            console.log("GELUKT!");
        } else {
            message.error("Er ging iets mis.");
        }
        // change all records for certain caseNumber & month in uploads to PENDING
        // manual SYNC
        // if ok: success message
        // if loading: button loading
        // if failed: fail message

    }
}
