import { Form, Input } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { ErrorCode, parseError } from "@utils/parseError";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { Button } from "@style/button";
import { Box } from "@style/box";
import { SetPasswordPageStyle } from "@pages/setPasswordPage/setPasswordPageStyle";
import { SetPasswordPageProps } from "@pages/setPasswordPage/setPasswordPageContainer";
import { Alert } from "@components/alert/alert";

export interface SetPasswordPageState {
    submitting: boolean;
    submitError: string | null;

}

@autobind
export class SetPasswordPage extends React.Component<SetPasswordPageProps, SetPasswordPageState> {
    public state: SetPasswordPageState = {
        submitError: null,
        submitting: false

    };

    public render() {
        const { form, intl } = this.props;
        const { submitError, submitting } = this.state;

        return (
            <SetPasswordPageStyle>
                <InnerContainer>
                    <FormattedMessage tagName="h1" id="setPasswordPage.title" />
                    <FormattedMessage tagName="h2" id="setPasswordPage.subTitle" />
                </InnerContainer>

                <InnerContainer>
                    <Box>
                        <Form onSubmit={this.onSubmit}>
                            <Form.Item label={intl.formatMessage({ id: "setPasswordPage.password" })}>
                                {form.getFieldDecorator("password", {
                                    rules: [
                                        { required: true, message: intl.formatMessage({ id: "setPasswordPage.passwordRequired" }) }

                                    ],
                                    validateTrigger: "onSubmit"
                                })(
                                    <Input type="password" placeholder={intl.formatMessage({ id: "setPasswordPage.password" })} />
                                )}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage({ id: "setPasswordPage.passwordRepeat" })}>
                                {form.getFieldDecorator("passwordRepeat", {
                                    rules: [
                                        { required: true, message: intl.formatMessage({ id: "setPasswordPage.passwordRequired" }) },
                                        { validator: this.validateRepeatPassword }

                                    ],
                                    validateTrigger: "onSubmit"
                                })(
                                    <Input type="password" placeholder={intl.formatMessage({ id: "setPasswordPage.passwordRepeat" })} />
                                )}
                            </Form.Item>
                            {submitError && <Alert type="error"><FormattedMessage id={submitError} /></Alert>}

                            <Button loading={submitting} htmlType="submit" onClick={this.onSubmit} type="primary"><FormattedMessage id="setPasswordPage.submit" /></Button>
                        </Form>
                        <div className="bottomBar">
                            <Link className="back" to="/login"><Icon type="back" /><FormattedMessage id="setPasswordPage.back" /></Link>
                        </div>
                    </Box>
                </InnerContainer>
            </SetPasswordPageStyle>

        );
    }

    private validateRepeatPassword(rule: any, value: any, callback: (err?: string) => void, source?: any, options?: any) {
        const { form, intl } = this.props;
        const pwd = form.getFieldValue("password");

        if (pwd !== value) {
            callback(intl.formatMessage({ id: "setPasswordPage.passwordsDontMatch" }));
        } else {
            callback();
        }
    }

    private get token() {
        const { search } = this.props.location;

        if (search.match(/\?role=(parent|organiser|daycare|daycare_direct)&token=.*$/) || search.match(/\?role=(parent|organiser|daycare|daycare_direct)&&token=.*$/)) {
            return search.replace(/\?role=(parent|organiser|daycare|daycare_direct)&token=/, "").replace(/\?role=(parent|organiser|daycare|daycare_direct)&&token=/, "");
        }

        return null;
    }

    private get role() {
        const { search } = this.props.location;

        if (search.match(/\?role=(parent|organiser|daycare|daycare_direct)&token=.*$/) || search.match(/\?role=(parent|organiser|daycare|daycare_direct)&&token=.*$/)) {
            return search.replace(/\?role=/, "").replace(/&token=.*$/, "").replace(/&&token=.*$/, "");
        }

        return null;
    }

    private onSubmit(e: React.MouseEvent | React.FormEvent) {
        e.preventDefault();

        const { form, setPassword, history } = this.props;

        form.validateFields(async (errors, values) => {
            this.setState({
                submitError: null
            });

            if (!this.token) {
                this.setState({
                    submitError: "error.noValidTokenPresent"
                });

                return;
            }
            if (!errors) {
                this.setState({
                    submitting: true
                });

                try {
                    const result = await setPassword({
                        password: values.password,
                        token: this.token
                    });

                    if (result) {
                        history.push(`/login/${this.role}/success`);
                    } else {
                        this.setState({
                            submitting: false,
                            submitError: ErrorCode.UNKNOWN
                        });
                    }
                } catch (err) {
                    console.error(err);
                    this.setState({
                        submitting: false,
                        submitError: parseError(err)
                    });
                }
            }
        });
    }
}
