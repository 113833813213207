import * as React from "react";
import { compose, Dispatch } from "redux";

import { ConfirmRegistrationPage } from "@pages/confirmRegistrationPage/confirmRegistrationPage";
import { EntryType } from "@models/graphql/types";
import { AddEntryMutationResult, createAddEntryMutation } from "@graphql/hocs/addEntry";
import { withRouter, RouteComponentProps } from "react-router";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { connect } from "react-redux";
import { setRegistrationFlowRegistrationResult, SetRegistrationFlowRegistrationResultActionOptions } from "@redux/actions/setRegistrationFlowRegistrationResult";
import { setRegistrationFlowChildNotFound, SetRegistrationFlowChildNotFoundActionOptions } from "@redux/actions/setRegistrationFlowChildNotFound";
import { SetRegistrationFlowCodeActionOptions, setRegistrationFlowCode } from "@redux/actions/setRegistrationFlowCode";

export interface ConfirmRegistrationPageContainerProps {

}

export const mapStateToProps = (state: StoreState, ownProps: ConfirmRegistrationPageContainerProps) => ({
    code: state.registrationFlow.code,
    entryType: state.registrationFlow.entryType,
    registrationResult: state.registrationFlow.registrationResult,
    childNotFound: state.registrationFlow.childNotFound
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setRegistrationResult(data: SetRegistrationFlowRegistrationResultActionOptions) { dispatch(setRegistrationFlowRegistrationResult(data)); },
    setCode(data: SetRegistrationFlowCodeActionOptions) { dispatch(setRegistrationFlowCode(data)); },
    setChildNotFound(data: SetRegistrationFlowChildNotFoundActionOptions) { dispatch(setRegistrationFlowChildNotFound(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withAddEntryMutation = createAddEntryMutation();

export const ConfirmRegistrationPageContainer = compose<React.ComponentClass<ConfirmRegistrationPageContainerProps>>(
    withRedux,
    withAddEntryMutation,
    withRouter,
    injectIntl
)(ConfirmRegistrationPage);

export type ConfirmRegistrationPageProps =
    ConfirmRegistrationPageContainerProps &
    RouteComponentProps &
    InjectedIntlProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    AddEntryMutationResult;
