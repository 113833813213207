import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { DateSelector } from "@components/registrations/dateSelector/dateSelector";
import { SetRegistrationSelectedDateActionOptions, setRegistrationSelectedDate } from "@redux/actions/setRegistrationSelectedDate";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { ExportSelector } from "./exportSelector";
import { SetRegistrationAggregationActionOptions, setRegistrationAggregation } from "@redux/actions/setRegistrationAggregation";
import { createGetChildrenRegistrationListQuery } from "@graphql/hocs/getChildrenRegistrationList";
import { start } from "repl";

export interface ExportSelectorContainerProps {
}

export const mapStateToProps = (state: StoreState, ownProps: ExportSelectorContainerProps) => ({
    aggregation: state.registration.aggregation,
    startDate: state.registration.startDate,
    selectedDaycareId: state.daycare.selectedDaycareId,
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setAggregation(opts: SetRegistrationAggregationActionOptions) { dispatch(setRegistrationAggregation(opts)); },
    setDate(data: SetRegistrationSelectedDateActionOptions) { dispatch(setRegistrationSelectedDate(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

// get all of month registrations + child

export const ExportSelectorContainer = compose<React.ComponentClass<ExportSelectorContainerProps>>(
    withRedux,
    injectIntl
)(ExportSelector);

export type ExportSelectorProps =
    ExportSelectorContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    InjectedIntlProps;
