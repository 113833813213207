import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface UpdateDaycareOverviewSearchActionOptions {
    search: string | null;
}

export const updateDaycareOverviewSearch = (data: UpdateDaycareOverviewSearchActionOptions): UpdateDaycareOverviewSearchAction => {
    return {
        type: ActionTypes.UpdateDaycareOverviewSearch,
        ...data
    };
};

export type UpdateDaycareOverviewSearchAction = Action & UpdateDaycareOverviewSearchActionOptions;
export const isUpdateDaycareOverviewSearchAction = (action: Action): action is UpdateDaycareOverviewSearchAction => action.type === ActionTypes.UpdateDaycareOverviewSearch;
