import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Action, compose, Dispatch } from "redux";

import { createLocalState, LocalStateProps } from "@utils/withLocalState";
import { StoreState } from "@redux/reducers/root";
import {
    setRegistrationFlowEntryType,
    SetRegistrationFlowEntryTypeActionOptions
} from "@redux/actions/setRegistrationFlowEntryType";
import { setRegistrationFlowCode, SetRegistrationFlowCodeActionOptions } from "@redux/actions/setRegistrationFlowCode";
import { ManualChildEntryPage } from "@pages/manualChildEntryPage/manualChildEntryPage";
import { createGetChildrenNamesQuery, GetChildrenNamesQueryResult } from "@graphql/hocs/getChildrenNames";

export interface ManualChildEntryPageContainerProps {

}

interface LocalState {
    childSearch: string;
}

export const mapStateToProps = (state: StoreState, ownProps: ManualChildEntryPageContainerProps) => ({
    entryType: state.registrationFlow.entryType
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setEntryType(data: SetRegistrationFlowEntryTypeActionOptions) { dispatch(setRegistrationFlowEntryType(data)); },
    setCode(data: SetRegistrationFlowCodeActionOptions) { dispatch(setRegistrationFlowCode(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withLocalState = createLocalState<LocalState>({
    childSearch: ""
});

const withGetChildrenNames = createGetChildrenNamesQuery<LocalStateProps<LocalState>>({
    options(ownProps) {
        return {
            variables: {
                filter: {
                    search: ownProps.childSearch,
                    isActive: true
                }
            }
        };
    }
});

export const ManualChildEntryPageContainer = compose<React.ComponentClass<ManualChildEntryPageContainerProps>>(
    withLocalState,
    injectIntl,
    withGetChildrenNames,
    withRedux,
    withRouter
)(ManualChildEntryPage);

export type ManualChildEntryPageProps =
    ManualChildEntryPageContainerProps &
    LocalStateProps<LocalState> &
    InjectedIntlProps &
    GetChildrenNamesQueryResult &
    WithReduxStateProps &
    WithReduxDispatchProps &
    RouteComponentProps;
