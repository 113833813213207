import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";
import { DaycareFilterInput } from "@models/graphql/types";

export interface UpdateDaycareFilterActionOptions {
    filter: DaycareFilterInput;
}

export const updateDaycareFilter = (data: UpdateDaycareFilterActionOptions): UpdateDaycareFilterAction => {
    return {
        type: ActionTypes.UpdateDaycareFilter,
        ...data
    };
};

export type UpdateDaycareFilterAction = Action & UpdateDaycareFilterActionOptions;
export const isUpdateDaycareFilterAction = (action: Action): action is UpdateDaycareFilterAction => action.type === ActionTypes.UpdateDaycareFilter;
