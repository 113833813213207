import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface UpdateDaycareLoginActionOptions {
    daycareLogin: {
        caseNumber: string;
        redirectPath: string;
    } | null;
}

export const updateDaycareLogin = (data: UpdateDaycareLoginActionOptions): UpdateDaycareLoginAction => {
    return {
        type: ActionTypes.UpdateDaycareLogin,
        ...data
    };
};

export type UpdateDaycareLoginAction = Action & UpdateDaycareLoginActionOptions;
export const isUpdateDaycareLoginAction = (action: Action): action is UpdateDaycareLoginAction => action.type === ActionTypes.UpdateDaycareLogin;
