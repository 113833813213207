import { OperationOption } from "react-apollo";

export const addRefetchQueries = (opOptions: OperationOption<any, any, any, any> | undefined, refetchQueries: string[], awaitRefetchQueries: boolean = true) => {
    let options = opOptions;

    if (options) {

        options.options = {
            ...options.options,
            refetchQueries,
            awaitRefetchQueries
        };

    } else {
        options = {
            options: {
                refetchQueries,
                awaitRefetchQueries
            }
        };
    }

    return options;
};
