import { autobind } from "core-decorators";
import * as React from "react";

import { queueLink } from "./client";
import { OperationQueueEntry, ProcessQueueArgs } from "./queueLink";

const INTIAL_QUEUE_STATE: QueueLinkState = {
    mutationsPending: queueLink.opQueue,
    isOpen: queueLink.isOpen,
    initializeLink: queueLink.initializeLink,
    processQueue: queueLink.processQueue,
    processing: queueLink.processing,
    removeOperation: queueLink.removeOperation,
    open: queueLink.open,
    close: queueLink.close,
    initialized: queueLink.initialized,
    completed: queueLink.completed,
    purgeCompleted: queueLink.purgeCompleted
};

const QueueLinkContext = React.createContext<QueueLinkState>(INTIAL_QUEUE_STATE);

export const QueueLinkConsumer = QueueLinkContext.Consumer;

export interface QueueLinkState {
    mutationsPending: OperationQueueEntry[];
    isOpen: boolean;
    initialized: boolean;
    processing: boolean;
    completed: boolean;
    processQueue(args: ProcessQueueArgs): void;
    initializeLink(): void;
    removeOperation(operationId: string): void;
    purgeCompleted(): void;
    close(): void;
    open(): void;
}

@autobind
export class QueueLinkProvider extends React.Component<{}, QueueLinkState> {
    public state: QueueLinkState = INTIAL_QUEUE_STATE;

    public componentWillMount() {
        queueLink.setListener(this.onQueue);
    }

    public render() {
        return (
            <QueueLinkContext.Provider value={{
                ...this.state
            }}>
                {this.props.children}
            </QueueLinkContext.Provider>
        );
    }

    private onQueue(state) {
        this.setState({ ...state });
    }
}
