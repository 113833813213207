import * as classNames from "classnames";
import { autobind } from "core-decorators";
import * as React from "react";

import { Placeholder } from "@style/placeholder";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { SubHeaderStyle } from "@components/subHeader/subHeaderStyle";
import { SubHeaderProps } from "@components/subHeader/subHeaderContainer";

export interface SubHeaderState {

}

@autobind
export class SubHeader extends React.Component<SubHeaderProps, SubHeaderState> {

    public render() {

        const { title, children, showBackButton, titleLoading } = this.props;

        return (
            <SubHeaderStyle className={classNames({ withBackButton: showBackButton })}>
                <InnerContainer>
                    <div onClick={this.goBack} className="left">
                        {this.renderBackButton()}

                        <span className="info">
                            {titleLoading ? <Placeholder /> : title}
                        </span>
                    </div>

                    <div className="right">
                        {!titleLoading && children}
                    </div>

                </InnerContainer>
            </SubHeaderStyle>
        );
    }

    private goBack() {
        const { history, showBackButton, backPath } = this.props;

        if (showBackButton) {
            if (backPath) {
                history.push(backPath);
            } else {
                history.goBack();
            }
        }
    }

    private renderBackButton() {
        const { showBackButton } = this.props;

        if (showBackButton) {
            return (

                <Icon type="back" />

            );
        }
    }

}
