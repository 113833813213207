import styled from "styled-components";
import { Padding } from "@style/padding";

export const MoreActionsButtonStyle = styled.div`
    padding: 0 0 0 ${Padding.medium};
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
        font-size: 8px;
        margin-left: 8px;
    }
`;
