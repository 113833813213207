import styled from "styled-components";

import { Padding } from "@style/padding";
import { AlertStyle } from "@components/alert/alertStyle";
import { ChildSearchStyle } from "@components/addChild/childSearch/childSearchStyle";

export const ChangePinCodePageStyle = styled(ChildSearchStyle)`
    padding: 0;
    form {
        flex-direction: column;
    }

    ${AlertStyle}{
        &.info {
            margin: 0 0 ${Padding.medium} 0;
        }
    }

    .ant-form-item:not(:first-of-type) {
        margin-top: 20px;
    }


    .buttons {
        width: 100%;
        & > button {
            width: calc(50% - 7.5px);
            height: 50px;

            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
`;
