import styled from "styled-components";
import { InnerContainer } from "@style/innerContainer";
import { Padding } from "@style/padding";
import { SubHeaderStyle } from "@components/subHeader/subHeaderStyle";
import { ChildDetailRegistrationsListStyle } from "@components/childDetail/childDetailRegistrationsList/childDetailRegistrationsListStyle";
import { ChildDetailsFormStyle } from "@components/childDetail/childDetailsForm/childDetailsFormStyle";

export const ChildDetailPageStyle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    & > ${InnerContainer} {
        display: flex;
        padding-top: ${Padding.large};
        padding-bottom: ${Padding.large};
        flex: 1;
        align-items: flex-start;

        ${ChildDetailRegistrationsListStyle} {
            margin-left: ${Padding.medium};
        }
    }

    @media (max-width: 960px) {
        & > ${InnerContainer} {
            flex-wrap: wrap;
            ${ChildDetailsFormStyle} {
                width: 100%;
                flex-basis: initial;
                margin-top: ${Padding.medium};
                margin-bottom: ${Padding.medium};
                order: 2;
            }

            ${ChildDetailRegistrationsListStyle} {
                width: 100%;
                margin: 0;
                order: 1;
            }
        }

    }
`;
