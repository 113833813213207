import styled from "styled-components";

export const InnerContainer = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 50px;

    @media (max-width: 700px) {
        padding: 0 25px;
    }

    @media (max-width: 450px) {
        padding: 0 15px;
    }
`;
