import { Checkbox } from "antd";
import { autobind } from "core-decorators";
import * as moment from "moment";
import * as React from "react";

import { Box } from "@style/box";

import { UploadsByDaycareProps } from "./uploadsByDaycareContainer";
import { UploadsByDaycareStyle } from "./uploadsByDaycareStyle";

export interface UploadsByDaycareState {
}

@autobind
export class UploadsByDaycare extends React.Component<UploadsByDaycareProps, UploadsByDaycareState> {

    public state: UploadsByDaycareState = {

    };

    public render() {
        return (
            <UploadsByDaycareStyle>
                    <Box>
                        <h2>Gevonden uploads</h2>
                        <Checkbox.Group onChange={this.props.onChange}>
                            { this.renderUploads() }
                        </Checkbox.Group>
                    </Box>
            </UploadsByDaycareStyle>
        );
    }

    private renderUploads() {
        const { uploads } = this.props;

        if (uploads) {
            return uploads.map(upload => {
                return (
                <div>
                    <Checkbox value={upload.id}>
                        { upload.id } - { moment(upload.startOfMonth).format("MMMM YYYY") } - { upload.status }
                    </Checkbox>
                </div>);
            });
        }
    }
}
