import { GetChildDetailQuery, GetChildDetailVariables, GetChildDetailChildren } from "@models/graphql/types";
import { graphql, OperationOption } from "react-apollo";
import { GraphQL } from "@graphql/graphQL";

export interface GetChildDetailQueryResult {
    child: GetChildDetailChildren | null;
    childLoading: boolean;
}

export const createGetChildDetailQuery = <TProps = {}>(options?: OperationOption<TProps, GetChildDetailQuery, GetChildDetailVariables, GetChildDetailQueryResult>) =>
    graphql<TProps, GetChildDetailQuery, GetChildDetailVariables, GetChildDetailQueryResult>(GraphQL.getChildDetail, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                child: data.children && data.children.length ? data.children[0] : null,
                childLoading: data.loading
            };
        },
        ...options
    });
