import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { DaycareChildrenListContainer } from "@components/daycareChildrenList/daycareChildrenListContainer";
import { DaycareSearchResultsStyle } from "@components/daycare/daycareSearchResults/daycareSearchResultsStyle";
import { DaycareSearchResultsProps } from "@components/daycare/daycareSearchResults/daycareSearchResultsContainer";
import {
    DaycareSearchResultListContainer
} from "@components/daycare/daycareSearchResultList/daycareSearchResultListContainer";

export interface DaycareSearchResultsState {

}

@autobind
export class DaycareSearchResults extends React.Component<DaycareSearchResultsProps, DaycareSearchResultsState> {

    public render() {

        const { } = this.props;

        return (
            <DaycareSearchResultsStyle>
                <div className="title">
                    <FormattedMessage tagName="h1" id="daycareSearchResults.searchResulst" />
                    <span onClick={this.onCancel}>

                        <FormattedMessage id="daycareSearchResults.cancel" />
                    </span>
                </div>

                <div className="results">
                    <DaycareSearchResultListContainer />
                    <DaycareChildrenListContainer />
                </div>
            </DaycareSearchResultsStyle>
        );
    }

    private onCancel() {
        this.props.changeSearch({ search: null });
    }
}
