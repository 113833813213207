import { Reducer } from "redux";

import { isSetRegistrationFlowRegistrationResultAction } from "@redux/actions/setRegistrationFlowRegistrationResult";
import { isSetRegistrationFlowManualEntryAction } from "@redux/actions/setRegistrationFlowManualEntry";
import { isSetRegistrationFlowEntryTypeAction } from "@redux/actions/setRegistrationFlowEntryType";
import { isSetRegistrationFlowCodeAction } from "@redux/actions/setRegistrationFlowCode";
import { isSetRegistrationFlowChildNotFoundAction } from "@redux/actions/setRegistrationFlowChildNotFound";
import { Action } from "@redux/actions/action";
import { AddEntryRegistration, EntryType } from "@models/graphql/types";

const INITIAL_STATE: RegistrationFlowReducerState = {
    code: null,
    registrationResult: null,
    entryType: EntryType.IN,
    childNotFound: false,
    manualEntry: false
};

export interface RegistrationFlowReducerState {
    code: string | null;
    registrationResult: AddEntryRegistration | null;
    entryType: EntryType;
    childNotFound: boolean;
    manualEntry: boolean;
}

export const registrationFlowReducer: Reducer<RegistrationFlowReducerState> = (state: RegistrationFlowReducerState = INITIAL_STATE, action: Action): RegistrationFlowReducerState => {

    if (isSetRegistrationFlowCodeAction(action)) {
        return {
            ...state,
            code: action.code
        };
    }

    if (isSetRegistrationFlowEntryTypeAction(action)) {
        return {
            ...state,
            entryType: action.entryType
        };
    }

    if (isSetRegistrationFlowRegistrationResultAction(action)) {
        return {
            ...state,
            registrationResult: action.registrationResult || null
        };
    }

    if (isSetRegistrationFlowChildNotFoundAction(action)) {
        return {
            ...state,
            childNotFound: action.childNotFound
        };
    }

    if (isSetRegistrationFlowManualEntryAction(action)) {
        return {
            ...state,
            childNotFound: action.manualEntry
        };
    }

    return state;
};
