import { message, Popconfirm } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";

import { parseError } from "@utils/parseError";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { ChildDetailPageStyle } from "@pages/childDetailPage/childDetailPageStyle";
import { ChildDetailPageProps } from "@pages/childDetailPage/childDetailPageContainer";
import { SubHeaderContainer } from "@components/subHeader/subHeaderContainer";
import { QrCodeViewerContainer } from "@components/qrCodeViewer/qrCodeViewerContainer";
import { MoreActionsButtonContainer } from "@components/moreActionsButton/moreActionsButtonContainer";
import { ChildDetailsFormContainer } from "@components/childDetail/childDetailsForm/childDetailsFormContainer";
import {
    ChildDetailRegistrationsListContainer
} from "@components/childDetail/childDetailRegistrationsList/childDetailRegistrationsListContainer";

export interface ChildDetailPageState {
    showQr: boolean;

}

@autobind
export class ChildDetailPage extends React.Component<ChildDetailPageProps, ChildDetailPageState> {
    public state: ChildDetailPageState = {
        showQr: false
    };

    public render() {

        const { child, childLoading, match } = this.props;
        const { showQr } = this.state;

        return (
            <ChildDetailPageStyle >

                <SubHeaderContainer title={child ? `${child.firstName} ${child.lastName}` : ""} titleLoading={childLoading} showBackButton={true} >
                    {showQr ?

                        <span onClick={this.hideQrCode}><FormattedMessage id="childDetailPage.close" /></span>
                        :
                        <>
                            <MediaQuery minWidth={600}>

                                <Popconfirm okText="Verstuur!" cancelText="Annuleer" onConfirm={this.sendQrCode} placement="bottomRight" title="Ben je zeker dat je de QR-code wil versturen naar alle contactpersonen?">
                                    <span >
                                        <FormattedMessage id="childDetailPage.sendQR" />
                                    </span>
                                </Popconfirm>

                                <span className="divider" />
                                <span onClick={this.showQrCode}>
                                    <FormattedMessage id="childDetailPage.showQR" />
                                </span>
                            </MediaQuery>
                            <MediaQuery maxWidth={599}>
                                <MoreActionsButtonContainer>
                                    <>
                                        <Popconfirm okText="Verstuur!" cancelText="Annuleer" onConfirm={this.sendQrCode} placement="bottomRight" title="Ben je zeker dat je de QR-code wil versturen naar alle contactpersonen?">
                                            <span className="item"><FormattedMessage id="childDetailPage.sendQR" /><Icon type="chevron-right" /></span>
                                        </Popconfirm>
                                        <span onClick={this.showQrCode} className="item"><FormattedMessage id="childDetailPage.showQR" /><Icon type="chevron-right" /></span>
                                    </>
                                </MoreActionsButtonContainer>
                            </MediaQuery>
                        </>
                    }
                </SubHeaderContainer>

                {showQr ?
                    <QrCodeViewerContainer code={child ? child.code : ""} />
                    :

                    <InnerContainer>
                        <ChildDetailsFormContainer childId={parseInt(match.params.childId, 10)} />
                        <ChildDetailRegistrationsListContainer childId={parseInt(match.params.childId, 10)} />
                    </InnerContainer>

                }
            </ChildDetailPageStyle>

        );
    }

    private showQrCode() {
        this.setState({ showQr: true });
    }

    private hideQrCode() {
        this.setState({ showQr: false });
    }

    private async sendQrCode() {
        const { sendQrCode, match, child } = this.props;
        if (!child) {
            throw new Error("Child not loaded yet!");
        }

        if (!child.contacts.length) {
            return message.info("Dit kind heeft nog geen contacten, voeg er eerst toe.");
        }

        try {
            await sendQrCode({
                childId: parseInt(match.params.childId, 10)
            });

            message.success("De QR code is succesvol verstuurd naar de contacten.");

        } catch (err) {
            message.error(parseError(err));
            console.log(err);
        }
    }
}
