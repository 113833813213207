import * as moment from "moment";
import { Reducer } from "redux";

import { isUpdateRegistrationsFilterAction } from "@redux/actions/updateRegistrationsFilter";
import { isUpdateEditRegistrationIdAction } from "@redux/actions/updateEditRegistrationId";
import { isUpdateCreateRegistrationAction } from "@redux/actions/updateCreateRegistration";
import { isSetRegistrationSelectedDateAction } from "@redux/actions/setRegistrationSelectedDate";
import { isSetRegistrationAggregationAction } from "@redux/actions/setRegistrationAggregation";
import { Action } from "@redux/actions/action";
import { Aggregation, ChildFilterInput, GetChildrenRegistrationListRegistration } from "@models/graphql/types";

moment.locale("nl");

const INITIAL_STATE: RegistrationReducerState = {
    startDate: moment().startOf("week"),
    endDate: moment().endOf("week"),
    aggregation: Aggregation.DAY,
    childrenFilter: {
        registrationsFrom: moment().startOf("week").format("YYYY-MM-DD"),
        registrationsTo: moment().endOf("week").format("YYYY-MM-DD")
    },
    editRegistration: null,
    createRegistration: null
};

export interface RegistrationReducerState {
    startDate: moment.Moment;
    endDate: moment.Moment;
    aggregation: Aggregation;
    childrenFilter: ChildFilterInput;
    editRegistration: {
        registration: GetChildrenRegistrationListRegistration;
        date: string;
        childId: number;
        daycareId?: string;
    } | null;
    createRegistration: {
        childId: number;
        date: string;
    } | null;
}

export const registrationReducer: Reducer<RegistrationReducerState> = (state: RegistrationReducerState = INITIAL_STATE, action: Action): RegistrationReducerState => {
    if (isSetRegistrationSelectedDateAction(action)) {
        return {
            ...state,
            startDate: action.startDate.clone().startOf("day"),
            endDate: action.endDate.clone().endOf("day"),
            childrenFilter: {
                ...state.childrenFilter,
                registrationsFrom: action.startDate.clone().startOf("day").format("YYYY-MM-DD"),
                registrationsTo: action.endDate.clone().endOf("day").format("YYYY-MM-DD")
            }
        };
    }

    if (isSetRegistrationAggregationAction(action)) {

        return {
            ...state,
            aggregation: action.aggregation
        };
    }

    if (isUpdateCreateRegistrationAction(action)) {
        return {
            ...state,
            createRegistration: action.create
        };
    }

    if (isUpdateEditRegistrationIdAction(action)) {
        return {
            ...state,
            editRegistration: action.edit
        };
    }

    if (isUpdateRegistrationsFilterAction(action)) {

        if (action.filter.registrationsFrom || action.filter.registrationsTo) {
            console.warn("Set date via setRegistrationSelectedDateAction");
        }

        return {
            ...state,
            childrenFilter: {
                ...state.childrenFilter,
                ...action.filter
            }
        };
    }

    return state;
};
