import * as jwtDecode from "jwt-decode";
import * as moment from "moment";

import { User } from "@utils/user";
import { STORE } from "@redux/setupStore";
import { setUser } from "@redux/actions/auth/setUser";
import { LoginLogin } from "@models/graphql/types";
import { cacheInstance } from "@graphql/client";

export type LoginResponse = SuccessResponse | ErrorResponse;

export interface SuccessResponse {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    "not-before-policy": number;
    session_state: string;
    scope: string;
}

export interface ErrorResponse {
    error: string;
    error_description: string;
}

export interface TokenData {
    jti: string;
    exp: number;
    nbf: number;
    iat: number;
    iss: string;
    aud: string;
    sub: string;
    typ: string;
    azp: string;
    auth_time: number;
    session_state: string;
    acr: string;
    "allowed-origins": any[];
    resource_access: ResourceAccess;
    scope: string;
    email_verified: boolean;
    preferred_username: string;
    realm_access: RealmAccess;
    organiser: Organiser;
    multiOrganiser?: Organiser[];
    daycareName: string;
    given_name?: string;
    family_name?: string;
    address: string;
    email: string;
}

export interface RefreshTokenData {
    jti: string;
    exp: number;
    nbf: number;
    iat: number;
    iss: string;
    aud: string;
    sub: string;
    typ: string;
    azp: string;
    auth_time: number;
    session_state: string;
    realm_access: RealmAccess;
    resource_access: ResourceAccess;
    scope: string;
}

export interface Organiser {
    name: string;
    id: string;
}

export interface RealmAccess {
    roles: string[];
}

export interface ResourceAccess {
    account: RealmAccess;
    app: RealmAccess;
}

export class AuthService {

    public static get acccessToken(): string | null {
        return localStorage.getItem("token");
    }

    public static set acccessToken(token: string | null) {
        if (token) {
            localStorage.setItem("token", token);
        }
    }

    public static get refreshTokenData(): RefreshTokenData | null {
        if (this.refreshToken) {
            return jwtDecode<RefreshTokenData>(this.refreshToken);
        }

        return null;
    }

    public static get refreshToken(): string | null {
        return localStorage.getItem("refresh_token");
    }

    public static set refreshToken(token: string | null) {
        if (token) {
            localStorage.setItem("refresh_token", token);
        }
    }

    public static isRefreshTokenValid(): boolean {
        const refreshToken = this.refreshToken;

        if (!refreshToken) {
            return false;
        }

        const data = jwtDecode<TokenData>(refreshToken);
        const expiration = moment.unix(data.exp);

        return expiration.isAfter(moment().subtract(1, "minute"));
    }

    public static get isAuthorized(): boolean {
        return localStorage.getItem("authorized") ? true : false;
    }

    public static set isAuthorized(val: boolean) {
        if (val) {
            localStorage.setItem("authorized", "true");
        } else {
            localStorage.removeItem("authorized");
        }
    }

    public static set redirectRoute(route: string | null) {
        if (route) {
            localStorage.setItem("redirectRoute", route);
        } else {
            localStorage.removeItem("redirectRoute");
        }
    }

    public static get redirectRoute(): string | null {
        return localStorage.getItem("redirectRoute");
    }

    public static login(loginResult: LoginLogin): User {
        this.acccessToken = loginResult.accessToken;
        this.refreshToken = loginResult.refreshToken;
        this.isAuthorized = true;

        const user = this.getUser();
        if (!user) {
            throw new Error("No user");
        }

        STORE.dispatch(setUser({ user }));

        return user;
    }

    public static async oAuthLogin(token: string) {
        try {
            localStorage.removeItem("token");
            localStorage.removeItem("selectedDaycareId");
            localStorage.removeItem("authorized");

            this.acccessToken = token;
            this.isAuthorized = true;
            const user = this.getUser();
            if (!user) {
                throw new Error("No user");
            }

            STORE.dispatch(setUser({ user }));

            return user;

        } catch (err) {
            throw err;
        }
    }

    public static logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("selectedDaycareId");
        localStorage.removeItem("authorized");
        localStorage.removeItem("refresh_token");
        cacheInstance.clear();

        window.location.replace("/");
    }

    public static getUser(): User | null {
        const token = this.acccessToken;

        if (token) {

            return new User(token);
        }

        return null;
    }

}
