import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose, Dispatch } from "redux";

import { StoreState } from "@redux/reducers/root";
import {
    updateDaycareSelectedDaycareId,
    UpdateDaycareSelectedDaycareIdActionOptions
} from "@redux/actions/updateDaycareSelectedDaycareId";
import { Action } from "@redux/actions/action";
import { createGetChildrenNamesQuery, GetChildrenNamesQueryResult } from "@graphql/hocs/getChildrenNames";
import { ChildrenSearchResultList } from "@components/daycare/childrenSearchResultList/childrenSearchResultList";

export interface ChildrenSearchResultListContainerProps {
    daycareId: string;
}

export const mapStateToProps = (state: StoreState, ownProps: ChildrenSearchResultListContainerProps) => ({
    searchValue: state.daycare.overviewSearch,
    organisationId: state.auth.organisationId
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setDaycare(data: UpdateDaycareSelectedDaycareIdActionOptions) { dispatch(updateDaycareSelectedDaycareId(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

const withGetChildrenNames = createGetChildrenNamesQuery<ChildrenSearchResultListContainerProps & WithReduxStateProps>({
    options(ownProps) {
        return {
            variables: {
                filter: {
                    search: ownProps.searchValue,
                    daycareId: ownProps.daycareId
                }
            }
        };
    },
    skip(ownProps) {
        if (ownProps.searchValue) {
            return false;
        }

        return true;
    }
});

export const ChildrenSearchResultListContainer = compose<React.ComponentClass<ChildrenSearchResultListContainerProps>>(
    withRedux,
    withRouter,
    withGetChildrenNames
)(ChildrenSearchResultList);

export type ChildrenSearchResultListProps =
    ChildrenSearchResultListContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    RouteComponentProps &
    GetChildrenNamesQueryResult;
